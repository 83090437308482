<div class="row" *ngIf="isShowPaginator"  >
  <div class="col-xl-12">
    <!--begin:: Components/Pagination/Default-->
    <div class="">

      <!--begin: Pagination-->
      <div class="kt-pagination kt-pagination--brand">
        <div class="kt-pagination__toolbar">
          <select class="form-control kt-font-brand" style="width: 60px" (change)="handlePageSizeChange($event.target.value)">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <span class="pagination__desc">
            {{'PAGINATOR.SHOWING'| translate}} {{ getShowing() }} {{'PAGINATOR.TO'| translate}} {{ lastItemInpage }} {{'PAGINATOR.OF'| translate}} {{ page.totalItemCount }}
            {{'PAGINATOR.ITEMS'| translate}}.
          </span>
        </div>
        <ul class="kt-pagination__links">

          <li [ngClass]="{'datatable__pager-link--disabled': page.pageNumber==1}" class="kt-pagination__link--first"
            (click)="setPagination('first', page.pageNumber, page.pageCount)">
            <a href="javascript:;"><i class="fa fa-angle-double-left kt-font-brand"></i></a></li>

          <li [ngClass]="{'datatable__pager-link--disabled': page.pageNumber==1}" class="kt-pagination__link--next"
            (click)="setPagination('prev', page.pageNumber, page.pageCount)">
            <a href="javascript:;"><i class="fa fa-angle-left kt-font-brand"></i></a>

          <li [ngClass]="{'kt-pagination__link--active': pgp.active}" (click)="goPageObj(pgp)"
            *ngFor="let pgp of pageList">
            <a href="javascript:;">{{pgp.num }}</a></li>

          <li [ngClass]="{'datatable__pager-link--disabled': page.pageNumber==page.pageCount}"
            class="kt-pagination__link--prev" (click)="setPagination('next', page.pageNumber, page.pageCount)">
            <a href="javascript:;"><i class="fa fa-angle-right kt-font-brand"></i></a>
          </li>

          <li [ngClass]="{'datatable__pager-link--disabled': page.pageNumber==page.pageCount}"
            class="kt-pagination__link--last" (click)="setPagination('last', page.pageNumber, page.pageCount)">
            <a href="javascript:;"><i class="fa fa-angle-double-right kt-font-brand"></i></a>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</div>
