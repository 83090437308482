import { Component, OnInit, ComponentRef, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { LoaderTaskInformationComponent } from '../loader-task-information/loader-task-information.component';
import { SharedTaskWorkflowComponent } from '../../../shared-task-workflow/shared-task-workflow.component';
import { SwitchTabComponent } from 'src/app/shared/component/switch-tab.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { LoaderTaskService } from 'src/app/core/services/_sprint3/loader/task.service';
import { UpdateSettingWorkareaEdc } from 'src/app/core/view-models/_sprint3/_setting/task-model';
import { LoaderViewTaskInformationComponent } from '../loader-view-task-information/loader-view-task-information.component';

@Component({
  selector: 'app-loader-view-modal0work-area',
  templateUrl: './loader-view-modal0work-area.component.html',
  styleUrls: ['./loader-view-modal0work-area.component.scss']
})
export class LoaderViewModal0workAreaComponent implements OnInit {
  id:number
  feature: number;
  componentRef: ComponentRef<any>; 
  @ViewChild(SwitchTabDirective, { static: true }) switchTab: SwitchTabDirective;
  item: UpdateSettingWorkareaEdc = new UpdateSettingWorkareaEdc;
  constructor(
    private activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,  
    private service: LoaderTaskService,
    private dialogService: MyDialogService
  ) { }

  async ngOnInit() {
    this.focusTabDetailType(1);
  }

  async focusTabDetailType(type: number) {
    this.feature = type;
    this.setComponentTab();
  }
  setComponentTab() {
    if (this.feature == 1) this.loadComponent(LoaderViewTaskInformationComponent);
    if (this.feature == 2) this.loadComponent(SharedTaskWorkflowComponent); 
  }

  loadComponent(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>this.componentRef.instance)
    this.componentRef.instance.id = this.id;
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
