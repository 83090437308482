<div class="modal-header">
    <h5 class="modal-title">{{ '_SHARED.HISTORY' | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>

<div class="modal-body ">
    <div class="table-responsive">
        <table class="table table-striped- table-bordered table-hover table-checkable  " id="kt_table_1">
            <thead>
                <tr align="center">
                    <th width="5%" nowrap=""> {{ '_SHARED.NO' | translate}}</th>
                    <th width=" " nowrap=""> ชื่อช่าง</th>
                    <th width=" " nowrap=""> ชื่อผู้เปลี่ยนช่าง</th>
                    <th width="10%" nowrap=""> วันที่ / เวลา</th>
                </tr>
            </thead>
            <tbody>

                <tr *ngFor="let item of items;index as i;">
                    <td align="center">{{i+1}}</td>
                    <td align="left" nowrap="">{{item.technicianName}}</td>
                    <td align="left" nowrap="">{{item.changeName}}</td>
                    <td align="center" nowrap="">{{item.dateTime | date: 'dd/MM/yyyy HH:mm'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-dark waves-effect" (click)="activeModal.dismiss()">{{ '_SHARED.CLOSE' |
        translate }}</button>
</div>