    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    {{ '_SHARED.INFORMATION' | translate }}
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-form">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{ '_SHARED.S_N' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{information.serialNumber}} </span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{ '_SHARED.MODEL' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{information.modelName}} </span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{ '_SHARED.INSTALL_DATE' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{information?.installDate | date:'dd-MM-yyyy'}} </span>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{ '_SHARED.START_PROMOTION_DATE' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{information?.startDate | date:'dd-MM-yyyy' }} </span> <br/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{ '_SHARED.END_PROMOTION_DATE' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{information?.endDate | date:'dd-MM-yyyy'}} </span> <br/>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{ '_SHARED.STAND_ALONE_TID' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{information.standAlondTID}}</span> 
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    