<div class="modal-header">
  <h4 class="modal-title">{{'ORDER.SHOP.LOCATIONS' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="onClose()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body ">

  <app-related-edc-view-shared [id]="id" [worksheetId]="worksheetId"></app-related-edc-view-shared>

  <ng-container [formGroup]="localtionValidate">
    <kt-expansion-panel>
      <kt-expansion-panel-header>
        {{'ORDER.SHOP.LOCATIONS' | translate}}
      </kt-expansion-panel-header>
      <form class="kt-form">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">
                  {{'ORDER.SHOP.SHOP_NAME_EN' | translate}}:</label>

                <span class="kt-font-view">{{item?.shopNameEN}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">
                  {{'ORDER.SHOP.SHOP_NAME_TH' | translate}}:</label>
                <span class="kt-font-view">{{item?.shopNameTH}}</span>
              </div>
            </div>


            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary"> {{'ORDER.SHOP.ADDRESS' | translate}}:</label>
                <span class="kt-font-view">{{item?.shopAddress}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="form-control-label kt-font-primary">
                  {{'ORDER.SHOP.PROVINCE' | translate}}: </label>
                <span class="kt-font-view">{{item.province?.nameTH}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="form-control-label kt-font-primary">
                  {{'ORDER.SHOP.DISTRICT' | translate}}:</label>
                <span class="kt-font-view">{{item.district?.nameTH}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="form-control-label kt-font-primary">
                  {{'ORDER.SHOP.SUB_DISTRICT' | translate}}: </label>
                <span class="kt-font-view">{{item.subdistrict?.nameTH}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.POSCODE' | translate}}:</label>

                <span class="kt-font-view">{{item.zipCode}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.LATITUDE' | translate}}:</label>

                <span class="kt-font-view">{{item.latitude}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.LOCATION' | translate}}: </label>
                <!-- <span class="kt-font-view">{{item.longitude}}
                                    <a class="btn btn-primary" href="javascript:;" (click)="OpenModalMap()"> <i
                                        class="fa fa-map-marked-alt text-white"></i> </a>
                                </span>   -->

              </div>
            </div>


            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">
                  {{'ORDER.SHOP.CONTACT_PHONE_NUMBER' | translate}}:</label>

                <span class="kt-font-view">{{item.contactPhoneNumber}}</span>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.CONTACT_FAX_NUMBER' | translate}}:</label>

                <span class="kt-font-view">{{item.contactFaxNumber}}</span>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHARED.EMAIL'|translate}}:</label>
                <span class="kt-font-view">{{item.contactEmail}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.CONTACT_NAME' | translate}}:</label>
                <span class="kt-font-view">{{item.contactName}}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.LOCATION' | translate}}:</label>
                <span class="kt-font-view">{{item.location}}</span>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.REG' | translate}}:</label>
                <span class="kt-font-view">{{item.reg}}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.ORDER' | translate}}:</label>
                <span class="kt-font-view" [innerHTML]="item.command | keepHtml"> </span>

              </div>
            </div>
            <div class="col-sm-4">
              <div class="form-group mb-0">
                <label class="kt-font-primary">{{'ORDER.SHOP.REMARK' | translate}}:</label>
                <span class="kt-font-view" [innerHTML]="item.remark | keepHtml"> </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group mb-0">
                    <label class="kt-font-primary">
                      {{'_SHARED.DATE_AND_TIME_CONVENIENT_FOR_CUSTOMER' | translate }}:</label>
                    <span class="kt-font-view">{{item.clientConvenientDate  | date: 'dd/MM/yyyy' }}
                      {{item.clientConvenientHour}}:{{item.clientConvenientMiute}}</span>
                  </div>
                </div>
                <!-- <div class="col-sm-3">
                                    <div class="form-group mb-0">
                                        <label> ชั่วโมง:</label>
                                        <span class="kt-font-view" >{{item.clientConvenientHour}} </span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="form-group mb-0">
                                        <label> นาที: </label>
                                        <span class="kt-font-view" >{{item.clientConvenientMiute}} </span>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </form>

    </kt-expansion-panel>
    <kt-expansion-panel>
      <kt-expansion-panel-header [canExpand]="false">
        <h3 class="kt-portlet__head-title"> {{'_SHARED.COMMAND' | translate }} </h3>
      </kt-expansion-panel-header>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group"><label>{{'_SHARED.ISSUES' | translate }}</label><textarea rows="5" readonly
                [placeholder]="'_SHARED.ISSUES' | translate" class="form-control">{{item.issue}}</textarea>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group"><label>{{'_SHARED.WORK_COMMAND' | translate }}</label><textarea rows="5"
            [placeholder]="'_SHARED.WORK_COMMAND' | translate" readonly
              class="form-control">{{item.workCommand}}</textarea></div>
        </div>

      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group"><label>{{'_SHARED.RECOMMEND' | translate }}</label><textarea rows="5"
            placeholder="{{'_SHARED.RECOMMEND' | translate }}" readonly
              class="form-control">{{item.recommend}}</textarea></div>
        </div>

      </div>
    </kt-expansion-panel>
  </ng-container>
</div>

<div class="modal-footer">
  <div class="col-lg-12 text-right">
    <button type="button" class="btn btn-dark waves-effect"
      (click)="onClose()">{{'ORDER.SHOP.CLOSE' | translate}}</button></div>
</div>
