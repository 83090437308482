import { Component, ViewChild, Input, ComponentRef, ComponentFactoryResolver, Type, OnInit } from '@angular/core';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { SharedUserReadonlyCommunicationDetailComponent } from '../../../shared-user/edc-view/shared-readonly-communication-detail/shared-readonly-communication-detail.component';
import { SharedUserReadonlyOrderDetailInformationComponent } from '../../../shared-user/edc-view/shared-readonly-order-detail-information/shared-readonly-order-detail-information.component';
import { SharedUserReadonlyFeatureConnectorComponent } from '../../../shared-user/edc-view/shared-readonly-feature-connector/shared-readonly-feature-connector.component';
import { SharedUserReadonlyShopDetailComponent } from '../../../shared-user/edc-view/shared-readonly-shop-detail/shared-readonly-shop-detail.component';
import { OrderEdcBankHostViewModel, FeatureConnectorTypeViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { OrderEdcViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { OrderReprogramViewModel } from 'src/app/core/view-models/_order/order-reprogram.view-model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { OrderShareService } from 'src/app/core/services/order/order-share.service';
import { statusConst } from 'src/app/core/_static/status.const';
import { ShopViewModel } from 'src/app/core/view-models/_shop/shop.view-model';
import { orderConst } from 'src/app/core/_static/order.const';
import { SharedUserReadonlySingleShopComponent } from '../../../shared-user/edc-view/shared-readonly-single-shop/shared-readonly-single-shop.component';
import { SharedUserReadonlyMulthiShopComponent } from '../../../shared-user/edc-view/shared-readonly-multhi-shop/shared-readonly-multhi-shop.component';
import { SwitchTabComponent } from 'src/app/shared/component/switch-tab.component';
import { TransformOrderService } from 'src/app/core/models-mapper/transform.order';

@Component({
  selector: 'app-serial-number-info',
  templateUrl: './serial-number-info.component.html',
  styleUrls: ['./serial-number-info.component.scss']
})
export class SerialNumberInfoComponent implements OnInit {
  @ViewChild(SwitchTabDirective, { static: true }) switchTab: SwitchTabDirective;
  @ViewChild('communication', { static: false }) communication: SharedUserReadonlyCommunicationDetailComponent;
  @ViewChild('information', { static: false }) information: SharedUserReadonlyOrderDetailInformationComponent;
  @ViewChild('connector', { static: false }) connector: SharedUserReadonlyFeatureConnectorComponent;
  @ViewChild('shop', { static: false }) shop: SharedUserReadonlyShopDetailComponent;
  edcId:number;

  isValid(): boolean {
    throw new Error("Method not implemented.");
  }
  submit: boolean;
  data:number;

  bankHosts: OrderEdcBankHostViewModel[] = [];
  connectorTypes: FeatureConnectorTypeViewModel[] = [];
  item: OrderEdcViewModel = new OrderEdcViewModel();
  head: OrderReprogramViewModel = new OrderReprogramViewModel();
  componentRef: ComponentRef<any>;
  detailType: number;

  constructor( 
    public activeModal:NgbActiveModal,  
    private componentFactoryResolver: ComponentFactoryResolver,
    private actRoute: ActivatedRoute,
    private dialogService: MyDialogService,
    private orderSharedService: OrderShareService, 
    private _transformOrder: TransformOrderService,
  ) {
    
   }

  ngOnInit(): void {
   this.loadInitial();
  }
  get createState() {
    return statusConst.createState;
  }
  async loadInitial() {
    console.log(this.edcId);
    this.item = await this.orderSharedService.getEdc(this.edcId);
    // this.item = await this.orderSharedService.getEdc(this.)

    this.communication.setInitial(this.item.communicationBase);
    this.information.setInitial(this.item.information);
    this.shop.setInitial(this.item.shop);
    this.connector.setInitial(this.item.feature ,this.item.connectors.map(x => this._transformOrder.transformOrderConnectorModel(x)));

    this.focusTabDetailType(this.orderDetailType.single);
  
  }

  async onSaveShopCallback(item: ShopViewModel) {
    // const result = await this._headService.saveShop(this.orderId, item);
    // if (result) {
    //   this.dialogService.successProceed();
    //   this.item.shop.id = result.id;
    //   this.item.shop.code = result.code;
    // }
  }
  focusTabDetailType(type: number) {
    this.detailType = type;
    this.setComponentTab();
  }

  get orderDetailType() {
    return orderConst.orderDetailType;
  }
  
  setComponentTab() {
    if (this.detailType == this.orderDetailType.single) this.loadSingleComponent(SharedUserReadonlySingleShopComponent);
    if (this.detailType == this.orderDetailType.multhi) this.loadMulthiComponent(SharedUserReadonlyMulthiShopComponent);
  }

  async loadSingleComponent(component: Type<any>) {

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>this.componentRef.instance).data = this.item.partitions;
    this.componentRef.instance.bankHosts = this.bankHosts;
  }

  async loadMulthiComponent(component: Type<any>) {
  
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>this.componentRef.instance).data = this.item.multhi;
    this.componentRef.instance.bankHosts = this.bankHosts;
    this.componentRef.instance.eventType = this.item.information.eventType;
    this.componentRef.instance.createState = this.createState.install;
  }

  
}
