import { Component, OnInit, Input } from "@angular/core";
import { ChangeSerialNumberViewModel, CurrentStatus} from "src/app/core/view-models/_sprint3/_shared/change-serial-number-view-model";
import { ChangeSerialNumberService } from "src/app/core/services/_sprint3/_shared/change-serial-number.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MyDialogService } from "src/app/shared/utils/my-dialog";
import { Current } from 'src/app/core/models/_sprint3/change-serial-number-api-view-model';
import { CommonService } from 'src/app/core/services/common.service';
import { UpdateStatus } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';

@Component({
  selector: "app-current-change-serial-number",
  templateUrl: "./current-change-serial-number.component.html",
  styleUrls: ["./current-change-serial-number.component.scss"],
})
export class CurrentChangeSerialNumberComponent implements OnInit {
  current: Current = new Current();
  // changeToStatus: ChangeToStatus[]=[];
  form: FormGroup;
  submit: boolean;
  @Input() id:number;
  updateStatus: CurrentStatus[]=[]
  constructor(
    private formBuilder: FormBuilder,
    private dialogService: MyDialogService,
    private changeSerialNumberService: ChangeSerialNumberService,
    private commoneService: CommonService,
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({ 
      // name: ['', Validators.required], 
      changeToStatusId:['']
    
    }); 
    // this.loadDataList();
  }
  public async setInitial(item:Current){
    this.current = item;
    this.form.patchValue({
      changeToStatusId: this.current?.changeToStatus?.id, 
   });  
   this.updateStatus = await this.commoneService.getEdcList();

  }

  onchangeToStatusSelect() {
    // this.pageRequest.criteria.sourceId = this.form.value.sourceId;
    // this.loadDataList();
  }

  get f() {
    return this.form.controls;
  }
  public isValid(submit: boolean) {
    this.submit = submit;

    if (this.submit && !this.form.valid) {
      this.dialogService.warningValidate();
      return false;
    }
    this.current.changeToStatus = this.updateStatus.find(x => x.id == this.form.value.changeToStatusId);
    return true;
  }
}
