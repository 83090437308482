<body style="background-image: url('assets/img/BG_JOBORDER-2.jpg')"
    lass="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--enabled kt-subheader--transparent kt-page--loading">

    <!-- begin:: Page -->
    <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
        <div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v2 kt-login--signin" id="kt_login">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                    <div class="kt-login__container">
                        <div class="kt-login__logo">
                            <a href="#">
                                <img src="assets/img/JOBSORDER_LOGO.png" class="img-responsive">
                            </a>
                        </div>
                        <div class="kt-login__signin">
                            <div class="kt-login__head">
                                
                                <h3 class="kt-login__title">{{ 'LOGIN.AUTHENTICATION_SYSTEM' | translate}}</h3>
                                <h5 class="kt-login__desc">{{ 'LOGIN.FOR_INTERNAL_USERS' | translate}} </h5>
                            </div>
                            <form class="kt-form" id="loginform" [formGroup]="authForm" (ngSubmit)="submitForm()">

                                <div class="input-group">
                                    <input class="form-control" type="text" [placeholder]="'LOGIN.USERNAME' | translate" name="Username"
                                    formControlName="username" notThaiChar [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
                                      englishOnly  autocomplete="off">
                                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                            <div *ngIf="f.username.errors.required">{{ 'LOGIN.PLEASE_INPUT_USERNAME' | translate}} </div>
                                        </div>
                                </div>
                                <div class="input-group">
                                    <input class="form-control" type="password" [placeholder]="'LOGIN.PASSWORD' | translate" name="password"
                                    notThaiChar formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">{{ 'LOGIN.PLEASE_INPUT_PASSWORD' | translate}}</div>
                                    </div>
                                </div>

                                <div class="kt-login__actions">
                                    <button class="btn btn-pill kt-login__btn-primary btn-login" type="submit">{{ 'LOGIN.LOGIN' | translate}}</button>
                                </div>
                            </form>

                        </div>
                        <div class="kt-login__account">
                            <span style="color: white;">
                                Copyright <span><i class="fa fa-copyright"></i></span> 2020 POSNET. All Rights Reserved.
                            </span>&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>