import { Injectable } from '@angular/core';
import { ChangeSerialNumberApiViewModel } from 'src/app/core/models/_sprint3/change-serial-number-api-view-model';
import { ChangeSerialNumberViewModel } from 'src/app/core/view-models/_sprint3/_shared/change-serial-number-view-model';
import { Mapper } from '../../mapper';

@Injectable({
    providedIn: 'root'
  })
export class ChangeSerialNumberMapper implements Mapper<ChangeSerialNumberApiViewModel,ChangeSerialNumberViewModel>{
    mapForm(param: ChangeSerialNumberApiViewModel): ChangeSerialNumberViewModel {
        return{
            ...param
        }
    }
    mapTo(param: ChangeSerialNumberViewModel): ChangeSerialNumberApiViewModel {
        throw new Error("Method not implemented.");
    }
}
