import { Information, WorkType, State } from '../packing/task-model';
import { FormStatus } from '../store/work-area-edc.model';

export class StoreTaskInformationViewModel {
  froms: State[];
  command: Status;
  state: Status;
  status: Status;
  workType: WorkType;
  subType: Status;
  nextTos: State[];
  edcId: number;
  serialNumber: string;
  materialTypeName: string;
  materialCode: string;
  tid: string;
  worksheetId: number;
  worksheetNo: string;
  startDateTime: Date;
  sapMaterialId: number;
  endDateTime: Date;
  modelName: string;
  box: string;
  location: string;
  actualDateTime: Date;
  taskOwnerName: string;
  taskResponsibleName: string; 
  quantity: string; 
  measure: string; 
  requestRemark: string;

}

export class Status {
  id: number;
  name: string;
}
export class StoreTaskInfoViewModel {
  authrorize: Authrorize;
  info: StoreTaskInformationViewModel;
  currentStatus: Status;
  updateStatus: UpdateStatus;
  remark: string;
  reject: Reject
  isNoMachine:boolean
  boxNumber:string;
  receiveInventoryStatus:Status
}
export class Reject {
  hasReject: boolean;
  rejectByName: string;
  rejectDateTime: string;
  remark: string;
}
export class Authrorize {
  canSwitch: boolean;
  canReset: boolean;
  canSave: boolean;
  canSubmit: boolean;
  canTakeNewEdc: boolean;
  canTakeNewConnector: boolean;
  canTakeNewSim: boolean;
}
export class UpdateStatus {
  id: number;
  code: string;
  name: string;
}

export class UpdateStoreWorkareaEdc {
  newEdcId: number;
  hasTakeNewEdc:boolean;
  updateStatusId: number = 300;
  remark: string = '';
  isNoMachine:boolean
  boxNumber:string = '';
  receiveInventoryStatusId: number;
}
export class UpdateStoreWorkareaConnector { 
  newConnectorId: number;
  hasTakeNewConnector:boolean; 
  updateStatusId: number = 300;
  remark: string = '';
}

export class SimRequestModel {
  remark: string = '';
  updateStatusId: number = 1;
}
export class StoreTaskInfomationViewModel {
  information: Information = new Information
  currentStatus: Status;
  updateStatus: UpdateStatus = new UpdateStatus;
  remark: string;
}

export class UpdateStoreWorkareaSim {
  newSimInventoryId: number;
  hasTakeNewSim:boolean;
  updateStatusId: number = 300;
  remark: string = '';
  
}