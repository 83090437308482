<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.SHARED.FEATURE/CONNECTOR'|translate}}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-form">
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group mb-0">
                        <span>{{getFeature()}}</span>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group mb-0 pt-2">

                        <div class="row">
                            <div class="col-sm-12">
                                <label class="kt-font-primary">{{'ORDER.SHARED.PANTANCATE'|translate}} : </label>
                                <span> {{feature.pantancateFormat1?.name}} - {{feature.pantancateFormat2?.name}} -
                                    {{feature.pantancateFormat3?.name}} - {{feature.pantancateFormat4?.name}}</span> &nbsp;
                                <label class="kt-font-primary">{{'ORDER.SHARED.OTHER'|translate}} : </label>
                                <span> {{feature.pantancateFormatOther}}</span>
                                <br />
                                <label class="kt-font-primary">{{'ORDER.SHARED.PANTANCATE2'|translate}} : </label>
                                <span> {{feature.pantancateFormat5?.name}} - {{feature.pantancateFormat6?.name}} -
                                    {{feature.pantancateFormat7?.name}} - {{feature.pantancateFormat8?.name}}</span> &nbsp;
                                <label class="kt-font-primary">{{'ORDER.SHARED.OTHER'|translate}} : </label>
                                <span> {{feature.pantancateFormatOther2}}</span>
                                <!-- <label class="kt-font-primary">{{'ORDER.SHARED.CONNECTOR_TYPE'|translate}} : </label>
                                <span>{{feature.connectorType.name}}</span>
                                <br />
                                <label class="kt-font-primary">{{'ORDER.SHARED.SERIAL_NUMBER'|translate}} :
                                </label><span>{{feature.serialNumber}}</span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">
          {{'ORDER.SHARED.CONNECTORS'|translate}}
        </h3>
      </div>
    </div>
    <div class="kt-portlet__body">
      <div class="kt-form">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table class="table table-striped- table-bordered table-hover table-checkable">
              <thead>
                <tr align="center">
                  <th width="2%" nowrap="">{{'ORDER.SHARED.NO' | translate}}</th>
                  <th width="20%" nowrap="">{{'ORDER.SHARED.CONNECTOR_TYPE' | translate}}</th>
                  <th width="15%" nowrap="">{{'ORDER.SHARED.SERIAL_NUMBER' | translate}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of feature.connectorItems;let i = index;">
                  <td align="center">{{i + 1}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.serialNumber}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>