import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';

@Component({
  selector: 'app-filter-tpl',
  templateUrl: './filter-tpl.component.html',
  styleUrls: ['./filter-tpl.component.scss']
})
export class FilterTPLComponent implements OnInit {
  itemSearch: FilterListModal[] = [];
  colSearchModel: SearchFilter = new SearchFilter;
  toggle = true;
  onSelected: Subject<FilterListModal[]> = new Subject<FilterListModal[]>();
  constructor(
    public activeModalClose: NgbActiveModal,
    private dialogService: MyDialogService) { }

  ngOnInit(): void {
    this.onSelect();
  }
  confirmSave() {
    // this.dialogService.confirmSaveData(() => { 
    this.onSelected.next(this.itemSearch.filter(x => x.isCheck == true));
    this.onClose();
    // });
  }

  onClose() {
    this.activeModalClose.dismiss();
  }
  onSelectAll() {
    this.toggle = !this.toggle;
    this.itemSearch.forEach(f => f.isCheck = this.toggle);
  }
  onSelect() {
    this.itemSearch.filter(f => {
      if(!f.isCheck){
        f.isCheck = false;
      }
    })
    console.log(this.itemSearch.filter(f => !f.isCheck));
    console.log(this.itemSearch);
    if (this.itemSearch.filter(f => f.isCheck == false).length > 0) {
      this.toggle = false;
    } else {
      this.toggle = true;
    }
  }
}

export class FilterListModal {
  isCheck: boolean = true
  id: number;
  code: string;
  name: string;
}
export class FilterTeleportModel {
  id: number;
  name: string;
  contactName: string;
  phoneNumber: string;
  address: string
}
export class SearchFilter {
  name: string;
}
  
export class ColumnsListModal { 
  isChecked: boolean =true; 
  name:string;
  sortName:string;

}