import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LoaderService } from 'src/app/core/http/loader.service';

@Component({
  selector: 'app-loader-screen',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @ViewChild('loaderScreen', {static: true}) loaderScreen: ElementRef;
  constructor(
    
  ) { }

  ngOnInit(): void {
    
  }

}
