import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper';
import { AdminBKKWorkAreaModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { WorksheetCloseJobViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-list-share.view-model';
import { WorksheetCloseJobApisModel, TechnicianFormCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { until } from 'protractor';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { MechanicModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';

@Injectable({
    providedIn: "root",
})
export class AdminBKKClosejobMechanicMapper implements Mapper<TechnicianFormCloseJobApis, MechanicModel> {
    mapForm(param: TechnicianFormCloseJobApis): MechanicModel {
        const item = new MechanicModel();
        item.detail = param.detail;
        item.attachFiles  = param.attachFiles
        item.attachFile = param.attachFile;
        item.startJobDate = DateUtils.toDate(param.startJobDate) ;
        item.startJobHour = param.startJobHour;
        item.startJobMinute = param.startJobMinute;
        item.finishJobDate = DateUtils.toDate(param.finishJobDate);
        item.finishJobHour = param.finishJobHour;
        item.finishJobMinute = param.finishJobMinute;

        item.actualOperationDate = DateUtils.toDate(param.actualOperationDate);
        item.actualOperationHour = param.actualOperationHour;
        item.actualOperationMinute = param.actualOperationMinute;
 

        return item;
    }
    mapTo(param: MechanicModel): TechnicianFormCloseJobApis {
        return null
    }
}
