import { Injectable } from '@angular/core';
import { CallHistoryModel, CallHistoryApiModel, SearchCallHistoryModel } from '../../models/user/help-desk/call-history-model';
import { ApiService } from '../../http/api.service';
import { CallHistoryModelMapper } from '../../models-mapper/help-desk/call-history.mapper';
import { PageRequestModel, PageResponseModel } from '../../models/_shared/page.model';

@Injectable({
  providedIn: "root",
})
export class CallHistoryService {
  constructor(
    private apiService: ApiService,
    private callHistoryMapper: CallHistoryModelMapper) { }


  //   async search(id: number,pageRequest: PageRequestModel<CallHistoryModel>): Promise<CallHistoryModel[]> {
  //     const result = await this.apiService.patch<CallHistoryModel[]>(`/inbounds/call-history/${id}`, pageRequest);
  //     return result.map(x=>this.callHistoryMapper.mapForm(x));
  // }
  // async search(id: number,pageRequest: PageRequestModel<SearchCallHistoryCriteriaModel>) : Promise<CallHistoryModel>{
  //   const result = await this.apiService.post<CallHistoryModel>(`/inbounds/call-history/${id}`,pageRequest);
  //   return result.map(x=>this.callHistoryMapper.mapForm(x));
  // }

  async search(id: number, request: PageRequestModel<SearchCallHistoryModel>): Promise<PageResponseModel<CallHistoryApiModel>> {
    const dataResponse: PageResponseModel<CallHistoryModel> = new PageResponseModel<CallHistoryModel>();
    const result = await this.apiService.post<PageResponseModel<CallHistoryApiModel>>(`/inbounds/call-history/${id}`, request);
    dataResponse.pageCount = result.pageCount;
    dataResponse.pageNumber = result.pageNumber;
    dataResponse.pageSize = result.pageSize;
    dataResponse.totalItemCount = result.totalItemCount;
    dataResponse.items = result.items.map(map => this.callHistoryMapper.mapForm(map));
    return dataResponse;
  }

  async searchBySupport(id: number, request: PageRequestModel<SearchCallHistoryModel>): Promise<PageResponseModel<CallHistoryApiModel>> {
    const dataResponse: PageResponseModel<CallHistoryModel> = new PageResponseModel<CallHistoryModel>();
    const result = await this.apiService.post<PageResponseModel<CallHistoryApiModel>>(`/support/inbounds/call-history/${id}`, request);
    dataResponse.pageCount = result.pageCount;
    dataResponse.pageNumber = result.pageNumber;
    dataResponse.pageSize = result.pageSize;
    dataResponse.totalItemCount = result.totalItemCount;
    dataResponse.items = result.items.map(map => this.callHistoryMapper.mapForm(map));
    return dataResponse;
  }
}
