import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TopicHeaderViewModel, TicketMainViewModel, TicketInboundViewModel, TicketDetailViewModel, InboundCreateModalEdcListModel, returnDetailModel, InboundBankHostViewModel, InboundBankHostCriteriaModel, BankHostItem, InboundEdcItemViewModel, statusEdcId } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { isNumeric } from 'jquery';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InboundT1Service } from 'src/app/core/services/helpDesk/inbound-t1.service';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { CallHistoryModel, Edc } from 'src/app/core/models/user/help-desk/call-history-model';

@Component({
  selector: 'app-call-history-view',
  templateUrl: './call-history-view.component.html',
  styleUrls: ['./call-history-view.component.scss']
})
export class CallHistoryViewComponent implements OnInit {
  @Input() item:Edc
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.loadInitial()
  }

  async loadInitial() {

  }
}
