import { FileModel } from './file.model';

export class UserProfileModel {
    public id: number;
    public displayName: string;
    public profilePictureFile?: FileModel;
    public email: string;
    public detailLine1: string;
    public detailLine2: string;
    public detailLine3: string;
}

export class NotificationsItemsModel {
    items:NotificationsModel[]=[]
}
export class NotificationsModel {
    entityType: EntityType
    notifyId: number;
    title: string;
    message: string;
    createdDateTime: Date
    parameters: string;
    timeAgoText: string;
    status: Status
}
export class EntityType {
    id: number;
    name: string
}
export class Status {
    kind: string;
    id: number;
    code: string;
    name: string
}