<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'_SHARED.INFORMATION' | translate }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-form">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.FORM' | translate }} : </label>
                            <ng-container *ngFor="let item of items?.froms">
                                <span class="badge badge-dark badge-custom" >{{item?.name}}</span>&nbsp;
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.COMMAND' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{items?.command?.name}}</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.STATE' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{items?.state?.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.STATUS' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{items?.status?.name}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TYPE' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{items?.workType?.name}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.SUB_TYPE' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{items?.subType?.name}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.S_N' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.serialNumber}}</span> 
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TYPE' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.workType?.name}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TID' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.tid}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.WORKSHEET_NO' | translate }}  : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.worksheetNo}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.START_DATE_TIME' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.startDateTime | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.PLACE_IN' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.endDateTime | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.NEXT_TO' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator" *ngFor="let item of items.nextTos">{{item.name}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.MODEL' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.modelName}}</span>
                            <br>
                        </div>
                    </div> 
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.BOX' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.box}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.LOCATION' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.location}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.ACTUAL_DATE_TIME' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.actualDateTime | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TASK_OWNER' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.taskOwnerName}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TASK_RESPONSIBLE' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.taskResponsibleName}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">Sap Mat Code
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.materialCode}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary"> Quantity
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.quantity}}</span>
                            <br>
                        </div>
                    </div> 
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">Measure
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.measure}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">Remark
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{items?.requestRemark}}</span>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
