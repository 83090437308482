import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { ChangeSerialNumberViewModel } from 'src/app/core/view-models/_sprint3/_shared/change-serial-number-view-model';
import { ChangeSerialNumberMapper } from 'src/app/core/models-mapper/_sprint3/change-sn/change-serial-number-mapper';
import { ChangeSerialNumberApiViewModel, UpdateChangeSerialNumberApiViewModel, UpdateChangeSerialNumberPerApiViewModel } from 'src/app/core/models/_sprint3/change-serial-number-api-view-model';
import { PageRequestModel, PageResponseModel } from 'src/app/core/models/_shared/page.model';
import { SearchStockControlEdcCriteriaViewModel, StockControlEdcApiViewModel } from 'src/app/core/models/_sprint3/stock/stock-control-edc';
import { StockControlEdcViewModel } from 'src/app/core/view-models/_sprint3/store/stock-control-edc';
import { StockControlEdcMapper } from 'src/app/core/models-mapper/_sprint3/store/stock-control-edc-mapper';
import { SearchStockControlSimViewCriteria, StockControlSimViewModel } from 'src/app/core/view-models/_sprint3/store/stock-control-sim';
import { StockControlSimApisModel } from 'src/app/core/models/_sprint3/stock/stock-control-sim';
import { StockSimListMapper } from 'src/app/core/models-mapper/_sprint3/stock/sim-list.mapper';

@Injectable({
  providedIn: 'root'
})
export class ChangeSerialNumberService {

  constructor(
    private apiService:ApiService,
    private changeSerialNumberMapper:ChangeSerialNumberMapper,
    private stockControlEdcMapper:StockControlEdcMapper,
    private _simListMapper: StockSimListMapper,
    ) { }

    async getChangeSn(id:number): Promise<ChangeSerialNumberViewModel> {
      const result = await this.apiService.get<ChangeSerialNumberApiViewModel>(`/store/work-area/edcs/${id}/change-sn`);
      return this.changeSerialNumberMapper.mapForm(result);
    }

    async saveChangeStatus(id:number,updateRequest:UpdateChangeSerialNumberApiViewModel): Promise<boolean> {
      const result = await this.apiService.patch<boolean>(`/store/work-area/edcs/${id}/change-sn`,updateRequest);
      return result
    }

    async searchEdc(id:number,pageRequest:PageRequestModel<SearchStockControlEdcCriteriaViewModel>): Promise<PageResponseModel<StockControlEdcViewModel>> {
      const dataResponse: PageResponseModel<StockControlEdcViewModel> = new PageResponseModel<StockControlEdcViewModel>();
      const result = await this.apiService.post<PageResponseModel<StockControlEdcApiViewModel>>(`/store/work-area/edcs/${id}/change-sn/search`, pageRequest);
      dataResponse.pageCount = result.pageCount;
      dataResponse.pageNumber = result.pageNumber;
      dataResponse.pageSize = result.pageSize;
      dataResponse.totalItemCount = result.totalItemCount;
      dataResponse.items = result.items.map(map => this.stockControlEdcMapper.mapForm(map));
      return dataResponse;
  
    } 
    async searchNewEdc(id:number,pageRequest:PageRequestModel<SearchStockControlEdcCriteriaViewModel>): Promise<PageResponseModel<StockControlEdcViewModel>> {
      const dataResponse: PageResponseModel<StockControlEdcViewModel> = new PageResponseModel<StockControlEdcViewModel>();
      const result = await this.apiService.post<PageResponseModel<StockControlEdcApiViewModel>>(`/store/work-area/edcs/${id}/take/search`, pageRequest);
      dataResponse.pageCount = result.pageCount;
      dataResponse.pageNumber = result.pageNumber;
      dataResponse.pageSize = result.pageSize;
      dataResponse.totalItemCount = result.totalItemCount;
      dataResponse.items = result.items.map(map => this.stockControlEdcMapper.mapForm(map));
      return dataResponse;
  
    } 
    async searchNewSim(id: number, pageRequest: PageRequestModel<SearchStockControlSimViewCriteria>): Promise<PageResponseModel<StockControlSimViewModel>> {
      const dataResponse: PageResponseModel<StockControlSimViewModel> = new PageResponseModel<StockControlSimViewModel>();
      const result = await this.apiService.post<PageResponseModel<StockControlSimApisModel>>(`/store/work-area/sims/${id}/take/search`, pageRequest);
      dataResponse.pageCount = result.pageCount;
      dataResponse.pageNumber = result.pageNumber;
      dataResponse.pageSize = result.pageSize;
      dataResponse.totalItemCount = result.totalItemCount;
      dataResponse.items = result.items.map(map => this._simListMapper.mapForm(map));
      return dataResponse;
    }
    async getChangeSnPeripheral(id:number): Promise<ChangeSerialNumberViewModel> {
      const result = await this.apiService.get<ChangeSerialNumberApiViewModel>(`/store/work-area/peripherals/${id}/change-sn`);
      return this.changeSerialNumberMapper.mapForm(result);
    }

    async saveChangeStatusPeripheral(id:number,updateRequest:UpdateChangeSerialNumberPerApiViewModel): Promise<boolean> {
      const result = await this.apiService.patch<boolean>(`/store/work-area/peripherals/${id}/change-sn`,updateRequest);
      return result
    }

    async searchPeripheral(id:number,pageRequest:PageRequestModel<SearchStockControlEdcCriteriaViewModel>): Promise<PageResponseModel<StockControlEdcViewModel>> {
      const dataResponse: PageResponseModel<StockControlEdcViewModel> = new PageResponseModel<StockControlEdcViewModel>();
      const result = await this.apiService.post<PageResponseModel<StockControlEdcApiViewModel>>(`/store/work-area/peripherals/${id}/take/search`, pageRequest);
      dataResponse.pageCount = result.pageCount;
      dataResponse.pageNumber = result.pageNumber;
      dataResponse.pageSize = result.pageSize;
      dataResponse.totalItemCount = result.totalItemCount;
      dataResponse.items = result.items.map(map => this.stockControlEdcMapper.mapForm(map));
      return dataResponse;
  
    } 

  /*  mock */ 
  // async get(): Promise<ChangeSerialNumberViewModel> {
  //   const result = ChangeSerialNumberConst as ChangeSerialNumberViewModel;
  //   return result;
  // }

  // async getChangeToStatusSelect(): Promise<ChangeToStatus[]> {
  //   const result = ChangeToStatusConst as ChangeToStatus[];
  //   return result;
  // }
}
