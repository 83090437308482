import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'filterPipe',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(value: any, args?: any[]): any {
        if (args.length > 0) {
            return value.filter(item => { 
                return args.length ? args.indexOf(item.validType) != -1 : value;
            })
        }
    }
}