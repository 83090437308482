import { Component, OnInit, Type, ComponentRef, ComponentFactoryResolver, ViewChild } from '@angular/core';
import { SwitchTabComponent } from 'src/app/shared/component/switch-tab.component';
import { SharedTaskWorkflowComponent } from '../../../shared-task-workflow/shared-task-workflow.component';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateStoreWorkareaEdc, StoreTaskInfoViewModel } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { Authrorize } from 'src/app/core/models/_sprint3/store/work-area.model';
import { SharedStoreViewTaskInformationComponent } from '../../../shared-store-view-task-information/shared-store-view-task-information.component';
import { StoreViewTaskInformationComponent } from '../store-view-task-information/store-view-task-information.component';

@Component({
  selector: 'app-store-view-modal-work-area',
  templateUrl: './store-view-modal-work-area.component.html',
  styleUrls: ['./store-view-modal-work-area.component.scss']
})
export class StoreViewModalWorkAreaComponent implements OnInit {
  id:number
  feature: number;
  componentRef: ComponentRef<any>;
  item: UpdateStoreWorkareaEdc = new UpdateStoreWorkareaEdc;
  @ViewChild(SwitchTabDirective, { static: true }) switchTab: SwitchTabDirective;
  Authrorize :Authrorize = new Authrorize;
  constructor(
    private activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  async ngOnInit() {
    this.focusTabDetailType(1);
  }

  async focusTabDetailType(type: number) {
    this.feature = type;
    this.setComponentTab();
  }
  setComponentTab() {
    if (this.feature == 1) this.loadComponent(StoreViewTaskInformationComponent);
    if (this.feature == 2) this.loadComponent(SharedTaskWorkflowComponent);
  }

  loadComponent(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>this.componentRef.instance)
    this.componentRef.instance.id = this.id;
    
  }

  closeModal() {
    this.activeModal.dismiss();
  }
}
