<kt-expansion-panel>
  <kt-expansion-panel-header [canExpand]="false">
    <h3 class="kt-portlet__head-title"> {{'_SHARED.FOR_TECHNICIAN' | translate }} </h3>
  </kt-expansion-panel-header>

  <div class="kt-form">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>{{'_SHARED.CHECK_DETAIL_REPAIR' | translate }}</label>
          <textarea rows="5" [(ngModel)]="item.detail" readonly
            placeholder="{{'_SHARED.CHECK_DETAIL_REPAIR' | translate }}" class="form-control"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label>{{'_SHARED.ATTACH_FILE' | translate }}</label>
        <a href="javascript:;" (click)="fileInputclick()" style="margin: 3px;"> <i class="fa fa-paperclip "></i></a>
        <a style="margin: 3px;">{{item.attachFiles.length}}</a>

        <!-- <label>{{'_SHARED.ATTACH_FILE' | translate }}</label>
        <input #fileInput (change)="fileEvent($event.target.files)" type="file" style="display:none"
          class="custom-file-input hover-pointer " id="inputGroupFile">
        <a href="javascript:;" style="margin: 3px;"> <i class="fa fa-paperclip "></i></a>
        <a href="javascript:;" (click)="onFileClick()">{{fileSaveData?.originalName}}</a> -->
      </div> 

      <!-- <div class="col-sm-2">
                <label>
                    <a href="javascript:;" (click)="OnGalleryClick(item)" *ngIf="canEdit">
                        <i class="fa fa-paperclip "></i>
                    </a>
                    {{'_SHARED.ATTACH_IMAGE' | translate }}
                    <a href="javascript:;" (click)="OnGalleryClick(item)">{{item?.totalGallery}}</a>
                    {{'_SHARED.IMAGES' | translate }}
                </label>  
            </div> -->
    </div>
    <div class="row">
      <div class="col-sm-12">

        <div class="form-group mb-0">
          <label>  {{'_SHARED.ACTUAL_DATE_TIME' | translate }} :</label>
          <span class="kt-subheader__breadcrumbs-separator">{{item.actualOperationDate == null? '-' : item.actualOperationDate |
            date:'dd/MM/yyyy HH:mm'}} </span>

        </div>
      </div>

      <div class="col-sm-12">

        <div class="form-group mb-0">
          <label> {{ '_SHARED.START_DATE' | translate }} :</label>
          <span class="kt-subheader__breadcrumbs-separator">{{item.startJobDate == null? '-' : item.startJobDate |
            date:'dd/MM/yyyy HH:mm'}} </span>

        </div>
      </div>
      <div class="col-sm-12">
        <div class="form-group mb-0">
          <label> {{ '_SHARED.FINISH_DATE' | translate }} : </label>
          <span class="kt-subheader__breadcrumbs-separator">{{item.finishJobDate == null? '-' : item.finishJobDate |
            date:'dd/MM/yyyy HH:mm'}} </span>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="item.pictures.length > 0">
      <div class="col-sm-12">
        <label>
          <a href="javascript:;" (click)="OnGalleryClick(item)">
            <i class="fa fa-paperclip "></i> {{'_SHARED.ATTACH_IMAGE' | translate }}
          </a>
          <!-- {{'_SHARED.IMAGES' | translate }} -->
        </label>
      </div>
    </div>

  </div>

</kt-expansion-panel>