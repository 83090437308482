<p style="padding: 50px;">
    นโยบายคุ้มครองข้อมูลส่วนบุคคล<br>
    บริษัทตระหนักและให้ความสำคัญอย่างยิ่งกับสิทธิและความเป็นส่วนตัวของเจ้าของข้อมูลส่วนบุคคล และบริษัทจะดำเนินมาตรการที่เข้มงวดในการรักษาความมั่นคงปลอดภัย การรักษาความลับตลอดจนการป้องกันมิให้มีการนำข้อมูลส่วนบุคคลไปใช้โดยมิได้รับอนุญาตจากเจ้าของข้อมูลส่วนบุคคลก่อน โดยบริษัทจะดำเนินการตามวัตถุประสงค์และความยินยอมจากเจ้าของข้อมูลส่วนบุคคลที่ให้ไว้กับบริษัทในเรื่องเกี่ยวกับ การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้น หรือ ตามที่กฎหมายกำหนดและสอดคล้องกับมาตรฐานสากล <br>
    1. ข้อมูลส่วนบุคคล คืออะไร <br>
    ข้อมูลส่วนบุคคล หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม <br>
    2. ข้อมูลส่วนบุคคลที่บริษัทเก็บรวบรวม <br>
    บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลอันเกี่ยวกับการใช้บริการในปัจจุบันและที่อาจมีเพิ่มขึ้นในอนาคตดังต่อไปนี้ <br>
    • ข้อมูลที่บ่งชี้ตัวตน เช่น ชื่อ นามสกุล วันเกิด เพศ สัญชาติ ภาพถ่าย ภาพและหมายเลขบัตรประจำตัวประชาชน ข้อมูลอุปกรณ์อิเล็กทรอนิกส์ ข้อมูลตำแหน่งที่อยู่ <br>
    • ข้อมูลติดต่อ เช่น ที่อยู่ สถานที่ติดต่อ หมายเลขโทรศัพท์ อีเมล <br>
    • ข้อมูลและพฤติกรรมการใช้บริการผลิตภัณฑ์ของบริษัทฯ <br>
    3. แหล่งที่มาของข้อมูลส่วนบุคคล<br>
    บริษัทฯ อาจได้รับข้อมูลส่วนบุคคลของท่านจากช่องทางดังนี้<br>
    • ขั้นตอนการสมัครใช้บริการ หรือขั้นตอนการยื่นคำร้องขอใช้สิทธิต่าง ๆ กับบริษัทฯ<br>
    • จากความสมัครใจของท่าน ในการทำแบบสอบถาม (Survey) หรือการโต้ตอบทางอีเมล หรือช่องทางการสื่อสารอื่น ๆ ระหว่างบริษัทฯ และท่าน<br>
    4. สิทธิของเจ้าของข้อมูลส่วนบุคคล<br>
    เมื่อบทบัญญัติหมวดสิทธิของเจ้าของข้อมูลส่วนบุคคลตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 มีผลบังคับใช้ ท่านในฐานะเจ้าของข้อมูลส่วนบุคคลมีสิทธิเกี่ยวกับข้อมูลส่วนบุคคล ดังนี้<br>
    สิทธิในการถอนความยินยอม (Right to withdraw consent)<br>
    • เมื่อท่านให้ความยินยอมกับบริษัทฯ เพื่อวัตถุประสงค์อย่างหนึ่งอย่างใดโดยเฉพาะแล้ว ท่านมีสิทธิถอนความยินยอมเมื่อใดก็ได้ เว้นแต่เป็นกรณีที่มีข้อจำกัดสิทธิตามกฎหมาย หรือเกี่ยวข้องกับสัญญาที่ให้ประโยชน์แก่ท่าน<br>
    • ท่านมีสิทธิในการถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคลที่ท่านได้ให้ความยินยอมกับบริษัทฯ ได้ตลอดระยะเวลาที่ข้อมูลส่วนบุคคลของท่านอยู่กับบริษัทฯ <br>
    • หากการถอนความยินยอมของท่านทำให้บริษัทฯ ไม่สามารถส่งมอบผลิตภัณฑ์หรือให้บริการบางอย่างให้แก่ท่านได้ บริษัทฯ จะแจ้งให้ท่านทราบถึงผลกระทบจากการถอนความยินยอม<br>
    สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคล (Right to object) <br>
    • ท่านมีสิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้<br>
    สิทธิในการขอให้ลบข้อมูลส่วนบุคคล (Right to erasure)<br>
    • ท่านมีสิทธิในการขอให้บริษัทฯ ลบข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้<br>
    สิทธิในการขอให้ระงับการใช้ข้อมูลส่วนบุคคล (Right to restriction of processing)<br>
    • ท่านมีสิทธิในการขอให้บริษัทฯ ระงับการใช้ข้อมูลส่วนบุคคลของท่านด้วยเหตุบางประการได้<br>
    สิทธิในการแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง (Right to rectification)<br>
    • ท่านมีสิทธิในการขอให้บริษัทฯ แก้ไขข้อมูลส่วนบุคคลของท่านที่ไม่ถูกต้อง ไม่เป็นปัจจุบันหรือไม่สมบูรณ์ โดยบริษัทฯ จะดำเนินการตรวจสอบและแก้ไขให้ถูกต้อง<br>
    • ในกรณีที่ท่านขอแก้ไขข้อมูลส่วนบุคคลบางอย่างที่บริษัทฯ พิจารณาแล้วว่ามีความจำเป็นต้องยืนยันตัวตนท่านเพิ่มเติม บริษัทฯ จะขอเอกสารและ/หรือข้อมูลเพิ่มเติม<br>
    5. ในกรณีที่ท่านปฏิเสธไม่ให้ใช้ข้อมูลส่วนบุคคล<br>
    บริษัทฯ มีความจำเป็นต้องรวบรวมข้อมูลส่วนบุคคลตามกฎหมายหรือเพื่อการเข้าทำสัญญาระหว่างท่านกับบริษัทฯ หากท่านเลือกที่จะไม่ให้ข้อมูลส่วนบุคคล บริษัทฯ อาจไม่สามารถให้บริการแก่ท่านได้<br>

</p>
