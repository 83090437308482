import { number } from '@amcharts/amcharts4/core';
import { PageResponseModel } from 'src/app/core/models/_shared/page.model';
import { Teleport } from '../admin-upc/admin-upc-view-model';
import { TaskResponsible } from 'src/app/core/models/_sprint3/store/work-area.model';

export class WorkAreaWorksheetViewViewModel {
    taskDate: Date = new Date;
    summary: WorkAreaWorksheetSummary = new WorkAreaWorksheetSummary();
    items: WorkAreaWorkSheetListViewModel[] = [];
    page: PageResponseModel<WorkAreaWorkSheetListViewModel> = new  PageResponseModel<WorkAreaWorkSheetListViewModel>();
}
export class WorkAreaWorksheetSummary {
    totalAll: number;
    totalOverdue: number;
    totalWaiting: number;
    totalToDo: number;
    totalDoing: number;
    totalDone: number;
    totalRemain: number;
}
export class WorkAreaWorkSheetListViewModel {
    isChecked: boolean;
    isOverdue: boolean;
    hasCompleted: boolean;
    canSelect: boolean;
    canDelete: boolean;
    isView: boolean;
    isEdit: boolean;
    isResponsible: boolean;
    workSheetNo: string;
    id: number;
    zone: Status;
    state: Status;
    actionState: number;
    status: Status;
    operationDate: Date;
    type: Status;
    subType: Status;
    progressType: Status;
    progressStatus: ProgressModel[];
    numberOfMachines: number;
    totalOnsiteCompleted : number;
    totalOnsiteUncompleted: number;
    totalOnsitePostpone : number;
    numberOfMachinesTotal: number;
    startDate: Date;
    endDate: Date;
    createName: string;
    onsiteTechnicianName: string;
    shopName: string;
    location: string;
    actualDate: Date;
    ownerName: string;
    responsibleName: string;
    bankName: string;
    worksheetId: number;
    worksheetStatus: Status;
    receiveStatus: Status;
    teleport: Teleport;
    taskResponsible: TaskResponsible;
    taskResponsibleName: string;
    responsibleRemark: string;
    tid: string;
    mainEdcTid: string;
    referInboundTicketNo: string;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    ticketDateTime: Date;
    worksheetDate: Date;
    canReviseCloseJob: boolean;
    canReviseWorksheet: boolean;
    terminalId: string; 
    
    technicianName : string; 
    technicianPlanningDate: Date;
    hasPrintWorksheet: boolean;
    finishDateTime: Date;
    startPromotionDate: Date;
    finishPromotionDate: Date;
    postponeStatus:Status
}
export class Status {
    id: number;
    name: string;
}
export class ProgressModel {
    state: number;
    name: string;
}

export class StoreSearchWorksheetListViewModel {
    fromOperationDate: Date = null;
    toOperationDate: Date = null;
    taskDate: string = '';
    textSearch: string = '';
    worksheetNo: string = '';
    zoneIdList: number[] = [];
    actionStateIdList: number[] = [];
    statusIdList: number[] = [];
    workTypeIdList: number[] = [];
    subTypeIdList: number[] = [];
    isOverdue: boolean = false;
    shopName: string = '';
    location: string = '';
    taskOwnerName: string = '';
    taskResponsibleName: string = '';
    createdByName: string = '';
    provinceName: string = '';
    districtName: string = '';
    subdistrictName: string = '';
    isTaskDate: boolean = true;
    fromWorksheetDate: Date = null;
    toWorksheetDate: Date = null;
    searchRoleType:number
}

export class WorkTypeListModel {
    id: number;
    name: string;
    description: string;
}

export class WorkTypeCommitRequest {
    idList: number[]
}