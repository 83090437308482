import { BranchViewModel } from "../_shared/branch.view-model";
import { BUsViewModel } from "../_shared/bus.view-model";
import { ShopViewModel } from "../_shop/shop.view-model";
import { FileModel } from '../../models/_shared/file.model';

export class OrderSearchListViewModel {
  shopDetail: ShopViewModel = new ShopViewModel();
  orderInformation: OrderInformationViewModel = new OrderInformationViewModel();
}
export class OrderInformationViewModel {
  jobNumber: string;
  lot: string;
  branch?: BranchViewModel;
  businessUnit?: BUsViewModel;
  officeCode: string;
  isUrgent: boolean;
  isVip: boolean;
  orderNo: number;
  orderDate: Date;
  operationDate: Date;
  logoPictureFile: FileModel;
  attachFile: FileModel;
  hasLogo: boolean;
  branchName: string;
  buName: string;
  bc: string;
  pfCode: string;
  officer: string;
  nii: string;
  requestDate:Date
}

export class OrderAuthorizeViewModel {
  isAction: boolean;
  isReject: boolean;
  isSave: boolean;
  isSubmit: boolean;
  isApprove: boolean;
  isCancel: boolean;
  isSummary: boolean;
}

export class OrderDetailViewModel {
  lineSlip1: string;
  lineSlip2: string;
  lineSlip3: string;
  installDate: Date;
  startDate: Date;
  endDate: Date;
  edcs: OrderDetailEdcViewModel[] = [];
}
export class SearchCopyListViewModel {
  edcIdList: number[] = [];
}
export class SaveCopyListViewModel {
  edcIdList: number[] = [];
  fromEdcId:number
}
export class SubmitGenerateEdcViewModel {
  isGenerateTerminalId: boolean;
  eventType: number;
  lineSlip1: string;
  lineSlip2: string;
  lineSlip3: string;
  installDate: Date;
  startDate: Date;
  endDate: Date;
  items: OrderModelEdcViewModel[] = [];
}

export class SearchOrderDetailEdcViewModel {
  orderId: string = "";
  serialNumber: string = "";
  installDate: string = "";
  datePeriod: string = "";
  modelName: string = "";
  standAlondTid: string = "";
  tid: string = "";
  mid: string = "";
  main: string = "";
  status: number;
  backup: string = "";
}

export class SearchOrderItemEdcViewModel {
  eventType: number;
  serialNumber: string = "";
  modelName: string = ""; 
  boxNumber: string = "";
  project: string = "";
}

export class OrderItemEdcViewModel {
  selected: boolean = false;
  id: number;
  serialNumber: string;
  modelName: string;
  boxNumber: string;
  project: string;
}

export class OrderDetailEdcViewModel {
  selected: boolean;
  isChecked: boolean;
  orderNo: string;
  orderId:number;
  orderDate: string;
  typeOfWork: TypeOfWork;
  edcId: number;
  backup: string;
  endDate: string;
  installDate: string;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: string;
  inventoryStatus: Status;
  status: Status;
  tid: string;
  remark: string;
  hasCanceled: boolean;
  canceledAt: string;
  canceledName: string;
  hasReplaceEdc: boolean;
  replaceEdc: ReplaceEdc;
  replaceType: number;
  otherReplaceEdc: OtherReplaceEdc;
  hasOpenedWorksheet :boolean; 
  worksheetId: number;
  worksheetNo: string;
}
export class ReplaceEdc {
  kind: string;
  isChecked: boolean;
  orderNo: string;
  orderDate: string;
  typeOfWork: TypeOfWork;
  edcId: number;
  backup: string;
  endDate: string;
  installDate: string;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: string;
  status: Status;
  tid: string;
  remark: string;
  hasCanceled: boolean;
  canceledAt: string;
  canceledName: string;
}
export class OtherReplaceEdc {
  model: number;
  terminalId: string;
  remark: string;
  serialNumber: number;
  mid: string;
  shopName: string;
  standAlondTid: string;
}
export class CommunicationMainViewModel {
  id: number;
  name: string;
  type: number;
}

export class CommunicationProviderViewModel {
  id: number;
  name: string;
}
export class CommunicationPhoneNumberViewModel {
  id: number;
  code: string;
  phoneNumber: string;
  get phoneText(): string {
    return `${this.phoneNumber} (${this.code})`;
  }
}
export class FeatureConnectorViewModel {
  selected: boolean = false;
  id: number;
  name: string;
}
export class FeatureConnectorTypeViewModel {
  id: number;
  name: string;
  selected: boolean;
  serialNumber: string;
  remark: string;
  isShowSerialNumber: boolean;
  inventoryId: number;
  isShowQuantity: boolean;
  quantity:number;
  measure : number;
}

export class FeatureConnectorSerialNumberViewModel{
  serialNumber: string;
  id: number;
}
export class FeatureConnectorSerialNumberCriteriaModel{
  serialNumber: string = '';
  boxNumber: string = "";
  project: string = "";
}

export class OrderEdcBankHostTypeViewModel {
  id: number;
  name: string;
  type: number;
}
export class OrderEdcBankHostViewModel {
  id: number;
  name: string;
  type: number;
  defaultFunctions: DefaultFunctions[]=[]
}
export class DefaultFunctions {
  id: number;
  function: OrderEdcBankHostTypeViewModel;
  selected: boolean;
}
export class OrderEdcBankHostFunctionViewModel {
  id: number;
  function: OrderEdcBankHostTypeViewModel;
  selected: boolean;
}

export class OrderEdcPantancateFormatViewModel {
  id: number;
  name: string;
}

export class OrderModelEdcViewModel {
  id: number;
  name: string;
  amount: number = 0;
  request: number = 0;
}

export class OrderRemoveSearchShopListViewModel {
  id: number;
  code: string;
  group: Group;
  branchName: string;
  nameTH: string;
  nameEN: string;
  address: string;
  province: Province;
  district: District;
  subDistrict: SubDistrict;
  zipCode: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  email: string;
  placeInLocation: string;
  reg: string;
  bankPhoneNumber: string;
  bankName: string;
}

export class Province {
  kind: string;
  id: number;
  nameTH: string;
  nameEN: string;
}

export class District {
  kind: string;
  id: number;
  nameTH: string;
  nameEN: string;
}

export class SubDistrict {
  kind: string;
  id: number;
  nameTH: string;
  nameEN: string;
  latitude: number;
  longitude: number;
  zipCode: number;
}

export class Group {
  id: number;
  code: string;
  name: string;
  description: string;
  isActive: boolean;
  kind: string;
}

export class OrderCustomerViewModel {
  id: number;
  name: string;
}

export class ClientType {
  id: number;
  code: string;
  name: string;
  description: string;
}

export class GroupShop {
  id: number;
  code: string;
  name: string;
  clientType: ClientType;
  description: string;
  isActive: boolean;
  kind: string;
}

export class OrderShop {
  kind: string;
  id: number;
  code: string;
  group: GroupShop;
  branchName: string;
  nameTH: string;
  nameEN: string;
  address: string;
  province: Province;
  district: District;
  subDistrict: SubDistrict;
  posCode: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  email: string;
  placeInLocation: string;
  reg: string;
  bankPhoneNumber: string;
  bankName: string;
}

export class Authorize {
  isApprove: boolean;
  isReject: boolean;
  isSave: boolean;
  isSubmit: boolean;
  isCancel: boolean;
}

export class OrderRemoveUpdateShopDetailViewModel {
  id: number;
  customerId: number;
  clientId: number;
  shop: OrderShop = new OrderShop();
  remark: string;
  removeDate: Date;
  authorize: Authorize = new Authorize();
  information: OrderInformationViewModel = new OrderInformationViewModel();
}

export class OrderDetailEdcReplaceViewModel {
  selected: boolean;
  edcId: number;
  backup: string;
  endDate: Date;
  installDate: Date;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: Date;
  status: Status;
  tid: string;
  remark: string;
  kind: string;
  isChecked: boolean;
  orderNo: string;
  orderDate: string;
  typeOfWork: TypeOfWork;
  hasCanceled: boolean;
  canceledAt: string;
  canceledName: string;
}
export class Status {
  id: number;
  code: string;
  name: string;
}

export class SearchOrderItemEdcReplaceViewModel {
  eventType: number;
  serialNumber: string = "";
  modelName: string = "";
  tid: string = "";
  mid: string = "";
  standAlondTid: string = "";
}
export class OrderNewEdcViewModel {
  replaceEdcId: number;
}
export class OrderDetailEdcCopyViewModel {
  selected: boolean;

  orderNo: string;
  orderDate: Date;
  typeOfWork: TypeOfWork;

  edcId: number;
  backup: string;
  endDate: Date;
  installDate: Date;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTID: string;
  startDate: Date;
  status: Status;
  tid: string;
  remark: string;
}
export class TypeOfWork {
  id: number;
  code: string;
  nameTH: string;
  nameEN: string;
  name: string;
}

export class RemoveRequestReject {
  edcIdList: number[];
  remark: string;
}
export class RemoveModelSubmitCreateModel {
  kind: string;
  id: number;
  customerId: number;
  clientId: number;
  information: Information;
  shop: Shop;
  remark: string;
  removeDate: string;
  edcs: Edc[];
}

export class Edc {
  address: string;
  remark: string;
  kind: string;
  isChecked: boolean;
  orderNo: string;
  orderDate: string;
  typeOfWork: TypeOfWork;
  edcId: number;
  backup: string;
  endDate: string;
  installDate: string;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: string;
  status: Status;
  tid: string;
  hasCanceled: boolean;
  canceledAt: string;
  canceledName: string;
}

export class Shop {
  kind: string;
  id: number;
  code: string;
  group: Group;
  branchName: string;
  locationCode: string;
  nameTH: string;
  nameEN: string;
  address: string;
  province: Province;
  district: Province;
  subDistrict: SubDistrict;
  zipCode: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  email: string;
  placeInLocation: string;
  reg: string;
  bankPhoneNumber: string;
  bankName: string;
}

export class Information {
  kind: string;
  id: number;
  orderNo: string;
  orderDate: string;
  operationDate: string;
  jobNumber: string;
  lot: string;
  businessUnit: BusinessUnit;
  branch: Branch;
  officeCode: string;
  isUrgent: boolean;
  isVip: boolean;
  branchName: string;
  bc: string;
  pfCode: string;
  officer: string;
  nii: string;
}

export class Branch {
  id: number;
  isActive: boolean;
  businessUnitName: string;
  customerBusinessUnitId: number;
  code: string;
  name: string;
  description: string;
  kind: string;
}

export class BusinessUnit {
  id: number;
  isActive: boolean;
  code: string;
  name: string;
  description: string;
  kind: string;
}

export class CreateShopOther {
  serialNumber: string;
  terminalId: string;
  model: string;
  remark: string;
  mid: string;
  shopName: string;
  standAlondTid: string;
}

export class InboundEdcCopyViewModel {
  selected: boolean;
  orderNo: string;

  edcId: number;
  topic1: TopicHeaderViewModel;
  topic2: TopicHeaderViewModel;
  topic3: TopicHeaderViewModel;
  topic4: TopicHeaderViewModel;
  mid: string;
  modelName: string;
  serialNumber: string;
  standAloneTid: string;
  tid: string;
}
export class TopicHeaderViewModel {
  id: number;
  name: string;
  isShowExternalBankHost: boolean;
  showNextStep: boolean;
}
