<div class="modal-header">
    <h4 class="modal-title">{{'STORE.WORKSHEET.WORKSHEET' | translate}}</h4>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-custom2">
    <div class="kt-portlet kt-portlet--tabs margin-panel">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-toolbar">
            <ul
              class="nav nav-tabs nav-tabs-line nav-tabs-line-danger nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold"
              role="tablist">
              <li class="nav-item">
                <a class="nav-link" href="javascript:;" [ngClass]="{'active': tabChildNumber == 1}"
                  (click)="focusTabDetailType(1)">{{'_SHARED.INFORMATION' | translate}}
                  </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"
                  [ngClass]="{'active': tabChildNumber == 2}"
                  (click)="focusTabDetailType(2)">{{'_SHARED.DETAIL_WORKSHEET' | translate}}
                 </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="javascript:;"
                  [ngClass]="{'active': tabChildNumber == 3}"
                  (click)="focusTabDetailType(3)">{{'_SHARED.TIMELINE' | translate}}
                 </a>
              </li>
              <li class="nav-item" *ngIf="item.hasSettingCallOut ">
                <a class="nav-link" href="javascript:;"
                  [ngClass]="{'active': tabChildNumber == 4}"
                  (click)="focusTabDetailType(4)">Setting Call Out
                 </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div class="tab-content">
            <ng-template switch-tab></ng-template>
          </div>
        </div>
      </div>
</div>
<div class="modal-footer">
    <div class="col-lg-12 text-right">
      <!-- <button type="button" class="btn btn-info waves-effect" *ngIf="item.canPrintWorksheetEdcPaper" (click)="openInNewTab()">
        <i class="fa fa-print"></i>test
      </button>&nbsp; -->
      <button type="button" class="btn btn-info waves-effect" *ngIf="item.canPrintWorksheetEdcPaper && iscustomer == false" (click)="onFileEdcClick()">
        <i class="fa fa-print"></i>{{'_SHARED.EDC_DETAIL' | translate}}
      </button>&nbsp;
      <ng-container *ngxPermissionsOnly="'STD-WST-02'">
        <button type="button" class="btn btn-info waves-effect" *ngIf="item.canPrintWorksheetPaper && iscustomer == false" (click)="onFileClick()">
          <i class="fa fa-print"></i>{{'_SHARED.PRINT_WROKSHEET' | translate}}
        </button>&nbsp;
      </ng-container> 
      <!-- <button type="button" [ngbTooltip]="ประวัติการพิมพ์ใบงาน" class="btn btn-info waves-effect" *ngIf="item.hasPrintPaperHistory " (click)="onHistoryClick()">
        <i class="fa fa-history" style="padding: 0;"></i> 
      </button>   -->
      &nbsp;&nbsp;
      <button type="button" *ngIf="item.canReject && iscustomer == false" class="btn btn-warning waves-effect" (click)="onRejectClick()">{{'_SHARED.REJECT' | translate}}</button>&nbsp;
      <button type="button" *ngIf="item.canCancel && iscustomer == false" class="btn btn-danger waves-effect" (click)="onCancelClick()">{{'_SHARED.CANCEL' | translate}}</button>&nbsp;
        <button type="button" class="btn btn-dark waves-effect" (click)="closeModal()">{{'_SHARED.CLOSE' | translate}}</button>
    </div>
</div>
