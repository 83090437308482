import { Injectable } from '@angular/core'; 
import { ApiService } from 'src/app/core/http/api.service'; 
import { PackingTaskInformationViewModel, TaskWorkFlow, UpdatePackingWorkareaEdc, TaskWorkFlowViewModel, PackingTaskWrokareaModel, PackingSaveTaskWrokareaModel } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { taskData, workFlow } from 'src/app/core/view-models/_sprint3/_mock/packing-task-const';
import { UpdateSettingWorkareaEdc } from 'src/app/core/view-models/_sprint3/_setting/task-model';
import { PackingTaskInformationApisModel } from 'src/app/core/models/_sprint3/setting/setting-edc-view-model';
 
import { GetPackingEdcMapper } from 'src/app/core/models-mapper/_sprint3/store/work-area-edc-packing-mapper';
import { PackingTaskWrokareaSimModel } from 'src/app/core/view-models/_sprint3/store/work-area-sim.model';
import { UpdateStoreWorkareaSim } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
 

@Injectable({
  providedIn: "root",
})
export class PackingTaskService {


  constructor(
    private apiService: ApiService, 
    private getPackingEdcMapper:GetPackingEdcMapper 
  ) { } 
 
  async getEdc(id:number): Promise<PackingTaskInformationViewModel> {
    const result = await this.apiService.get<PackingTaskInformationApisModel>(`/packing/work-area/edcs/${id}`);
    return this.getPackingEdcMapper.mapForm(result);
  }
  async getperipheral(id:number): Promise<PackingTaskInformationViewModel> {
    const result = await this.apiService.get<PackingTaskInformationApisModel>(`/packing/work-area/peripherals/${id}`);
    return this.getPackingEdcMapper.mapForm(result);
  }
  async getSim(id:number): Promise<PackingTaskInformationViewModel> {
    const result = await this.apiService.get<PackingTaskInformationApisModel>(`/packing/work-area/sims/${id}`);
    return this.getPackingEdcMapper.mapForm(result);
  }
  async saveSim(id:number,updateRequest:PackingTaskInformationViewModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/packing/work-area/sims/${id}`,updateRequest);
    return result
  }
  async submitSim(id:number,updateRequest:PackingTaskInformationViewModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/packing/work-area/sims/${id}/submit`,updateRequest);
    return result
  }
  async saveEdc(id:number,updateRequest:UpdatePackingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/packing/work-area/edcs/${id}`,updateRequest);
    return result 
  }
  async submitEdc(id:number,updateRequest:UpdatePackingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/packing/work-area/edcs/${id}/submit`,updateRequest);
    return result
  }
  async savePeripheral(id:number,updateRequest:UpdatePackingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/packing/work-area/peripherals/${id}`,updateRequest);
    return result 
  }
  async submitPeripheral(id:number,updateRequest:UpdatePackingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/packing/work-area/peripherals/${id}/submit`,updateRequest);
    return result
  }
  // async get(): Promise<PackingTaskInformationViewModel> {
  //   const mockList = taskData as PackingTaskInformationViewModel;
  //   return mockList;
  // } 
  async getworkFlow(id:number): Promise<TaskWorkFlow[]> {
    const mockList = workFlow as TaskWorkFlow[];
    return mockList;
  } 
  async getworkFlowEdc(id:number): Promise<TaskWorkFlowViewModel[]> {
    const result = await this.apiService.get<TaskWorkFlowViewModel[]>(`/work-area/edcs/${id}/timelines`);
    return result;
  }
 async getworkFlowPeripheral(id:number): Promise<TaskWorkFlowViewModel[]> {
    const result = await this.apiService.get<TaskWorkFlowViewModel[]>(`/work-area/connectors/${id}/timelines`);
    return result;
  }
 
  async saveEdcWorkarea(updateRequest:PackingTaskWrokareaModel): Promise<boolean> {
    const request :PackingSaveTaskWrokareaModel = {
      conNumber : updateRequest.conNumber,
      remark:updateRequest.remark,
      trackingNumber:updateRequest.trackingNumber,
      idList: updateRequest.edcList.map(x =>x.id)
    } 
    const result = await this.apiService.patch<boolean>(`/packing/work-area/edcs/multi`,request);
    return result
  }
  async submitEdcWorkarea(updateRequest:PackingTaskWrokareaModel): Promise<boolean> {
    const request :PackingSaveTaskWrokareaModel = {
      conNumber : updateRequest.conNumber,
      remark:updateRequest.remark,
      trackingNumber:updateRequest.trackingNumber,
      idList: updateRequest.edcList.map(x =>x.id)
    } 
    const result = await this.apiService.patch<boolean>(`/packing/work-area/edcs/multi/submit`,request);
    return result
  }
 
  async savePeripheralWorkarea(updateRequest:PackingTaskWrokareaModel): Promise<boolean> {
    const request :PackingSaveTaskWrokareaModel = {
      conNumber : updateRequest.conNumber,
      remark:updateRequest.remark,
      trackingNumber:updateRequest.trackingNumber,
      idList: updateRequest.edcList.map(x =>x.id)
    } 
    const result = await this.apiService.patch<boolean>(`/packing/work-area/peripherals/multi`,request);
    return result
  }
  async submitPeripheralWorkarea(updateRequest:PackingTaskWrokareaModel): Promise<boolean> {
    const request :PackingSaveTaskWrokareaModel = {
      conNumber : updateRequest.conNumber,
      remark:updateRequest.remark,
      trackingNumber:updateRequest.trackingNumber,
      idList: updateRequest.edcList.map(x =>x.id)
    } 
    const result = await this.apiService.patch<boolean>(`/packing/work-area/peripherals/multi/submit`,request);
    return result
  }


  async saveSimWorkarea(updateRequest:PackingTaskWrokareaSimModel): Promise<boolean> {
    const request :PackingSaveTaskWrokareaModel = {
      conNumber : updateRequest.conNumber,
      remark:updateRequest.remark,
      trackingNumber:updateRequest.trackingNumber,
      idList: updateRequest.edcList.map(x =>x.id)
    } 
    const result = await this.apiService.patch<boolean>(`/packing/work-area/sims/multi`,request);
    return result
  }
  async submitSimWorkarea(updateRequest:PackingTaskWrokareaSimModel): Promise<boolean> {
    const request :PackingSaveTaskWrokareaModel = {
      conNumber : updateRequest.conNumber,
      remark:updateRequest.remark,
      trackingNumber:updateRequest.trackingNumber,
      idList: updateRequest.edcList.map(x =>x.id)
    } 
    const result = await this.apiService.patch<boolean>(`/packing/work-area/sims/multi/submit`,request);
    return result
  }
}
 