<div class="kt-portlet__head">
    <div class="kt-portlet__head-label"> 
        <h3 class="kt-portlet__head-title">
            <ng-content></ng-content>
        </h3>
    </div>
    <div class="kt-portlet__head-toolbar">
        <div class="kt-portlet__head-group">
            <a data-toggle="collapse" attr.data-target="#{{panelElementId}}" aria-expanded="true" attr.aria-controls="{{panelElementId}}" 
                class="btn btn-sm btn-icon btn-default btn-pill btn-icon-md" *ngIf="canExpand">
                <i class="la la-angle-down"></i>
            </a>
        </div>
    </div>
</div>