import { Injectable } from "@angular/core";
import { EdcCustomerDetailApiViewModel } from "src/app/core/models/_sprint3/stock/edc-customer-detail-api-view-model";
import { StockControlViewInformationViewModel } from "src/app/core/view-models/_sprint3/store/stock-control-view-information-view-model"; 
import { WorksheetGeneralViewModel } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { WorksheetGeneralApisModel } from '../../models/_sprint3/worksheet/worksheet-api-view-model';
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils';

@Injectable({
  providedIn: "root",
})
export class CreateGeneralWorksheetMapper implements Mapper<WorksheetGeneralApisModel, WorksheetGeneralViewModel>{
  constructor( 
  ) { }
  mapForm(param: WorksheetGeneralApisModel): WorksheetGeneralViewModel {
      const item = new WorksheetGeneralViewModel(); 
      return item;
  }
  mapTo(param: WorksheetGeneralViewModel): WorksheetGeneralApisModel { 
    const item = new WorksheetGeneralApisModel();
    item.clientId = param.shop?.id; 
    item.convenientDate = DateUtils.toFormat(param.contact?.serviceDate, DateUtils.YYYY_MM_DD);
    item.convenientHour = param.contact?.serviceHour;
    item.convenientMinute = param.contact?.serviceMinute;
    item.issue = param.command?.issue;
    item.recommend = param.command?.recommend;
    item.workCommand = param.command?.command; 
    item.commandContactName = param.contact?.contactName;
    item.commandContactPhoneNumber = String(param.contact?.contactPhone) ; 
    item.remark = param.remark
    item.shopNameTH= param.location?.nameTH;
    item.shopNameEN= param.location?.nameEN;
    item.shopAddress= param.location?.address;
    item.provinceId= param.location?.provinceId;
    item.districtId= param.location?.districtId; 
    item.contactName = param.location?.contactName;
    item.contactPhoneNumber = String(param.location?.contactPhoneNumber); 
    item.contactFaxNumber= param.location?.contactFaxNumber;
    item.subdistrictId= param.location?.subdistrictId;
    item.zipCode= param.location?.posCode;
    item.latitude= '';
    item.longitude= '';  
    item.contactEmail= param.location?.email;
    item.location= param.location?.location;
    item.reg= param.location?.reg; 

    item.attachFileId = param.contact?.commandFile?.attachFileId;  
    item.typeOfWorkId = param.topicId; 
    item.eventTypeId = param.eventTypeId;
    item.sendToType= param.sendToStatus;
    return item;
  }

}