 import { Progress } from "src/app/core/view-models/_sprint3/admin-bkk/work-area-model";
import { AttachFile } from "../../_sprint3/admin-bkk/close-job-model";

export class CallHistoryModel { 
  historyType: number;
  taskId: number;
  typeName: string;
  taskNo: string;
  serialNumber: string;
  tid: string;
  shopNameTH: string;
  provinceName: string;
  bankName: string;
  issue: string;
  solution: string;
  createDateTime: Date = new Date;
  openJobName: string;
  closeJobName: string;
  technicianName: string;  
  contactName: string;
  contactTel: string;
  statusName: string;
  progresses: Progress[];
  signaturePictureFile: AttachFile = new AttachFile
  inboundEdc: Edc 
}
export interface Edc {
  topic1: Topic1;
  topic2: Topic1;
  topic3: Topic1;
  topic4: Topic1;
  detail: string;
  problem: string;
  solution: string;
  remark: string;
  detailStatus: Status;
  kind: string;
  edcId: number;
  customerName: string;
  serialNumber: string;
  modelName: string;
  standAloneTid: string;
  tid: string;
  mid: string;
  shopName: string;
  address: string;
  multhi: boolean;
  main: string;
  backup: string;
  isMain: boolean;
  inboundTaskWorksheetId: number;
  serviceBalanceStock: number;
  status: Status;
}
export interface Topic1 {
  id: number;
  level: number;
  code: string;
  name: string;
  isShowExternalBankHost: boolean;
  showNextStep: boolean;
}
export class Status {
  kind: string;
  id: number;
  code: string;
  name: string;
}
export class CallHistoryApiModel {
  historyType: number;
  taskId: number;
  typeName: string;
  taskNo: string;
  serialNumber: string;
  tid: string;
  shopNameTH: string;
  provinceName: string;
  bankName: string;
  issue: string;
  solution: string;
  createDateTime: Date = new Date;
  openJobName: string;
  closeJobName: string;
  technicianName: string;  
  contactName: string;
  contactTel: string;
  statusName: string;
  progresses: Progress[];
  signaturePictureFile: AttachFile = new AttachFile
  inboundEdc: Edc 
}

export class SearchCallHistoryModel {
  taskNo: string = "";
  serialNumber: string = "";
  tid: string = "";
  openJobName: string = "";
  closeJobName: number;
  typeIdList: number[] = []
}
export class StatusTypeInbound {
  kind: string;
  id: number;
  code: string;
  name: string;
}

export interface Edc {
  topic1: Topic1;
  topic2: Topic1;
  topic3: Topic1;
  topic4: Topic1;
  detail: string;
  problem: string;
  solution: string;
  remark: string;
  detailStatus: Status;
  kind: string;
  edcId: number;
  customerName: string;
  serialNumber: string;
  modelName: string;
  standAloneTid: string;
  tid: string;
  mid: string;
  shopName: string;
  address: string;
  multhi: boolean;
  main: string;
  backup: string;
  isMain: boolean;
  inboundTaskWorksheetId: number;
  serviceBalanceStock: number;
  status: Status;
}