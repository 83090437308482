import { PageResponseModel } from '../../_shared/page.model';

export interface EdcSelectModel {
  kind: string;
  id: number;
  name: string;
}

//////////////////////////////////
export class EdcClientsApiAllViewModel {
  pageNumber: number;
  totalItemCount: number;
  pageCount: number;
  pageSize: number;
  items: PageResponseModel<EdcClientsApiViewModel> = new PageResponseModel<EdcClientsApiViewModel>()
}

export class SearchEdcClientCriteriaModel {
  serialNumber: string="";
  modelName: string="";
}
export class EdcTidHistoryApiViewModel {
  id:number; 
  installDate: string; 
  serialNumber: string; 
  shopCode: string; 
  shopNameTH: string; 
  shopNameEN: string; 
}
export class SearchEdcTidHistoryCriteriaModel {
  serialNumber: string=""; 
  shopCode: string=""; 
  shopNameTH: string=""; 
  shopNameEN: string=""; 
  installDate: string=""; 
}
export class EdcClientsApiViewModel {
  id:number;
  kind: string;
  isChecked: boolean;
  orderNo: string;
  orderDate: string;
  typeOfWork: TypeOfWork;
  edcId: number;
  backup: string;
  endDate: string;
  installDate: string;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: string;
  status: Status;
  tid: string;
  remark: string;
  hasCanceled: boolean;
  canceledAt: string;
  canceledName: string;
}

export class Status {
  kind: string;
  id: number;
  code: string;
  name: string;
}

export class TypeOfWork {
  id: number;
  code: string;
  nameTH: string;
  nameEN: string;
  name: string;
  kind: string;
}
