import { PackingTaskInformationViewModel, TaskWorkFlow } from '../packing/task-model'
import { SettingTaskInformationViewModel } from '../_setting/task-model'

export const taskData  =
{
    id: 1,
    information:
    {
        formName: 'TAKE',
        commandName: 'TAKE',
        stateName: 'DOING',
        statusName: 'Save ',
        workTypeName: 'INSTALL',
        workSubTypeName: 'PERMANANCE',
        tid: 988563335,
        type: 'EDC',
        serialNumber: '16216CT25714014',
        worksheetNo: 'PN2020-06-0002',
        startDate: null,
        EndDate: null,
        nextTo: 'LOADER',
        modalName: 'CT220GEM 2 G',
        box: 'B-01',
        location: 'CT220GEM 2 G',
        actualDate: null,
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskResponsible: 'สมหญิง นามสมมุติ',
    },

    trackingNumber: null,
    currentStatus: 'Mock Loader',
    updateStatus: 'Mock Packing',
    remark: '',
}


export const workFlow: TaskWorkFlow[] = [
    {
        edcId:  0,
        taskDate: new Date,
        taskName: 'Take',
        statusName: 'Store',
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskDetail: 'เบิกอุปกรณ์ 16216CT25714014 เรียบร้อยแล้ว',
    },
    {
        edcId:  0,
        taskDate: new Date,
        taskName: 'Setting Program',
        statusName: 'Setting',
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskDetail: 'ติดตั้งระบบเรียบร้อยแล้ว พร้อมใช้งาน',
    },
    {
        edcId:  0,
        taskDate: new Date,
        taskName: 'Loader',
        statusName: 'Download',
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskDetail: 'ดาวน์โหลดโปรแกรมเรียบร้อยแล้ว',
    },
    {
        edcId:  0,
        taskDate: new Date,
        taskName: 'Packing',
        statusName: 'Packing',
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskDetail: 'พร้อมส่งระบบ',
    }
]

export const taskSettingData  =
{
    id: 1,
    information:
    {
        formName: 'TAKE',
        commandName: 'TAKE',
        stateName: 'DOING',
        statusName: 'Save ',
        workTypeName: 'INSTALL',
        workSubTypeName: 'PERMANANCE',
        tid: 988563335,
        type: 'EDC',
        serialNumber: '16216CT25714014',
        worksheetNo: 'PN2020-06-0002',
        startDate: null,
        EndDate: null,
        nextTo: 'LOADER',
        modalName: 'CT220GEM 2 G',
        box: 'B-01',
        location: 'CT220GEM 2 G',
        actualDate: null,
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskResponsible: 'สมหญิง นามสมมุติ',
    },

    tackingNumber: null,
    currentStatus: 'Mock Loader',
    updateStatus: 'Mock Packing',
    remark: '',
    assessment: [
        {
            check: false,
            id:1,
            name: "	หัว Line slip",
        },
        {
            check: false,
            id:2,
            name: "Terminal",
        },
        {
            check: false,
            id:3,
            name: "Merchant",
        },
        {
            check: false,
            id:4,
            name: "Function",
        },
        {
            check: true,
            id:5,
            name: "Bank/host",
        },
        {
            check: false,
            id:6,
            name: "Muthi",
        }
    ]
}