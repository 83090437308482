import { Mapper } from "../../mapper";
import { Injectable } from "@angular/core";
import {
  StockControlEdcViewModel,
} from "src/app/core/view-models/_sprint3/store/stock-control-edc";
import { StockControlEdcApiViewModel } from 'src/app/core/models/_sprint3/stock/stock-control-edc';

@Injectable({
  providedIn: "root",
})
export class StockControlEdcMapper
  implements Mapper<StockControlEdcApiViewModel, StockControlEdcViewModel> {
  constructor() { }
  mapForm(param: StockControlEdcApiViewModel): StockControlEdcViewModel {
    return {
      edcId: param.edcId,
      connectorId: param.connectorId,
      status: param.status,
      canView: param.canView,
      canEdit: param.canEdit,
      canSetting: param.canSetting,
      serialNumber: param.serialNumber,
      assetsNumber: param.assetsNumber,
      model: param.modelName,
      tid: param.tid,
      mid: param.mid,
      shopName: param.shopName,
      type: param.typeOfWork,
      subType: param.subType,
      sapMaterialId: param.sapMaterialId,
      bankName: param.bankName,
      box: param.box,
      location: param.location,
      selected: param.selected
    };
  }
  mapTo(param: StockControlEdcViewModel): StockControlEdcApiViewModel {
    throw new Error("Method not implemented.");
  }
}
