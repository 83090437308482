import { Component, OnInit, Input } from '@angular/core';
import { FeatureService } from 'src/app/core/services/shared/feature.service';
import { OrderEdcFeatureConnectorViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { FeatureConnectorTypeViewModel } from 'src/app/core/view-models/_order/order-share.view-model';

@Component({
  selector: 'app-view-shared-feature-connector',
  templateUrl: './view-shared-feature-connector.component.html',
  styleUrls: ['./view-shared-feature-connector.component.scss']
})
export class ViewSharedFeatureConnectorComponent implements OnInit {

  @Input()
  feature: OrderEdcFeatureConnectorViewModel = new OrderEdcFeatureConnectorViewModel();
  // features: FeatureConnectorViewModel[] = [];
  // pantancateFormats1: OrderEdcPantancateFormatViewModel[] = [];
  // pantancateFormats2: OrderEdcPantancateFormatViewModel[] = [];
  // pantancateFormats3: OrderEdcPantancateFormatViewModel[] = [];
  // pantancateFormats4: OrderEdcPantancateFormatViewModel[] = [];
  connectorTypes: FeatureConnectorTypeViewModel[] = [];
  constructor(
    private _featureService: FeatureService) { }

  ngOnInit(): void {
    this.loadInitial();
  }
  async loadInitial() {
    /*   this.features = await this._featureService.features();
       const pantancates = await this._featureService.pantancates();
       this.pantancateFormats1 = pantancates;
       this.pantancateFormats2 = pantancates;
       this.pantancateFormats3 = pantancates;
       this.pantancateFormats4 = pantancates;*/
  }

  public setInitial(item: OrderEdcFeatureConnectorViewModel) {
    this.feature = item;
  }

  getFeature() {
    return this.feature.features.map(x => x.name).join("/");
  }
}
