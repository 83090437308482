import { PageResponseModel } from 'src/app/core/models/_shared/page.model';
import { Client, AvailableEdc, Authorize, District, SubDistrict } from '../../_shared/worksheet.view-model';
import { MechanicModel, ContactModel, CommandModel } from '../worksheet/worksheet-model';
import { WorkType } from '../admin-upc/admin-upc-view-model';
import { TaskResponsible } from 'src/app/core/models/_sprint3/store/work-area.model';
import { CheckList } from '../_setting/task-model';
import { EdcCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { ReferWorksheetInfo } from '../worksheet/worksheet-pm-model';
import { TaskWorkFlowViewModel } from '../packing/task-model';
import { AttachFile } from 'src/app/core/models/_sprint3/setting/setting-task-model';
import { SettingCalloutShopDetailInfo, SettingCalloutWorksheetInfo, SettingCalloutEdcs, Province, Subdistrict } from '../_setting/setting-callout-edit-model';
import { EventType } from 'src/app/core/models/_sprint3/order-monitoring/order-monitoring-api-view-model';
import { AvailableEdcListApis } from 'src/app/core/models/_sprint3/worksheet/worksheet-api-view-model';
import { ShopDetailViewModel } from '../../_inbound/inbound.view-model';
import { DateUtils } from 'src/app/shared/utils/date-utils';


export class AdminBKKTechnicianWorkAreaModel {
  isSelected: boolean;
  technicianId: number;
  name: string;
  phoneNumber: string;
  totalWork: number;
  totalRemainWork: number;
  profilePictureFile: ProfilePictureFile;
  workloadLevel: number;
  source: Source;
}
export class Source {
  id: number;
  name: string;
}
export class SearchAdminBKKTechnicianWorkAreaModel {
  taskDate: string;
  isTaskDate: boolean;
  teleportIdList: number[] = [];
  fromOperationDate: Date = null;
  toOperationDate: Date = null;
  fromWorksheetDate: Date = null;
  toWorksheetDate: Date = null;
}
export class SaveChangeTechnicialList {
  technicianIdList: number[] = [];
  remark: string;
}
export class WorksheetViewAllModel {
  id: number;
  worksheetInfo: WorksheetInfo = new WorksheetInfo();
  technicians: Technician[] = [];
  receiveJobEmployee: ReceiveJobEmployee = new ReceiveJobEmployee();
  receiveJobDate: string;
  receiveJobHour: number;
  receiveJobMinute: number;
  receiveJobRemark: string;
  allTechnicians: AllTechnician[] = [];
  employeeId: number = 0
  checkList: CheckList[];
  remark: string;
  edcList: EdcCloseJobApis[] = [];
}
export class AdminBKKWorkAreaModel {
  isOverdue: boolean;
  canRecheckCloseJob: boolean;
  isChecked: boolean;
  isView: boolean;
  isEdit: boolean;
  isResponsible: boolean;
  workSheetNo: string;
  id: number;
  zone: Status;
  bankName: string;
  man: number;
  state: number;
  status: Status;
  actionState: number;
  type: Status;
  subType: Status;
  startDate: Date;
  endData: Date;
  shopName: string;
  location: string;
  actualDate: Date;
  ownerName: string;
  responsibleName: string;
  canCloseWorksheet: boolean;
  worksheetId: number;
  worksheetStatus: Status;
  operationDate: Date;
  taskResponsible: TaskResponsible;
  taskResponsibleName: string;
  responsibleRemark: string;
  canChangeTechnician: boolean;
  canTechnicianReceiveJob: boolean;
  readyToSubmit: boolean;
  hasCompleted: boolean;
  progresses: Progress[];
  createdByName: string;
  provinceName: string;
  districtName: string;
  subdistrictName: string;
  worksheetOwnerName: string;
  worksheetResponsibleName: string;
  teleport: Teleport;
  totalEdc: number;
  canReviseWorksheet: boolean;
  canReviseCloseJob: boolean;
  ticketDateTime: Date;
  worksheetDate: Date;
  hasPrintWorksheet: boolean;
  canRecheckBroken: boolean;
  isNoMachine: boolean;
  noMachines: NoMachines[] = []
  pickMachineType: number;
  postponeStatus: Status
}
export class NoMachines {
  serialNumber: string;
  remark: string;
}

export class Status {
  id: number = 1040;
  name: string;
}
export class Progress {
  name: string;
  state: number;
}
export class Teleport {
  id: number;
  name: string;
  contactName: string;
  phoneNumber: string;
  address: string;
}
export class AdminBKKWorkAreaSummaryModel {
  taskDate: Date = new Date;
  summary: AdminBKKSummaryModel = new AdminBKKSummaryModel;
  pageResult: PageResponseModel<AdminBKKWorkAreaModel> = new PageResponseModel<AdminBKKWorkAreaModel>();
}

export class AdminBKKSummaryModel {
  total: number = 0;
  overdue: number = 0;
  waiting: number = 0;
  toDo: number = 0;
  doing: number = 0;
  done: number = 0;
  remain: number = 0;
}


export class SearchAdminBKKWorkAreaApiCriteria {
  searchBy: number = 1;
  fromOperationDate: Date = null;
  toOperationDate: Date = null;
  taskDate: string = '';
  textSearch: string = '';
  zoneIdList: Number[] = [];
  actionStateIdList: Number[] = [];
  statusIdList: Number[] = [];
  workTypeIdList: Number[] = [];
  subTypeIdList: Number[] = [];
  worksheetStatusIdList: Number[] = [];
  bankIdList: Number[] = [];
  postponeStatusIdList: Number[] = [];
  isOverdue: boolean = false;
  isTaskDate: boolean = true;
  worksheetNo: string = '';
  shopName: string = '';
  location: string = '';
  districtName: string = '';
  taskOwnerName: string = '';
  taskResponsibleName: string = '';
  operationDate: Date = null;
  fromWorksheetDate: Date = null;
  toWorksheetDate: Date = null;
  districtIdList: Number[] = [];
}

export class AdminBKKModalTechnicianWorkAreaModel {
  id: number;
  technicians: Technician[];
}

export class Technician {
  isSelected: boolean;
  technicianId: number;
  name: string;
  phoneNumber: string;
  totalWork: number;
  profilePictureFile: ProfilePictureFile;
  workloadLevel: number;
}

export class ProfilePictureFile {
  kind: string;
  id: number;
  originalName: string;
  storeName: string;
  mimeType: string;
  sizeInBytes: number;
  url: string;
  fileStream: FileStream;
}

export class FileStream {
  canRead: boolean;
  canSeek: boolean;
  canTimeout: boolean;
  canWrite: boolean;
  length: number;
  position: number;
  readTimeout: number;
  writeTimeout: number;
}


export class WorksheetBKKSetJobViewModel {
  id: number;
  worksheetInfo: WorksheetInfo = new WorksheetInfo();
  technicians: Technician[] = [];
  receiveJobEmployee: ReceiveJobEmployee = new ReceiveJobEmployee();
  receiveJobDate: string;
  receiveJobHour: number;
  receiveJobMinute: number;
  receiveJobRemark: string;
  allTechnicians: AllTechnician[] = [];
  employeeId: number = 0;
  canPrintWorksheetPaper: boolean;
  worksheetPaperDownloadUrl: string
  canPrintWorksheetEdcPaper: boolean;
  worksheetEdcPaperDownloadUrl: string;
  hasPrintPaperHistory: boolean;
}
export class WorksheetAllInformationViewModel {
  id: number;
  workType: WorkType;
  authorize: Authorize;
  clientId: number;
  shopDetailInfo: ShopDetailAllViewModel;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  province: Province;
  district: Province;
  subdistrict: Subdistrict;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFile: AttachFile;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string;
  selectedEdcs: AvailableEdc[] = [];
  attachFiles: AttachFile[] = [];

}
export class ShopDetailAllViewModel {
  id: number;
  bankTel: string;
  bankBranchName: string;
  address: string;
  contactFaxNumber: string;
  callPhoneNumber: string;
  contactName: string;
  contactPhoneNumber: string;
  provinceName: string;
  districtName: string;
  subdistrictName: string;
  email: string;
  location: string;
  reg: string;
  shopCode: string;
  shopNameEN: string;
  shopNameTH: string;
  zipCode: string;
}
export class Group {
  id: number;
  code: string;
  name: string;
  type: Type;
  description: string;
  isActive: boolean;
}
export class Type {
  id: number;
  code: string;
  name: string;
  description: string;
}
export class AttachFiles {
  id: number;
  originalName: string;
  storeName: string;
  mimeType: string;
  sizeInBytes: number;
  type: number;
  url: string;

  getFileSizeInKB() {
    return this.sizeInBytes / 1000;
  }
}
export class WorksheetAllDetailViewModel {
  id: number;
  worksheetInfo: WorksheetInfo = new WorksheetInfo();
  technicians: Technician[] = [];
  receiveJobEmployee: ReceiveJobEmployee = new ReceiveJobEmployee();
  receiveJobDate: string;
  receiveJobHour: number;
  receiveJobMinute: number;
  receiveJobRemark: string;
  allTechnicians: AllTechnician[] = [];
  employeeId: number = 0;
  edcs: EdcCloseJobApis[] = [];
  addonEdcTasks: AddOnEdcJobApis[] = [];
  contactName: string;
  contactPhone: string;
  serviceDate: Date = new Date;
  serviceHour: number = null;
  serviceMinute: number = null;
  postponeForm: Contact = new Contact;
  technicianForm: ForMechanicModel = new ForMechanicModel;
  clientSignaturePictureFile: AttachFile = new AttachFile;
  pictures: Pictures[] = [];
}
export class AddOnEdcJobApis {
  edcId: number;
  backup: string;
  installDate: string;
  main: string;
  mid: string;
  serialNumber: string;
  tid: string;
  remark: string;
  detail: string;
  multi: boolean;
  subjectName: string;
}
export class Pictures {
  categoryId: number;
  categoryName: string;
  pictureFiles: AttachFile[] = [];

}
export class HistoryTechnician {
  technicianId: number;
  technicianName: string;
  changeName: string;
  dateTime: DateUtils;
}
export class Contact {

  contactName: string;
  contactPhone: string;
  convenientDate: Date = new Date;
  convenientHour: number = null;
  convenientMinute: number = null;
}
export class ForMechanicModel {
  detail: string
  attachFile: AttachFile;
  startJobDate: Date;
  startJobHour: number;
  startJobMinute: number;
  finishJobDate: Date;
  finishJobHour: number;
  finishJobMinute: number;
  attachFiles: AttachFile[] = [];
  attachFileIdList: number[] = [];
  clientSignaturePictureFile: AttachFile = new AttachFile;

  actualOperationDate: Date
  actualOperationHour: number
  actualOperationMinute: number

}
export class WorksheetAllViewModel {
  infomation: WorksheetAllInformationViewModel = new WorksheetAllInformationViewModel;
  onsite: WorksheetAllDetailViewModel = new WorksheetAllDetailViewModel;
  timelines: TaskWorkFlowViewModel[] = [];

  // settingCallout : SettingCalloutGetDetailViewModel = new SettingCalloutGetDetailViewModel
  hasSettingCallOut: boolean;
  settingCallOutId: number;

  canCancel: boolean;
  canReject: boolean;
  canPrintWorksheetPaper: boolean;
  worksheetPaperDownloadUrl: string;
  canPrintWorksheetEdcPaper: boolean;
  worksheetEdcPaperDownloadUrl: string;
  hasPrintPaperHistory: boolean;
}
export class AllTechnician {
  employeeId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  fullName: string;
  source: Source;
}

export class ReceiveJobEmployee {
  kind: string;
  id: number = 0;
  code: string;
  firstName: string;
  lastName: string;
  fullName: string;
}
export class SaveAllTechnician {
  employeeId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  fullName: string;
}

export class WorksheetInfo {
  worksheetNo: string;
  workType: WorkType;
  worksheetDate: string;
  operationDate: string;
  assignBy: string;
  technicianName: string;
  status: Status;
  finishDateTime: string;
}
export class AdminBKKModalChangeTechnicianWorkAreaModel {
  id: number;
  currentTechnicians: Technician[];
  technicians: Technician[];
  remark: string;
}
export class SaveWorksheetBKKSetJobModel {
  employeeId: number;
  receiveJobDate: Date;
  receiveJobHour: number;
  receiveJobMinute: number;
  remark: string
}


export class WorksheetBKKRefrestJobViewModel {
  id: number;
  edcs: Edcs[] = [];
  remark: string;
  status: Status = new Status;
  operationDate: string;
  operationHour: number;
  operationMinute: number;
}
export class Edcs {
  isChecked: boolean
  edcId: number;
  modelName: string;
  serialNumber: string;
  mid: string;
  tid: string;
  remark: string;
}
export class WorksheetBKKRefrestJobSaveModel {
  edcs: EdcsSave[] = [];
  remark: string;
  statusId: number;
}
export class EdcsSave {
  edcId: number;
  remark: string;
}