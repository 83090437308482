import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper'; 
import { WorksheetInfoCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model'; 
import { SettingCalloutWorksheetInfo } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';

@Injectable({
    providedIn: "root",
}) 
export class AdminBKKClosejobWorksheetMapper implements Mapper<WorksheetInfoCloseJobApis, SettingCalloutWorksheetInfo> {
    mapForm(param: WorksheetInfoCloseJobApis): SettingCalloutWorksheetInfo {
        const item = new SettingCalloutWorksheetInfo();  
        item.worksheetNo = param.worksheetNo;
        item.worksheetDate = param.worksheetDate;
        item.operationDate = param.operationDate;
        item.assignBy = param.assignBy;
        item.technicianName = param.technicianName;
        item.status = param.status;
        item.finishDate = param.finishDateTime;
        item.call = '-' ;
        return item;
    }
    mapTo(param: SettingCalloutWorksheetInfo): WorksheetInfoCloseJobApis {
        return null
    }
}
