<div class="row">
    <div class="col-md-12">
        <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        {{ '_SHARED.FEATURE_CONNECTOR' | translate }}
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-form">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <span> {{ '_SHARED.LOCAL' | translate }} / {{ '_SHARED.VISA' | translate }} / {{ '_SHARED.M_C' | translate }} / {{ '_SHARED.JCB' | translate }}</span>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group mb-0 pt-2">

                                    <div class="row">
                                        <div class="col-sm-12">
                                            <label class="kt-font-primary">{{'ORDER.SHARED.PANTANCATE'|translate}} : </label>
                                            <span> {{feature.pantancateFormat1?.name}} - {{feature.pantancateFormat2?.name}} -
                                                {{feature.pantancateFormat3?.name}} - {{feature.pantancateFormat4?.name}}</span> &nbsp;
                                            <label class="kt-font-primary">{{'ORDER.SHARED.OTHER'|translate}} : </label>
                                            <span> {{feature.pantancateFormatOther}}</span>
                                            <br />
                                            <label class="kt-font-primary">{{'ORDER.SHARED.PANTANCATE2'|translate}} : </label>
                                            <span> {{feature.pantancateFormat5?.name}} - {{feature.pantancateFormat6?.name}} -
                                                {{feature.pantancateFormat7?.name}} - {{feature.pantancateFormat8?.name}}</span> &nbsp;
                                            <label class="kt-font-primary">{{'ORDER.SHARED.OTHER'|translate}} : </label>
                                            <span> {{feature.pantancateFormatOther2}}</span>
                                            <!-- <label class="kt-font-primary">{{'ORDER.SHARED.CONNECTOR_TYPE'|translate}} : </label>
                                            <span>{{feature.connectorType.name}}</span>
                                            <br />
                                            <label class="kt-font-primary">{{'ORDER.SHARED.SERIAL_NUMBER'|translate}} :
                                            </label><span>{{feature.serialNumber}}</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label class="col-form-label">Pantancate</label>
                                    <div class="row">
                                        <div class="col-sm-12">
                                        <label> XXXX - 1234 - XXXX - 1234</label> <br/>
                                        <label> Connector Type : Rabit (BSS)</label><br/>
                                        <label> Serial number: 16168WL24558586</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>