<div class="modal-header">
    <h5 class="modal-title">TASK DETAIL</h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body modal-body-custom2">
    <div class="kt-portlet kt-portlet--tabs  kt-portlet-no-border">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-toolbar">
                <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-danger nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold"
                    role="tablist">
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:;" [ngClass]="{'active': feature == 1}"
                            (click)="focusTabDetailType(1)">{{ '_SHARED.TASK_INFORMATION' | translate }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="javascript:;" [ngClass]="{'active': feature == 2}"
                            (click)="focusTabDetailType(2)">{{ '_SHARED.TASK_WORKFLOW' | translate }}
                        </a>
                    </li>
                </ul> 
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="tab-content"> 
                <ng-template switch-tab></ng-template>
            </div>
        </div>
    </div> 

</div>
<div class="modal-footer">
    <div class="col-lg-12 text-right">
        <button type="button" class="btn btn-dark waves-effect" (click)="closeModal()">Close</button>
    </div>
</div>