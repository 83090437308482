import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ApiService } from '../http/api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { TokenApiModel } from '../models/auth/token.model';
import { ApiPath } from '../http/api-path';
import { UserProfileModel } from '../models/_shared/user.model';
import { LoginApiRequest } from '../models/auth/request';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly PROFILE = 'PROFILE';

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  private userProfileSubject = new ReplaySubject<UserProfileModel>(null);
  public userProfile$ = this.userProfileSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private permissions: NgxPermissionsService
  ) { }

  async login(username: string, password: string,role:number): Promise<boolean> {
    const loginReqForm: LoginApiRequest = { username, password,role };
    const result = await this.apiService.post<TokenApiModel>(ApiPath.auth.login, loginReqForm);
    if (result) {
      this.setAuth(result);
      await this.loadPermissions();
    }
    return result != null;
  }
  async loginByEmployee(username: string, password: string,role:number): Promise<boolean> {
    const loginReqForm: LoginApiRequest = { username, password , role};
    const result = await this.apiService.post<TokenApiModel>(ApiPath.auth.loginByEmployee, loginReqForm);
    if (result) {
      this.setAuth(result);
      await this.loadPermissions();
    }
    return result != null;
  }

  async logout(): Promise<boolean> {
    const logoutBody = {
      refreshToken: this.getRefreshToken()
    };
    const result = await this.apiService.post<TokenApiModel>(ApiPath.auth.logout, logoutBody);
    if (result) {
      this.clearAuth();
    }
    return result != null;
  }

  async refreshToken(): Promise<TokenApiModel> {
    const body = {
      refreshToken: this.getRefreshToken(),
      accessToken: this.getToken()
    };
    const result = await this.apiService.post<TokenApiModel>(ApiPath.auth.refreshToken, body, false);
    this.storeJwtToken(result.accessToken);
    return result;
  }

  setAuth(tokens: TokenApiModel) {
    this.isAuthenticatedSubject.next(true);
    this.storeTokens(tokens);
  }

  clearAuth() {
    this.removeTokens();
    this.isAuthenticatedSubject.next(false);
    this.userProfileSubject.next(null);
    this.permissions.flushPermissions();
    localStorage.removeItem(this.PROFILE);
  }

  getToken(): string {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  setUserProfile(profile: UserProfileModel) {
    localStorage.setItem(this.PROFILE, JSON.stringify(profile));
    this.userProfileSubject.next(profile);
  }

  getUser(): UserProfileModel {
    const json = localStorage.getItem(this.PROFILE);
    const user = JSON.parse(json);
    return Object.assign(new UserProfileModel, user);
  }

  hasLogined(): boolean {
    return !!this.getToken();
  }

  async loadPermissions() {
    if (!this.hasLogined()) { return; }
    const permissions = await this.apiService.get<Array<string>>(ApiPath.auth.permissions);
    this.permissions.loadPermissions(permissions);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: TokenApiModel) {
    this.storeJwtToken(tokens.accessToken);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
