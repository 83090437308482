import { Component, OnInit } from '@angular/core';
import { TaskWorkFlowViewModel, TaskWorkFlow } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkSheetViewEdcComponent } from '../../../cs/view/view-edc/view-edc.component';
import { SharedWorkAreaModalComponent } from '../modal/shared-work-area-modal/shared-work-area-modal.component';

@Component({
  selector: 'app-time-line',
  templateUrl: './time-line.component.html',
  styleUrls: ['./time-line.component.scss']
})
export class WorksheetAllTimeLineComponent implements OnInit {
 
  items : TaskWorkFlowViewModel[] = []
  constructor(  
    private modalService: NgbModal
    ) { }

  ngOnInit(): void {  
    console.log(this.items)
  }
  
  OnViewClick(item: TaskWorkFlow) {
    console.log(item);
    const modalRef = this.modalService.open(WorkSheetViewEdcComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'width70'
    });
    modalRef.componentInstance.edcId = item.edcId;
  }
  OnViewRemarkClick(item: TaskWorkFlowViewModel) {
    console.log(item);
    const modalRef = this.modalService.open(SharedWorkAreaModalComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'width70'
    });
    modalRef.componentInstance.items = item.moreMaterials;
  }
}
