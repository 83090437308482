<div class="kt-portlet kt-portlet--mobile" data-ktportlet="true">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                EDC List
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body"  >
        <div class="table-responsive form-group">
            <table id="kt_table_1" class="table table-striped- table-bordered table-hover table-checkable">
                <thead>
                <tr align="center">
                    <th width="2%" nowrap="">{{'_SHARED.NO' | translate}}</th>
                    <th width="2%" nowrap="">{{'_SHARED.ACTION' | translate}}</th>
                    <th width="2%" nowrap="">{{'_SHARED.TYPE' | translate}}</th>
                    <th width="5%" nowrap="">{{'_SHARED.REMARK' | translate}}</th>
                    <th width="5%" nowrap="">{{'_SHARED.STATUS' | translate}}</th>
                    <th width="14%" nowrap="">{{'_SHARED.S_N' | translate}}</th>
                    <th width="" nowrap="">{{'_SHARED.MODEL' | translate}}</th>
                    <th width="10%" nowrap="">{{'_SHARED.STAND_ALONE_TID' | translate}}</th>
                    <th width="10%" nowrap="">{{'_SHARED.TID' | translate}}</th>
                    <th width="10%" nowrap="">{{'_SHARED.MID' | translate}}</th>
                    <th width="5%" nowrap="">{{'_SHARED.INSTALL_DATE' | translate}} </th>
                    <th width="5%" nowrap="">{{'_SHARED.MULTHI' | translate}}</th>
                    <th width="5%" nowrap="">{{'_SHARED.MAIN' | translate}}</th>
                    <th width="5%" nowrap="">{{'_SHARED.BACKUP' | translate}}</th>
                </tr>
                <tr align="center">
                    <td align="center"></td>
                    <td align="center">

                    </td>
                    <td align="center"></td>
                    <td align="center"></td>
                    <td align="center"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.S_N' | translate"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.MODEL' | translate"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.STAND_ALONE_TID' | translate"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.TID' | translate"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.MID' | translate"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.INSTALL_DATE' | translate"></td>
                    <td align="center"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.MAIN' | translate"></td>
                    <td align="center"><input class="form-control ng-untouched ng-pristine ng-valid" [placeholder]="'_SHARED.BACKUP' | translate"></td>
                </tr>
                </thead>
                <tbody>
                <tr class="ng-star-inserted" *ngFor="let item of items; let i = index;">
                    <td align="center">{{ i +1}}</td>

                    <td align="center" nowrap="">
                        <a class="cursor-pointer" href="javascript:;" (click)="OnViewClick(item)" ><i class="flaticon-eye"></i></a>
                    </td>
                    <td align="center" nowrap=""> 
                        <span class="badge badge-dark badge-custom" >{{item.status?.name}}</span>
                    </td>
                    <td align="center" nowrap="">
                        <a class="cursor-pointer"  (click)="onRemarkClick(item)"><i class="fa fa-file"></i></a>
                    </td>
                    <td align="center" nowrap="">
                        <span class="badge badge-dark badge-custom">{{item.reprogramType?.name}}</span>
                    </td>
                    <td align="center" nowrap="">{{item.serialNumber}}</td>

                    <td align="left" nowrap="">
                        {{item.modelName}}
                    </td>
                    <td align="center" nowrap="">{{item.standAlondTid}}</td>
                    <td align="center" nowrap="">{{item.tid}}</td>
                    <td align="center" nowrap="">{{item.mid}}</td>
                    <td align="center" nowrap="">{{item.installDate | date :'dd-MM-yyyy'}}</td>
                    <td align="center" nowrap=""><i class="la la-check ng-star-inserted" *ngIf="item.multhi"></i>
                    </td>
                    <td align="center" nowrap="" >{{item.main}}</td>
                    <td align="center" nowrap="">{{item.backup}}</td>
                </tr> 
                </tbody>
            </table>
        </div>
        <div class="kt-pagination kt-pagination--brand">
            <div class="kt-pagination__toolbar">
                <span class="pagination__desc"> {{'_SHARED.DISPLAYING' | translate}} {{items.length}} {{'_SHARED.RECORDS' | translate}} </span>
            </div>
        </div>
    </div>
</div>