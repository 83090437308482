import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class DateUtils {
    static YYYY_MM_DD = 'YYYY-MM-DD';
    static YYYY_MM_DD_HH_mm_ss = 'YYYY-MM-DD HH:mm:ss';
    static YYYY_MM_DD_HH_mm_ss_2 = 'YYYY-MM-DDTHH:mm:ss';

    static toCurrentLangFormat(date: string): string {
        moment().locale('en');
        const d = moment(date);
        let initDateText = d.lang('en').format('DD/MM/');
        initDateText += '' + (d.year() + 543);
        return initDateText;
    }

    static toDate(value: string, format = 'YYYY-MM-DD') {
        if (value == null) { return null; }
        const d = moment(value, format);
        return d.toDate();
    }
    static toDateTime(value: string, format = 'YYYY-MM-DD') {
        if (value == null) { return null; }
        const d = moment(value, format);
        return d.toDate();
    }
    static toFormat(date: Date, format = 'YYYY-MM-DD'): string {
        moment().locale('en');
        if (date == null) { return null; }
        const d = moment(date);
        return d.format(format);
    }

    static toDisplayCharFormat(date: Date, format = 'DD/MM/YYYY'): string {
        if (date == null) { return null; }
        const d = moment(date);
        return d.format(format);
    }

    static toCharAndTimeFormat(date: Date, format = "YYYY-MM-DD HH:mm:ss"): string {
        if (date == null) return null;
        let d = moment(date);
        return d.format(format);
      }
      
    static toFormatByAddingTime(date: Date, hour: number, minute: number, second: number,
        format = 'YYYY-MM-DD HH:mm:ss'): string {
        const d = moment(date);
        return d.hours(hour).minutes(minute).seconds(second).format(format);
    }

    static addTime(value: string, format = 'HH:mm:ss') {
        const t = moment(value, format);
        return t;
    }

    static subDays(date: Date, days: number): Date {
        const newDate = new Date();
        newDate.setFullYear(date.getFullYear());
        newDate.setMonth(date.getMonth());
        newDate.setDate(date.getDate() - days);
        return newDate;
    }

    static addDays(date: Date, days: number): Date {
        const newDate = new Date();
        newDate.setFullYear(date.getFullYear());
        newDate.setMonth(date.getMonth());
        newDate.setDate(date.getDate() + days);
        return newDate;
    }
    static setMinDate(day: number, month: number, year: number) {
        const setDate = { day, month, year };
        return setDate;
    }

    static getMinDate(value: Date = new Date) {
        return { year: value.getFullYear() - 1, month: value.getMonth() + 1, day: value.getDate() };
    }

    static getMinDate4Year(value: Date = new Date) {
        return { year: value.getFullYear() - 4, month: value.getMonth() + 1, day: value.getDate() };
    }

    static getFirstOfWeek(date: Date) {
        const today = moment(date);
        const firstDayofWeek = today.day('Sunday');
        return firstDayofWeek;
    }

    static getLastOfWeek(date: Date) {
        const today = moment(date);
        const lastDayofWeek = today.day('Saturday');
        return lastDayofWeek;
    }


}
