import { Injectable } from "@angular/core"; 
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { WorksheetAllInformationViewModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { ContactModel, FileUp } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
import { Mapper } from '../../mapper';

@Injectable({
    providedIn: "root",
})
export class AdminAllWorksheetContactMapper implements Mapper<ContactModel, WorksheetAllInformationViewModel>{
    constructor(
    ) { }
    mapForm(param: ContactModel): WorksheetAllInformationViewModel {
        const item = new WorksheetAllInformationViewModel();
        return null 
    }
    mapTo(param: WorksheetAllInformationViewModel): ContactModel {
        const item = new ContactModel();
        item.contactName = param.contactName
        item.contactPhone= param.contactPhoneNumber
        item.serviceDate = DateUtils.toDate(param.convenientDate) ;
        item.serviceHour = param.convenientHour
        item.serviceMinute = param.convenientMinute
        item.commandFile = new FileUp
        item.commandFile.attachFileId = param.attachFile?.id;
        item.commandFile.name = param.attachFile?.originalName
        item.commandFile.url = param.attachFile?.url
        item.attachFiles = param.attachFiles
        return item
    }

}