<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.SHARED.PARTITION'|translate}}
            </h3>
        </div>
    </div>

    <div class="kt-portlet__body">
        <div class="kt-form">
            <table class="table table-striped- table-bordered table-hover table-checkable table-responsive">
                <thead>
                    <tr align="center">
                        <th width="5%">{{'ORDER.SHARED.NO'|translate}}</th>
                        <th nowrap="" width="5%">{{'ORDER.SHARED.MAIN'|translate}}</th>
                        <th nowrap="" width="10%">{{'ORDER.SHARED.TERMINAL_ID'|translate}}</th>
                        <th nowrap="" >{{'ORDER.SHARED.MERCHANT_ID'|translate}}</th>
                        <th nowrap="" width="13%">{{'ORDER.SHARED.BANK/HOST'|translate}}</th>
                        <th nowrap="" width="2%" *ngFor="let function of functions;">{{function.name}}</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of data; index as i">
                        <td align="center">{{i + 1}}</td>
                        <td align="center">
                            <label class="kt-radio kt-radio--bold kt-radio--brand left-10-bottom-15">
                                <input type="radio" [checked]="item.isMain" disabled >
                                <span></span>
                            </label>
                        </td>
                        <td>
                            <label class="kt-font-primary font-18">{{item.terminalId}} </label>
                        </td>
                        <td>
                            <label class="kt-font-primary font-18">{{item.merchantId}} </label>
                        </td>
                        <td>
                            <label class="kt-font-primary font-18">{{item.bankHost?.name}} </label>
                        </td>
                        <td align="center" *ngFor="let function of item.functions;">
                          

                            <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand disbled-div-view">
                                <input type="checkbox" [(ngModel)]="function.selected"   
                                    [ngModelOptions]="{standalone: true}">
                                <span></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>