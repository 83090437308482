<div class="table-responsive">
    <table class="table table-striped- table-bordered table-hover table-checkable table-responsive">
        <thead>
            <tr align="center">
                <th width="5%">{{'ORDER.SHARED.NO'|translate}}</th>
                <th nowrap="" width="5%">{{'ORDER.SHARED.MAIN'|translate}}</th>
                <th nowrap="" width="10%">{{'ORDER.SHARED.TERMINAL_ID'|translate}}</th>
                <th nowrap="">{{'ORDER.SHARED.MERCHANT_ID'|translate}}</th>
                <th nowrap="" width="13%">{{'ORDER.SHARED.BANK/HOST'|translate}}</th>
                <th nowrap="" width="2%" *ngFor="let function of functions;">{{function.name}}</th>
                <th nowrap=""></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of data; index as i">
                <td align="center">{{i + 1}}</td>
                <td align="center">
                    <label class="kt-radio kt-radio--brand">
                        <input type="radio" [checked]="item.isMain" disabled>
                        <span></span>
                    </label>
                </td>
                <td>
                    <label class="kt-font-primary">{{item.terminalId}} </label>
                </td>
                <td>
                    <label class="kt-font-primary">{{item.merchantId}} </label>
                </td>
                <td>
                    <label class="kt-font-primary">{{item.bankHost?.name}} </label>
                </td>
                <td align="center" *ngFor="let function of item.functions;">
                    <label class="kt-checkbox kt-checkbox--brand">
                        <input type="checkbox" [(ngModel)]="function.selected" disabled
                            [ngModelOptions]="{standalone: true}">
                        <span></span>
                    </label>
                </td>
                <td class="text-center">
                    <a title="Management" class="btn btn-sm btn-clean btn-icon btn-icon-sm btn-cus"
                        *ngIf="showSettingBilling(item)" (click)="doChangeBilling(item)"><i class="la la-cog"></i></a>
                </td>
            </tr>
        </tbody>
    </table>
</div>