import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedWorksheetService } from 'src/app/core/services/_sprint3/worksheet/worksheet.service';
import { HistoryTechnician } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';

@Component({
  selector: 'app-history-technician',
  templateUrl: './history-technician.component.html',
  styleUrls: ['./history-technician.component.scss']
})
export class HistoryTechnicianComponent implements OnInit { 
  items: HistoryTechnician[] = [];
  id:number;
  constructor(
    private sharedWorksheetService: SharedWorksheetService,
    public activeModal: NgbActiveModal,
    ) { }

    ngOnInit(): void {
      this.loadData();
    }
  
    async loadData() {
      this.items = await this.sharedWorksheetService.getChangeTechnician(this.id);
    }

}
