import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ARValidators } from 'src/app/shared/validator/validatorsSpace.validators';
import { SharedWorksheetService } from 'src/app/core/services/_sprint3/worksheet/worksheet.service';
import { SaveRejectModel } from 'src/app/core/models/user/worksheet/worksheet-store';

@Component({
  selector: 'app-modal-worksheet-reject',
  templateUrl: './modal-worksheet-reject.component.html',
  styleUrls: ['./modal-worksheet-reject.component.scss']
})
export class ModalWorksheetRejectComponent implements OnInit {
 
  public onReturnData: EventEmitter<any> = new EventEmitter();
  // responsibleSelect: ResponsibleModel = new ResponsibleModel;
  submitted: boolean = false;
  form: FormGroup;
  id:number;
  remark:string; 
  constructor(
    public activeModal: NgbActiveModal, 
    private dialogService: MyDialogService, 
    private fb: FormBuilder,
    private sharedWorksheetService: SharedWorksheetService,  
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({ 
      remark: ["",Validators.required]
    })
    this.loadDataList(); 
  }

  async loadDataList() { 
    this.form.patchValue({ 
      remark: this.remark,  
   });
  }

  get f() {
    return this.form.controls;
  }
   
  onConfirmSave() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.submitted && !this.form.invalid) {
      return;
    }
    this.dialogService.confirmSaveData(() => this.doCreate());
  }
  async doCreate() {
    const model: SaveRejectModel = { 
      remark:this.form.value.remark
    };
    const result = await this.sharedWorksheetService.saveReject(model,this.id);
    if (result) {
      this.dialogService.successProceed(() => 
      this.activeModal.close());
    }
    this.onReturnData.emit();
  }
}
