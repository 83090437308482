import { ShopViewModel } from '../../_shop/shop.view-model';
import { CommandModel, MechanicModel, ContactModel } from './worksheet-model';
import { Client, AvailableEdc } from '../../_shared/worksheet.view-model';
import { ReferWorksheetInfo } from './worksheet-pm-model';


export class WorksheetSupportViewModel {
  id: number;
  shop: Client = new Client;
  location: Client = new Client;
  mechanic:  MechanicModel = new MechanicModel;
  eventTypeId:number;
  command: CommandModel = new CommandModel;
  contact: ContactModel = new ContactModel;
  remark: string = ''; 
  availableEdcs: AvailableEdc[] = [];
  selectedEdcs: AvailableEdc[] = [];
  referWorksheetInfo:ReferWorksheetInfo = new ReferWorksheetInfo;
}
export class WorksheetRefViewModel {
  worksheetNo: string;
  worksheetDate: string;
  operationDate: string;
  assignByName: string;
  technicianName: string;
  statusName: string;
  finishDateTime: string;
  phoneNumber: string;
  worksheetType: string; 
  worksheetId:number;
}
export class OrderEdcListViewModel {
  checked: boolean = false;
  id: number;
  orderNo: string;
  edcId: number;
  orderDate: string;
  serialNumber: string;
  tid: number;
  mid: number;
  modelName: string;
  main: string;
  backUp: string;  
  remark:string
}

export class MechanicGallery {
  id: number;
  name: string;
  gallerList: GallerList[] = []
  totalGallery: number;
}
export class GallerList {
  id: number;
  name: string;
  url: string;
}