export const statusworksheetConst = {
  zoneStatus: { 
    bkk: 1,
    upc: 2, 
  }, 
  stateStatus: { 
    todo: 0,
    doing: 3, 
    done: 4, 
    waiting: 5,
  }, 
  statusStatus: { 
    none: 0,
    save: 500, 
    submit: 501, 
  }, 
  typeStatus: { 
    permanence: 0,
    onsite: 1,
    install: 2,
    service: 3,
    support:4,
    remove:5,
    reProgram:6,
    pm:7,
    general:8
  }, 
  subtypeStatus: { 
    permanance: 0,
    promotion: 1,
    join: 2, 
  }, 
  progress: {  
    isprogress: 1,
    unprogress: 2, 
  }, 
  worktype: {  
    install: 1,
    reProgram:2,
    remove:3,
    service: 4,
    support:5,
    pm:6,
    general:7
  },

  colorStatus: { 
    none: 0,
    min: 1, 
    normal: 2, 
    higt: 3,
  }, 
  refreshStatus: { 
    all: 1040,
    noAll: 1041, 
  }, 
};