export const StatusConstSp3 = {
  tabType: {
    Information: 3,
    timeline: 4,
  },
  zoneStatus: {
    bkk: 1,
    upc: 2,
  },
  stateStatus: {
    waiting: 0,
    todo: 1,
    doing: 2,
    done: 3,
  },
  statusStatus: {
    none: 0,
    save: 500,
    submit: 501,
  },
  typeStatus: {
    other: 0,
    isJoin: 1,
    unJoin: 2,
  },
  progress: {
    isprogress: 1,
    unprogress: 2,
  },
  stockControlStatus: {
    installed: 0,
    available: 1,
  },
  type: {
    permanence: 0,
    onsite: 1,
    install: 2,
    service: 3,
    support: 4,
    remove: 5,
    reProgram: 6,
    pm: 7,
    general: 8
  },
  subType: {
    permanence: 1
  },
  fromStatus: {
    waiting: 0,
    todo: 1,
    success: 2,
  },
  tplStatus: {
    todo: 0,
    doing: 3,
    done: 4,
    waiting: 5,
    none: -3
  },
  packingState: {
    none: 0,
    approve: 1
  },
  stateInboundStatus: {
    pending: 1,
    inProgress: 2,
    complete: 3,
  },
  nextTo: {
    store: 0,
    loader: 1,
    packing: 2,
    setting: 3
  },
  installUpdate: {
    yes: 1,
    no: 0
  },
  stateSettingStatus: {
    todo: 0,
    doing: 3,
    done: 4,
    waiting: 5,
  },

};
