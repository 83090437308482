import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class MyDialogService {

    constructor(
        private translateService: TranslateService
    ) { }
    
    public confirmExport(callback: () => void) {
        const confirmTitle = this.translateService.instant('CONFIRM.EXPORT');
        const confirmText = this.translateService.instant('CONFIRM.EXPORT_DATA');
        this.confirm(confirmTitle, confirmText, callback);
    }

    public confirmStartTimeCount(callback: () => void) {
        const confirmTitle = this.translateService.instant('CONFIRM.WARNING');
        const confirmText = this.translateService.instant('CONFIRM.START_TIME_COUNT');
        this.confirm(confirmTitle, confirmText, callback);
    }
    public confirmSetTechnician(callback: () => void) {
        const confirmTitle = this.translateService.instant('CONFIRM.WARNING');
        const confirmText = this.translateService.instant('CONFIRM.SET_TECHNICIAN');
        this.confirm(confirmTitle, confirmText, callback);
    }

    public confirmSaveData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.SAVE_DATA');
        this.confirm(null, confirmText, callback);
    }
    
    public confirmSendData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM_SEND_DATA');
        this.confirm(null, confirmText, callback);
    }

    public confirmSubmitData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.SUBMIT_DATA');
        this.confirm(null, confirmText, callback);
    }

    public confirmRejectData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.REJECT_DATA');
        this.confirm(null, confirmText, callback);
    }

    public confirmApproveData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.APPROVE_DATA');
        this.confirm(null, confirmText, callback);
    }
    public confirmCancelData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.CANCEL_DATA');
        this.confirm(null, confirmText, callback);
    }
    public confirmResetData(callback: () => void, calcelBack: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.RESET_DATA');
        this.confirmWithCancel(null, confirmText, callback, calcelBack);
    }

    public confirmDeleteData(callback: () => void) {
        const confirmText = this.translateService.instant('CONFIRM.DELETE_DATA');
        this.confirm(null, confirmText, callback);
    }

    public confirmWithCancel(title: string, text: string, acceptCallback: () => void, cancelCallback: () => void) {
        const confirmText = this.translateService.instant('BUTTON.YES');
        const cancelText = this.translateService.instant('BUTTON.CANCEL');
        Swal.fire({
            title,
            text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmText,
            cancelButtonText: cancelText
        }).then((result) => {
            if (result.value) {
                acceptCallback();
            }
            else if (result.dismiss === Swal.DismissReason.cancel) {
                cancelCallback();
            }
        });
    }

    public confirm(title: string, text: string, acceptCallback: () => void) {
        const confirmButtonText = this.translateService.instant('BUTTON.YES');
        const cancelButtonText = this.translateService.instant('BUTTON.CANCEL');
        Swal.fire({
            title,
            text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText,
            cancelButtonText
        }).then((result) => {
            if (result.value) {
                acceptCallback();
            }
        });
    }

    public confirmCreate(title: string, text: string, acceptCallback: () => void,canncelCallback?: () => void) {
        const confirmButtonText = this.translateService.instant('BUTTON.YES');
        const cancelButtonText = this.translateService.instant('BUTTON.CANCEL');
        Swal.fire({
            title,
            text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText,
            cancelButtonText
        }).then((result) => {
            if (result.value) {
                acceptCallback();
            } else if (result.dismiss == Swal.DismissReason.cancel) {
                canncelCallback();
            }
        });
    }
    public confirmCancel(title: string, text: string, acceptCallback: () => void, canncelCallback: () => void) {
        Swal.fire({
            title,
            text,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.value) {
                acceptCallback();
            } else if (result.dismiss == Swal.DismissReason.cancel) {
                canncelCallback();
            }
        })
    }

    public error(text: string, title: string = null, callback?: () => void) {
        Swal.fire({
            icon: 'error',
            title: 'Error!',
            text,
            allowOutsideClick: false
        }).then(result => {
            if (callback !== undefined) { callback(); }
        });
    }

    public errorNoTitle(text: string, callback?: () => void) {
        Swal.fire({
            icon: 'error',
            text,
            allowOutsideClick: false
        }).then(result => {
            if (callback !== undefined) { callback(); }
        });
    }

    public successProceed(callback?: () => void) {
        const text = this.translateService.instant('ACTION.RESULT_PROCESS_COMPLETED');
        this.success(text, callback);
    }

    public success(text: string, callback?: () => void) {
        Swal.fire({
            icon: 'success',
            title: text,
            showConfirmButton: false,
            timer: 1500,
            allowOutsideClick: false
        }).then(result => {
            if (callback !== undefined) { callback(); }
        });
    }

    public warningValidate(callback?: () => void) {
        const title = this.translateService.instant('ACTION.TITLE_VALIDATE_FAIL');
        const text = this.translateService.instant('ACTION.VALIDATE_FAIL');
        this.warning(title, text, callback);
    }
    
    public warningValidateTest(textSwap: string, callback?: () => void) {
        const title = this.translateService.instant('ACTION.TITLE_VALIDATE_FAIL');
        const text = this.translateService.instant(textSwap);
        this.warning(title, text, callback);
    }

    public warning(title: string, text: string, callback?: () => void) {
        Swal.fire({
            icon: 'warning',
            title,
            text,
            showConfirmButton: false,
            timer: 1500,
            allowOutsideClick: false
        }).then(result => {
            if (callback !== undefined) { callback(); }
        });
    }
    
    public warningNotitle(textLang: string, callback?: () => void) {
        const title = this.translateService.instant('ACTION.TITLE_VALIDATE_FAIL');
        const text = this.translateService.instant(textLang);
        Swal.fire({
            icon: 'warning',
            title,
            text,
            showConfirmButton: false,
            timer: 1500,
            allowOutsideClick: false
        }).then(result => {
            if (callback !== undefined) { callback(); }
        });
    }
}
