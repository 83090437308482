import { Component, OnInit, Input } from '@angular/core';
import { Client } from 'src/app/core/view-models/_shared/worksheet.view-model';
import { ProvinceModel, DistrictModel, SubdistrictModel } from 'src/app/core/models/_shared/fix.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-worksheet-location-view-shared',
  templateUrl: './worksheet-location-view-shared.component.html',
  styleUrls: ['./worksheet-location-view-shared.component.scss']
})
export class WorksheetLocationViewSharedComponent implements OnInit {

  @Input() location: Client = new Client();  
  submitted: boolean;

  constructor( 
  ) { }

  ngOnInit(): void { 
  }  
  public async setInitial(item: Client) {
    this.location = item;
    console.log(this.location);
     
  } 
 
}
