import { UpdateStatus } from 'src/app/core/models/_sprint3/store/work-area.model';
import { EdcListViewModel } from '../store/work-area-edc.model';



export class PackingTaskInformationViewModel {
  id: number;
  authrorize: PackingAuthrorize;
  info: PackingTaskInformationListModel;
  checkList: CheckList[];
  trackingNumber: string = '';
  conNumber: string = '';
  remark: string = '';
  currentStatus: Status = new Status;
  updateStatus: UpdateStatus;
}
export class PackingTaskWrokareaModel { 
  trackingNumber: string = '';
  conNumber: string = '';
  remark: string = ''; 
  edcList:EdcListViewModel[]=[];
} 
export class PackingSaveTaskWrokareaModel{
  trackingNumber: string = '';
  conNumber: string = '';
  remark: string = ''; 
  idList:number[]=[];
}
export class PackingAuthrorize {
  canRejectStore:boolean;
  canRejectSetting:boolean;
  canSave: boolean;
  canSubmit: boolean;

  canSwitch: boolean;
  canReset: boolean; 
  canTakeNewEdc: boolean;
  canTakeNewConnector: boolean;
  canTakeNewSim: boolean;
}
export class CheckList {
  id: number;
  canCheck: boolean;
  isChecked: boolean;
  topicName: string;
  detail: string;
}
export class PackingTaskInformationListModel {
  froms: State[];
  command: Status;
  state: Status;
  status: Status;
  workType: WorkType;
  subType: Status;
  nextTos: State[];
  edcId: number;
  serialNumber: string;
  sapMaterialId:number;
  materialTypeName: string;
  tid: string;
  worksheetId: number;
  worksheetNo: string;
  startDateTime: Date;
  endDateTime: Date;
  modelName: string;
  box: string;
  location: string;
  actualDateTime: Date;
  taskOwnerName: string;
  taskResponsibleName: string;
  quantity: string; 
  measure: string; 
  requestRemark: string;
}
export class WorkType {
  id: number;
  name: string;
  description: string;
}
export class State {
  state: number;
  name: string;
}
export class Status {
  id: number;
  name: string;
}
export class TaskWorkFlow {
  edcId: number;
  taskDate: Date;
  taskName: string;
  statusName: string;
  taskOwnerName: string;
  taskDetail: string; 
}
export class TaskWorkFlowViewModel {
  taskId: number;
  taskDate: Date;
  taskName: string;
  commandName: string;
  actorName: string;
  actionEmployeeName: string;
  remark: string; 
  isCurrent: boolean;
  hasMoreMaterialDetail:boolean
  moreMaterials: MoreMaterial[]=[]
 
}
export class MoreMaterial{
  itemType: number;
  taskId: number;
  taskState: number;
  bankName: string;
  serialNumber: string;
  modelName: string;
  tid: string;
  remark: string;
  ticketDateTime: Date;
  finishDateTime: Date;
  taskResponsibleName: string; 

}
export class Information {
  froms: State[] = []; 
  command: Status;
  commandName:string;
  stateName:string;
  workTypeName:string;
  taskResponsible:string;
  statusName:string;
  workSubTypeName:string;
  state: Status;
  status: Status;
  workType: WorkType;
  subType: Status;
  nextTos: State[]; 
  edcId:number;
  serialNumber: string;
  sapMaterialId:number;
  materialTypeName: string;
  materialCode: string;
  tid: string;
  type: string;
  worksheetId:number;
  worksheetNo: string;
  actualDate: Date;
  startDateTime: Date;
  startDate: Date;
  endDateTime: Date;
  EndDate: Date;
  modelName: string;
  modalName: string;
  box: string;
  location: string;
  actualDateTime: Date;
  taskOwnerName: string;
  taskResponsibleName: string;
  quantity: string; 
  measure: string; 
  requestRemark: string;
}
export class CommandModel {
  id:number;
  name:string
}
export class UpdatePackingWorkareaEdc {
  trackingNumber: string;
  conNumber: string;
  remark: string;
}