import { Injectable } from '@angular/core'; 
import { Mapper } from '../../mapper';
import { StoreTaskInfoViewModel } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { StoreTaskInfoViewApisModel } from 'src/app/core/models/_sprint3/store/work-area.model';
import { SettingTaskInformationViewModel } from 'src/app/core/view-models/_sprint3/_setting/task-model';
import { SettingTaskInformationApisModel } from 'src/app/core/models/_sprint3/setting/setting-edc-view-model';

@Injectable({
    providedIn: "root",
  })
export class GetSettingEdcMapper implements Mapper<SettingTaskInformationViewModel,SettingTaskInformationApisModel> {
    mapForm(param: SettingTaskInformationViewModel): SettingTaskInformationApisModel {
        return{
            ...param
        }
    }
    mapTo(param: SettingTaskInformationApisModel): SettingTaskInformationViewModel {
        return{
            ...param
        }
    }
}
 