import { Status } from '../../models/user/worksheet/worksheet';
import { OutboundSatisfactionWorksheetInfo } from '../../models/user/help-desk/outbound-model';
import { ReprogramCase } from '../_sprint3/_shared/reprogram-case';

export class workSheetListViewModel {
    orderId: number;
    worksheetNo: number;
    orderNo: string;
    orderDate: string;
    customer: string
    shopName: string;
    address: string;
    amount: number;
    typeOfWork: typeOfWork;
    typeOfWorkStatus: number;
    eventType: string;
    DatePeriod: string;
    status: number;
    id: number;
    // responsible:string;

}
export class typeOfWork {
    code: string;
    id: number
    kind: string;
    nameEN: string;
    nameTH: string;
    name: string;
}

export class workSheetViewModel {
    shopDetail: ShopDetail;
    order: OrderDetail
    workSheetDetail: WorkSheetDetail
}
export class ShopDetail {
    orderId: number;
    worksheetNo: number;
    shopCode: number;
    shopNameTH: string;
    shopNameEN: string;
    address: string;
    province: string;
    district: string;
    subDistrict: string;
    postCode: number;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
}
export class OrderDetail {
    orderId: number;
    worksheetNo: number;
    orderNo: string;
    orderDate: string;
    s_n: string;
    tid: number;
    mid: number;
    installDate: string;
    reProgramDate: string;
    shopName: string;
    addressOrder: string;
    multhi: true;
    main: string;
    backup: string;
    typeOfWork: string;
}
export class WorkSheetDetail {
    orderId: number;
    worksheetNo: number;
    startDate: string;
    endDate: string;
    problem: string;
    solution: string;
    rootCause: string;
    responsible: string;
}

export class WorkSheetOrderObjectsViewsModel {
    id: number;
    orderNo: string;
    orderDate: string;
    s_n: string
    tid: number;
    mid: number;
    installDate: string;
    removeDate: string;
    reProgramDate: string;
    shopName: string;
    addressOrder: string;
    multhi: true;
    main: string;
    backup: string;
    typeOfWork: string;
    detail: string;
    checked: boolean;
}
export class WorkSheetOp {
    orderId: number;
    worksheetNo: number;
    orderDate: string;
    sN: string;
    tid: number;
    mid: number;
    installDate: string;
    shopName: string;
    address: string;
    multi: number;
    status: number;
    launchDate: string;
}
// ws cs
export class CreateUpdateWorkSheetViewModel {
    startDate: Date;
    endDate: Date;
    problem: string;
    solution: string;
    rootCause: string;
    edcIdList: number[];
    saveAction: number;
    clientContactName: string;
    clientContactTel: string;
    clientOperationDate: Date;
    clientOperationHour: number = -1;
    clientOperationMinute: number = -1;
}

export class SearchOrderInWorkSheetViewModel {
    orderId: number;
    id: number;
    documentNo: string;
    orderDate: Date;
    isUrgent: boolean;
    orderType: number;
    shopNameTH: string;
    address: string;
    edcAmount: number;
    eventType: string;
    datePeriod: Date;
    actionState: number;
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
    status: WorkSheetStatus = new WorkSheetStatus();
    typeOfWork: TypeOfWork = new TypeOfWork();
}

export class OrderDetailListInWorksheet {
    order: WorkSheetOrderObjects = new WorkSheetOrderObjects();
    client: Client = new Client();
    availableEdcs: AvailableEdc[] = [];
}


export class ClientDetailListInWorksheet {
    client: Client = new Client();
    typeOfWork: number;
    eventType: number;
    availableEdcs: AvailableEdc[] = [];
    selectedEdcs: AvailableEdc[] = [];
}

export class WorkSheetUpdateDetail {
    availableEdcs: AvailableEdc[] = [];
    selectedEdcs: AvailableEdc[] = [];
    authorize: Authorize = new Authorize();
    kind: string;
    id: number;
    documentNo: string;
    startDate: Date;
    endDate: Date;
    problem: string;
    solution: string;
    rootCause: string;
    clientContactName: string;
    clientContactTel: string;
    clientOperationDate: Date;
    clientOperationHour: number = -1;
    clientOperationMinute: number = -1;
    isSentToStore: boolean;
    eventType: number;
    typeOfWork: number;
    client: Client = new Client();
    branchName: string;
    locationCode: number;
    nameTH: string;
    nameEN: string;
    address: string;
    province: Province;
    district: District;
    subDistrict: SubDistrict;
    zipCode: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    location: string;
    reg: string;
    bankPhoneNumber: string;
    bankName: string

    order: WorkSheetOrderObjects = new WorkSheetOrderObjects();
    worksheetInfo: OutboundSatisfactionWorksheetInfo = new OutboundSatisfactionWorksheetInfo();
}


export class BusinessUnit {
    id: number;
    isActive: boolean;
    code: string;
    name: string;
    description: string;
}

export class Branch {
    id: number;
    isActive: boolean;
    businessUnitName: string;
    customerBusinessUnitId: number;
    code: string;
    name: string;
    description: string;
}

export class WorkSheetOrderObjects {
    id: number
    orderNo: string;
    orderDate: Date;
    jobNumber: string;
    lot: string;
    businessUnit: BusinessUnit;
    branch: Branch;
    branchName: string;
    buName: string;
    officeCode: string;
    isUrgent: boolean;
}

export class Type {
    id: number;
    code: string;
    name: string;
    description: string;
}

export class Group {
    id: number;
    code: string;
    name: string;
    type: Type;
    description: string;
    isActive: boolean;
}

export class Province {
    id: number;
    nameTH: string;
    nameEN: string;
}

export class District {
    id: number;
    nameTH: string;
    nameEN: string;
}

export class SubDistrict {
    id: number;
    nameTH: string;
    nameEN: string;
    latitude: number;
    longitude: number;
    zipCode: number;
}

export class Client {
    id: number;
    code: string;
    group: Group;
    branchName: string;
    nameTH: string;
    nameEN: string;
    address: string;
    addressTH: string;
    province: Province = new Province();
    district: District = new District();
    subDistrict: SubDistrict = new SubDistrict();
    zipCode: string;
    contactName: string;
    contactPhoneNumber: string;
    contactTel: string;
    contactFaxNumber: string;
    email: string;
    placeInLocation: string;
    location?: string;
    reg: string;
    bankPhoneNumber: string;
    bankName: string;
    provinceId: number;
    districtId: number;
    subdistrictId: number;
    posCode: string;
    locationCode: string;
    bankTel: string;
    bankBranchName: string;
    provinceName: string;
    districtName: string;
    subDistrictName: string;
    isHq: boolean;
}

export class SelectedEdc {
    orderNo: string;
    orderDate: Date;
    edcId: number;
    backup: string;
    endDate: Date;
    installDate: Date;
    main: string;
    mid: string;
    modelName: string;
    multhi: boolean;
    serialNumber: string;
    standAlondTid: string;
    startDate: Date;
    status: number;
    tid: string;
}

export class AvailableEdc {
    isChecked: boolean = false;
    isShow: boolean = true;
    hasChangeEdc: boolean = false;
    changedSerialNumber: number;

    orderNo: string;
    orderDate: Date;
    operation: OperationEdc;
    backup: string;
    endDate: Date;
    installDate: Date;
    main: string;
    mid: string;
    modelName: string;
    multhi: boolean;
    serialNumber: string;
    standAlondTid: string;
    startDate: Date;
    status: Status;
    inventoryStatus: Status;
    tid: string;
    remark: string;
    edcId: number;
    oldEdcId: number;
    reprogramCaseRemark: string;
    reprogramCase: ReprogramCase = new ReprogramCase; 
    serialNumberReplace: string;
    tidReplace: string;
    hasReplaceEdc: boolean;
    replaceSerialNumber: string;
    replaceTid: string;
    connectors: Connector[]=[]
    onsiteItemTypeId:number;
}
export class Connector {
    isActive: boolean;
    connectorId: number;
    connectorName: string;
    remark: string;
}
export class AvailableEdcSearch {
    id: number;
    serialNumber: string = '';
    modelName: string = '';
    standAloneTid: string = '';
    tid: string = '';
    mid: string = '';
}
export class OperationEdc {
    completedDate: Date;
    remark: string;
    status: Status;
    operationEmployeeName: string;
    problem: string;
    solution: string;
    rootCause: string;
}
export class TypeOfWork {
    id: number;
    code: string;
    nameTH: string;
    nameEN: string;
    kind: string;
}
export class WorkSheetStatus {
    id: number;
    code: string;
    name: string;
}

export class TextSearchViewModel {
    textSearch: string = '';
}

export class Authorize {
    canSave: boolean;
    canSubmit: boolean;
    canSendToStore: boolean;
}


export class OrderHistory {
    id: number
    orderNo: string;
    orderDate: Date;
    bankName: string;
    remark: string;
    typeOfWork: TypeOfWork;
    eventType: EventType;
    orderId: number;
}
export class EventType {
    id: number;
    name: string;
}
export class SaveRemark {
    id: number;
    remark: string;
}

export class OrderHistorySearch {
    searchBy:number = 2;
    textSearch: string = '';
    jobNumber: string = '';
    orderNo: string = '';
    remark: string = '';
    orderRemark: string = '';
}
