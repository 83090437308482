<div class="modal-header">
    <h4 class="modal-title">TID History</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form>
        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table table-striped- table-bordered table-hover table-checkable">
                    <thead>
                        <tr align="center">
                            <th width="2%" nowrap="">{{'ORDER.ORDER_DETAIL_PERMNENE.NO' | translate}}</th>
                            <th width="14%" nowrap="">{{'ORDER.ORDER_DETAIL_PERMNENE.SN' | translate}}</th>
                            <th width="10%" nowrap="">{{'ORDER.SHOP.SHOP_CODE'|translate}}</th>
                            <th width="20%" nowrap="">{{'ORDER.SHOP.SHOP_NAME_TH'|translate}}</th>
                            <th width="20%" nowrap="">{{'ORDER.SHOP.SHOP_NAME_EN'|translate}} </th>
                            <th width="5%" nowrap="">{{'ORDER.ORDER_DETAIL_PERMNENE.INSTALL_DATE' | translate}}</th>
                        </tr>
                        <tr align="center" colFilter (colFilterChange)="colFilterChange($event)">
                            <th align="center">
                            </th>
                            <th><input col-filter-name="serialNumber" class="form-control"
                                    [placeholder]="'ORDER.ORDER_DETAIL_PERMNENE.SN' | translate"></th>
                            <th><input col-filter-name="code" class="form-control"
                                    [placeholder]="'ORDER.SHOP.SHOP_CODE'|translate"></th>
                            <th><input col-filter-name="nameTH" class="form-control"
                                    [placeholder]="'ORDER.SHOP.SHOP_NAME_TH'|translate">
                            </th>
                            <th><input col-filter-name="nameEN" class="form-control"
                                    [placeholder]="'ORDER.SHOP.SHOP_NAME_EN'|translate"></th>
                            <th align="center"> </th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of items ;let i = index;">
                            <td align="center">{{pageResult?.startIndex+i}}</td>
                            <td align="left">{{item.serialNumber}}</td>
                            <td nowrap="">{{item.shopCode}}</td>
                            <td nowrap="">{{item.shopNameTH}}</td>
                            <td nowrap="">{{item.shopNameEN}}</td>
                            <td align="center">{{item.installDate | date:'dd/MM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <app-admin-table-paginator #pagination (onPageChange)="onPageChange($event)"
                (onPageSizeChange)="onPageSizeChange($event)"></app-admin-table-paginator>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss()"
        [translate]="'BUTTON.CLOSE'"></button>
</div>