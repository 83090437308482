<div class="modal-header">
    <h5 class="modal-title">{{'_SHARED.WORKSHEET' | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body modal-body-custom2">
    <div class="row">
        <div class="col-md-6">
            <app-current-change-serial-number #current [id]="id"></app-current-change-serial-number>
        </div>
        <div class="col-md-6">
            <app-new-change-serial-number #news [id]="id"></app-new-change-serial-number>
        </div>
    </div>
    <div class="form-group mb-0" [formGroup]="form">
        <label class="kt-font-primary">{{'_SHARED.REMARK' | translate }}: </label>
        <br>
        <textarea class="form-control" formControlName="remark" rows="5"></textarea>
    </div>
</div>
<div class="modal-footer">
    <div class="col-lg-12 text-right">
        <button type="button" class="btn btn-primary waves-effect"
            (click)="onSaveClick()">{{'_SHARED.SAVE' | translate }}</button>&nbsp;
        <button type="button" class="btn btn-dark waves-effect"
            (click)="activeModal.dismiss()">{{'_SHARED.CLOSE' | translate }}</button>
    </div>
</div>