import { ShopLocation } from '../_order/order-shared.model';
import { ProvinceModel, DistrictModel, SubdistrictModel } from '../_shared/fix.model';

export class ShopModel {
    id: number;
    code: string;
    group: ShopGroupModel;
    nameTH: string;
    nameEN: string;
    address: string;
    province: ProvinceModel;
    district: DistrictModel;
    subDistrict: SubdistrictModel;
    postCode: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    placeInLocation: string;
    reg: string;
    branchName: string;
    branchTel: string;
    bankBranchName: string;
    bankTel: string;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    contactTel: string;
}

export class ClientViewModel {
    kind: string;
    id: number;
    code: string;
    group: ShopGroupModel;
    nameTH: string;
    nameEN: string;
    address: string;
    province: ProvinceModel;
    district: ProvinceModel;
    subDistrict: SubdistrictModel;
    posCode: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    location: string;
    reg: string;
    bankPhoneNumber: string;
    bankName: string;
    branchName: string;
    locationCode: string;
    subDistrictId: number;
    districtId: number;
    provinceId: number;
    bankBranchName: string;
    bankTel: string;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    subDistrictName: string;
    contactTel: string;
    hasLogo: boolean;
    locationId: number
    shopLocation: ShopLocation;
} 
export class SaveShopMulthiRequestModel {
    sequence: number;
    //id: number;
    // code: string;
    // groupId: number;
    // nameTH: string;
    // nameEN: string;
    // address: string;
    // provinceId: number;
    // districtId: number;
    // subdistrictId: number;
    // posCode: string;
    // contactName: string;
    // contactPhoneNumber: string;
    // contactFaxNumber: string;
    // email: string;
    // placeInLocation: string;
    // reg: string;
    // branchName: string;
    // locationCode: string;
    // bankTel: string;
    // bankName: string;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
}

export class SaveShopRequestModel {
    id: number;
    code: string;
    groupId: number;
    nameTH: string;
    nameEN: string;
    address: string;
    provinceId: number;
    districtId: number;
    subdistrictId: number;
    posCode: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    placeInLocation: string;
    reg: string;
    branchName: string;
    locationCode: string;
    bankTel: string;
    bankName: string;
    locationId:number;
}

export class ShopGroupModel {
    id: number;
    code: string;
    name: string;
}
