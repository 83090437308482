import { AttachFile } from '../setting/setting-task-model';
import { EventType } from '../order-monitoring/order-monitoring-api-view-model';
import { Status } from '../../user/help-desk/call-history-model';
import { ShopDetailViewModel } from '../support-apis-model';
import { Province, District } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { SubDistrict } from '../../master/location-site/teleport-location.model';
import { ReprogramCase } from 'src/app/core/view-models/_sprint3/_shared/reprogram-case';
import { Technician } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { ReferWorksheetInfo } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-pm-model';
import { Connector } from 'src/app/core/view-models/_shared/worksheet.view-model';

export class WorksheetGeneralApisModel{
  clientId: number;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string;
  typeOfWorkId: number;
  eventTypeId: number;
  sendToType: number;
}
 
export class WorksheetSupportApisModel{
  clientId: number;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  eventTypeId:number;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string 
  edcIdList: number[] = [];
}
export class WorksheetPMApisModel{
  clientId: number;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string 
  edcIdList: number[] = [];
  eventTypeId:number
  isSetSim:boolean; 
  attachFileIdList:number[]=[];
  isHq:boolean;
  reviseRemark : string = '';
}

export class WorksheetReprogramApisModel{
  clientId: number;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string 
  edcList: EdcList[] = [];
  eventTypeId:number
  isSetSim:boolean; 
  attachFileIdList: number[]=[];
  isHq:boolean;
}
export class EdcList {
  edcId:number;
  reprogramCaseId:number;
  reprogramCaseRemark: string;
  onsiteItemTypeId:number;
  connectors: ConnectorEdc[]=[]
}
export class ConnectorEdc{
  connectorId: number;
  remark:string;
}
export class WorksheetInstallApisModel{
  clientId: number;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string 
  edcIdList: number[] = [];
}
export class OrderEdcListApisModel {
  checked: boolean = false;
  id: number;
  orderNo: string;
  edcId: number;
  orderDate: string;
  serialNumber: string;
  tid: number;
  mid: number;
  modelName: string;
  main: string;
  backUp: string;  
  remark:string
}


export class GetWorksheetPMApisModel{
  clientId: number;

  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string ;
  edcIdList: number[] = [];
  attachFile: AttachFile;
  eventType:EventType;
  availableEdcs: AvailableEdcListApis[];
  selectedEdcs: AvailableEdcListApis[];
  isSetSim:boolean; 
  id:number;
  authorize: Authorize; 
  shopDetailInfo?: ShopDetailViewModel
  province: Province ;
  district: District; 
  subdistrict: SubDistrict  ;
  typeOfWork:EventType ; 
  sendToType:number;
  eventTypeId:number;
  typeOfWorkId:number; 
  workType : WorkType;
  bankTel: string; 
  bankBranchName: string; 
  referWorksheetInfo: ReferWorksheetInfo = new ReferWorksheetInfo();
  attachFileIdList:number[]=[]; 
  attachFiles: AttachFile[] = [];
  worksheetNo:string;
  isHq:boolean;
}
export class WorkType {
  description: string;
  id: number;
  name: string;
}
export class Authorize {
  canSave: boolean;
  canSubmit: boolean;
  canCancel: boolean
}
export class AvailableEdcListApis {
  isChecked: boolean = false;
  orderNo: string;
  orderDate: Date; 
  isShow: boolean = true;
  edcId: number; 
  backup: string;
  endDate: Date;
  installDate: Date;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: Date;
  status: Status;
  tid: string;
  remark: string;
  typeOfWork:EventType ;
  hasCanceled: boolean ; 
  canceledAt: Date;
  canceledName:string; 
  reprogramCase:ReprogramCase ;
  reprogramCaseRemark:string;
  oldEdcId:number; 
  connectors: Connector[]=[]
  onsiteItemTypeId:number;
}

export class GetWorksheetBkkSetJobApisModel{
  id: number;
   
  remark: string = '';
  serviceDate: Date = new Date;
  serviceHour: number = null;
  serviceMinute: number = null; 
  technicians: Technician[];
  technician:Technician;
}