import { Injectable } from '@angular/core'; 
import { Mapper } from '../../mapper';
import { StoreTaskInfoViewModel } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { StoreTaskInfoViewApisModel } from 'src/app/core/models/_sprint3/store/work-area.model';

@Injectable({
    providedIn: "root",
  })
export class GetStoreEdcMapper implements Mapper<StoreTaskInfoViewModel,StoreTaskInfoViewApisModel> {
    mapForm(param: StoreTaskInfoViewModel): StoreTaskInfoViewApisModel {
        return{
            ...param
        }
    }
    mapTo(param: StoreTaskInfoViewApisModel): StoreTaskInfoViewModel {
        return{
            ...param
        }
    }
}
