import { FileUp } from '../worksheet/worksheet-model';
import { AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';

export class WorksheetInformationViewModel {
    worksheetNo:string;
    worksheetDate:Date;
    operationDate:Date;
    assignBy:string;
    technicianName:string;
    status:Status;
    finishDate:Date;
    phoneNumber:string
    worksheetType:Status;
}
export class Status {
   id:number;
   name:string;
}

export class  WorksheetShopDetailViewModel {
    orderId: number;   
    orderNo: string;   
    orderDate: Date;
    shopCode: string;
    shopNameTH: string;
    shopNameEN: string;
    address: string;
    province: string;
    district: string;
    subDistrict: string;
    postCode: number;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
}
export class  WorksheetLocationViewModel {
    orderId: number;   
    orderNo: string;
    orderDate: Date;
    shopCode: string;
    shopNameTH: string;
    shopNameEN: string;
    address: string;
    province: string;
    district: string;
    subDistrict: string;
    postCode: number;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    reg: string;
    locationDetail:string;
}

export class WorksheetCommandViewModel { 
    issue: string;
    command: string;
    recommend: string;
    contactName: string;
    contactPhone: string;
    serviceDate: Date = new Date;
    serviceHour: number = null;
    serviceMinute: number = null; 
    commandFile: AttachFile;
  }