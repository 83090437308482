import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeLoginComponent } from './features/login/login.component';
import { LoginCustomerComponent } from './features/login-customer/login-customer.component';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { PrivacyPolicyComponent } from './features/user/privacy-policy/privacy-policy.component';


const routes: Routes = [
  {
    path: 'emp-login',
    canActivate: [NoAuthGuard],
    component: EmployeeLoginComponent,
  },
  {
    path: 'login',
    canActivate: [NoAuthGuard],
    component: LoginCustomerComponent,
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'u',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/user/user.module').then(m => m.UserModule)
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/login'
    // redirectTo: '/u/dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
