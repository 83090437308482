<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'_SHARED.EDC_TIMELINE' | translate }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="row">
            <div class="col-xl-1"></div>
            <div class="col-xl-10">
                <div class="kt-timeline-v1 kt-timeline-v1--justified">
                    <div class="kt-timeline-v1__items" *ngFor="let item of items">
                        <div class="kt-timeline-v1__marker"></div>
                        <div class="kt-timeline-v1__item kt-timeline-v1__item--first">
                            <div class="kt-timeline-v1__item-circle">
                                <div class="kt-bg-danger"></div>
                            </div>
                            <span class="kt-timeline-v1__item-time kt-font-brand">
                            {{item.transactionDate|date:'dd/MM/yyyy'}}
                            <!-- <span>{{item.date|date:'HH:mm'}}</span> -->
                        </span>
                            <div class="kt-timeline-v1__item-content card-border">
                                <div class="kt-timeline-v1__item-title">
                                    <b>{{item.workTypeName}}</b>
                                </div>
                                <div class="kt-timeline-v1__item-body">
                                    <h6>{{item.shopNameTH}}</h6>
                                    <p>
                                       {{item.shopAddress}}
                                    </p>
                                </div>
                                <!-- <div class="kt-timeline-v1__item-actions">
                                    <a href="#"
                                       class="btn btn-sm btn-label-brand btn-bold">{{item.worksheetNo}}
                                        <i class="fa fa-external-link-alt"></i></a>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    
                </div>


            </div>
            <div class="col-xl-1"></div>
        </div>
    </div>
</div>