export const bankHostFunctionConst = [
    {
        id: 1,
        name: "Adjust"
    },
    {
        id: 2,
        name: "Authorize"
    },
    {
        id: 3,
        name: "Off-Line"
    },
    {
        id: 4,
        name: "Refund"
    },
    {
        id: 5,
        name: "Tip"
    },
    {
        id: 6,
        name: "Key In"
    }
];