import { Injectable } from "@angular/core"; 
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils'; 
import { AvailableEdc } from '../../view-models/_shared/worksheet.view-model';
import { EdcList } from '../../models/_sprint3/worksheet/worksheet-api-view-model';

@Injectable({
  providedIn: "root",   
})
export class CreateReprogramListWorksheetMapper implements Mapper<EdcList, AvailableEdc>{
  constructor( 
  ) { }
  mapForm(param: EdcList): AvailableEdc { 
      const item = new AvailableEdc();  
      return item;
  }
  mapTo(param: AvailableEdc): EdcList { 
    const item = new EdcList();  
    item.edcId = param.edcId;
    item.reprogramCaseId = param.reprogramCase?.id;
    item.reprogramCaseRemark = param.reprogramCaseRemark
    item.onsiteItemTypeId = param.onsiteItemTypeId
    item.connectors = param.connectors
    return item;
  }

}