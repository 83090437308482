 
<div class="modal-header">
    <h5 class="modal-title">{{ '_SHARED.JOB_STATUS' | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>

<div class="modal-body ">
    <form >
        <div class="col-sm-12">
            <div class="row" >
                <div class="col-sm-12">
                    <label for="">{{ '_SHARED.TYPE' | translate }} </label>
                    <select class="form-control" [(ngModel)]="statusId" [disabled]="isRevise" [ngModelOptions]="{standalone: true}" [ngClass]="{'disbled-select-view': isView}">
                        <option [value]="0" disabled >-- ALL --</option> &nbsp;
                        <option *ngFor="let item of setType" [value]="item.id">{{item.name}}</option>
                    </select>
                </div>
            </div> 
            <div class="row">
                <div class="col-sm-12">
                    <label for="">Remark</label>
                    <textarea class="form-control" cols="10" rows="5" [(ngModel)]="item.remark" [ngModelOptions]="{standalone: true}" 
                    [ngClass]="{ 'is-invalid': submitted && statusId != 800 && item.remark == '' }" [readonly]="isView"></textarea>
                    <div *ngIf="submitted && statusId != 800 && item.remark == ''" class="invalid-feedback">
                        <div *ngIf="statusId != 800 &&  item.remark == ''" [translate]="'FORM.REQUIRED'"></div>
                      </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary waves-effect" *ngIf="iscustomer == false" (click)="onSaveClick()">{{ '_SHARED.SAVE' | translate }}</button>
    <button type="button" class="btn btn-dark waves-effect" (click)="activeModal.dismiss()">{{ '_SHARED.CLOSE' | translate }}</button>
</div> 

