<div class="modal-header">
    <h5 class="modal-title">{{ '_SHARED.ATTACH_IMAGE' | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body modal-body-custom">
    <div class="kt-portlet kt-portlet--mobile" *ngFor="let item of pictures">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    {{item.categoryName}} </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-form">
                <div class="row row-nowrap">
                    <div class="col-sm-3 text-center" *ngFor="let items of item.pictureFiles">
                        <div class="kt-avatar kt-avatar--outline" id="kt_user_avatar_1">
                            <div class="kt-avatar__holder">
                                <img alt="Pic" [src]="items.url" class="img-thumbnail">
                            </div>
                            <!-- <label class="kt-avatar__upload" *ngIf="canEdit">
                                <a (click)="OnClickDelete()" href="javascript:;"> <i class="fa fa-trash text-red"></i></a>
                            </label> -->
                        </div>
                    </div> 
                </div>  
            </div>
        </div>
    </div>



    <div class="kt-portlet kt-portlet--mobile" *ngIf="clientSignaturePictureFile" >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                     Signature Picture </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-form">
                <div class="row row-nowrap">
                    <div class="col-sm-12 text-center"  >
                        <img alt="Pic" [src]="clientSignaturePictureFile.url" class="bg-back">
                    </div> 
                </div>  
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-dark waves-effect" (click)="activeModal.dismiss()">{{ '_SHARED.CLOSE' |
        translate }}</button>
</div>