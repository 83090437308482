
import { ShopLocation } from '../../models/_order/order-shared.model';
import { SubdistrictModel, DistrictModel, ProvinceModel } from '../../models/_shared/fix.model';

export class ShopViewModel {
    id: number;
    code: string;
    group: ShopGroupViewModel;
    nameTH: string;
    nameEN: string;
    address: string;
    province: ProvinceModel;
    district: DistrictModel;
    subDistrict: SubdistrictModel;
    posCode: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    reg: string;
    branchName: string;
    locationCode: string;
    bankName: string;
    bankPhoneNumber: string;
    subDistrictId : number;
    districtId : number;
    provinceId : number;
    bankBranchName:string;
    bankTel:string;
    provinceName :string;
    districtName :string;
    subdistrictName :string;
    subDistrictName :string;
    contactTel :string;
    hasLogo:boolean;
    locationId:number; 
    location: string;
    shopLocation: ShopLocation;
    // location: Location;
}
export class Location {
    id:number; 
    name: string;
}
export class ShopCriteriaModel {
    textSearch: string;
    code: string = '';
    nameTH: string = '';
    nameEN: string = '';
    address: string = '';
    clientType: number = 0;
    workTypeId: number = 0;
    worksheetId : number = 0;
}
export class DragDropShopViewModel{
    id: number;
    previousIndex: number;
    currentIndex: number;
}
export class ShopGroupViewModel {
    id: number;
    code: string;
    name: string;
}

export class GroupViewModel {
    id: number;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
}

export class OrderInstallSearchRequest {
    textSearch: string = '';
    orderId: number;
    orderType: number;
    orderInstallEventType: number;
}

