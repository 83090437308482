import { Component, OnInit, Input } from '@angular/core';
import { ChangeSerialNumberViewModel, CurrentStatus } from 'src/app/core/view-models/_sprint3/_shared/change-serial-number-view-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { ChangeSerialNumberService } from 'src/app/core/services/_sprint3/_shared/change-serial-number.service';
import { Current } from 'src/app/core/models/_sprint3/change-serial-number-api-view-model';
import { StoreTaskInformationService } from 'src/app/core/services/_sprint3/_shared/store-task-information.service';
import { UpdateStatus } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchEdcChangeSerialNumberComponent } from '../_modal/search-edc-change-serial-number/search-edc-change-serial-number.component';

@Component({
  selector: 'app-new-change-serial-number',
  templateUrl: './new-change-serial-number.component.html',
  styleUrls: ['./new-change-serial-number.component.scss']
})
export class NewChangeSerialNumberComponent implements OnInit {

  news: Current = new Current();
  // changeToStatus: ChangeToStatus[]=[];
  form: FormGroup;
  submit: boolean;
  @Input() id:number;
  updateStatus: CurrentStatus[]=[];
  edcId:number;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private dialogService: MyDialogService,
    private changeSerialNumberService: ChangeSerialNumberService,
    private storeTaskInformationService: StoreTaskInformationService,
  ) {}

  ngOnInit(): void { 
    this.form = this.formBuilder.group({ 
      // name: ['', Validators.required], 
      changeToStatusId:['']
    
    }); 
    // this.loadDataList();
  }
  public async setInitial(item:Current){
    this.news = item;
    this.form.patchValue({
      changeToStatusId: this.news?.changeToStatus?.id, 
   });  
   this.news.serialNumber = item.serialNumber
   this.edcId = this.news.id
   this.updateStatus = await this.storeTaskInformationService.getStatusSelect();
  }
  onScanClick(){
    console.log(this.id);
    const modalRef = this.modalService.open(SearchEdcChangeSerialNumberComponent,{
      backdrop:'static',
      keyboard: false, 
      size: 'lg', 
    }); 
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.onReturnData.subscribe(async result => {  
      this.edcId = result.edcId;
      this.news.serialNumber = result.serialNumber;
      this.news.mid = result.mid;
      this.news.tid = result.tid;
      this.news.box = result.box;
    });
  }   

  get f() {
    return this.form.controls;
  }
  isValid(submit: boolean) {
    this.submit = submit;

    if (this.submit && !this.form.valid) {
      this.dialogService.warningValidate();
      return false;
    }
    this.news.changeToStatus = this.updateStatus.find(x => x.id == this.form.value.changeToStatusId);
    return true;
  }
}
