import { UpdateStatus } from 'src/app/core/models/_sprint3/store/work-area.model';
import { Reject } from '../_shared/store-task-information-view-model';

  
export class SettingTaskInformationViewModel{  
  id:number;
  authrorize: SettingAuthrorize;
  info:SettingTaskInformationListModel;
  checkList:CheckList[];
  remark:string;
  currentStatus:Status;
  updateStatus:UpdateStatus;
  canReject:boolean;  
  reject: Reject;
  tmsCompares:TmsCompares[]=[]
  tmsCompareNoticeMessage:string;
}
export class TmsCompares{  
  description: string;
  jobOrderText: string;
  sequence: number;
  tmsText:  string;
  topic:  string;
  validState: number;
}
export class SettingAuthrorize{  
  canRejectStore:boolean;
  canRejectSetting:boolean;
  canSave:boolean;
  canSubmit:boolean;
  canSwitch: boolean;
  canReset: boolean; 
  canTakeNewEdc: boolean;
  canTakeNewConnector: boolean;
  canTakeNewSim: boolean;
}
export class CheckList{ 
  id: number;
  canCheck: boolean;
  isChecked: boolean;
  topicName: string;
  detail: string;
}
export class SettingTaskInformationListModel {
  froms: State[]; 
  command: Status;
  state: Status;
  status: Status;
  workType: WorkType;
  subType: Status;
  nextTos: State[]; 
  edcId:number;
  serialNumber: string;
  materialTypeName: string;
  tid: string;
  sapMaterialId:number;
  worksheetId:number;
  worksheetNo: string;
  startDateTime: Date;
  endDateTime: Date;
  modelName: string;
  box: string;
  location: string;
  actualDateTime: Date;
  taskOwnerName: string;
  taskResponsibleName: string;  
   quantity: string; 
  measure: string; 
  requestRemark: string;
}
export class WorkType{  
  id:number;
  name:string;
  description:string;
}
export class State{  
  state:number;
  name:string;
}
export class Status{  
  id:number;
  name:string;
}
export class UpdateSettingWorkareaEdc{
  checkIdList:number[]; 
  remark:string;
}
export class RejectWorkareaEdc{
  isSendToStore:boolean;
  isSendToPacking:boolean;
  packingRemark:string;
  storeRemark:string;
}
export class RejectRemarkWorkareaEdc{ 
  rejectRemark:string;
}