import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper'; 
import { WorksheetCommandViewModel } from 'src/app/core/view-models/_sprint3/_shared/worksheet-information-view-model';
import { WorksheetCloseJobApisModel, AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { FileUp } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
import { DateUtils } from 'src/app/shared/utils/date-utils';

@Injectable({
    providedIn: "root",
}) 
export class AdminBKKClosejobCommandMapper implements Mapper<WorksheetCloseJobApisModel, WorksheetCommandViewModel> {
    mapForm(param: WorksheetCloseJobApisModel): WorksheetCommandViewModel {
        const item = new WorksheetCommandViewModel(); 
        item.issue = param.issue;
        item.command = param.workCommand;
        item.recommend = param.recommend;
        item.contactName = param.contactName;
        item.contactPhone = param.contactPhoneNumber;
        item.serviceDate = DateUtils.toDate(param.convenientDate);
        item.serviceHour = param.convenientHour;
        item.serviceMinute = param.convenientMinute;
        item.commandFile = new AttachFile;
        item.commandFile = param.attachFile
        return item;
    }
    mapTo(param: WorksheetCommandViewModel): WorksheetCloseJobApisModel {
        return null
    }
}
