import { Injectable } from '@angular/core'; 
import { Mapper } from '../../mapper';
import { StoreTaskInfoViewModel } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { StoreTaskInfoViewApisModel } from 'src/app/core/models/_sprint3/store/work-area.model'; 
import { PackingTaskInformationViewModel } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { PackingTaskInformationApisModel } from 'src/app/core/models/_sprint3/setting/setting-edc-view-model';

@Injectable({
    providedIn: "root",
  })
export class GetPackingEdcMapper implements Mapper<PackingTaskInformationViewModel,PackingTaskInformationApisModel> {
    mapForm(param: PackingTaskInformationViewModel): PackingTaskInformationApisModel {
        return{
            ...param
        }
    }
    mapTo(param: PackingTaskInformationApisModel): PackingTaskInformationViewModel {
        return{
            ...param
        }
    }
}
