import { Component, OnInit } from '@angular/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ChartService } from 'src/app/core/services/shared/chart.service';
import { ChartDashboardService } from 'src/app/core/services/shared/chart-dashboard.service';
import { UserDashboardService } from 'src/app/core/services/dashboard/user-dashboard.service';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'chart-order-statistics-customer-right',
  templateUrl: './order-statistics-customer-right.component.html',
  styleUrls: ['./order-statistics-customer-right.component.scss']
})
export class OrderStatisticsCustomerRightComponent implements OnInit {

  constructor(
    public chartService : UserDashboardService,
  ) { }

  ngOnInit(): void {
  }


  async ngAfterViewInit() {
    // Create chart instance
    let chart = am4core.create("chartStack", am4charts.XYChart);

    chart.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871")
    ];

    chart.data = await this.chartService.getOrderCustomer();
    

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    // categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.labels.template.maxWidth = 120;
    categoryAxis.renderer.labels.template.tooltipText = "{category}";

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    // valueAxis.title.text = "Expenditure (M)";

    // Create series
    function createSeries(field, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "label";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = true;
      series.columns.template.width = am4core.percent(95);
    }

    createSeries("install", "Install");
    createSeries("reprogram", "Reprogram");
    createSeries("remove", "Remove");

    // Legend
    chart.legend = new am4charts.Legend();

  }

}
