<div class="modal-header">
    <h4 class="modal-title">{{ '_SHARED.WORK_TYPE'  | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModalClose.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="formWorkType">
        <div class="form-group text-left">
            <label class="kt-font-primary"><i class="required">*</i> {{ '_SHARED.WORK_TYPE' | translate }} </label>
            <div class="kt-radio-inline">
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" formControlName="workTypeId" [value]="workTypeStatus.repair">
                    {{ '_SHARED.MECHANIC_TO_FIX'  | translate }}
                    <span></span>
                </label>
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" formControlName="workTypeId"
                        [value]="workTypeStatus.withdraEdc"> {{ '_SHARED.TAKE_EDC_MACHINE'  | translate }}
                    <span></span>
                </label>
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" formControlName="workTypeId"
                        [value]="workTypeStatus.withdrawConnector"> {{ '_SHARED.TAKE_PERIPHERALS'  | translate }}
                    <span></span>
                </label>
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" formControlName="workTypeId"
                        [value]="workTypeStatus.withdrawEdcAndConnector"> {{ '_SHARED.TAKE_PERIPHERALS_AND_EDC'  | translate }}
                    <span></span>
                </label>
            </div>

        </div>
        <div class="form-group" *ngIf="submit">
            <div class="row">
                <div class="col-sm-12">
                    <div class="invalid-feedback">
                        <div [translate]="'FORM.REQUIRED'">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-sm-12">
                    <label>{{ '_SHARED.REMARK'  | translate }}</label>
                    <textarea class="form-control" [placeholder]="'_SHARED.REMARK'  | translate" formControlName="remark" rows="4"></textarea>
                </div>
            </div>
        </div>
    </form>
    <label class="kt-font-primary"> {{ '_SHARED.CONNECTOR' | translate }} </label>
    <div class="table-responsive form-group">
        <table class="table table-striped- table-bordered table-hover table-checkable">
            <thead>
                <tr align="center">
                    <th width="1%" nowrap="">{{ '_SHARED.NO'  | translate }}</th>
                    <th width="1%" nowrap="">{{ '_SHARED.SELECT'  | translate }}</th>
                    <th width="2%" nowrap="">{{ '_SHARED.NAME'  | translate }}</th>
                    <th width="5%" nowrap="">{{ '_SHARED.REMARK'  | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr class="ng-star-inserted" *ngFor="let row of connectors;index as i">
                    <td align="center">{{i + 1}}</td>
                    <td align="center">
                        <label class="kt-checkbox">
                            <input type="checkbox" [(ngModel)]="row.connector.isActive" disabled>
                            <span></span>
                        </label>
                    </td>
                    <td align="left" nowrap="">
                        {{row.connector.nameEN}}
                    </td>

                    <td align="center" nowrap="">
                        <input class="form-control" [(ngModel)]="row.remark">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
 
<div class="modal-footer">
    <div class="col-lg-12 text-center">
        <button type="button"  class="btn btn-dark waves-effect" (click)="activeModalClose.dismiss()">{{ '_SHARED.CLOSE'  | translate }}</button></div>
</div>