import { Component, OnInit } from '@angular/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ChartService } from 'src/app/core/services/shared/chart.service';
import { ChartDashboardService } from 'src/app/core/services/shared/chart-dashboard.service';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'chart-order-statistics-op',
  templateUrl: './order-statistics.component.html',
  styleUrls: ['./order-statistics.component.scss']
})
export class OrderStatisticsComponent implements OnInit {

  constructor(
    public chartService : ChartDashboardService,
  ) { }

  ngOnInit(): void {

  }

  
  /*async ngAfterViewInit() {

    let chart = am4core.create("chartdiv", am4charts.XYChart);

    // Add data
    chart.data = await this.chartService.getOPChart();

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "bank";
    categoryAxis.renderer.grid.template.location = 0;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;
    // valueAxis.title.text = "Expenditure (M)";
    // Create series
    function createSeries(field, name) {

      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "bank";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }


    createSeries("install", "Install");
    createSeries("reprogram", "Re Program");
    createSeries("remove", "Remove");


    // Legend
    chart.legend = new am4charts.Legend();

  }*/


}
