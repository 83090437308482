export const orderConst = {
  orderDetailType: {
    single: 1,
    multhi: 2
  },
  feature: {
    old: 1,
    new: 2
  },
  eventType: {
    permanence: 1,
    promotion: 2,
    stockTotal: 3
  },
  eventT:{
    permanence: 0,
    promotion: 1,
  },
  communicationType: {
    other: 3,
    lan: 1,
    GPS: 2
  },
  bankHostType: {
    none: 1,
    billing: 2,
    billingOther: 3
  },
  balanceType: {
    sap: 1,
    transToJob: 2,
    balance: 3
  },
  workArea: {
    edc: 1,
    peripheral: 2,
    sim: 3
  },
  task: {
    task: 1,
    Workflow: 2, 
  },
  storkControl: {
    info: 1,
    timeline: 2, 
  },
  stockControl: {
    overview:1,
    edc:2,
    peripheral:3,
    sim:4
  },
  settingControl: {
    info: 1,
    detail: 2, 
  },
};
