<div class="modal-header">
    <h5 class="modal-title">{{ '_SHARED.ATTACH_IMAGE' | translate }}</h5>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body modal-body-custom-by-ton">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <input #fileInput (change)="fileEvent($event.target.files)" type="file" style="display:none"
                    class="custom-file-input hover-pointer " id="inputGroupFile">
                <h3 class="kt-portlet__head-title">
                    {{ '_SHARED.FILE_LIST' | translate }} <i class="fa fa-paperclip" (click)="fileInput.click()" *ngIf="isView == false"></i>
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-form">
                <div class="table-responsive ">
                    <!--                                                                <div class="table-responsive" style="max-height: calc(100vh - 296px);overflow-y: auto;">-->
                    <table class="table table-striped- table-bordered table-hover table-checkable " id="kt_table_1">
                        <thead>
                            <tr align="center">
                                <th width="5%" nowrap=""> {{'_SHARED.NO' | translate }}
                                </th> 
                                <th nowrap=""> {{'_SHARED.NAME' | translate }}
                                </th>
                                <th  width="5%" nowrap="" *ngIf="isView == false"> {{'_SHARED.ACTION' | translate }}
                                </th>
                            </tr>
                        </thead> 
                        <tbody>
                            <tr *ngFor="let item of attachFiles ;index as i;"> 
                                <td class="text-center"> {{ i + 1}} </td> 
                                <td class="text-left"><a href="javascript:;" class="" (click)="onFileClick(item)">{{item?.originalName}}</a></td>
                                <td class="text-center" *ngIf="isView == false"> <i class="fa fa-trash text-red" ngbTooltip="{{ 'ORDER.SHARED.DELETE' | translate }}"
                                    (click)="onFileDeleteClick(i)"></i> </td> 
                             </tr> 
                        </tbody>
                    </table> 
                </div>
 
            </div>
        </div>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary waves-effect" *ngIf="isView == false"
        (click)="onSaveClick()">{{'_SHARED.SAVE' | translate }}</button>
    <button type="button"
        class="btn btn-dark waves-effect" (click)="activeModal.dismiss()">{{ '_SHARED.CLOSE' | translate }}</button>
</div>