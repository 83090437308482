import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { ApiPath } from '../../http/api-path';
import { OrderEdcViewModel, OrderEdcMainPatitionViewModel, OrderDetailEdcMulthiViewModel, OrderInstallViewModel } from '../../view-models/_order/order-install.view-model';
import { BUsViewModel } from '../../view-models/_shared/bus.view-model';
import { BUsModel } from '../../models/_shared/bus.model';
import { BranchViewModel } from '../../view-models/_shared/branch.view-model';
import { BranchModel } from '../../models/_shared/branch.model';
import { OrderDetailEdcViewModel, SubmitGenerateEdcViewModel, CommunicationPhoneNumberViewModel, FeatureConnectorSerialNumberViewModel, FeatureConnectorSerialNumberCriteriaModel } from '../../view-models/_order/order-share.view-model';
import { OrderInstallMockService } from '../_mock/_order/order-install.service.mock';
import { ShopViewModel, DragDropShopViewModel } from '../../view-models/_shop/shop.view-model';
import { OrderDetailEdcModel, CommunicationPhoneNumberModel } from '../../models/_order/order-shared.model';
import { TransformOrderService } from '../../models-mapper/transform.order';
import { OrderEdcDetailModel, OrderEdcPartitionModel, OrderDetailEdcMulthiModel } from '../../models/_order/order-install.model';
import { BankHostViewService } from '../shared/bank-host.service';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { PageResponseModel, PageRequestModel } from '../../models/_shared/page.model';

@Injectable({
    providedIn: 'root'
})
export class OrderEdcInstallService {

    constructor(
        private _mock: OrderInstallMockService,
        private apiService: ApiService,
        private _transformOrder: TransformOrderService,
        private _bankHostService: BankHostViewService
    ) { }

    async list(id: number): Promise<OrderDetailEdcViewModel[]> {
        const response = await this.apiService.get<OrderDetailEdcModel[]>(`/order/installs/${id}/edcs`);
        return response.map(item => this._transformOrder.transformOrderDetailEdcViewModel(item));
    }

    async deleteEdc(orderId: number, edcId: number): Promise<boolean> {
        const request = {
            edcIdList: [edcId]
        };
        return await this.apiService.patch<boolean>(`/order/installs/${orderId}/edcs/remove`, request);
    }

    async deleteEdcs(orderId: number, edcIds: number[]): Promise<boolean> {
        const request = {
            edcIdList: edcIds
        };
        return await this.apiService.patch<boolean>(`/order/installs/${orderId}/edcs/remove`, request);
    }

    async connectorInventory(orderId:number, edcId:number, connectorId: number, page: PageRequestModel<FeatureConnectorSerialNumberCriteriaModel>): Promise<PageResponseModel<FeatureConnectorSerialNumberViewModel>>{
        const response = await this.apiService.post<any>(`/order/installs/${orderId}/edcs/${edcId}/connectors/${connectorId}/search-inventory`, page);
        return  PageResponseModel.transform<FeatureConnectorSerialNumberViewModel>(response);
    }

    async getMulthi(orderId: number, edcId: number): Promise<OrderDetailEdcMulthiViewModel[]> {
        const response = await this.apiService.get<OrderDetailEdcMulthiModel[]>(`/order/installs/${orderId}/edcs/${edcId}/multi`);
        const functions = await this._bankHostService.bankHostFunctions();
        return response.map(x => this._transformOrder.transformOrderDetailEdcMulthiViewModel(x, functions));
    }

    async getPartitions(orderId: number, edcId: number): Promise<OrderEdcMainPatitionViewModel[]> {
        const response = await this.apiService.get<OrderEdcPartitionModel[]>(`/order/installs/${orderId}/edcs/${edcId}/partitions`);
        const functions = await this._bankHostService.bankHostFunctions();
        return response.map(x => this._transformOrder.transformOrderEdcMainPatitionViewModel(x, functions));
    }

    async get(orderId: number, edcId: number): Promise<OrderEdcViewModel> {
        const response = await this.apiService.get<OrderEdcDetailModel>(`/order/installs/${orderId}/edcs/${edcId}`);
        return this._transformOrder.transformOrderEdcModel(response);
    }

    async save(orderId: number, edcId: number, item: OrderEdcViewModel): Promise<boolean> { 
        const request = this._transformOrder.transformEdcModel(item);
        // console.log("save edc install", request);
        return await this.apiService.patch<boolean>(`/order/installs/${orderId}/edcs/${edcId}`, request);
    }

    async bus(id: number): Promise<Array<BUsViewModel>> {
        const result = await this.apiService.get<Array<BUsModel>>(`/order/installs/${id}/business-units`);
        return result;
    }

    async branches(id: number, buId: number): Promise<Array<BranchViewModel>> {
        const result = await this.apiService.get<Array<BranchModel>>(`/order/installs/${id}/business-units/${buId}/branches`);
        return result;
    }

    async addShopMulthi(orderId: number, edcId: number, shop: OrderDetailEdcMulthiViewModel): Promise<boolean> {
        const response = this._transformOrder.transformShopMulthiSaveModel(shop);
        console.log("add shop multhi edc install", response);
        return await this.apiService.post<boolean>(`/order/installs/${orderId}/edcs/${edcId}/multi`, response);
    }

    async editShopMulthi(orderId: number, edcId: number, shop: OrderDetailEdcMulthiViewModel): Promise<boolean> {
        const response = this._transformOrder.transformShopMulthiSaveModel(shop);
        console.log("edit shop multhi edc install", response);
        return await this.apiService.patch<boolean>(`/order/installs/${orderId}/edcs/${edcId}/multi/${shop.multiId}`, response);
    }

    async dragShopMulthi(id: number, edcId: number, item: DragDropShopViewModel): Promise<boolean> {
        console.log("drag shop multhi edc install");
        return await this.apiService.patch<boolean>(`/order/installs/${id}/edcs/${edcId}/multi-sequence`, item);
    }

    async savePartition(id: number, edcId: number, shop: OrderDetailEdcMulthiViewModel): Promise<boolean> {
        const request = {
            partitions: shop.partitions.map(x => this._transformOrder.transformEdcPartitionModel(x))
        };
        const response = await this.apiService.patch<boolean>(`/order/installs/${id}/edcs/${edcId}/multi/${shop.multiId}/partitions`, request);
        shop.callbackSavePartition.next(response);
        return response;
    }

    async deleteShop(id: number, edcId: number, shop: OrderDetailEdcMulthiViewModel): Promise<boolean> {
        return await this.apiService.delete<boolean>(`/order/installs/${id}/edcs/${edcId}/multi/${shop.multiId}`);
    }

    async addEdc(id: number, item: OrderInstallViewModel, edcIds: number[]): Promise<boolean> {
        const response = {
            edcIdList: edcIds,
            endDate: DateUtils.toFormat(item.orderDetail?.endDate, DateUtils.YYYY_MM_DD),
            startDate: DateUtils.toFormat(item.orderDetail?.startDate, DateUtils.YYYY_MM_DD),
            installDate: DateUtils.toFormat(item.orderDetail?.installDate, DateUtils.YYYY_MM_DD),
            lineSlip1: item.orderDetail.lineSlip1,
            lineSlip2: item.orderDetail.lineSlip2,
            lineSlip3: item.orderDetail.lineSlip3
        };
        return await this.apiService.patch<boolean>(`/order/installs/${id}/edcs/add`, response);
    }

    async generateEdc(id: number, item: SubmitGenerateEdcViewModel): Promise<boolean> {
        return await this.apiService.post<boolean>(`/order/installs/${id}/generate-edc`, item);
    }

    async phoneNumbers(orderId: number, providerId: number): Promise<CommunicationPhoneNumberViewModel[]> {
        const result = await this.apiService.get<CommunicationPhoneNumberModel[]>(`${ApiPath.common.providers}`);
        const response: CommunicationPhoneNumberViewModel[] = [];
        result.forEach(item => {
            const model = new CommunicationPhoneNumberViewModel();
            model.id = item.id;
            model.code = item.code;
            model.phoneNumber = item.phoneNumber;
            response.push(model);
        });
        return response;
    }
    async updateSequence(id: number, edcId: number, item: OrderDetailEdcMulthiViewModel): Promise<boolean | null> {
        const request = {
            sequence: item.sequence
        };
        return await this.apiService.patch<boolean>(`/order/installs/${id}/edcs/${edcId}/multi/${item.multiId}/sequence`, request);
    }

    async cancelInstall(Id: number, edcId: number): Promise<boolean> {
        return await this.apiService.patch<boolean>(`/order/installs/${Id}/edcs/${edcId}/cancel`);
    }
    async cancelRemove(Id: number, edcId: number): Promise<boolean> {
        return await this.apiService.patch<boolean>(`/order/removes/${Id}/edcs/${edcId}/cancel`);
    }
    async cancelReprogram(Id: number, edcId: number): Promise<boolean> {
        return await this.apiService.patch<boolean>(`/order/re-programs/${Id}/edcs/${edcId}/cancel`);
    }

}
