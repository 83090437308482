
import { ClientViewModel } from '../_shop/shop.model';
import { FileModel } from '../_shared/file.model';

export class OrderInformationModel {
    jobNumber: string;
    lot: string;
    branch: CustomerBranchModel;
    businessUnit: CustomerBusinessUnitModel;
    officeCode: string;
    isUrgent: boolean;
    isVip: boolean;
    orderNo: number;
    logoPictureFile: FileModel;
    attachFile: FileModel;
    hasLogo: boolean;
    orderDate: Date;
    operationDate: Date;
    branchName: string;
    bc: string;
    pfCode: string;
    officer: string;
    nii: string; 
    buName: string;
    requestDate:Date
}

export class CommunicationMainModel {
    id: number;
    name: string;
    type: number;
}

export class CommunicationProviderModel {
    id: number;
    name: string;
}
export class CommunicationPhoneNumberModel {
    id: number;
    code: string;
    phoneNumber: string;
}

export class FeatureConnectorModel {
    id: number;
    name: string;
}

export class ConnectorTypeModel {
    id: number;
    name: string;
}
export class PantancateFormatModel {
    id: number;
    name: string;
}

export class OrderEdcPantancateFormatModel {
    id: number;
    name: string;
}

export class OrderItemEdcModel {
    id: number;
    name: string;
    amount: number = 0;
}


export class OrderAuthorizeModel {
    isApprove: boolean;
    isReject: boolean;
    isSave: boolean;
    isSubmit: boolean;
    isCancel: boolean;
}


export class OrderModel {
    reProgramDate: Date;
    id: number;
    removeDate: Date;
    eventType: number;
    information: OrderInformationModel;
    authroize: OrderAuthorizeModel;
    shop: ClientViewModel;
    customer: OrderCustomerModel = new OrderCustomerModel();
    remark: string;
    installDate: Date;
    startPromotionDate: Date;
    endPromotionDate: Date;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    isJoin: boolean;
    bankBranchName: string;
    bankTel: string;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    contactTel: string;
 
    locationId:number; 
    shopLocation: ShopLocation;
}
export class ShopLocation {
    id:number; 
    name: string;
}


/// ----------------------------------------------

export class CustomerBusinessUnitModel {
    id: number;
    code: string;
    name: string;
    description: string;
}

export class CustomerBranchModel {
    id: number;
    businessUnitName: string;
    code: string;
    name: string;
    description: string;
}

export class ResponseShopKeyModel {
    id: number;
    code: string;
}

export class OrderDetailEdcModel {
    selected: boolean;
    isChecked: boolean;
    orderNo: string;
    orderDate: string;
    typeOfWork: TypeOfWork;
    orderId: number;
    edcId: number;
    backup: string;
    endDate: string;
    installDate: string;
    main: string;
    mid: string;
    hasOpenedWorksheet:boolean;
    modelName: string;
    multhi: boolean;
    serialNumber: string;
    standAlondTid: string;
    startDate: string;
    inventoryStatus: Status;
    status: Status;
    tid: string;
    remark: string;
    hasCanceled: boolean;
    canceledAt: string;
    canceledName: string;
    hasReplaceEdc: boolean;
    replaceEdc: ReplaceEdc;
    replaceType: number;
    otherReplaceEdc: OtherReplaceEdc;
    worksheetId: number;
    worksheetNo: string;



}
export class OrderReprogramSetTypeModel extends OrderDetailEdcModel {
    reprogramType: ReprogramType;
    installUpdateFlag: number;
    setTypeRemark: string;
    replaceEdc: ReplaceEdc;
}
export class ReprogramType {
    id: number;
    name: string;
    detail: string;
}
export class ReplaceEdc {
    kind: string;
    isChecked: boolean;
    orderNo: string;
    orderDate: string;
    typeOfWork: TypeOfWork;
    edcId: number;
    backup: string;
    endDate: string;
    installDate: string;
    main: string;
    mid: string;
    modelName: string;
    multhi: boolean;
    serialNumber: string;
    standAlondTid: string;
    startDate: string;
    status: Status;
    tid: string;
    remark: string;
    hasCanceled: boolean;
    canceledAt: string;
    canceledName: string;
}
export class Status {
    kind: string;
    id: number;
    code: string;
    name: string;
}

export class TypeOfWork {
    id: number;
    code: string;
    nameTH: string;
    nameEN: string;
    name: string;
    kind: string;
}
export class OtherReplaceEdc {
    model: number;
    terminalId: string;
    remark: string;
    serialNumber: number;
    mid: string;
    shopName: string;
    standAlondTid: string;
}
export class BankHostFunctionOrderModel {
    id: number;
    code: string;
    name: string;
    type: number;
    description: string;
}

export class OrderEdcInformationModel {
    eventType: number;
    serialNumber: string;
    installDate: Date;
    startDate: Date;
    endDate: Date;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    modelName: string;
    location: string;
    reg: string;
    standAlondTID: string; 
    REG: string; 
    placeInLocation : string 
    edcInventoryId:number;
}

export class OrderSimInventoryModel {
    id: number;
    serialNumber: string;
    phoneNumber: string;
}


export class OrderCustomerModel {
    id: number;
    name: string;
}