import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorksheetCloseJobSNListViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-list-share.view-model';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Status } from 'src/app/core/view-models/_sprint3/store/work-area-worksheet.model';
import { EdcCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';

@Component({
  selector: 'app-modal-job-edc-status',
  templateUrl: './modal-job-edc-status.component.html',
  styleUrls: ['./modal-job-edc-status.component.scss']
})
export class ModalJobEdcStatusComponent implements OnInit {
  iscustomer:boolean = false;
  isRevise: boolean;
  isView: boolean = false
  submitted: boolean = false;
  setType: Status[] = [];
  item: EdcCloseJobApis = new EdcCloseJobApis
  id: number;
  form: FormGroup;
  remark: string;
  typeId: number = 0;
  onReturn: Subject<EdcCloseJobApis> = new Subject();
  statusId: number; 
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private dialogService: MyDialogService,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    
    this.loadDataList();
  }
  async loadDataList() {
    this.setType = await this.commonService.getEdcOnsite();
  
    this.statusId = this.item.onsiteStatus?.id;
  }

  onClose() {
    this.activeModal.dismiss();
  }
  get f() {
    return this.form.controls;
  }
  isValid(submit: boolean) {
    this.submitted = submit;
    if (this.submitted && this.statusId != 800 && this.item.remark == '') {
      this.dialogService.warningValidate();
      return false;
    }
    // if (this.submitted && !this.form.valid) {
    //   this.dialogService.warningValidate();
    //   return false;
    // }

    return true;
  }
  onSaveClick() {
    if (this.isValid(true)) {
      this.dialogService.confirmSaveData(() => {
        this.item.onsiteStatus = this.setType.find(x => x.id == this.statusId); 

        this.onReturn.next(this.item);
        this.closeModal()
      });
    }
  }
  closeModal() {
    this.activeModal.dismiss();
  }
}
