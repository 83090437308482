import { Injectable } from "@angular/core";
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { WorksheetSupportApisModel } from '../../models/_sprint3/worksheet/worksheet-api-view-model';
import { WorksheetSupportViewModel } from '../../view-models/_sprint3/worksheet/worksheet-support-model';
import { CreateSettingCalloutApisModel, SettingGetTaskApisModel } from '../../models/_sprint3/setting/setting-task-model';
import { CreateSettingCalloutViewModel } from '../../view-models/_sprint3/_setting/setting-callout-model';
import { Client } from '../../view-models/_shared/worksheet.view-model';
import { MechanicModel, ContactModel, CommandModel } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { SettingCalloutGetDetailApisModel } from '../../models/_sprint3/setting/setting-callout-model';
import { SettingCalloutGetDetailViewModel } from '../../view-models/_sprint3/_setting/setting-callout-edit-model';

@Injectable({
  providedIn: "root",
})
export class GetSettingCalloutMapper implements Mapper<SettingCalloutGetDetailApisModel, SettingCalloutGetDetailViewModel>{
  constructor(
  ) { }
  mapForm(param: SettingCalloutGetDetailApisModel): SettingCalloutGetDetailViewModel {
    return {
      ...param,
      onsiteOperationDate:param.onsiteOperationDate == null ? param.onsiteOperationDate:  DateUtils.toDate(String(param.onsiteOperationDate)),
  }
  }
  mapTo(param: SettingCalloutGetDetailViewModel): SettingCalloutGetDetailApisModel {
    return {
      ...param
  }
  }

}