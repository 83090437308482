<app-shared-readonly-order-detail-information #information
[information]="item.information">
</app-shared-readonly-order-detail-information>
<app-shared-readonly-shop-detail [shop]="item.shop" #shop></app-shared-readonly-shop-detail>
<app-shared-readonly-communication-detail #communication
[communication]="item.communicationBase">
</app-shared-readonly-communication-detail>

<app-shared-readonly-feature-connector #connector [feature]="item.feature">
</app-shared-readonly-feature-connector>

<div class="kt-portlet kt-portlet--tabs">
<div class="kt-portlet__head">
    <div class="kt-portlet__head-toolbar">
        <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-danger nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold"
            role="tablist">
            <li class="nav-item">
                <a class="nav-link" href="javascript:;"
                    [ngClass]="{'active': detailType == orderDetailType.single}"
                    (click)="focusTabDetailType(orderDetailType.single)">{{'ORDER.SHARED.MAIN'|translate}}
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="javascript:;"
                    [ngClass]="{'active': detailType == orderDetailType.multhi}"
                    (click)="focusTabDetailType(orderDetailType.multhi)">{{'ORDER.SHARED.MULTHI'|translate}}
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="kt-portlet__body" style="background-color: aliceblue">
    <div class="tab-content">
        <div class="tab-pane active" role="tabpanel">
            <ng-template switch-tab></ng-template>
        </div>
    </div>
</div>
</div>
<div class="kt-portlet kt-portlet--mobile">
<div class="kt-portlet__head kt-portlet__head--lg">
    <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">
            {{'ORDER.CREATE_ORDER.REMARK' | translate}}
        </h3>
    </div>
</div>
<div class="kt-portlet__body">
    <form class="kt-form">
        <div class="col-lg-12">
            <textarea readonly class="form-control" rows="6" [placeholder]="'ORDER.CREATE_ORDER.REMARK' | translate"
                [(ngModel)]="item.remark" [ngModelOptions]="{standalone: true}"></textarea>
        </div>
    </form>
</div>
</div>