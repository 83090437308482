export class ChangeSerialNumberApiViewModel {
  
        current: Current;
        new: Current;
        remark: string;
      }
      
      export class Current {
        id: number;
        serialNumber: string;
        modelName: string;
        tid: string;
        mid: string;
        box: string;
        currentStatus: CurrentStatus = new CurrentStatus();
        changeToStatus: CurrentStatus = new CurrentStatus();
      }
      
      export class CurrentStatus {
        kind: string;
        id: number = 0;
        code: string;
        name: string;
      }

      export class UpdateChangeSerialNumberApiViewModel {
        currentStatusId: number;
        newEdcInventoryId: number;
        newStatusId: number;
        remark: string;
      } 
      export class UpdateChangeSerialNumberPerApiViewModel {
        currentStatusId: number;
        newConnectorInventoryId: number;
        newStatusId: number;
        remark: string; 
      }