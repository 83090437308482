import { PageResponseModel } from 'src/app/core/models/_shared/page.model';
import { WorkAreaWorkSheetListViewModel } from '../store/work-area-worksheet.model';
import { ShopViewModel } from '../../_shop/shop.view-model';
import { MechanicModel, CommandModel, ContactModel } from '../worksheet/worksheet-model';
import { Client } from '../../_shared/worksheet.view-model';
import { TaskResponsible } from 'src/app/core/models/_sprint3/store/work-area.model';
import { EventType } from 'src/app/core/models/_sprint3/order-monitoring/order-monitoring-api-view-model'; 
import { SettingAuthrorize } from 'src/app/core/models/_sprint3/setting/setting-task-model';


export class SettingSettingCalloutListViewModel {
  id: number;
  canView: boolean;
  canEdit: boolean;
  canAssign: boolean;
  canOverdue: boolean;
  workSheetNo: string;
  state: Status;
  status: Status;
  type: Status;
  subType: Status;
  ticketDate: Date;
  ticketOwner: string;
  phoneNumber: string;
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  responsible: string;
  startDateTime: Date;
  endDateTime: Date;
  usingTime: string;
  address: string;
  contactName: string;
  contactPhone: string;
  contactTel: string;
  remark: string;
  actionState: number;
  taskResponsible:TaskResponsible;
  taskResponsibleName:string;
  responsibleRemark:string;
}

export class Status {
  id: number;
  name: string;
}

export class SearchSettingSettingCalloutCriteria {
  taskDate: Date = new Date();
  textSearch: string = "";
  isOverdue: boolean = false;
  isPending: boolean = true;
  isInProgress: boolean = true;
  isComplete: boolean = true;
  isCallIn: boolean = true;
  isCallOut: boolean = true;
  isOnSite: boolean = true;
  worksheetNo: string = "";
  workTypeIdList: number[] = [];
  subTypeIdList: number[] = [];
  statusIdList: number[] = [];
  actionStateIdList: number[] = [];
  tellPhoneNumber: string = "";
  shopCode: string = "";
  shopNameTH: string = "";
  shopNameEN: string = "";
  responsible: string = "";
  ticketOwner: string = "";
  contactName: string = "";
  contactTel: string = "";

}

export class SummariesSettingModel {
  totalAll: number = 0;
  totalOverdue: number = 0;
  totalPending: number = 0;
  totalInProgress: number = 0;
  totalComplete: number = 0;
  totalRemain: number = 0;
}
export class SettingSettingCalloutViewModel {
  summary: SummariesSettingModel = new SummariesSettingModel;
  taskDate: string;
  pageResult: PageResponseModel<SettingSettingCalloutListViewModel> = new PageResponseModel<SettingSettingCalloutListViewModel>();
}

export class taskManagementViewModel extends WorkAreaWorkSheetListViewModel {

}
export class SettingTaskManagementViewModel {
  pageNumber: number;
  totalItemCount: number;
  pageCount: number;
  pageSize: number;
  items: PageResponseModel<taskManagementViewModel> = new PageResponseModel<taskManagementViewModel>();
}
export class CreateSettingCalloutViewModel {
  id: number;
  shop: Client = new Client;
  location: Client = new Client;
  mechanic: MechanicModel = new MechanicModel;
  command: CommandModel = new CommandModel;
  contact: ContactModel = new ContactModel;
  remark: string = '';
  topicId: number;
  sendToStatus: number;
  inTernalStatus: number
  sendToType: number;
  eventTypeId: number = 0;
  sentToStore: boolean = false;
  typeOfWorkId: number; 
  eventType: EventType;
  authrorize:SettingAuthrorize = new SettingAuthrorize
}


export class UpdateSettingCalloutViewModel {
  edcs: UpdateSettingCalloutEdcList[];
  shopNameTH: string ;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: Date;
  convenientHour: number;
  convenientMinute: number;
  remark: string;
  onCallContactName: string;
  onCallPhoneNumber: string;
  statusId: number;
  callAgainType: number;
  callAgainDate: Date;
  callAgainHour: number;
  callAgainMinute: number;
  issue: string;
  workCommand: string;
  recommend: string;
  operationDate:Date
  onsiteOperationDate :Date
    onsiteOperationHour: number;
    onsiteOperationMinute: number;
}
export class UpdateSettingCalloutEdcList {
  edcId: number;
  statusId: number;
  remark: string;
}

