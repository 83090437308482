<div class="modal-header">
    <h5 class="modal-title">{{'_SHARED.FILTER' | translate }}</h5>
    <button type="button" class="close" (click)="onClose()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body p-4 modal-body-custom2" id="Questionnaire">
    <div class="table-responsive ">
        <!--                                                                <div class="table-responsive" style="max-height: calc(100vh - 296px);overflow-y: auto;">-->
        <table class="table table-striped- table-bordered table-hover table-checkable " id="kt_table_1">
            <thead>
                <tr align="center">
                    <th width="5%" nowrap=""> {{'_SHARED.NO' | translate }}
                    </th>
                    <th width="5%" nowrap=""> {{'_SHARED.SELECT' | translate }}
                    </th>
                    <th nowrap=""> {{'_SHARED.NAME' | translate }}
                    </th>
                </tr>
            </thead>
            <thead>
                <tr align="center" >
                    <th width="5%" nowrap="">
                    </th>
                    <th width="5%" nowrap="" class="text-center">
                        <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand ">
                            <input [checked]="toggle" (change)="onSelectAll()" type="checkbox">
                            <span></span>
                        </label> 
                    </th>
                    <th nowrap="">
                        <input type="text" [(ngModel)]="colSearchModel.name" class="form-control" [placeholder]="'_SHARED.NAME' | translate">
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of itemSearch | grdFilter: { 
                    name: colSearchModel.name
                    };index as i;"> 
                    <td class="text-center"> {{ i + 1}} </td>
                    <td class="text-center">
                        <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand" (change)="onSelect()">
                            <input type="checkbox" checked="" class="ng-untouched ng-pristine ng-valid"
                            [(ngModel)]="item.isCheck" [ngModelOptions]="{standalone: true}"> 
                            <span></span>
                        </label>
                    </td>
                    <td class="text-left">{{item.name}}</td>
                </tr> 
            </tbody>
        </table>
 
    </div>
    <div class="kt-pagination kt-pagination--brand">
        <div class="kt-pagination__toolbar">
            <span class="pagination__desc"> {{ '_SHARED.DISPLAYING' | translate}} {{(itemSearch| grdFilter: { 
                name: colSearchModel.name
                }).length}} {{ '_SHARED.RECORDS' | translate}} </span>
        </div>
    </div>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-primary waves-effect" (click)="confirmSave()">{{'_SHARED.OK' | translate }}</button>
    <button type="button" class="btn btn-dark waves-effect" (click)="onClose()">{{'_SHARED.CLOSE' | translate }}
    </button>
</div>