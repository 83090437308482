import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: 'input[englishUppercaseOnly]'
})
export class EnglishUpperCaseOnlyDirective {

  constructor(
    private _el: ElementRef
  ) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    // this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9\s]*$/i, '');
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9d*,.?;:'"!$%()+-@<>#&~\s]*$/i, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
    this._el.nativeElement.value = this._el.nativeElement.value.toUpperCase();
  }

}
