<div class="modal-header">
    <h4 class="modal-title">{{'STORE.WORKSHEET.WORKSHEET' | translate}}</h4>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-custom2">
    <div class="table-responsive">
        <table class="table table-striped- table-bordered table-hover table-checkable " id="kt_table_1">
            <thead>
                <tr align="center"> 
                    <th width="10%" nowrap=""> {{'STORE.WORKSHEET.NO' | translate}}</th>
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.ACTIONS' | translate}}</th>
                 
                    <th width="5%" nowrap=""> {{'BKK.WORK_AREA.TASK_STATE' | translate}}</th> 
                    <th width="5%" nowrap="">Bank</th>
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.S_N' | translate}}</th> 
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.MODEL' | translate}}</th>
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.TID' | translate}}</th> 
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.REMARK' | translate}}</th>
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.TICKET_DATE_TIME' | translate}}</th>
                   
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.FINISH_DATE_TIME' | translate}}
                        <br>{{'STORE.WORKSHEET.DATE_FORMAT' | translate}}
                    </th>
                    <th width="5%" nowrap=""> {{'STORE.WORKSHEET.TASK_RESPNSIBLE' | translate}}</th>
                   
                </tr> 
            </thead>
            <tbody>
                <tr *ngFor="let item of items; let i=index">
                    <td align="center" nowrap="">
                        {{i+1}}
                    </td>
                    <td align="left" nowrap="">
                        <div class="button-group">
                            <span class="ng-star-inserted" [ngbTooltip]="'_SHARED.VIEW' | translate">
                                <a href="javascript:;" (click)="onViewClick(item)"
                                    class="btn btn-sm btn-clean btn-icon btn-icon-md">
                                    <i class="fa fa-eye"></i>
                                </a>
                            </span>
                        </div>
                    </td> 
                    <td align="left" nowrap="">
                        <ng-container *ngIf="item?.taskState == statusStateConst.waiting">
                            <span
                                class="badge badge-warning badge-custom">{{'_SHARED.WAITING_B'|translate}}</span>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="item?.taskState == 0">
                            <span class="badge badge-info badge-custom">{{'_SHARED.TODO_B'|translate}}</span>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="item?.taskState == statusStateConst.doing">
                            <span class="badge badge-warning badge-custom">{{'_SHARED.DOING_B'|translate}}</span>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="item?.taskState == statusStateConst.done">
                            <span class="badge badge-success badge-custom">{{'_SHARED.DONE_B'|translate}}</span>&nbsp;
                        </ng-container>
                        <span class="badge badge-danger badge-custom badge-icon-custom" *ngIf="item?.isOverdue"><i
                                class="fa fa-clock "></i></span>
                    </td> 
                    <td nowrap="" class="text-left">{{item?.bankName}}</td>
                    <td align="left" nowrap=""> 
                        {{item?.serialNumber}} 
                    </td> 
                    <td align="left" nowrap="">{{item?.modelName}}</td>
                    <td align="left" nowrap="">{{item?.tid}}</td>
                    <td align="left" nowrap="">{{item?.remark}}</td>
                    
                    <td align="left" nowrap="">{{item?.ticketDateTime | date: 'dd/MM/yyyy HH:mm'}}</td>
                    
                    <td align="left" nowrap="">{{item?.finishDateTime  | date: 'dd/MM/yyyy HH:mm'}}</td>
                  
                    <td align="left" nowrap="">{{item?.taskResponsibleName}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
<div class="modal-footer">
    <div class="col-lg-12 text-right">
        <button type="button" class="btn btn-dark waves-effect" (click)="closeModal()">{{'_SHARED.CLOSE' |
            translate}}</button>
    </div>
</div>