export const statusConst = {
  edcStatus: {
    available: 0,
    reserved: 1
  },
  actionState: {
    save: 1,
    submit: 2,
    approve: 3,
    reject: 4,
    cancel: 5
  },
  createState: {
    install: 1,
    reprogram: 2,
    remove: 3
  },
  edcOrderType: {
    install: 1,
    reprogram: 2
  },
  inventoryStatus: {
    available: 1,
    using: 2
  },
  inboundStatus: {
    saveDraft: 106,
    complete: 100,
    callAgain: 101,
    tier2: 102,
    onsite: 103,
    unreachable: 104,
    uncooperative: 105, 
    reprogram:107,
    requestOnsite :103
  },
  supportInbound: {
    saveDraft: 900,
    complete: 901,
    callAgain: 902, 
    // onsite: 903,
    // unreachable: 104,
    // uncooperative: 105, 
    requestOnsite :903,
    onsite :904
  },
  inboundCallingStatus: {
    chooseDate: 1,
    notChooseDate: 0
  },
  inboundSendTier2Status: {
    set: 1,
    notSet: 0
  },
  outboundStatus:{
    none: 0,
    complete: 100,
    callAgain: 101,
    unreachable: 104,
    uncooperative: 105,
    saveDraft: 106,
  },
  outboundCallingStatus:{
    chooseDate: 1,
    notChooseDate: 0
  },
  callModel: {
    callIn: 1,
    callOut: 2
  },
  actionStateModel: {
    pending : 0,
    inprogress : 3,
    done  : 4
  },
  workType:{
    repair: 1,
    withdraEdc: 2, //เบิกเครื่อง
    withdrawConnector: 3, //เบิกเครื่องอุปกรณ์ต่อพ่วง
    withdrawEdcAndConnector: 4 //เบิกอุปกรณ์ต่อพ่วงและเครื่อง EDC
  },
  topicType:{
    checking: 1,
    satisfaction: 2
  },
  conficFocus:{
    initial: 0,
    default: 1,
    focusLastLevel: 2
  },
  searchKnowledgeType: {
    all: 1,
    byTopic: 2
  },
  orderEventType:{
    permanence: 1,
    promotion: 2,
    reprogram: 3,
    join: 4
  },
  orderStatus: {
    save: 200, 
    cancel: 202,
    approve: 203,
    waiting:207,
    reject: 208,
  },
  takeStatus:{
    save:21,
    submit:22
  },
  returnStatus:{
    complete:31,
    pending:32
  },
  typeOfWork:{
    install:11,
    reProgram:12,
    remove:13
  },
  calloutStatus:{
    none: 0,
    unreachable :600,
    complete :601,
    callAgain : 602,
    OnSite : 603
  }
};