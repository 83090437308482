import { Component, OnInit, Input } from '@angular/core';
import { LocationEdcItemViewModel, SearchLocationEdcItemViewModel } from 'src/app/core/models/user/help-desk/worksheet-model';
import { HdcWorksheetService } from 'src/app/core/services/helpDesk/worksheet.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkTypeModalViewComponent } from './_model/work-type-modal-view/work-type-modal-view.component';
import { SharedUserReadonlyBodyEdcViewComponent } from 'src/app/features/user/shared-user/body-edc-view/body-edc-view.component';

@Component({
  selector: 'app-related-edc-view-shared',
  templateUrl: './related-edc-view-shared.component.html',
  styleUrls: ['./related-edc-view-shared.component.scss']
})
export class RelatedEdcViewSharedComponent implements OnInit {

  @Input() id: number;
  @Input() worksheetId: number;
  selected: boolean;
  items: LocationEdcItemViewModel[] = [];
  searchCriteria: SearchLocationEdcItemViewModel = new SearchLocationEdcItemViewModel();
  constructor(
    private _service: HdcWorksheetService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.loadInitial();
  }
  async loadInitial() {
    this.items = await this._service.edcs(this.id, this.worksheetId);
  }

  OpenCreaatWorkType(item: LocationEdcItemViewModel) {
    const modalRef = this.modalService.open(WorkTypeModalViewComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.worksheetId = this.worksheetId;
    modalRef.componentInstance.onSaved.subscribe(async (result: LocationEdcItemViewModel)=>{
      const response = await this._service.edcSave(this.id, this.worksheetId, item.edcId, result);
      if(response){
        this.loadInitial();
      }
    });
  }

  async toggleSelect() {
    this.items.forEach(item => {
      item.isChecked = this.selected;
    });
    const edcIds = this.items.map(x => x.edcId);
    if (this.selected) {
      await this._service.edcCheckAll(this.id, this.worksheetId, edcIds);
    } else {
      await this._service.edcUnCheckAll(this.id, this.worksheetId, edcIds);
    }
  }

  async changeCheckEvent(item: LocationEdcItemViewModel) {
    if (item.isChecked) {
      await this._service.edcCheck(this.id, this.worksheetId, item.edcId);
    } else {
      await this._service.edcUnCheck(this.id, this.worksheetId, item.edcId);
    }
  }

  
  openModalEdcDetail(item: LocationEdcItemViewModel) {
    const modalRef = this.modalService.open(SharedUserReadonlyBodyEdcViewComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.edcId = item.edcId;
  }
   
}
