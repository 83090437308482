import { OrderInformationViewModel, OrderAuthorizeViewModel, OrderDetailViewModel, OrderCustomerViewModel } from './order-share.view-model';
import { ShopViewModel } from '../_shop/shop.view-model';


export class OrderReprogramViewModel {
    id: number;
    reProgramDate: Date;
    information: OrderInformationViewModel = new OrderInformationViewModel();
    shop: ShopViewModel = new ShopViewModel();
    remark: string;
    authorize: OrderAuthorizeViewModel = new OrderAuthorizeViewModel();
    customer: OrderCustomerViewModel = new OrderCustomerViewModel();
    isJoin:boolean =false;
}

export class OrderEdcKeyViewModel{
    orderId: number;
    edcId: number;
    oldEdcId:number;
}
export class ReprogramRequestReject {
    edcIdList: number[];
    remark: string;

}

export class SearchEdcListModel { 
    orderNo: string = "";
    serialNumber: string = "";
    orderDate: string = "";
    tid: string = "";
    mid: string = "";
    main: string = ""; 
    backup: string = ""; 
    shopName: string = "";
    serialNumberReplace: string = "";
    tidReplace: string = "";
    changedSerialNumber: string = "";
}