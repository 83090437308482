
export class WorksheetCloseJobApisModel {
  id: number;
  worksheetInfo: WorksheetInfoCloseJobApis;
  shopDetailInfo: ShopDetailInfoCloseJobApis;
  locationInfo: ShopDetailInfoCloseJobApis;
  issue: string;
  workCommand: string;
  recommend: string;
  contactName: string;
  contactPhoneNumber: string;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  attachFile: AttachFile;
  edcs: EdcCloseJobApis[];
  technicianForm: TechnicianFormCloseJobApis;
  postponeForm: PostponeFormCloseJobApis;
  attachFiles: AttachFile[] = [];
  worksheetRemark:string
}

export class PostponeFormCloseJobApis {
  contactName: string;
  contactPhoneNumber: string;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
}

export class TechnicianFormCloseJobApis {
  detail: string;
  attachFile: AttachFile; 
  attachFiles: AttachFile[] = [];
  startJobDate: string;
  startJobHour: number;
  startJobMinute: number;
  finishJobDate: string;
  finishJobHour: number;
  finishJobMinute: number;
  actualOperationDate: string;
  actualOperationHour: number;
  actualOperationMinute: number;

}

export class EdcCloseJobApis { 
  hasChangeEdc : boolean = false;
  changedSerialNumber : number;
  reprogramType: ReprogramType;
  kind: string;
  isChecked: boolean;
  orderNo: string;
  orderDate: string;
  typeOfWork: TypeOfWork;
  edcId: number;
  backup: string;
  endDate: string;
  installDate: string;
  main: string;
  mid: string;
  modelName: string;
  multhi: boolean;
  serialNumber: string;
  standAlondTid: string;
  startDate: string;
  status: Status;
  onsiteStatus: Status;
  tid: string;
  remark: string;
  hasCanceled: boolean;
  canceledAt: string;
  canceledName: string;
  shopName: string;
  serialNumberReplace:string;
  tidReplace:string;
  
  hasReplaceEdc : boolean;
  replaceSerialNumber :string;
  replaceTid :string;
}

export class TypeOfWork {
  id: number;
  code: string;
  nameTH: string;
  nameEN: string;
  name: string;
  kind: string;
}

export class ReprogramType {
  id: number;
  name: string;
  detail: string;
}

export class AttachFile {
  kind: string;
  id: number;
  originalName: string;
  storeName: string;
  mimeType: string;
  sizeInBytes: number;
  url: string;
  fileStream: FileStream;
}

export class FileStream {
  canRead: boolean;
  canSeek: boolean;
  canTimeout: boolean;
  canWrite: boolean;
  length: number;
  position: number;
  readTimeout: number;
  writeTimeout: number;
}

export class ShopDetailInfoCloseJobApis {
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  address: string;
  provinceName: string;
  districtName: string;
  subdistrictName: string;
  zipCode: string;
  callPhoneNumber: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  email: string;
  bankBranchName: string;
  bankTel: string;
  location: string;
  reg: string;
}

export class WorksheetInfoCloseJobApis {
  worksheetNo: string;
  workType: WorkType;
  worksheetDate: string;
  operationDate: string;
  assignBy: string;
  technicianName: string;
  status: Status;
  finishDateTime: string;
}

export class Status {

  id: number;
  name: string;
}

export class WorkType {
  id: number;
  name: string;
  description: string;
}

export class SaveCloseModel {
  technicianDetail: string;
  technicianAttachFileId: number;
  postponeContactName: string;
  postponeContactPhoneNumber: string;
  convenientDate: Date;
  convenientHour: number;
  convenientMinute: number;
  convenientDateNow: Date;
  edcs : SaveCloseEdcs[];
  startJobDate: Date;
  startJobHour: number;
  startJobMinute: number;
  finishJobDate: Date;
  finishJobHour: number;
  finishJobMinute: number;
  attachFileIdList:number[]=[]

  actualOperationDate: Date;
  actualOperationHour: number;
  actualOperationMinute: number; 
}

 
export class SaveCloseEdcs {
  edcId: number ;
  statusId: number;
  remark: string;
}

export class SaveCloseReviseModel { 

  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  convenientDate: Date;
  convenientHour: number;
  convenientMinute: number;
  attachFileIdList: number[]=[];
  onsiteAttachFileIdList : number[]=[];
  remark: string;
  technicianDetail: string;
  edcs: SaveCloseReviseEdcs[]=[];
  startJobDate: Date;
  startJobHour: number;
  startJobMinute: number;
  finishJobDate: Date;
  finishJobHour: number;
  finishJobMinute: number;
  
  actualOperationDate: Date;
  actualOperationHour: number;
  actualOperationMinute: number; 
  
  reviseRemark: string
}
export class SaveCloseReviseEdcs {
  edcId: number ; 
  remark: string;
}