import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, Inject } from '@angular/core';
import { SplashScreenService } from './core/_base/layout/services/splash-screen.service';
import { LayoutConfigService } from './core/_base/layout/services/layout-config.service';
import { NavigationEnd, Router, NavigationStart, ActivatedRoute, NavigationCancel, NavigationError, Event } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from './core/_base/layout/services/translation.service';
import { LoaderService } from './core/http/loader.service';
import { DOCUMENT } from '@angular/common';
import { AuthService } from './core/services/auth.service';
import { AccountService } from './core/services/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  showSplashScreen: boolean;
  showLoader = false;
  private loader$: Subscription;
  private displayLoader = false;
  private unsubscribe: Subscription[] = [];

  private isLoadedAdminStyle = false;
  private isLoadedUserStyle = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private layoutConfigService: LayoutConfigService,
    private splashScreenService: SplashScreenService,
    private translationService: TranslationService,
    public loaderService: LoaderService,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.handleRouteThemeStyles(window.location.pathname);
  }

  handleRouteThemeStyles(routeUrl: string) {
    console.log(routeUrl);
    const isStartWithUserRoute = routeUrl.startsWith('/u') || routeUrl.startsWith('/');
    const isStartWithAdminRoute = routeUrl.startsWith('/admin');
    if (isStartWithUserRoute && !this.isLoadedUserStyle) {
      this.loadStyle('user-theme.css');
      this.isLoadedUserStyle = true;
    }
    if (isStartWithAdminRoute && !this.isLoadedAdminStyle) {
      this.loadStyle('admin-theme.css');
      this.isLoadedAdminStyle = true;
    }
    this.authService.loadPermissions();
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }


  ngOnInit(): void {

    this.loader$ = this.loaderService.loaderCounter.subscribe((counter: number) => {
      this.displayLoader = counter !== 0;
    });
    const routerSubscription = this.router.events.subscribe((event: Event) => {
      this.handleRouteChange(event);
    });
    this.showSplashScreen = this.layoutConfigService.getConfig('loader.enabled');

    this.unsubscribe.push(routerSubscription);
  }

  ngAfterViewChecked(): void {
    const show = this.displayLoader;
    if (show !== this.showLoader) {
      this.showLoader = show;
      this.cdRef.detectChanges();
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach(sb => sb.unsubscribe());
    this.loader$.unsubscribe();
  }

  handleRouteChange(event: Event) {
    switch (true) {
      case event instanceof NavigationStart:
        this.loaderService.enable();
        break;
      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError: {
        this.loaderService.disable();
        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('kt-page--loaded');
        }, 500);
        break;
      }
      default: {
        break;
      }
    }
  }


}
