import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'grdFilter'
})
export class GrdFilterPipe implements PipeTransform {
  transform(items: any, filter: any, defaultFilter: boolean = false): any {
    if (!filter) {
      return items;
    }

    if (!Array.isArray(items)) {
      return items;
    }

    if (filter && Array.isArray(items)) {
      let keys = Object.keys(filter);
      let filterKeys: string[] = [];
      keys.forEach(keyName => {
        if (filter[keyName] != null && filter[keyName] != "")
          filterKeys.push(keyName);
      });

      if (defaultFilter) {
        return items.filter(item =>
          filterKeys.reduce((x, keyName) =>
            (x && new RegExp(filter[keyName], 'gi').test(item[keyName])) || filter[keyName] == "", true));
      }
      else {
        filterKeys.forEach(keyName => {
            items = items.filter(item => new RegExp(filter[keyName], 'gi').test(item[keyName]));
        });

        return items;
      }
    }
  }
}