import { Injectable } from "@angular/core";
import { Mapper } from '../mapper'; 
import { CreateSettingCalloutViewModel } from '../../view-models/_sprint3/_setting/setting-callout-model'; 
import { MechanicModel, ContactModel, CommandModel } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { SettingCalloutGetDetailApisModel } from '../../models/_sprint3/setting/setting-callout-model';
import { SettingCalloutGetDetailViewModel } from '../../view-models/_sprint3/_setting/setting-callout-edit-model';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
    providedIn: "root",
})
export class SettingCalloutCommandMapper implements Mapper<CommandModel, SettingCalloutGetDetailViewModel>{
    constructor(
    ) { }
    mapForm(param: CommandModel): SettingCalloutGetDetailViewModel {
        const item = new CreateSettingCalloutViewModel();
        return null
    }
    mapTo(param: SettingCalloutGetDetailViewModel): CommandModel {
        const item = new CommandModel();
        item.command = param.workCommand;
        item.issue = param.issue;
        item.recommend = param.recommend;
        return item
    }

}