import { Component, OnInit, Input, NgZone } from '@angular/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ChartService } from 'src/app/core/services/shared/chart.service';
import { UserDashboardService } from 'src/app/core/services/dashboard/user-dashboard.service';
import { DashboadStorePieModel } from 'src/app/core/models/_shared/dashboard.model';
import { TranslateService } from '@ngx-translate/core';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'chart-stock-monitoring',
  templateUrl: './chart-stock-monitoring.component.html',
  styleUrls: ['./chart-stock-monitoring.component.scss']
})
export class ChartStockMonitoringComponent implements OnInit {
 
  data:DashboadStorePieModel[]=[];
  
  @Input() bank: any = 0;
  @Input() isDispose: boolean;

  private chart: am4charts.XYChart;
  constructor(
    public zone: NgZone,
    public chartService : UserDashboardService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    // this.chartService.getChartCS();,
  }
 
  async ngAfterViewInit() { 
    this.chart = am4core.create("stockControlXyShart", am4charts.XYChart);

    // this.data = await this.chartService.getInventoryCs();
    this.data = await this.chartService.getChartBankAllEdcs(this.bank);
    // Add data
    this.chart.data = this.data;

    // Create axes
    let categoryAxis = this.chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    // categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    // valueAxis.title.text = "Expenditure (M)";

    // Create series 
    this.createSeries(this.chart, "available", this.translateService.instant('CHART_STORE_STOCK_CONTOLR_AVAILABLE')); //Available
    this.createSeries(this.chart, "permanentInstall", this.translateService.instant('CHART_STORE_STOCK_CONTOLR_PERMANENTINSTALL'));//PermanentInstall
    this.createSeries(this.chart, "promotionInstall", this.translateService.instant('CHART_STORE_STOCK_CONTOLR_PROMOINSTALL'));//PromotionInstall
    this.createSeries(this.chart, "service", this.translateService.instant('CHART_STORE_STOCK_CONTOLR_SERVICE'));//Service
    this.createSeries(this.chart, "reserve", this.translateService.instant('CHART_STORE_STOCK_CONTOLR_RESERVE'));//Reserve
    this.createSeries(this.chart, "postponeInstall", this.translateService.instant('CHART_STORE_STOCK_CONTOLR_POSTPONEINSTALL')); //PostponeInstall
 

    // this.createSeries(this.chart, "avaliable", "Avaliable");
    // this.createSeries(this.chart, "service", "Service");

    // Legend
    this.chart.legend = new am4charts.Legend();

    // Dispose
    // if(this.isDispose){
    //   this.chart.dispose()
    //   delete this.chart
    // }
    // console.log(this.isDispose)
  }
  createSeries(chart, field, name) {
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = field;
    series.dataFields.categoryX = "label";
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
    series.stacked = true;
    series.columns.template.width = am4core.percent(95);

    // Set up tooltips
    // series.tooltip.label.interactionsEnabled = true;
    series.tooltip.keepTargetHover = true;
    // series.columns.template.tooltipHTML = '<b>{category}</b><br><a href="https://en.wikipedia.org/wiki/{category.urlEncode()}">More info</a>';

    //click event
    series.columns.template.events.on('hit', ev => {
      // const a = "https://en.wikipedia.org/wiki/"
      // var win = window.open(a, '_blank');
    }, this)

  }
  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chart) {
        this.chart.dispose();
        delete this.chart
      }
    });
  };
}
