<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.SHARED.INFORMATION'|translate}}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-form">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.STAND_ALONE_TID'|translate}} : </label>
                            <span class="kt-subheader__breadcrumbs-separator">{{information.standAlondTID}}</span> <br>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="form-group mb-0 ellipsis-by-ton" >
                            <label class="kt-font-primary">{{'ORDER.SHARED.SERIAL_NUMBER'|translate}} : </label>
                            <span class="kt-subheader__breadcrumbs-separator" [ngbTooltip]="information.serialNumber">{{information.serialNumber}} </span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0 ellipsis-by-ton">
                            <label class="kt-font-primary">{{'ORDER.SHARED.MODEL'|translate}} : </label>
                            <span class="kt-subheader__breadcrumbs-separator" [ngbTooltip]="information.modelName">{{information.modelName}} </span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.INSTALL_DATE'|translate}} : </label>
                            <span
                                class="kt-subheader__breadcrumbs-separator">{{information?.installDate | date:'dd-MM-yyyy'}}</span>
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="information.eventType == eventType.promotion">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.START_PROMOTION_DATE'|translate}}: </label>
                            <span
                                class="kt-subheader__breadcrumbs-separator">{{information?.startDate | date:'dd-MM-yyyy' }}</span>
                            <br />
                        </div>
                    </div>
                    <div class="col-sm-4" *ngIf="information.eventType == eventType.promotion">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.END_PROMOTION_DATE'|translate}} : </label>
                            <span
                                class="kt-subheader__breadcrumbs-separator">{{information?.endDate | date:'dd-MM-yyyy'}}</span>
                            <br />
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.LINE_SLIP_1'|translate}} : </label>
                            <span class="kt-subheader__breadcrumbs-separator font-18 bold-text">{{information.lineSlip1}} </span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.LINE_SLIP_2'|translate}} : </label>
                            <span class="kt-subheader__breadcrumbs-separator font-18 bold-text">{{information.lineSlip2}} </span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{'ORDER.SHARED.LINE_SLIP_3'|translate}} : </label>
                            <span class="kt-subheader__breadcrumbs-separator font-18 bold-text">{{information.lineSlip3}} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>