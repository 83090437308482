import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-remark-shared',
  templateUrl: './modal-remark-shared.component.html',
  styleUrls: ['./modal-remark-shared.component.scss']
})
export class ModalRemarkSharedComponent implements OnInit {

  @Input() inboundT1Id:number;
  @Input() remark:string;

  constructor(
    public activeModalClose: NgbActiveModal, 
  ) {
    
  }

  ngOnInit(): void {
    this.loadInitial();
  }
  loadInitial(){
    console.log(this.remark)
  }
}
