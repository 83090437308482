import { Injectable } from '@angular/core'; 
import { InboundSupportListViewModel, SearchInboundSupportCriteria, InboundSupportViewModel, OnSiteSupportViewModel, ImportantShowModel } from 'src/app/core/view-models/_sprint3/support/inbound-model';
import { dataInbound } from 'src/app/core/view-models/_sprint3/_mock/admin-tpl-inbound-const';
import { ApiService } from 'src/app/core/http/api.service';  
import { taskData, workFlow, taskSettingData } from 'src/app/core/view-models/_sprint3/_mock/packing-task-const';
import { SettingTaskInformationViewModel, UpdateSettingWorkareaEdc, RejectWorkareaEdc, RejectRemarkWorkareaEdc } from 'src/app/core/view-models/_sprint3/_setting/task-model';
import { TaskWorkFlow } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { LoaderTaskInformationViewModel } from 'src/app/core/view-models/_sprint3/loader/task-model';
import { SettingTaskInformationApisModel } from 'src/app/core/models/_sprint3/setting/setting-edc-view-model';
import { GetSettingEdcMapper } from 'src/app/core/models-mapper/_sprint3/store/work-area-edc-setting-mapper';
import { ResponsiblesSaveModel } from 'src/app/core/view-models/_shared/common.view-model';
 

@Injectable({
  providedIn: "root",
})
export class LoaderTaskService {


  constructor(
    private apiService: ApiService, 
    private getSettingEdcMapper:GetSettingEdcMapper
  ) { }
 
  async getEdc(id:number): Promise<SettingTaskInformationViewModel> {
    const result = await this.apiService.get<SettingTaskInformationApisModel>(`/loader/work-area/edcs/${id}`);
    return this.getSettingEdcMapper.mapForm(result);
  }
  async getperipheral(id:number): Promise<SettingTaskInformationViewModel> {
    const result = await this.apiService.get<SettingTaskInformationApisModel>(`/loader/work-area/peripherals/${id}`);
    return this.getSettingEdcMapper.mapForm(result);
  }
 
  async saveEdc(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/edcs/${id}`,updateRequest);
    return result
  }
  async submitEdc(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/edcs/${id}/submit`,updateRequest);
    return result
  }
  async saveRejectEdc(id:number,updateRequest:RejectWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/edcs/${id}`,updateRequest);
    return result
  }
  async saveRejectSettingEdc(id:number,updateRequest:RejectRemarkWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/edcs/${id}/reject-setting`,updateRequest);
    return result
  }
  async saveRejectStoreEdc(id:number,updateRequest:RejectRemarkWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/edcs/${id}/reject-store`,updateRequest);
    return result
  }
  async saveRejectStorePeripheral(id:number,updateRequest:RejectRemarkWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/peripherals/${id}/reject-store`,updateRequest);
    return result
  }
  async savePeripheral(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/peripherals/${id}`,updateRequest);
    return result
  }
  async submitPeripheral(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/peripherals/${id}/submit`,updateRequest);
    return result
  }
  // async get(): Promise<LoaderTaskInformationViewModel> {
  //   const mockList = taskSettingData as LoaderTaskInformationViewModel;
  //   return mockList;
  // }  
  async saveEdcResponsibles(id:number,updateRequest:ResponsiblesSaveModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/edcs/${id}/close-instead`,updateRequest);
    return result
  }
  async savePeripheralResponsibles(id:number,updateRequest:ResponsiblesSaveModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/loader/work-area/peripherals/${id}/close-instead`,updateRequest);
    return result
  }
} 