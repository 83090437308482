import { Component, OnInit, ViewChild, ContentChild, AfterViewInit, ContentChildren, AfterContentInit, ElementRef } from '@angular/core';
import { StringUtils } from 'src/app/shared/utils/string-utils';
import { KtExpansionPanelContentComponent } from '../kt-expansion-panel-content/kt-expansion-panel-content.component';
import { KtExpansionPanelHeaderComponent } from '../kt-expansion-panel-header/kt-expansion-panel-header.component';

@Component({
  selector: 'kt-expansion-panel',
  templateUrl: './kt-expansion-panel.component.html',
  styleUrls: ['./kt-expansion-panel.component.scss']
})
export class KtExpansionPanelComponent implements OnInit,AfterViewInit,AfterContentInit  {

  panelElementId:string = StringUtils.random(10);
  @ViewChild('panelContent', { static: true }) panelContent: KtExpansionPanelContentComponent;

  constructor() { }
  
  ngOnInit(): void {
    this.panelContent.panelElementId = this.panelElementId;
    
  }
  ngAfterViewInit(): void {
   
  }
  ngAfterContentInit(): void {  
   
  }
 

}
