<kt-expansion-panel>
    <kt-expansion-panel-header>
        {{ '_SHARED.RELATED_EDC' | translate }}
    </kt-expansion-panel-header>
    <div class="table-responsive form-group">
        <table class="table table-striped- table-bordered table-hover table-checkable">
            <thead>
                <tr align="center">
                    <th width="2%" nowrap="">{{ '_SHARED.NO' | translate }}</th>
                    <th width="2%" nowrap=""></th>
                    <th width="2%" nowrap="">{{ '_SHARED.WORK_TYPE' | translate }}</th>
                    <th width="14%" nowrap="">{{ '_SHARED.S_N' | translate }}</th>
                    <th width="" nowrap="">{{ '_SHARED.MODEL' | translate }}</th>
                    <th width="10%" nowrap="">{{ '_SHARED.STAND_ALONE_TID' | translate }}</th>
                    <th width="10%" nowrap="">{{ '_SHARED.TID' | translate }}</th>
                    <th width="10%" nowrap="">{{ '_SHARED.MID' | translate }}</th>
                    <th width="5%" nowrap="">{{ '_SHARED.SHOP_NAME' | translate }}</th>
                    <th width="5%" nowrap="">{{ '_SHARED.ADDRESS' | translate }}</th>
                    <th width="5%" nowrap="">{{ '_SHARED.MULTHI' | translate }}</th>
                    <th width="5%" nowrap="">{{ '_SHARED.MAIN' | translate }}</th>
                    <th width="5%" nowrap="">{{ '_SHARED.BACKUP' | translate }}</th>
                </tr>
                <tr align="center">
                    <td align="center"></td>
                    <td align="center"></td>
                    <!-- <td align="center"><label class="kt-checkbox">
                                <input type="checkbox" [(ngModel)]="selected" (change)="toggleSelect()">
                                <span></span>
                            </label></td> -->
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.edcServiceWorkType"
                            [placeholder]="'_SHARED.WORK_TYPE' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.serialNumber"
                            [placeholder]="'_SHARED.S_N' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.modelName"
                            [placeholder]="'_SHARED.MODEL' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.standAloneTid"
                            [placeholder]="'_SHARED.STAND_ALONE_TID' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.tid"
                            [placeholder]="'_SHARED.TID' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.mid"
                            [placeholder]="'_SHARED.MID' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.shopName"
                            [placeholder]="'_SHARED.SHOP_NAME' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.address"
                            [placeholder]="'_SHARED.ADDRESS' | translate"></td>
                    <td align="center"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.main"
                            [placeholder]="'_SHARED.MAIN' | translate"></td>
                    <td align="center"><input class="form-control " [(ngModel)]="searchCriteria.backup"
                            [placeholder]="'_SHARED.BACKUP' | translate"></td>
                </tr>
            </thead>
            <tbody>
                <tr class="ng-star-inserted" *ngFor="let item of items| grdFilter: {
                        edcServiceWorkTypeName: searchCriteria.edcServiceWorkType,
                        serialNumber: searchCriteria.serialNumber,
                        standAloneTid: searchCriteria.standAloneTid,
                        tid: searchCriteria.tid,
                        mid: searchCriteria.mid,
                        shopName: searchCriteria.shopName,
                        address: searchCriteria.address,
                        main: searchCriteria.main,
                        backup: searchCriteria.backup
                    };index as i">
                    <td align="center">{{i + 1}}</td>

                    <td align="center"><span>
                            <a href="javascript:;" (click)="OpenCreaatWorkType(item)"><i
                                    class="la la-cog text-primary"></i></a></span>
                    </td>
                    <td align="left" nowrap="">{{item.edcServiceWorkType?.name}}</td>
                    <td align="center"><a href="javascript:;"
                            (click)="openModalEdcDetail(item)">{{item.serialNumber}}</a></td>
                    <td align="left" nowrap="">{{item.modelName}}</td>
                    <td align="left" nowrap="" class="bold-text">{{item.standAloneTid}}</td>
                    <td align="left" nowrap="" class="bold-text">{{item.tid}}</td>
                    <td align="left" nowrap="" class="bold-text">{{item.mid}}</td>
                    <td align="left" nowrap="">{{item.shopName}}</td>
                    <td align="left" nowrap="">{{item.address}}</td>
                    <td align="center"><i class="la la-check ng-star-inserted" *ngIf="item.multhi"></i></td>
                    <td align="left" nowrap="">{{item.main}}</td>
                    <td align="left" nowrap="">{{item.backup}}</td>
                </tr>
            </tbody>
        </table>
    </div>
<div class="kt-pagination kt-pagination--brand">
    <div class="kt-pagination__toolbar">
        <span class="pagination__desc"> {{ '_SHARED.DISPLAYING' | translate }} 2 {{ '_SHARED.RECORDS' | translate }}
        </span>
    </div>
</div>
</kt-expansion-panel>

<!-- </div> -->