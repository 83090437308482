import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PackingTaskInformationViewModel } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { SharedTaskInformationComponent } from '../../../shared-task-information/shared-task-information.component';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { PackingTaskService } from 'src/app/core/services/_sprint3/packing/task.service';
import { SettingCallOutInforMapper } from 'src/app/core/models-mapper/_sprint3/setting/setting-call-out-infor-mapper';
import { SharedViewTaskInformationComponent } from '../../../shared-view-task-information/shared-view-task-information.component';

@Component({
  selector: 'app-packing-view-task-information',
  templateUrl: './packing-view-task-information.component.html',
  styleUrls: ['./packing-view-task-information.component.scss']
})
export class PackingViewTaskInformationComponent implements OnInit {
  onSave: Subject<any> = new Subject();
  @ViewChild('taskInformation', { static: true }) taskInformation: SharedViewTaskInformationComponent;

  item: PackingTaskInformationViewModel = new PackingTaskInformationViewModel
  formValidate: FormGroup;
  submit:boolean;
  id:number;
  constructor(
    private formBuilder: FormBuilder,
    private dialogService: MyDialogService,
    private service: PackingTaskService,
    private inforMapper:SettingCallOutInforMapper,
  ) { }

  ngOnInit(): void {
    this.formValidate = this.formBuilder.group({
      trackingNumber: [null, Validators.required],
      remark: [null],
    });
    this.loadInitial();
  }

  async loadInitial() {
    this.item = await this.service.getEdc(this.id);
 
    this.taskInformation.loadInitial(this.inforMapper.mapTo(this.item.info))

    this.formValidate.patchValue({
      trackingNumber: this.item.trackingNumber,
      remark: this.item.remark,
    });
  }
  get f() { return this.formValidate.controls; }

}
