import { Injectable } from "@angular/core"; 
import { Mapper } from '../mapper'; 
import { AvailableEdc } from '../../view-models/_shared/worksheet.view-model'; 
import { AvailableEdcListApis } from '../../models/_sprint3/worksheet/worksheet-api-view-model';

@Injectable({
  providedIn: "root", 
})
export class AvailableEdcMapper implements Mapper<AvailableEdcListApis, AvailableEdc>{
  constructor( 
  ) { }
  mapForm(param: AvailableEdcListApis): AvailableEdc { 
      const item = new AvailableEdc(); 
      item.isChecked = param.isChecked;
      item.isShow = true; 
      item.orderNo = param.orderNo;
      item.orderDate= param.orderDate;
      item.edcId= param.edcId;
      item.operation= null;
      item.backup= param.backup;
      item.endDate= param.endDate;
      item.installDate= param.installDate;
      item.main= param.main;
      item.mid= param.mid;
      item.modelName= param.modelName;
      item.multhi= param.multhi;
      item.serialNumber= param.serialNumber;
      item.standAlondTid= param.standAlondTid;
      item.startDate= param.startDate;
      item.status = param.status;
      item.tid= param.tid;
      item.remark= param.remark;
      item.reprogramCase = param.reprogramCase;
      item.reprogramCaseRemark = param.reprogramCaseRemark
      item.oldEdcId = param.oldEdcId;
      item.onsiteItemTypeId = param.onsiteItemTypeId;
      item.connectors = param.connectors
      return item;
  }
  mapTo(param: AvailableEdc): AvailableEdcListApis { 
    const item = new AvailableEdcListApis();
    
    return item;
  }

}