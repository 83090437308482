<app-view-shared-edc-information #information [information]="item.information"></app-view-shared-edc-information>

<app-view-shared-shop-detail [shop]="item.shop" #shop></app-view-shared-shop-detail>

<app-shared-readonly-communication-detail #communication [communication]="item.communicationBase">
</app-shared-readonly-communication-detail>

<app-view-shared-feature-connector #connector [feature]="item.feature"></app-view-shared-feature-connector>

<div class="kt-portlet kt-portlet--tabs">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-toolbar">
            <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-danger nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold"
                role="tablist">
                <li class="nav-item">
                    <a class="nav-link" href="javascript:;" [ngClass]="{'active': detailType == orderDetailType.single}"
                        (click)="focusTabDetailType(orderDetailType.single)">{{'ORDER.SHARED.MAIN'|translate}}
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:;" [ngClass]="{'active': detailType == orderDetailType.multhi}"
                        (click)="focusTabDetailType(orderDetailType.multhi)">{{'ORDER.SHARED.MULTHI'|translate}}
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="kt-portlet__body" style="background-color: aliceblue">
        <div class="tab-content">
            <div class="tab-pane active" role="tabpanel">
                <ng-template switch-tab></ng-template>
            </div>
        </div>
    </div>
</div> 