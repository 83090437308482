import { Component, OnInit } from '@angular/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ChartService } from 'src/app/core/services/shared/chart.service';
import { ChartDashboardService } from 'src/app/core/services/shared/chart-dashboard.service';
import { UserDashboardService } from 'src/app/core/services/dashboard/user-dashboard.service';

@Component({
  selector: 'chart-cs-statistics',
  templateUrl: './cs-chart.component.html',
  styleUrls: ['./cs-chart.component.scss']
})
export class CsChartComponent implements OnInit {

  constructor(
    public chartService: UserDashboardService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {

    // Create chart instance
    let chart = am4core.create("worksheetChart", am4charts.XYChart);

    chart.data = await this.chartService.getStaticsOrderCS();
    // Add data
    // chart.data = [{
    //   "day": "01/01/2563",
    //   "save":32,
    //   "send":45,
    //   "storeProcessing":10,
    //   "storeCommit":12,
    //   "submit":25,
    // }, {
    //   "day": "02/01/2563",
    //   "save":32,
    //   "send":45,
    //   "storeProcessing":10,
    //   "storeCommit":12,
    //   "submit":25,
    // }, {
    //   "day": "03/01/2563",
    //   "save":23,
    //   "send":35,
    //   "storeProcessing":12,
    //   "storeCommit":12,
    //   "submit":22,
    // }, {
    //   "save":23,
    //   "send":54,
    //   "storeProcessing":30,
    //   "storeCommit":12,
    //   "submit":25,
    // }
    //   , {
    //   "day": "05/01/2563",
    //   "save":12,
    //   "send":25,
    //   "storeProcessing":7,
    //   "storeCommit":19,
    //   "submit":36,
    // }
    // ];

    chart.colors.list = [
      am4core.color("#845EC2"),
      am4core.color("#D65DB1"),
      am4core.color("#FF6F91"),
      am4core.color("#FF9671"),
      am4core.color("#FFC75F"),
      am4core.color("#F9F871")
    ];
    
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.grid.template.location = 0;


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.inside = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.min = 0;

    // Create series
    function createSeries(field, name) {

      // Set up series
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "label";
      series.sequencedInterpolation = true;

      // Make it stacked
      series.stacked = true;

      // Configure columns
      series.columns.template.width = am4core.percent(60);
      series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px]{categoryX}: {valueY}";

      // Add label
      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.locationY = 0.5;
      labelBullet.label.hideOversized = true;

      return series;
    }

    createSeries("save", "Save");
    // createSeries("send", "Send");
    createSeries("storeProcessing", "Store Processing");
    // createSeries("storeCommit", "Store Commit");
    createSeries("submit", "Submit");


    // Legend
    chart.legend = new am4charts.Legend();
  }
}
