export class ProvinceModel{
    id: number;
    nameTH: string;
    nameEN: string;
}

export interface DistrictModel{
    id: number;
    nameTH: string;
    nameEN: string;
}

export interface SubdistrictModel{
    id: number;
    nameTH: string;
    nameEN: string;
    zipCode: number;
}
export interface ClientGroupModel {
    id: number;
    code: string;
    name: string;
    description: string;
    isActive: boolean;
  }

  export interface CustomerModel{
    id: number;
    name: string; 
}