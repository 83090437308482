import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper'; 
import { WorksheetAllInformationViewModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { WorksheetLocationViewModel } from 'src/app/core/view-models/_sprint3/_shared/worksheet-information-view-model';
import { Client } from 'src/app/core/view-models/_shared/worksheet.view-model';
 

@Injectable({
    providedIn: "root",
}) 
export class AdminAllClosejobLocationMapper implements Mapper<WorksheetAllInformationViewModel, Client> {
    mapForm(param: WorksheetAllInformationViewModel): Client {
        const item = new Client(); 
        item.id = param.id;
        item.code = ''
        item.group = null ;
        item.branchName =  ''
        item.nameTH = param.shopNameTH
        item.nameEN = param.shopNameEN
        item.address = param.shopAddress
        item.addressTH = param.shopAddress
        item.province = param.province
        item.district = param.district
        item.subDistrict = param.subdistrict
        item.zipCode = param.zipCode
        item.contactName = param.contactName
        item.contactPhoneNumber = param.contactPhoneNumber
        item.contactTel = param.contactPhoneNumber
        item.contactFaxNumber = param.contactFaxNumber
        item.email = param.contactEmail
        item.placeInLocation = param.location
        item.location = param.location
        item.reg = param.reg
        item.bankPhoneNumber =  ''
        item.bankName =  ''
        item.provinceId = param.province?.id
        item.districtId = param.district?.id
        item.subdistrictId = param.subdistrict?.id
        item.posCode = param.zipCode
        item.locationCode = ''
        item.bankTel =  ''
        item.bankBranchName =  ''
        item.provinceName = param.province?.nameTH
        item.districtName = param.district?.nameTH
        item.subDistrictName = param.subdistrict?.nameTH 
        return item;
    }
    mapTo(param: Client): WorksheetAllInformationViewModel {
        return null
    }
}
