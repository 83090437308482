import { Injectable } from '@angular/core';
import { OrderEdcBankHostTypeViewModel, OrderEdcBankHostViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { BankHostMockService } from '../_mock/_shared/bank-host.service.mock';
import { ApiService } from '../../http/api.service';
import { BankHostFunctionOrderModel } from '../../models/_order/order-shared.model';

@Injectable({
    providedIn: 'root'
})
export class BankHostViewService {

    constructor(
        private apiService: ApiService,
        private _mock: BankHostMockService,
    ) { }

    async bankHosts(id: number): Promise<Array<OrderEdcBankHostViewModel>> {
        const response = await this.apiService.get<OrderEdcBankHostViewModel[]>(`/order/installs/${id}/bank-hosts`);
        return response;
    }
    async getBankHostsByOrderType(id: number, type: number): Promise<Array<OrderEdcBankHostViewModel>> {
        if (type == 1) { 
            const response = await this.apiService.get<OrderEdcBankHostViewModel[]>(`/order/installs/${id}/bank-hosts`);
            return response;
        }
        if (type == 2) {
            const response = await this.apiService.get<OrderEdcBankHostViewModel[]>(`/order/re-programs/${id}/bank-hosts`);
            return response;
        }
        return null
    }

    async bankHostsImport(): Promise<Array<OrderEdcBankHostViewModel>> {
        const response = await this.apiService.get<OrderEdcBankHostViewModel[]>(`/bank-hosts`);
        return response;
    }

    async reprogramBankHosts(id: number): Promise<Array<OrderEdcBankHostViewModel>> {
        const response = await this.apiService.get<OrderEdcBankHostViewModel[]>(`/order/re-programs/${id}/bank-hosts`);
        return response;
    }


    async bankHostFunctions(): Promise<Array<OrderEdcBankHostTypeViewModel>> {
        const response = await this.apiService.get<BankHostFunctionOrderModel[]>(`/bank-host-functions`);
        return response;
    }
}