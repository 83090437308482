<kt-expansion-panel>
  <kt-expansion-panel-header [canExpand]="false">
    <h3 class="kt-portlet__head-title"> {{ '_SHARED.LOCATION' | translate }} </h3>
  </kt-expansion-panel-header>

  <form class="kt-form">
    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.SHOP_NAME_EN' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator"> {{location?.nameEN}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.SHOP_NAME_TH' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.nameTH}}</span>
          </div>
        </div>
        <div class="col-sm-4">

        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.ADDRESS' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.addressTH}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.PROVINCE' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.provinceName}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.DISTRICT' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.districtName}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.SUB_DISTRICT' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.subDistrictName}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.POSCODE' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.posCode}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.CONTACT_PHONE_NUMBER' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.contactPhoneNumber}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.CONTACT_FAX_NUMBER' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.contactFaxNumber}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.EMAIL' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.email}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.CONTACT_NAME' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.contactName}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.LOCATION' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.location}}</span>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-group mb-0">
            <label class="kt-font-primary">{{'ORDER.SHOP.REG' | translate}} : </label>
            <span class="kt-subheader__breadcrumbs-separator">{{location?.reg}}</span>
          </div>
        </div>
      </div>

    </div>
  </form>

</kt-expansion-panel>
