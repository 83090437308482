import { Component, OnInit, Input } from '@angular/core';
import { OrderEdcCommunicationBaseViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { orderConst } from 'src/app/core/_static/order.const';

@Component({
  selector: 'app-shared-readonly-communication-detail',
  templateUrl: './shared-readonly-communication-detail.component.html',
  styleUrls: ['./shared-readonly-communication-detail.component.scss']
})
export class SharedUserReadonlyCommunicationDetailComponent implements OnInit {


  @Input()
  communication: OrderEdcCommunicationBaseViewModel = new OrderEdcCommunicationBaseViewModel();
  constructor() { }

  ngOnInit(): void {
    console.log(this.communication)
  }
  
  public setInitial(item: OrderEdcCommunicationBaseViewModel) {
    console.log(item);
    this.communication = item;
  } 

  get communicationType(){
    return orderConst.communicationType;
  }

}
