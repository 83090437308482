
import { Injectable } from '@angular/core';
import { Mapper } from '../mapper';
import { InboundBankHostViewModel } from '../../view-models/_inbound/inbound.view-model';
import { InboundBankHostSearchApiModel } from '../../models/_inbound/onsite-info-apis-model';

@Injectable({
    providedIn: 'root'
})
export class InboundBankHostSearchMapper implements Mapper<InboundBankHostSearchApiModel, InboundBankHostViewModel>{
    constructor(){}
    mapForm(param: InboundBankHostSearchApiModel): InboundBankHostViewModel {
        return {
           id: param.id,
           name: param.nameEN,
           remark: "",
           selected: false
        };
    }
    mapTo(param: InboundBankHostViewModel): InboundBankHostSearchApiModel {
        return null;
    }

}