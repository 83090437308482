import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeSerialNumberComponent } from '../change-serial-number/change-serial-number.component';
import { StoreTaskInformationViewModel } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { StoreTaskInformationService } from 'src/app/core/services/_sprint3/_shared/store-task-information.service';
import { StatusConstSp3 } from 'src/app/core/_static/sp3-const/status-const-sp3';

@Component({
  selector: 'app-shared-store-view-task-information',
  templateUrl: './shared-store-view-task-information.component.html',
  styleUrls: ['./shared-store-view-task-information.component.scss']
})
export class SharedStoreViewTaskInformationComponent implements OnInit {
  items: StoreTaskInformationViewModel = new StoreTaskInformationViewModel
  constructor(
    private modalService: NgbModal,
    private storeTaskInformationService: StoreTaskInformationService,

  ) { }

  ngOnInit(): void {
  }

  async loadDataList(item: StoreTaskInformationViewModel) {
    this.items = item
  }

  get nextTo() {
    return StatusConstSp3.nextTo
  }
}
