import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './http/http.token.interceptor';
import { AuthService } from './services/auth.service';
import { ApiService } from './http/api.service';
import { DateUtils } from '../shared/utils/date-utils';
import { AuthGuard } from './guards/auth.guard';
import { NoAuthGuard } from './guards/no-auth.guard';
import { StringUtils } from '../shared/utils/string-utils';



@NgModule({
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    AuthService,
    ApiService,
    DateUtils,
    StringUtils,
    AuthGuard,
    NoAuthGuard,
  ],
  imports: [
    CommonModule
  ],
  exports:[]
})
export class CoreModule { }
