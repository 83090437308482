import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderEdcInstallService } from 'src/app/core/services/order/order-edc-install.service';
import {   OrderEdcViewModel, OrderDetailEdcMulthiViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { OrderEdcBankHostTypeViewModel, OrderEdcBankHostViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { BankHostMockService } from 'src/app/core/services/_mock/_shared/bank-host.service.mock';
@Component({
  selector: 'app-readonly-multhi-shop-detail',
  templateUrl: './readonly-multhi-shop-detail.component.html',
  styleUrls: ['./readonly-multhi-shop-detail.component.scss']
})
export class ReadonlyMulthiShopDetailComponent implements OnInit {
  item: OrderDetailEdcMulthiViewModel = new OrderDetailEdcMulthiViewModel();

  bankHosts: OrderEdcBankHostViewModel[] = [];
  functions: OrderEdcBankHostTypeViewModel[] = [];
  isMain: number;
  constructor(
    private _service: OrderEdcInstallService,
    private _bankHostService: BankHostMockService,
    public activeModal:NgbActiveModal,
    ) { }

  ngOnInit( ): void {
    console.log(this.item);
    // this.loadInitial();
    
  }

  async loadInitial(){
    //this.item = await this._service.getEdc(this.orderId);
    this.bankHosts = await this._bankHostService.bankHosts();
    this.functions = await this._bankHostService.bankHostFunctions();
  }
}
