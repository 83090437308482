
export class ChangeSerialNumberViewModel {
    current: Current;
    new: Current;
    remark: string;
  }
  
  export class Current {
    id: number;
    serialNumber: string;
    modelName: string;
    tid: string;
    mid: string;
    box: string;
    currentStatus: CurrentStatus;
    changeToStatus: CurrentStatus;
  }
  
  export class CurrentStatus {
    kind: string;
    id: number;
    code: string;
    name: string;
  }
