import { ShopViewModel } from '../../_shop/shop.view-model';
import { CommandModel, MechanicModel, ContactModel } from './worksheet-model';
import { Client, AvailableEdc, EventType } from '../../_shared/worksheet.view-model';
import { Status } from 'src/app/core/models/user/help-desk/inbound-t1-model';


export class WorksheetPMViewModel {
  id: number;
  shop: Client = new Client;
  location: Client = new Client;
  mechanic: MechanicModel = new MechanicModel;
  command: CommandModel = new CommandModel;
  contact: ContactModel = new ContactModel;
  remark: string = '';
  availableEdcs: AvailableEdc[] = [];
  selectedEdcs: AvailableEdc[] = [];
  eventTypeId: number = 0;
  sendToType: number = 0;
  sentToStore: boolean = false;
  inTernalStatus: number;
  typeOfWorkId: number;
  topicId: number;
  sendToStatus: number;
  workType : WorkType;
  referWorksheetInfo: ReferWorksheetInfo = new ReferWorksheetInfo();
  eventType:EventType;
  attachFileIdList: number[]=[]
  worksheetNo:string;
  authorize: Authorize; 
  reviseRemark : string = '';
}
export class Authorize {
  canSave: boolean;
  canSubmit: boolean;
  canCancel: boolean
}
export class ReferWorksheetInfo {
  worksheetNo: string; 
  worksheetDate: string;
  operationDate: string;
  assignBy: string;
  technicianName: string;
  finishDateTime: string;
  workType : WorkType;
  status: Status;
  phoneNumber:string;
}
 
export class WorkType {
  description: string;
  id: number;
  name: string;
}

export class MechanicGallery {
  id: number;
  name: string;
  gallerList: GallerList[] = []
  totalGallery: number;
}
export class GallerList {
  id: number;
  name: string;
  url: string;
}