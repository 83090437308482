import { Injectable } from "@angular/core"; 
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { WorksheetPMViewModel } from '../../view-models/_sprint3/worksheet/worksheet-pm-model';
import { WorksheetPMApisModel, WorksheetReprogramApisModel } from '../../models/_sprint3/worksheet/worksheet-api-view-model';
import { CreateReprogramListWorksheetMapper } from './create-reprogram-edc-list-mapper';

@Injectable({
  providedIn: "root",
})
export class CreateReprogramWorksheetMapper implements Mapper<WorksheetReprogramApisModel, WorksheetPMViewModel>{
  constructor( 
      private edcMapper: CreateReprogramListWorksheetMapper,
  ) { }
  mapForm(param: WorksheetReprogramApisModel): WorksheetPMViewModel { 
      const item = new WorksheetPMViewModel(); 
      return item;
  }
  mapTo(param: WorksheetPMViewModel): WorksheetReprogramApisModel { 
    const item = new WorksheetReprogramApisModel();
    item.clientId = param.shop?.id; 
    item.convenientDate = DateUtils.toFormat(param.contact?.serviceDate, DateUtils.YYYY_MM_DD);
    item.convenientHour = param.contact?.serviceHour;
    item.convenientMinute = param.contact?.serviceMinute;
    item.issue = param.command?.issue;
    item.recommend = param.command?.recommend;
    item.workCommand = param.command?.command;
    item.commandContactName = param.contact?.contactName;
    item.commandContactPhoneNumber = param.contact?.contactPhone ; 
    item.remark = param.remark
    item.shopNameTH= param.location?.nameTH;
    item.shopNameEN= param.location?.nameEN;
    item.shopAddress= param.location?.address;
    item.provinceId= param.location?.provinceId; 
    item.districtId= param.location?.districtId; 
    item.contactName = param.location?.contactName;
    item.contactPhoneNumber = param.location?.contactPhoneNumber; 
    item.contactFaxNumber= param.location?.contactFaxNumber;
    item.subdistrictId= param.location?.subdistrictId;
    item.zipCode= param.location?.posCode;
    item.latitude= '';
    item.longitude= '';  
    item.contactEmail= param.location?.email;
    item.location= param.location?.location;
    item.reg= param.location?.reg; 
    item.eventTypeId = param.eventTypeId;
    item.isSetSim = param.sentToStore; 
    item.attachFileId = param.contact?.commandFile?.attachFileId;   
    item.edcList = param.selectedEdcs.map(x => this.edcMapper.mapTo(x))
    item.attachFileId = param.contact?.commandFile?.attachFileId;  
    item.attachFileIdList = param.attachFileIdList; 
    item.isHq = param.location.isHq
    return item;
  }

}