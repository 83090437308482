<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.WORKSHEET.SHOP_DETAIL'|translate}} &nbsp;
                <a class="btn btn-primary btn-sm" href="javascript:void(0)" (click)="onHistoryClick()" data-toggle="modal">
                    <i class="fa fa-phone-alt"></i> {{ '_SHARED.HISTORY' | translate}}
                  </a>
            </h3>
        </div>
    </div>
    <!-- end sub header 1-->
    <!-- body 1-->
    <div class="kt-portlet__body">
        <form class="kt-form">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.SHOP_CODE'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{ item.shopDetailInfo?.shopCode}}</span><br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.SHOP_NAME_TH'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.shopNameTH}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.SHOP_NAME_EN'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.shopNameEN}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.ADDRESS'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.address}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.PROVINCE'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.provinceName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.DISTRINCT'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.districtName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.SUB_DISTRINCT'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">
                            {{item.shopDetailInfo?.subdistrictName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.POST_CODE'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.zipCode}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.CONTACT_NAME'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.contactName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.CONTACT_PHONE_NUMBER'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">
                            {{item.shopDetailInfo?.contactPhoneNumber}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.CONTACT_FAX_NUMBER'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">
                            {{item.shopDetailInfo?.contactFaxNumber}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.EMAIL'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo?.email}}</span>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.BANK_NAME'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo.bankBranchName}}</span>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.WORKSHEET.BANK_TEL'|translate}} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.shopDetailInfo.bankTel}}</span>
                    </div>
                </div>

            </div>
        </form>
    </div>
    <!-- end body 1-->
</div>

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.WORKSHEET.SELECTED_SERIAL_LIST'|translate}}
            </h3>
        </div>
    </div>

    <div class="kt-portlet__body">

        <div class="table-responsive">
            <table class="table table-striped- table-bordered table-hover table-checkable table-responsive">
                <thead>
                    <tr align="center">

                        <th width="5%" nowrap=""> {{'ORDER.WORKSHEET.NO'|translate}}</th>
                        <!-- <th width="5%" nowrap="">Order No</th>
                            <th width="10%" nowrap=""> Order Date </th> -->
                        <th width="5%" nowrap="">{{'ORDER.WORKSHEET.ACTIONS'|translate}}</th>
                        <th width="15%" nowrap=""> {{'ORDER.WORKSHEET.ORDER_NO'|translate}} </th>
                        <th width="15%" nowrap=""> {{'ORDER.WORKSHEET.ORDER_DATE'|translate}} </th>
                        <th width="15%" nowrap=""> {{'ORDER.WORKSHEET.S/N'|translate}} </th>
                        <th width="15%" nowrap=""> {{'_SHARED.S_N_RE_NEW'|translate}} </th>
                        <th width="10%" nowrap=""> {{'ORDER.WORKSHEET.TID'|translate}} </th>
                        <th width="10%" nowrap=""> {{'ORDER.WORKSHEET.MID'|translate}} </th>
                        <th width="5%" nowrap="">{{'ORDER.WORKSHEET.INSTALL_DATE'|translate}} </th>
                        <th width="5%" nowrap=""> {{'ORDER.WORKSHEET.MULTHI'|translate}} </th>
                        <th width="5%" nowrap=""> {{'ORDER.WORKSHEET.MAIN'|translate}} </th>
                        <th width="5%" nowrap=""> {{'ORDER.WORKSHEET.BACKUP'|translate}} </th>
                        <th width="15%" nowrap=""> {{'ORDER.WORKSHEET.S/N_REPLACE'|translate}} </th>
                        <th width="10%" nowrap=""> {{'ORDER.WORKSHEET.TID_REPLACE'|translate}} </th>
                        <!-- <th width="" nowrap="">Type Of Work</th> -->
                    </tr>
                    <tr>

                        <th></th>
                        <!-- <th><input class="form-control" placeholder="Order No"> </th>
                            <th><input class="form-control" placeholder="Order Date"></th> -->
                        <th></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.orderNo" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.ORDER_NO'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.orderDate" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.ORDER_DATE'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.serialNumber" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.S/N'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.changedSerialNumber" type="text"
                                class="form-control" [placeholder]="'_SHARED.S_N_RE_NEW'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.tid" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.TID'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.mid" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.MID'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.installDate" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.INSTALL_DATE'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.multhi" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.MULTHI'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.main" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.MAIN'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.backup" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.BACKUP'|translate"></th>
                        <!-- <th></th> -->
                        <th><input [(ngModel)]="colSearchSelectedModel.serialNumberReplace" type="text"
                                class="form-control" [placeholder]="'ORDER.WORKSHEET.S/N'|translate"></th>
                        <th><input [(ngModel)]="colSearchSelectedModel.tidReplace" type="text" class="form-control"
                                [placeholder]="'ORDER.WORKSHEET.TID'|translate"></th>

                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of item.selectedEdcs  | grdFilter : {
                            orderNo: colSearchSelectedModel.orderNo,
                            orderDate: colSearchSelectedModel.orderDate,
                            serialNumber: colSearchSelectedModel.serialNumber,
                            tid: colSearchSelectedModel.tid,
                            mid: colSearchSelectedModel.mid,
                            installDate: colSearchSelectedModel.installDate,
                            multhi: colSearchSelectedModel.multhi,
                            main: colSearchSelectedModel.main,
                            backup: colSearchSelectedModel.backup,
                            serialNumberReplace: colSearchSelectedModel.serialNumberReplace,
                            tidReplace: colSearchSelectedModel.tidReplace,
                            changedSerialNumber: colSearchSelectedModel.changedSerialNumber
                        } ; let i=index;">
                        <tr>
                            <!-- placement="top" [ngbTooltip]="item.location" -->
                            <td align="center">{{ i + 1}}</td>
                            <!-- <td nowrap="" align="center">{{ item.orderNo }}</td>
                            <td nowrap="" align="center">{{ item.orderDate}}</td> -->
                            <td nowrap="" align="center">
                                <div class="btn-group">
                                    <a class="btn btn-sm btn-clean btn-icon btn-icon-md" placement="top"
                                        ngbTooltip="{{'_SHARED.VIEW' | translate}}"><i class="flaticon-eye"
                                            (click)="OnViewClick(item)"></i></a>
                                    <!-- <a title="View" class="btn btn-sm btn-clean btn-icon btn-icon-md" placement="top" ngbTooltip="Setting"
                      (click)="openCreaateWorksheetModal(item.edcId)">
                      <i class="fa fa-cog"></i></a>  -->

                                </div>
                            </td>
                            <td nowrap="" align="left">{{ item.orderNo}}</td>
                            <td nowrap="" align="left">{{ item.orderDate| date: 'dd/MM/yyyy'}}</td>
                            <td nowrap="" align="left">{{ item.serialNumber}}</td>
                            <td nowrap="" align="left" class="bold-text-b">{{ item.changedSerialNumber}}</td>
                            <td nowrap="" align="left" class="bold-text"><a href="javascript:;" (click)="openTidHistory(item.tid)">{{ item.tid }}</a> </td>
                            <td nowrap="" align="left" class="bold-text">{{ item.mid}}</td>
                            <td nowrap="" align="center">{{ item.installDate | date: 'dd/MM/yyyy'}}</td>
                            <td align="center"><i class="flaticon2-check-mark multhi" *ngIf="item.multhi"></i></td>
                            <td nowrap="" align="left">{{ item.main}}</td>
                            <td nowrap="" align="left">{{ item.backup}}</td>

                            <td nowrap="" align="left" class="bold-text">{{ item.replaceSerialNumber}}</td>
                            <td nowrap="" align="left" class="bold-text">{{ item.replaceTid }}</td>
                            <!-- <td nowrap="" align="center"> {{ item.}}
                                    <span class="kt-badge  kt-badge--primary kt-badge--inline kt-badge--pill">Install</span>
                                    <span class="kt-badge  kt-badge--info kt-badge--inline kt-badge--pill">Re Program</span>
                                    <span class="kt-badge  kt-badge--warning kt-badge--inline kt-badge--pill">Remove</span>
                                </td> -->
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        <h3 class="kt-portlet__head-title"> {{'_SHARED.COMMAND' | translate }} </h3>
    </kt-expansion-panel-header>
    <form>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"><label>{{'_SHARED.ISSUES' | translate }}</label><textarea rows="5"
                        [(ngModel)]="item.issue" [ngModelOptions]="{standalone: true}"
                        [placeholder]="'_SHARED.ISSUES' | translate" readonly class="form-control"></textarea></div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"><label>{{'_SHARED.WORK_COMMAND' | translate }}</label><textarea rows="5"
                        [(ngModel)]="item.workCommand" [ngModelOptions]="{standalone: true}"
                        [placeholder]="'_SHARED.WORK_COMMAND' | translate" readonly class="form-control"></textarea>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"><label>{{'_SHARED.RECOMMEND' | translate }}</label><textarea rows="5"
                        [(ngModel)]="item.recommend" [ngModelOptions]="{standalone: true}"
                        placeholder="{{'_SHARED.RECOMMEND' | translate }}" readonly class="form-control"></textarea>
                </div>
            </div>

        </div>
    </form>


</kt-expansion-panel>
<app-worksheet-location-view-shared #location></app-worksheet-location-view-shared>

<app-worksheet-contact-view-shared #contact></app-worksheet-contact-view-shared>

<!-- <div class="form-group col-6">
        <div class="kt-checkbox-list">
            <label class="kt-checkbox kt-checkbox--brand">
                <input [(ngModel)]="isSentToStore" [ngModelOptions]="{standalone: true}" [checked]="true"
                    type="checkbox"> {{'ORDER.WORKSHEET.SEND_TO_STORE'|translate}}
                <span></span>
            </label>
        </div>
    </div> -->

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'_SHARED.REMARK' | translate}} </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-form">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group"><textarea rows="5" readonly maxlength="4000" class="form-control"
                                [(ngModel)]="item.remark" [ngModelOptions]="{standalone: true}"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>