import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model'; 

@Component({
  selector: 'app-call-history-view-signature',
  templateUrl: './call-history-view-signature.component.html',
  styleUrls: ['./call-history-view-signature.component.scss']
})
export class CallHistoryViewSignatureComponent implements OnInit {

   item:AttachFile
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.loadInitial()
  }

  async loadInitial() {

  }

}
