import { Directive, ElementRef, HostListener, Input } from '@angular/core';
@Directive({
  selector: 'input[terminalId]'
})
export class TerminalIdDirective {

  constructor(
    private _el: ElementRef
  ) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace();
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
