import { Component, OnInit, Input } from '@angular/core';
import { PackingTaskInformationViewModel, TaskWorkFlow, TaskWorkFlowViewModel } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { PackingTaskService } from 'src/app/core/services/_sprint3/packing/task.service';
import { WorkSheetViewEdcComponent } from '../../worksheet/cs/view/view-edc/view-edc.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shared-task-workflow',
  templateUrl: './shared-task-workflow.component.html',
  styleUrls: ['./shared-task-workflow.component.scss']
})
export class SharedTaskWorkflowComponent implements OnInit {
  id:number;
  items : TaskWorkFlowViewModel[] = []
  constructor( 
    private service: PackingTaskService,
    private modalService: NgbModal
    ) { }

  ngOnInit(): void { 
    this.loadInitial()
  }
  async loadInitial() {
    this.items = await this.service.getworkFlowEdc(this.id); 
  }
  OnViewClick(item: TaskWorkFlow) {
    console.log(item);
    const modalRef = this.modalService.open(WorkSheetViewEdcComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'width70'
    });
    modalRef.componentInstance.edcId = item.edcId;
  }
}
