
    <!-- begin:: Page -->
    <div class="kt-grid kt-grid--ver kt-grid--root kt-page">
        <div class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v2 kt-login--signin" id="kt_login">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                <div class="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
                    <div class="kt-login__container">
                        <div class="kt-login__logo">
                            <a href="#">
                                <img src="assets/img/JOBSORDER_LOGO.png" class="img-responsive">
                            </a>
                        </div>
                        <div class="kt-login__signin">
                            <div class="kt-login__head">
                                <h3 class="kt-login__title">{{ 'LOGIN.AUTHENTICATION_SYSTEM' | translate}}</h3>
                            </div>
                            <form class="kt-form" id="loginform" [formGroup]="authForm" (ngSubmit)="submitForm()">

                                <div class="input-group">
                                    <input class="form-control" type="text" [placeholder]="'LOGIN.USERNAME' | translate" name="Username"
                                        formControlName="username"
                                        [ngClass]="{ 'is-invalid': submitted && f.username.errors }" englishOnly
                                        autocomplete="off">
                                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                        <div *ngIf="f.username.errors.required">
                                            {{ 'LOGIN.PLEASE_INPUT_USERNAME' | translate}}</div>
                                    </div>
                                </div>
                                <div class="input-group">
                                    <input class="form-control" type="password" [placeholder]="'LOGIN.PASSWORD' | translate" name="password"
                                        formControlName="password"
                                        [ngClass]="{ 'is-invalid': submitted && f.password.errors }" notThaiChar>
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">
                                            {{ 'LOGIN.PLEASE_INPUT_PASSWORD' | translate}}</div>
                                    </div>
                                </div>
                                <div class="row kt-login__extra">
                                    <!-- <div class="col">
                                        <label class="kt-checkbox">
                                            <input type="checkbox" value="1" name="Internaluser" formControlName="Internaluser"> {{ 'LOGIN.INTERNAL_USER' | translate}}
                                            <span></span>
                                        </label>
                                    </div> -->
                                    <div class="kt-radio-inline">
                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                            <input type="radio" [value]="1" formControlName="Internaluser">Internal
                                            <span></span>
                                        </label>
                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                            <input type="radio" [value]="2" formControlName="Internaluser">Bank
                                            <span></span>
                                        </label>
                                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                                            <input type="radio" [value]="3" formControlName="Internaluser">Teleport
                                            <span></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="kt-login__actions">
                                    <button class="btn btn-pill kt-login__btn-primary btn-login"
                                        type="submit">{{ 'LOGIN.LOGIN' | translate}}</button>
                                </div>
                            </form>

                        </div>
                        <div class="kt-login__account">
                            <span style="color: white;">
                                Copyright <span><i class="fa fa-copyright"></i></span> 2020 POSNET. All Rights Reserved.
                            </span>&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>