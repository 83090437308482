import {
  Component,
  OnInit,
  ViewChild,
  ComponentFactoryResolver,
  Input,
  ComponentRef,
  Type,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { OrderEdcViewModel, OrderConnectorTypeViewModel } from "src/app/core/view-models/_order/order-install.view-model";
import { SwitchTabDirective } from "src/app/shared/directives/switch-tab.directive";
import { AppOrderReadonlyCommunicationDetailComponent } from "src/app/features/user/order/_shared/readonly-communication-detail/readonly-communication-detail.component";
import { AppOrderReadonlyFeatureConnectorComponent } from "src/app/features/user/order/_shared/readonly-feature-connector/readonly-feature-connector.component";
import { FeatureService } from "src/app/core/services/shared/feature.service";
import { OrderShareService } from "src/app/core/services/order/order-share.service";
import {
  FeatureConnectorTypeViewModel,
  OrderEdcBankHostViewModel,
} from "src/app/core/view-models/_order/order-share.view-model";
import { statusConst } from "src/app/core/_static/status.const";
import { AppOrderReadonlySingleShopComponent } from "src/app/features/user/order/_shared/_tab/readonly-single-shop/readonly-single-shop.component";
import { AppOrderReadonlyMulthiShopComponent } from "src/app/features/user/order/_shared/_tab/readonly-multhi-shop/readonly-multhi-shop.component";
import { orderConst } from "src/app/core/_static/order.const";
import { SwitchTabComponent } from "src/app/shared/component/switch-tab.component";
import { StatusConstSp3 } from 'src/app/core/_static/sp3-const/status-const-sp3';
import { TabTimeLineComponent } from '../tab-time-line/tab-time-line.component';
import { SerialNumberInfoComponent } from './serial-number-info/serial-number-info.component';

@Component({
  selector: "app-serial-number-modal",
  templateUrl: "./serial-number-modal.component.html",
  styleUrls: ["./serial-number-modal.component.scss"],
})
export class SerialNumberModalComponent implements OnInit {
  @Input() itemId;
  @Input() orderId: number;
  @Input() edcId: number

  item: OrderEdcViewModel = new OrderEdcViewModel();
  connectorTypes: OrderConnectorTypeViewModel[] = [];
  bankHosts: OrderEdcBankHostViewModel[] = [];
  componentRef: ComponentRef<any>;
  detailType: number;
  data: number;

  @ViewChild(SwitchTabDirective, { static: true })
  switchTab: SwitchTabDirective;

  constructor(
    public activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private _featureService: FeatureService,
    private _serviceShared: OrderShareService
  ) {}

  ngOnInit(): void {
    this.loadInitial();
  }
  async loadInitial() {
    this.item = await this._serviceShared.getEdc(this.edcId);
    console.log(this.item);
    this.connectorTypes =  this.item.connectors
    this.focusTabDetailType(this.tabType.Information);
  }
  focusTabDetailType(type: number) {
    this.detailType = type;
    this.setComponentTab();
  }
  get tabType() {
    return StatusConstSp3.tabType;
  }
  setComponentTab() {
   
    if (this.detailType == this.tabType.Information)
      this.loadSingleComponent(SerialNumberInfoComponent);
    if (this.detailType == this.tabType.timeline)
      this.loadMulthiComponent(TabTimeLineComponent);
  }

  async loadSingleComponent(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component
    );

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>(
      this.componentRef.instance
    )).data = this.item.partitions;
    this.componentRef.instance.bankHosts = this.bankHosts;
    this.componentRef.instance.edcId = this.edcId;
  }

  async loadMulthiComponent(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      component
    );

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.eventType = this.item.information.eventType;
    this.componentRef.instance.edcId = this.edcId;
  }
}
