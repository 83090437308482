import { Component, OnInit, ViewChild } from '@angular/core';
import { StoreTaskInfoViewModel, UpdateStatus, UpdateStoreWorkareaEdc } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SharedStoreTaskInformationComponent } from '../../../shared-store-task-information/shared-store-task-information.component';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { StoreTaskInformationService } from 'src/app/core/services/_sprint3/_shared/store-task-information.service';
import { SharedStoreViewTaskInformationComponent } from '../../../shared-store-view-task-information/shared-store-view-task-information.component';

@Component({
  selector: 'app-store-view-task-information',
  templateUrl: './store-view-task-information.component.html',
  styleUrls: ['./store-view-task-information.component.scss']
})
export class StoreViewTaskInformationComponent implements OnInit {
  @ViewChild('information', { static: true }) information: SharedStoreViewTaskInformationComponent;
  id:number;
  items: StoreTaskInfoViewModel = new StoreTaskInfoViewModel
  updateStatus: UpdateStatus[]=[]
  form: FormGroup;
  submit:boolean;
  updateStatusId:number = 300
  requestModel:UpdateStoreWorkareaEdc = new UpdateStoreWorkareaEdc;
  constructor(
    private formBuilder: FormBuilder,
    private dialogService: MyDialogService,
    private storeTaskInformationService: StoreTaskInformationService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      remark: [null],
    });
    this.loadInitial();
  }
  async loadInitial() {
    this.items = await this.storeTaskInformationService.getEdc(this.id);
    this.updateStatus = await this.storeTaskInformationService.getUpdateStatusSelect();
    console.log(this.items);
    this.information.loadDataList(this.items.info)

    this.form.patchValue({
      remark: this.items.remark,
    });
    if(this.items.updateStatus != null){
      this.updateStatusId = this.items.updateStatus.id
    }
    this.requestModel.updateStatusId = this.updateStatusId
  }
  onUpdateStatusSelect(){
    // this.pageRequest.criteria.sourceId = this.form.value.sourceId;
    // this.loadInitial();
  }
  get f() { return this.form.controls; }
}
