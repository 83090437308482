export const connectorConst = [
    {
        id: 1,
        name: 'KTC Scanner'
    },
    {
        id: 2,
        name: 'Rabit (BSS)'
    },
    {
        id: 3,
        name: 'Paywave'
    },
    {
        id: 4,
        name: 'Base'
    },
    {
        id: 5,
        name: 'Sign Pad'
    }
];