<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head kt-portlet__head--lg">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.SHARED.COMMUNICATION_DETAIL'|translate}}
            </h3>
        </div>
    </div>

    <div class="kt-portlet__body">
        <div class="kt-form">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-6"><label>{{'ORDER.SHARED.MAIN'|translate}}</label>
                        <div class="kt-option" style="min-height: 120px;">
                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <label class="kt-font-primary">{{'ORDER.SHARED.COMMUNICATION'|translate}} :</label>
                                    <span class="kt-subheader__breadcrumbs-separator">{{communication.main.communicate?.name}}</span> <br />
                                    <span *ngIf="communicationType.lan == communication.main.communicate?.type">
                                        <label class="kt-font-primary">{{'ORDER.SHARED.IP_ADDRESS'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.main?.ipAddress}}</span><br />
                                        <label class="kt-font-primary">{{'ORDER.SHARED.SUBNET_MARK'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.main?.subnetMark}}</span><br />
                                        <label class="kt-font-primary">{{'ORDER.SHARED.GATEWAY'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.main?.gateway}}</span>
                                    </span>
                                    <span *ngIf="communicationType.GPS == communication.main.communicate?.type">
                                        <label class="kt-font-primary">{{'ORDER.SHARED.PROVIDER'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.main.provider?.name}}</span><br />
                                        <label class="kt-font-primary">{{'ORDER.SHARED.PHONE_NUMBER'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator"> {{communication.main.phoneNumber?.phoneText}}</span>
                                    </span>
                                    <span *ngIf="communicationType.other == communication.main.communicate?.type">
                                        <label class="kt-font-primary">{{'ORDER.SHARED.OTHER'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator"> {{communication.main.other}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6"><label>{{'ORDER.SHARED.BACKUP'|translate}}</label>
                        <div class="kt-option" style="min-height: 120px;">
                            <div class="col-lg-12">
                                <div class="form-group mb-0">
                                    <label class="kt-font-primary">{{'ORDER.SHARED.COMMUNICATION'|translate}} :</label>
                                    <span class="kt-subheader__breadcrumbs-separator">{{communication.backup.communicate?.name}}</span><br />
                                    <span *ngIf="communicationType.lan == communication.backup.communicate?.type">
                                        <label class="kt-font-primary">{{'ORDER.SHARED.IP_ADDRESS'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.backup?.ipAddress}}</span><br />
                                        <label class="kt-font-primary">{{'ORDER.SHARED.SUBNET_MARK'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.backup?.subnetMark}}</span><br />
                                        <label class="kt-font-primary">{{'ORDER.SHARED.GATEWAY'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.backup?.gateway}}</span>
                                    </span>
                                    <span *ngIf="communicationType.GPS == communication.backup.communicate?.type">
                                        <label class="kt-font-primary">{{'ORDER.SHARED.PROVIDER'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator">{{communication.backup.provider?.name}}</span><br />
                                        <label class="kt-font-primary">{{'ORDER.SHARED.PHONE_NUMBER'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator"> {{communication.backup.phoneNumber?.phoneText}}</span>
                                    </span>
                                    <span *ngIf="communicationType.other == communication.backup.communicate?.type">
                                        <label class="kt-font-primary">{{'ORDER.SHARED.OTHER'|translate}} :</label>
                                        <span class="kt-subheader__breadcrumbs-separator"> {{communication.backup.other}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>