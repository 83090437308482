import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { ApiPath } from '../../http/api-path';
import { OrderInformationViewModel, OrderSearchListViewModel } from '../../view-models/_order/order-share.view-model';
import { OrderListModal } from '../_mock/_order/_const/order-list.const';
import { CustomerViewsModel } from '../../models/_shared/customer.model';
import { BankHostViewService } from '../shared/bank-host.service';
import { TransformOrderService } from '../../models-mapper/transform.order';
import { OrderEdcViewModel, CaseViewModel, Case } from '../../view-models/_order/order-install.view-model';
import { OrderEdcDetailModel } from '../../models/_order/order-install.model';
import { caseData, statusCaseData } from '../../view-models/_sprint3/_mock/worksheet-const';

@Injectable({
  providedIn: 'root'
})
export class OrderShareService {

  constructor(
    private apiService: ApiService,
    private _bankHostService: BankHostViewService,
    private _transformOrder: TransformOrderService,
  ) { }

  async searchOrderList(): Promise<Array<OrderSearchListViewModel>> {
    let model: Array<OrderSearchListViewModel> = [];
    // const result = await this.apiService.get<Array<ClientGroupModel>>(`${ApiPath.clientGroup.base}`);
    return model;
  }
  async getEdc(orderEdcId:number): Promise<OrderEdcViewModel> {
    const response = await this.apiService.get<OrderEdcDetailModel>('/orders/edcs/' + orderEdcId);
    const functions = await this._bankHostService.bankHostFunctions();
    
    var model = this._transformOrder.transformOrderEdcModel(response);
    model.partitions = response.partitions.map(x => this._transformOrder.transformOrderEdcMainPatitionViewModel(x, functions));
    model.multhi = response.multiClients.map(x => this._transformOrder.transformOrderDetailEdcMulthiViewModel(x, functions));
    return model;
  }

  async getinformation(orderId:number) : Promise<OrderInformationViewModel>{
    const result = await this.apiService.get<OrderInformationViewModel>('/order/removes/' + orderId)
    return result
  } 

  async getCustomer() : Promise<CustomerViewsModel[]>{
    const result = await this.apiService.get<CustomerViewsModel[]>(`/customer`);
    console.log(result);
    return result;
  }
  async getIdOfInstall(customerId:number) {
    // const resp  = await this.apiService.
  }
  async getCase(): Promise<CaseViewModel> {
    const mockList = caseData as CaseViewModel;
    return mockList;
  } 
  async getCaseList(): Promise<Case[]> {
    const mockList = statusCaseData as Case[];
    return mockList;
  } 
  
}
