import { ModelMapper } from '../model-mapper';
import { SourceViewModel } from '../../view-models/_inbound/inbound.view-model';
import { Injectable } from '@angular/core';
import { Mapper } from '../mapper';
import { SourceModel } from '../../models/_shared/inbound-shared.model';

@Injectable({
    providedIn: 'root'
})
export class InboundSourceMapper implements Mapper<SourceModel, SourceViewModel>{
    constructor(){}
    mapForm(param: SourceModel): SourceViewModel {
        return {
            ...param
        };
    }
    mapTo(param: SourceViewModel): SourceModel {
        return {
            ...param
        };
    }

}

@Injectable({
    providedIn: 'root'
})
export class InboundSourceNewMapper extends ModelMapper<SourceViewModel>{
    
}