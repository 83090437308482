import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { CSInventoriesViewModel, CSOrderViewModel } from 'src/app/core/models/_chart/chart-cs.model';
import { CustomerInventoreisViewModel, CustomerOrderViewModel } from 'src/app/core/models/_chart/chart-customer.model';
import { OPInventoreisViewModel } from 'src/app/core/models/_chart/chart-op.model';

@Injectable({
  providedIn: 'root'
})
export class ChartDashboardService {

  constructor(
    private apiService: ApiService,
  ) { }

  async getCSInventoriesChart(): Promise<CSInventoriesViewModel[]> {
    const resp = await this.apiService.get<CSInventoriesViewModel[]>('/dashboards/cs/inventories');
    return resp;
  }

  async getCSOrderChart(): Promise<CSOrderViewModel[]> {
    const resp = await this.apiService.get<CSOrderViewModel[]>('/dashboards/cs/orders');
    return resp;
  }

  
  async getCustomerInventoriesChart(): Promise<CustomerInventoreisViewModel[]> {
    const resp = await this.apiService.get<CustomerInventoreisViewModel[]>('/dashboards/customer/inventories');
    return resp;
  }

  async getCustomerOrderChart(): Promise<CustomerOrderViewModel[]> {
    const resp = await this.apiService.get<CustomerOrderViewModel[]>('/dashboards/customer/orders');
    return resp;
  }

  async getOPChart(): Promise<OPInventoreisViewModel[]> {
    const resp = await this.apiService.get<OPInventoreisViewModel[]>('/dashboards/op');
    return resp;
  }

}
