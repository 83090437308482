<div class="modal-header">
    <h4 class="modal-title">{{ '_SHARED.HISTORY_DETAIL' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-custom">
    <div>
        <div class="kt-portlet kt-portlet--mobile" data-ktportlet="true">
            <div class="kt-portlet__head kt-portlet__head--lg">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Signature Picture
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-form"> 
                    <div class="row row-nowrap">
                        <div class="col-sm-12 text-center"  >
                            <img alt="Pic" [src]="item.url" class="bg-back">
                        </div> 
                    </div>   
                </div>
            </div>
        </div>
    </div> 

     
</div>

<div class="modal-footer">
    <button type="button" class="text-center btn btn-outline-dark" (click)="activeModal.dismiss()"
        [translate]="'BUTTON.CLOSE'"></button>
</div>