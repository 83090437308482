export const bankHostConst = [
    {
        id: 1,
        name: "QR-CODE",
        type: 2
    },
    {
        id: 2,
        name: "BSS",
        type: 3
    },
    {
        id: 3,
        name: "BBL",
        type: 1
    }
];