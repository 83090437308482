import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper';
import { AdminBKKWorkAreaModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';  
import { WorksheetCloseJobViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-list-share.view-model';
import { WorksheetCloseJobApisModel } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { until } from 'protractor';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { AdminBKKClosejobWorksheetMapper } from './close-job-worksteet-infor-mapper';
import { AdminBKKClosejobShopMapper } from './close-job-shop-mapper';
import { AdminBKKClosejobLocationMapper } from './close-job-location-mapper';
import { AdminBKKClosejobCommandMapper } from './close-job-command-mapper';
import { AdminBKKClosejobMechanicMapper } from './close-job-mechanic-mapper';

@Injectable({
    providedIn: "root",
}) 
export class AdminBKKClosejobMapper implements Mapper<WorksheetCloseJobApisModel, WorksheetCloseJobViewModel> {
    constructor( 
        private worksheetMapper :  AdminBKKClosejobWorksheetMapper,
        private shopMapper : AdminBKKClosejobShopMapper,
        private locationMapper : AdminBKKClosejobLocationMapper,
        private commandMapper : AdminBKKClosejobCommandMapper,
        private mechanicMapper : AdminBKKClosejobMechanicMapper,
        ) { } 
    mapForm(param: WorksheetCloseJobApisModel): WorksheetCloseJobViewModel {
        const item = new WorksheetCloseJobViewModel(); 
        item.id = param.id;
        item.worksheetInformation  = this.worksheetMapper.mapForm(param.worksheetInfo);
        item.shopDetail  = this.shopMapper.mapForm(param.shopDetailInfo);
        item.location  = this.locationMapper.mapForm(param.locationInfo);
        item.command  = this.commandMapper.mapForm(param);
        item.mechanic  = this.mechanicMapper.mapForm(param.technicianForm);
        item.contactName  = param.postponeForm.contactName;
        item.contactPhone  = param.postponeForm.contactPhoneNumber;
        item.serviceDate  = DateUtils.toDate(param.postponeForm.convenientDate) ;
        item.serviceHour  = param.postponeForm.convenientHour;
        item.serviceMinute  = param.postponeForm.convenientMinute;
        item.attachFile  = param.attachFile;
        item.edcList = param.edcs;
        // item.attachFiles = param.attachFiles;
        item.onsiteAttachFiles = param.attachFiles; 
        item.contactReviseName = param.contactName;
        item.contactRevisePhone= param.contactPhoneNumber
        item.serviceReviseDate=   DateUtils.toDate(param.convenientDate);
        item.serviceReviseHour = param.convenientHour
        item.serviceReviseMinute= param.convenientMinute;
        item.worksheetRemark = param.worksheetRemark

        return item;
    }
    mapTo(param: WorksheetCloseJobViewModel): WorksheetCloseJobApisModel {
        return null
    }
}
