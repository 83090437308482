import { Injectable } from "@angular/core"; 
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils'; 
import { WorksheetSupportApisModel } from '../../models/_sprint3/worksheet/worksheet-api-view-model';
import { WorksheetSupportViewModel } from '../../view-models/_sprint3/worksheet/worksheet-support-model';

@Injectable({
  providedIn: "root",
})
export class CreateSupportWorksheetMapper implements Mapper<WorksheetSupportApisModel, WorksheetSupportViewModel>{
  constructor( 
  ) { }
  mapForm(param: WorksheetSupportApisModel): WorksheetSupportViewModel { 
      const item = new WorksheetSupportViewModel(); 
      return item;
  }
  mapTo(param: WorksheetSupportViewModel): WorksheetSupportApisModel { 
    const item = new WorksheetSupportApisModel();
    item.clientId = param.shop?.id; 
    item.convenientDate = DateUtils.toFormat(param.contact?.serviceDate, DateUtils.YYYY_MM_DD);
    item.convenientHour = param.contact?.serviceHour;
    item.convenientMinute = param.contact?.serviceMinute;
    item.issue = param.command?.issue;
    item.recommend = param.command?.recommend;
    item.workCommand = param.command?.command;
    item.commandContactName = param.contact?.contactName;
    item.commandContactPhoneNumber = String(param.contact?.contactPhone) ; 
    item.remark = param.remark
    item.shopNameTH= param.location?.nameTH;
    item.shopNameEN= param.location?.nameEN;
    item.shopAddress= param.location?.address;
    item.provinceId= param.location?.provinceId;
    item.districtId= param.location?.districtId; 
    item.contactName = param.location?.contactName;
    item.contactPhoneNumber = String(param.location?.contactPhoneNumber); 
    item.contactFaxNumber= param.location?.contactFaxNumber;
    item.subdistrictId= param.location?.subdistrictId;
    item.zipCode= param.location?.posCode;
    item.latitude= '';
    item.longitude= '';  
    item.contactEmail= param.location?.email;
    item.location= param.location?.location;
    item.reg= param.location?.reg;  
    item.eventTypeId = param.eventTypeId;

    item.attachFileId = param.contact?.commandFile?.attachFileId;  
 
    item.edcIdList = param.selectedEdcs.map(x => x.edcId)
    return item;
  }

}