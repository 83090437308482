<div class="modal-header">
    <h5 class="modal-title">{{'ORDER.SHARED.BILLER_SETTING'|translate}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
    </button>
</div>
<div class="modal-body" *ngIf="item.bankHost?.type == orderBankHostType.billing">
    <form class="kt-form">
        <div class="col-lg-12">
            <div class="form-group">
                <label>{{'ORDER.SHARED.BILLER_NAME'|translate}} : </label><br>
                <label>{{item.billerName}}</label>
            </div>
        </div>
    </form>
</div>
<div class="modal-body" *ngIf="item.bankHost?.type == orderBankHostType.billingOther">
    <form class="kt-form">
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label>{{'ORDER.SHARED.LOCATION_ID'|translate}} : </label><br>
                    <label>{{item.locationId}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label>{{'ORDER.SHARED.LOCATION'|translate}} : </label><br>
                    <label>{{item.location}}</label>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label>{{'ORDER.SHARED.SP'|translate}} : </label><br>
                    <label>{{item.sp}}</label>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-dark" data-dismiss="modal"
        (click)="activeModal.dismiss()">{{'ORDER.SHARED.CLOSE'|translate}}</button>
</div>