import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { HdcWorksheetModel, WorksheetLocaltionViewModel, LocationEdcItemViewModel, EdcConnectorViewModel, LocationEdcConnectorViewModel, SearchHdcWorksheetApiModelCriteria } from '../../models/user/help-desk/worksheet-model';
import { HdcWorksheetConstModel } from '../_mock/_helpDesk/worksheet';
import { SaveWorksheetLocaltionMapper } from '../../models-mapper/help-desk/worksheet-save-mapper';
import { waitForAsync } from '@angular/core/testing';
import { HdcWorksheetModelMapper } from '../../models-mapper/help-desk/hdc-worksheet.mapper';
import { PageRequestModel, PageResponseModel } from '../../models/_shared/page.model';
import { WorkSheetModalViewModel } from '../../models/user/help-desk/worksheet-view-model';

@Injectable({
  providedIn: "root",
})
export class HdcWorksheetService {



  constructor(
    private apiService: ApiService,
    private _saveMapper: SaveWorksheetLocaltionMapper,
    private _hdcWorksheetMapper:HdcWorksheetModelMapper
  ) { }


  async search( pageRequest: PageRequestModel<SearchHdcWorksheetApiModelCriteria>): Promise<PageResponseModel<HdcWorksheetModel>> {
    const result = await this.apiService.post<any>("/worksheets/hdc/search", pageRequest);
    // return this._hdcWorksheetMapper.mapForm(result);
    const pageResult = PageResponseModel.transform<HdcWorksheetModel>(result);
    return pageResult;
  }

  async get(id: number, worksheetId: number): Promise<WorksheetLocaltionViewModel> {
    return await this.apiService.get<WorksheetLocaltionViewModel>(`/inbounds/${id}/worksheets/${worksheetId}`);
  }

  async getSupport(id: number, worksheetId: number): Promise<WorksheetLocaltionViewModel> {
    return await this.apiService.get<WorksheetLocaltionViewModel>(`/support/inbounds/${id}/worksheets/${worksheetId}`);
  }
  async submit(id: number, worksheetId: number, item: WorksheetLocaltionViewModel): Promise<boolean> {
    const request = this._saveMapper.mapForm(item);
    return await this.apiService.patch<boolean>(`/inbounds/${id}/worksheets/${worksheetId}/submit`, request);
  }
  async submitSupport(id: number, worksheetId: number, item: WorksheetLocaltionViewModel): Promise<boolean> {
    const request = this._saveMapper.mapForm(item);
    return await this.apiService.patch<boolean>(`/support/inbounds/${id}/worksheets/${worksheetId}/submit`, request);
  }
  async save(id: number, worksheetId: number, item: WorksheetLocaltionViewModel): Promise<boolean> {

    const request = this._saveMapper.mapForm(item);
    return await this.apiService.patch<boolean>(`/inbounds/${id}/worksheets/${worksheetId}/save`, request);
  }
  async saveSupport(id: number, worksheetId: number, item: WorksheetLocaltionViewModel): Promise<boolean> {
    console.log("item", item);
    const request = this._saveMapper.mapForm(item);
    console.log("request", request);
    return await this.apiService.patch<boolean>(`/support/inbounds/${id}/worksheets/${worksheetId}/save`, request);
  }

  async edcs(id: number, worksheetId: number): Promise<LocationEdcItemViewModel[]> {
    const response = await this.apiService.get<LocationEdcItemViewModel[]>(`/inbounds/${id}/worksheets/${worksheetId}/edcs`);
    response.forEach(item => {
      if(!item.connectors){ item.connectors = []}
      item.edcServiceWorkTypeName = item.edcServiceWorkType?.name;
    });
    return response;
  }
  async edcsSupport(id: number, worksheetId: number): Promise<LocationEdcItemViewModel[]> {
    const response = await this.apiService.get<LocationEdcItemViewModel[]>(`/support/inbounds/${id}/worksheets/${worksheetId}/edcs`);
     
    response.forEach(item => {
      if(!item.connectors){ item.connectors = []}
      item.edcServiceWorkTypeName = item.edcServiceWorkType?.name;
    });
    return response;
  }

  async edcCheck(id: number, worksheetId: number, edcId: number): Promise<boolean> {
    const request = {
      isChecked: true
    };
    return await this.apiService.patch<boolean>(`/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/check`, request);
  }
  async edcUnCheck(id: number, worksheetId: number, edcId: number): Promise<boolean> {
    const request = {
      isChecked: false
    };
    return await this.apiService.patch<boolean>(`/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/check`, request);
  }
  async edcCheckSupport(id: number, worksheetId: number, edcId: number): Promise<boolean> {
    const request = {
      isChecked: true
    };
    return await this.apiService.patch<boolean>(`/support/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/check`, request);
  }
  async edcUnCheckSupport(id: number, worksheetId: number, edcId: number): Promise<boolean> {
    const request = {
      isChecked: false
    };
    return await this.apiService.patch<boolean>(`/support/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/check`, request);
  }

  async edcCheckAllSupport(id: number, worksheetId: number, edcIds: number[]): Promise<boolean> {
    edcIds.forEach(async edcId => {
      await this.edcCheckSupport(id, worksheetId, edcId);
    });
    return true;
  }
  async edcUnCheckAllSupport(id: number, worksheetId: number, edcIds: number[]): Promise<boolean> {
    edcIds.forEach(async edcId => {
      await this.edcUnCheckSupport(id, worksheetId, edcId);
    });
    return true;
  }
  async edcCheckAll(id: number, worksheetId: number, edcIds: number[]): Promise<boolean> {
    edcIds.forEach(async edcId => {
      await this.edcCheck(id, worksheetId, edcId);
    });
    return true;
  }
  async edcUnCheckAll(id: number, worksheetId: number, edcIds: number[]): Promise<boolean> {
    edcIds.forEach(async edcId => {
      await this.edcUnCheck(id, worksheetId, edcId);
    });
    return true;
  }

  async edcSave(id: number, worksheetId: number, edcId: number, result: LocationEdcItemViewModel): Promise<boolean> {
    const request = {
      workTypeId: result.edcServiceWorkType.id,
      remark: result.remark,
      connectors: result.connectors,
      isRequireTakeSim: result.isRequireTakeSim
    };
    return await this.apiService.patch<boolean>(`/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/save`, request);
  }
  async edcSaveSuppport(id: number, worksheetId: number, edcId: number, result: LocationEdcItemViewModel): Promise<boolean> {
    const request = {
      workTypeId: result.edcServiceWorkType.id,
      remark: result.remark,
      connectors: result.connectors,
      isRequireTakeSim: result.isRequireTakeSim
    };
    return await this.apiService.patch<boolean>(`/support/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/save`, request);
  }

  async connectors(id: number, worksheetId: number, edcId: number): Promise<LocationEdcConnectorViewModel[]> {
    return await this.apiService.get<LocationEdcConnectorViewModel[]>(`/inbounds/${id}/worksheets/${worksheetId}/edcs/${edcId}/connectors`);
  }



  async getViewModal(id: number ): Promise<WorkSheetModalViewModel> {
    return await this.apiService.get<WorkSheetModalViewModel>(`/worksheets/hdc/${id}`);
  }
}
