
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    {{'_SHARED.NEW' | translate }}
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-form">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group mb-0">
                                <div class="row">
                                    <div class="col-5" style="margin-bottom: auto;margin-top: auto">
                                        <label class="kt-font-primary">{{'_SHARED.S_N' | translate }} : </label>
                                    </div>
                                    <div class="col-7">
                                        <div class="input-group ">
                                            <input type="text" class="form-control" readonly [(ngModel)]="news.serialNumber" [placeholder]="'_SHARED.S_N' | translate " aria-describedby="btnGroupAddon2">
                                            <div class="input-group-prepend">
                                                    <span class="input-group-text" ><a href="javascript:;" (click)="onScanClick()">
                                                            <i class="fa fa-search"></i>
                                                        </a>
                                                    </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div> 
                        <div class="col-sm-12">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{'_SHARED.MODEL' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{news.modelName}} </span>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{'_SHARED.TID' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{news.tid}} </span>
                            </div>
                        </div>

                        <div class="col-sm-12">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{'_SHARED.MID' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{news.mid}} </span> <br/>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group mb-0">
                                <label class="kt-font-primary">{{'_SHARED.BOX' | translate }} : </label>
                                <span class="kt-subheader__breadcrumbs-separator">{{news.box}}  </span> <br/>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group mb-0">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label class="kt-font-primary">{{'_SHARED.CURRENT_TO_STATUS' | translate }} : </label>
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="kt-font-primary">{{'_SHARED.CHANGE_TO_STATUS' | translate }} : </label>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <span class="badge badge-dark badge-custom">{{news?.currentStatus?.name}}</span>
                                    </div>
                                    <div class="col-sm-6" [formGroup]="form"> 
                                        <select class="form-control  " formControlName="changeToStatusId"  >
                                            <option *ngFor="let item of updateStatus" [ngValue]="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>