import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper'; 
import { WorksheetAllInformationViewModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { WorksheetCommandViewModel } from 'src/app/core/view-models/_sprint3/_shared/worksheet-information-view-model';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { FileUp } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
 

@Injectable({
    providedIn: "root",
})  
export class AdminAlllosejobCommandMapper implements Mapper<WorksheetAllInformationViewModel, WorksheetCommandViewModel> {
    mapForm(param: WorksheetAllInformationViewModel): WorksheetCommandViewModel {
        const item = new WorksheetCommandViewModel(); 
        if (param?.issue != null)  item.issue =   param?.issue;
        if (param?.workCommand != null) item.command = param?.workCommand;
        if (param?.recommend != null) item.recommend = param?.recommend; 
        return item;
    }
    mapTo(param: WorksheetCommandViewModel): WorksheetAllInformationViewModel {
        return null
    }
}
