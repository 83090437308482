import { SubDistrict } from 'src/app/core/view-models/_shared/worksheet.view-model';
import { EventType } from '../order-monitoring/order-monitoring-api-view-model';

export class CreateSettingCalloutApisModel{
  clientId: number;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  provinceId: number;
  districtId: number;
  subdistrictId: number;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  issue: string;
  workCommand: string;
  recommend: string;
  commandContactName: string;
  commandContactPhoneNumber: string;
  attachFileId: number;
  convenientDate: string;
  convenientHour: number;
  convenientMinute: number;
  remark: string 
  edcIdList: number[] = [];
  sendToType: number; 
}

export class SettingGetTaskApisModel{
  sendToType: number; 
  id: number; 
  authrorize: SettingAuthrorize;
  clientId: number; 
  shopDetailInfo:ShopDetailInfo; 
  shopNameTH: string
  shopNameEN: string
  shopAddress: string
  provinceId: Province
  districtId: District
  subdistrictId:Subdistrict
  zipCode: string
  latitude: string
  longitude: string
  contactName: string
  contactPhoneNumber: string
  contactFaxNumber: string
  contactEmail: string
  location: string
  reg: string
  issue: string
  workCommand: string
  recommend: string
  commandContactName: string
  commandContactPhoneNumber: string
  attachFile: AttachFile;
  convenientDate: string ;
  convenientHour: number
  convenientMinute: number
  remark: string;
  eventType: EventType;
  province: Province ;
  district: District; 
  subdistrict: SubDistrict  ;
}
export class SettingAuthrorize{  
  canSave:boolean;
  canSubmit:boolean;
  canCancel:boolean;
}
export class ShopDetailInfo{  
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  address: string;
  provinceName: string;
  districtName: string;
  subdistrictName: string;
  zipCode: string;
  callPhoneNumber: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  email: string;
}
export class Province{  
  kind: string
  id: 0
  nameTH: string
  nameEN: string
}
export class District{  
  kind: string
  id: 0
  nameTH: string
  nameEN: string
}
export class Subdistrict{  
  kind: string
    id: 0
    nameTH: string
    nameEN: string
    latitude: 0
    longitude: 0
    zipCode: 0
}
export class AttachFile{  
  kind: string
  id: number
  originalName: string
  storeName: string
  mimeType: string
  sizeInBytes: number
  url: string
  fileStream: FileStream
}
export class FileStream{  
  canRead: true
  canSeek: true
  canTimeout: true
  canWrite: true
  length: number
  position: number
  readTimeout: number
  writeTimeout: number
}