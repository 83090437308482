import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { CommonService } from 'src/app/core/services/common.service';
import { FileUtils } from 'src/app/shared/utils/file-utils.service';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';

@Component({
  selector: 'app-modal-multi-file',
  templateUrl: './modal-multi-file.component.html',
  styleUrls: ['./modal-multi-file.component.scss']
})
export class ModalMultiFileComponent implements OnInit {
  isView:boolean= false;
  attachFiles: AttachFile[] = [];
  onReturn: Subject<AttachFile[]> = new Subject();
  fileToUpload: File;
  constructor(
    public activeModal: NgbActiveModal,
    private dialogService: MyDialogService,
    private commonService: CommonService,
  ) { }

  ngOnInit(): void {
  }



  fileName: string;
  File: any = new FormData();
  tempFile: any = null;

  async fileEvent(fileInput: FileList) {
    const fileToUpload: File = fileInput.item(0);
    const ext = FileUtils.getFileName(fileToUpload.name);
    this.fileToUpload = fileToUpload;
    this.fileName = this.fileToUpload.name;
    if (this.fileToUpload != null) {
      const resp = await this.commonService.uploadFile(this.fileToUpload); 
      const file: AttachFile = {
        fileStream: null,
        kind: '',
        id: resp.id,
        mimeType: resp.mimeType,
        originalName: resp.originalName,
        sizeInBytes: resp.sizeInBytes,
        storeName: resp.storeName,
        url: resp.url
      }
      this.attachFiles.push(file) 
    }
  }
  onFileDeleteClick(index: number) {
    this.dialogService.confirmDeleteData(async () => {
      this.attachFiles.splice(index, 1);
    });
  }
  onFileClick(file: AttachFile) {
    window.open(file.url, '_blank')
  }
  onSaveClick() {
    this.dialogService.confirmSaveData(() => {
      this.onReturn.next(this.attachFiles);
      this.closeModal() 
    });
  }
  closeModal() {
    this.activeModal.dismiss();
  }
}
