import { ProvinceModel, DistrictModel, SubdistrictModel } from '../../_shared/fix.model';
import { StatusViewModel } from 'src/app/core/view-models/_shared/status.view-model';

export class HdcWorksheetModel {
  id: number;
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
  operationStatus: Status;
  worksheetStatus: Status;
  worksheetTypeName: string;
  ticketNo: string;
  worksheetNo: string;
  worksheetDate: string;
  shopName: string;
  address: string;
  totalOrder: number;
  periodDateText: string;
  responsibleName: string;
  worksheetId:number

  serialNumber: string;
  tid: string;
  mid: string;
  standAloneTid: string;
}

export class SearchHdcWorksheetApiModelCriteria {
  isPending: boolean = true;
  isInProgress: boolean = true;
  isCompleted: boolean = true;
  isInComplete: boolean = true;
  textSearch: string="";
  ticketNo: string="";
  worksheetNo: string="";
  worksheetDate: string="";
  shopName: string="";
  address: string="";
  totalOrder: string="";
  periodDateText: string="";

  serialNumber: string="";
  tid: string="";
  mid: string="";
  standAloneTid: string="";

}

export class HdcWorksheetApiModel {
  id: number;
  canView: boolean;
  canEdit: boolean;
  canDelete: boolean;
  operationStatus: Status;
  worksheetStatus: Status;
  worksheetTypeName: string;
  ticketNo: string;
  worksheetNo: string;
  worksheetDate: string;
  shopName: string;
  address: string;
  totalOrder: number;
  periodDateText: string;
  responsibleName: string;
  worksheetId:number
  serialNumber: string;
  tid: string;
  mid: string;
  standAloneTid: string;
}

export class Status {
  kind: string;
  id: number;
  code: string;
  name: string;
}

export class SearchHdcWorksheetCriteria {
  isPending: boolean = true;
  isInprogress: boolean = true;
  isCompleted: boolean = true;
  isInComplete: boolean = true;
  textSearch: string = "";
  ticketNo: string = "";
  worksheetNo: string = "";
  worksheetDate: string;
  shopName: string = "";
  address: string = "";
  order: number;
  datePeriod: string = "";
  responsible: string = "";
}



export class WorksheetLocaltionViewModel {
  id: number;
  shopNameEN: string;
  shopNameTH: string;
  shopAddress: string;
  province: ProvinceModel;
  district: DistrictModel;
  subdistrict: SubdistrictModel;
  zipCode: string;
  latitude: string;
  longitude: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  contactEmail: string;
  location: string;
  reg: string;
  command: string;
  remark: string;
  clientConvenientDate: Date;
  clientConvenientHour: number;
  clientConvenientMiute: number;
  canSave: boolean;
  canSubmit: boolean;
  issue: string;
  workCommand:  string;
  recommend:  string;
  clientContactName: string;
  clientContactPhoneNumber: string;
}


export class EdcServiceWorkTypeViewModel {
  id: number;
  name: string;
  description: string;
}

export class EdcConnectorViewModel {
  id: number;
  isActive: boolean;
  code: string;
  nameTH: string;
  shortNameTH: string;
  nameEN: string;
  shortNameEN: string;
  isShowSerialNumber: boolean;
}

export class LocationEdcConnectorViewModel {
  connectorId: number;
  connector: EdcConnectorViewModel;
  remark: string;
}

export class LocationEdcItemViewModel {
  isChecked: boolean;
  edcServiceWorkType: EdcServiceWorkTypeViewModel;
  edcServiceWorkTypeName: string;
  remark: string;
  connectors: LocationEdcConnectorViewModel[]=[];
  edcId: number;
  serialNumber: string;
  modelName: string;
  standAloneTid: string;
  tid: string;
  mid: string;
  shopName: string;
  address: string;
  multhi: boolean;
  main: string;
  backup: string;
  isMain: boolean;
  inboundTaskWorksheetId: number;
  status: StatusViewModel;
  shopInfo: WorksheetLocaltionViewModel;
  isRequireTakeSim:boolean
}

export class SearchLocationEdcItemViewModel {
  edcServiceWorkType: string;
  remark: string;
  serialNumber: string;
  modelName: string;
  standAloneTid: string;
  tid: string;
  mid: string;
  shopName: string;
  address: string;
  main: string;
  backup: string;
}
