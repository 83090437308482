import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EdcClientsApiViewModel, EdcTidHistoryApiViewModel, SearchEdcClientCriteriaModel, SearchEdcTidHistoryCriteriaModel } from 'src/app/core/models/user/edc-models/edc-models';
import { PageResponseModel, PageRequestModel } from 'src/app/core/models/_shared/page.model';
import { EdcModelsService } from 'src/app/core/services/user/edc-models.service';
import { SearchOrderDetailEdcViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { AdminPaginatorComponent } from 'src/app/shared/layouts/_theme/_admin/table/paginator/paginator.component';

@Component({
  selector: 'app-modal-tid-history',
  templateUrl: './modal-tid-history.component.html',
  styleUrls: ['./modal-tid-history.component.scss']
})
export class ModalTidHistoryComponent implements OnInit {

  @ViewChild('pagination', { static: false }) pagination: AdminPaginatorComponent; 
  submitted: boolean;
  form: FormGroup
  searchFilter: SearchOrderDetailEdcViewModel;
  tid: number;
  // data: OrderDetailViewModel = new OrderDetailViewModel();
  // selected: boolean = false;
  items: EdcTidHistoryApiViewModel[] = [];
  pageResult: PageResponseModel<EdcTidHistoryApiViewModel>;
  pageRequest: PageRequestModel<SearchEdcTidHistoryCriteriaModel>;
  constructor(
    public activeModal: NgbActiveModal,
    private edcClientsService: EdcModelsService,
    private actRoute: ActivatedRoute,
  ) { 
  }

  ngOnInit(): void {
    this.searchFilter = new SearchOrderDetailEdcViewModel();
    this.pageRequest = PageRequestModel.createFirst(SearchEdcTidHistoryCriteriaModel);
    this.loadInitial();
  }
  async loadInitial() {
    this.pageResult = await this.edcClientsService.searchEdcTidHistory(this.tid, this.pageRequest);
    this.items = this.pageResult.items;
    this.pagination.setPageResult(this.pageResult);
    console.log(this.items)
  }
  colFilterChange(cols:any){
    this.pageRequest.pageNumber = 1
    this.pageRequest.criteria.shopCode = cols.code;
    this.pageRequest.criteria.shopNameTH = cols.nameTH;
    this.pageRequest.criteria.shopNameEN = cols.nameEN; 
    this.pageRequest.criteria.serialNumber = cols.serialNumber;
    this.pageRequest.criteria.installDate = cols.installDate;

    this.loadInitial();
  }
 
  onPageChange(page: number) {
    this.pageRequest.pageNumber = page;
    this.loadInitial();
  }
  onPageSizeChange(pageSize: number) {
    this.pageRequest.pageSize = pageSize;
    this.loadInitial();
  }

}
