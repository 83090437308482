import { StoreTaskInformationViewModel } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';

export class WorkAreaEdcViewApisModel {
    summary: WorkAreaEdcListApisSummary = new WorkAreaEdcListApisSummary();
    totalEdc: number;
    totalPeripheral: number;
    totalSimCard: number;


}
export class WorkAreaEdcListApisSummary {
    total: number;
    overdue: number;
    waiting: number;
    toDo: number;
    doing: number;
    done: number;
    remain: number;
}


export class EdcListApisModel {
    id: number;
    edcId: number;
    canView: boolean;
    canEdit: boolean;
    canSelect: boolean;
    hasCompleted: boolean;
    canChangeResponsible: boolean;
    canAssignResponsible: boolean;
    fromStates: FormStatus[] = [];
    nextStates: FormStatus[] = [];
    command: Status;
    state: Status;
    actionState: number;

    isOverdue: boolean;
    status: Status;
    workType: Status;
    subType: Status;
    serialNumber: string;
    typeName: string;
    tid: string;
    worksheetId: number;
    worksheetNo: string;
    startDateTime: Date;
    endDateTime: Date;
    modelName: string;
    box: string;
    location: string;
    actualDateTime: Date;
    taskOwnerName: string;
    taskResponsibleName: string;
    operationDate: Date;
    worksheetDate: Date;
    taskResponsible: TaskResponsible;
    responsibleRemark: string;
    ticketDateTime: Date;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    hasFromReject: boolean;
    bankName: string;
    shopNameTH: string;
    shopNameEN: string;
}
export class TaskResponsible {
    lastName: string;
    firstName: string;
    fullName: string;
    id: number;
}
export class FormStatus {
    name: string;
    state: number;
}
export class Status {
    id: number;
    name: string;
}

export class PeripheralListApisModel {
    id: number;
    canView: boolean;
    canEdit: boolean;
    canChangeResponsible: boolean;
    canSelect: boolean;
    hasCompleted: boolean;
    canAssignResponsible: boolean;
    from: FormStatus[] = [];

    fromStates: FormStatus[] = [];
    nextStates: FormStatus[] = [];
    command: Status;

    state: Status;
    actionState: number;

    isOverdue: boolean;
    status: Status;
    workType: Status;
    subType: Status;
    serialNumber: string;
    typeName: string;
    tid: string;
    worksheetId: number;
    worksheetNo: string;
    startDateTime: Date;
    endDateTime: Date;
    modelName: string;
    box: string;
    location: string;
    actualDateTime: Date;
    taskOwnerName: string;
    taskResponsibleName: string;
    zone: Status;
    operationDate: Date;
    taskResponsible: TaskResponsible;
    responsibleRemark: string;
    ticketDateTime: Date;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    hasFromReject: boolean;
    worksheetDate: Date;
    bankName: string;
    shopNameTH: string;
    shopNameEN: string;
}
export class SimListApisModel {
    hasFromReject: boolean;
    isChecked: boolean;
    hasCompleted: boolean;
    isSelect: boolean;
    id: number;
    canView: boolean;
    canEdit: boolean;
    canChangeResponsible: boolean;
    canSelect: boolean;
    canAssignResponsible: boolean;
    fromStates: FormStatus[] = [];
    command: Status;
    nextStates: FormStatus[] = [];

    state: Status;
    actionState: number;

    isOverdue: boolean;
    status: Status;
    workType: Status;
    subType: Status;
    serialNumber: string;
    typeName: string;
    tid: string;
    worksheetId: number;
    worksheetNo: string;
    startDateTime: Date;
    endDateTime: Date;
    modelName: string;
    box: string;
    location: string;
    actualDateTime: Date;
    taskOwnerName: string;
    taskResponsibleName: string;
    operationDate: Date;
    taskResponsible: TaskResponsible;
    responsibleRemark: string;
    ticketDateTime: Date;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    worksheetDate:Date;
    bankName: string;
}


export class StoreTaskInfoViewApisModel {
    authrorize: Authrorize;
    info: StoreTaskInformationViewModel;
    currentStatus: Status;
    updateStatus: UpdateStatus;
    remark: string;
    reject: Reject
    isNoMachine:boolean
  boxNumber:string;
  receiveInventoryStatus:Status
}
export class Reject {
    hasReject: boolean;
    rejectByName: string;
    rejectDateTime: string;
    remark: string;
}
export class UpdateStatus {
    id: number;
    code: string;
    name: string;
}
export class Authrorize {
    canSwitch: boolean;
    canReset: boolean;
    canSave: boolean;
    canSubmit: boolean;
    canTakeNewEdc: boolean;
    canTakeNewConnector: boolean;
    canTakeNewSim: boolean;
} 