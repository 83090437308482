import { Component, OnInit, Input, Host } from '@angular/core';
import { KtExpansionPanelComponent } from '../kt-expansion-panel/kt-expansion-panel.component';

@Component({
  selector: 'kt-expansion-panel-header',
  templateUrl: './kt-expansion-panel-header.component.html',
  styleUrls: ['./kt-expansion-panel-header.component.scss']
})
export class KtExpansionPanelHeaderComponent implements OnInit {
  panelElementId: string = '';

  @Input()
  canExpand:boolean = true;

  constructor(
    @Host() public panel: KtExpansionPanelComponent
  ) { }

  ngOnInit(): void {
    this.panelElementId = this.panel.panelElementId;
    // console.log(this.panelElementId);
  }

}
