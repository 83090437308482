import { Injectable } from '@angular/core';
import { FeatureConnectorViewModel, FeatureConnectorTypeViewModel, OrderEdcPantancateFormatViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { featureAllConst } from './_const/feature.const';
import { connectorConst } from './_const/connector.const';
import { pantancateConst } from './_const/pantancate.const';

@Injectable({
    providedIn: 'root'
})
export class FeatureMockService {

    async features(): Promise<Array<FeatureConnectorViewModel>> {
        const mockList = featureAllConst as Array<FeatureConnectorViewModel>;
        return mockList;
    }
    async connectors(): Promise<Array<FeatureConnectorTypeViewModel>> {
        const mockList = connectorConst as Array<FeatureConnectorTypeViewModel>;
        return mockList;
    }
    async pantancate1(): Promise<Array<OrderEdcPantancateFormatViewModel>> {
        const mockList = pantancateConst as Array<OrderEdcPantancateFormatViewModel>;
        return mockList;
    }
    async pantancate2(): Promise<Array<OrderEdcPantancateFormatViewModel>> {
        const mockList = pantancateConst as Array<OrderEdcPantancateFormatViewModel>;
        return mockList;
    }
    async pantancate3(): Promise<Array<OrderEdcPantancateFormatViewModel>> {
        const mockList = pantancateConst as Array<OrderEdcPantancateFormatViewModel>;
        return mockList;
    }
    async pantancate4(): Promise<Array<OrderEdcPantancateFormatViewModel>> {
        const mockList = pantancateConst as Array<OrderEdcPantancateFormatViewModel>;
        return mockList;
    }
}