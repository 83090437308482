import { Injectable } from "@angular/core";
import { Mapper } from '../mapper'; 
import { CreateSettingCalloutViewModel } from '../../view-models/_sprint3/_setting/setting-callout-model'; 
import { ContactModel, FileUp } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { SettingCalloutGetDetailApisModel } from '../../models/_sprint3/setting/setting-callout-model';
import { SettingCalloutGetDetailViewModel } from '../../view-models/_sprint3/_setting/setting-callout-edit-model';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { DateUtils } from 'src/app/shared/utils/date-utils';

@Injectable({
    providedIn: "root",
})
export class SettingCalloutContactMapper implements Mapper<ContactModel, SettingCalloutGetDetailViewModel>{
    constructor(
    ) { }
    mapForm(param: ContactModel): SettingCalloutGetDetailViewModel {
        const item = new CreateSettingCalloutViewModel();
        return null
    }
    mapTo(param: SettingCalloutGetDetailViewModel): ContactModel {
        const item = new ContactModel();
        item.contactName = param.contactName
        item.contactPhone= param.contactPhoneNumber
        item.serviceDate = DateUtils.toDate(param.convenientDate) ;
        item.serviceHour = param.convenientHour
        item.serviceMinute = param.convenientMinute
        item.commandFile = new FileUp
        item.commandFile.attachFileId = param.attachFile?.id;
        item.commandFile.name = param.attachFile?.originalName
        return item
    }

}