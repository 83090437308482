<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        <h3 class="kt-portlet__head-title"> {{'_SHARED.CONTACT' | translate }} </h3>
    </kt-expansion-panel-header>

    <div class="form-group  mb-0">
        <div class="row">
            <div class="col-sm-3">
                <div class="form-group mb-0">
                    <label class="kt-font-primary">{{'_SHARED.CONTACT_NAME' | translate}} : </label>
                    <span class="kt-subheader__breadcrumbs-separator">{{item?.contactName}}</span>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group mb-0">
                    <label class="kt-font-primary">{{'_SHARED.PHONE_NUMBER' | translate}} : </label>
                    <span class="kt-subheader__breadcrumbs-separator">{{item?.contactPhone}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="form-group  mb-0">

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group mb-0">
                    <label class="kt-font-primary">{{'_SHARED.CONVENIENT_DATE_TIME' | translate}} : </label>
                    <span
                        class="kt-subheader__breadcrumbs-separator">{{item?.serviceDate | date:'dd/MM/yyyy hh:mm'}}</span>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group mb-0">
                    <label class="kt-font-primary">{{'_SHARED.HOURS' | translate}} : </label>
                    <span class="kt-subheader__breadcrumbs-separator">{{item?.serviceHour}}</span>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group mb-0">
                    <label class="kt-font-primary">{{'_SHARED.MINUTES' | translate}} : </label>
                    <span class="kt-subheader__breadcrumbs-separator">{{item?.serviceMinute}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <label>{{'_SHARED.ATTACH_FILE' | translate }}</label>
            <a href="javascript:;" (click)="fileInputclick()" style="margin: 3px;"> <i
                class="fa fa-paperclip "></i></a>
            <a style="margin: 3px;">{{item.attachFiles.length}}</a>

            <!-- <label>{{'_SHARED.ATTACH_FILE' | translate }}</label>
            <input #fileInput type="file" style="display:none" class="custom-file-input hover-pointer "
                id="inputGroupFile">
            <a href="javascript:;" style="margin: 3px;"> <i class="fa fa-paperclip "></i></a>
            <a href="javascript:;" (click)="onFileClick()"> {{fileSaveData?.originalName}}</a> -->
        </div>

    </div>

</kt-expansion-panel>