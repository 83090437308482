import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingCalloutCommandMapper } from 'src/app/core/models-mapper/setting/setting-callout-command-mapper';
import { SettingCalloutContactMapper } from 'src/app/core/models-mapper/setting/setting-callout-contact.mapper';
import { SettingCalloutLocationMapper } from 'src/app/core/models-mapper/setting/setting-callout-location-mapper';
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { SettingSettingCalloutService } from 'src/app/core/services/_sprint3/setting/setting-cakkout.service';
import { SharedWorksheetService } from 'src/app/core/services/_sprint3/worksheet/worksheet.service';
import { InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { SettingCalloutGetDetailViewModel } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';
import { statusConst } from 'src/app/core/_static/status.const';
import { ViewSharedSerialNumberListViewComponent } from 'src/app/features/user/shared-sp3/view-shared-serial-number-list-view/view-shared-serial-number-list-view.component';
import { ViewSharedShopDetailComponent } from 'src/app/features/user/shared-sp3/view-shared-shop-detail/view-shared-shop-detail.component';
import { ViewSharedWorkSheetInformationViewComponent } from 'src/app/features/user/shared-sp3/view-shared-work-sheet-information/view-shared-work-sheet-information.component';
import { WorksheetCommandComponent } from 'src/app/features/user/shared-sp3/worksheet-command/worksheet-command.component';
import { WorksheetContactViewSharedComponent } from 'src/app/features/user/shared-sp3/worksheet-contact-view-shared/worksheet-contact-view-shared.component';
import { WorksheetLocationViewSharedComponent } from 'src/app/features/user/shared-sp3/worksheet-location-view-shared/worksheet-location-view-shared.component';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';

@Component({
  selector: 'app-setting-callout',
  templateUrl: './setting-callout.component.html',
  styleUrls: ['./setting-callout.component.scss']
})
export class SettingCalloutComponent implements OnInit {

  id:number
  item: SettingCalloutGetDetailViewModel = new SettingCalloutGetDetailViewModel;
  @ViewChild('info', { static: true }) info: ViewSharedWorkSheetInformationViewComponent;
  @ViewChild('shop', { static: true }) shop: ViewSharedShopDetailComponent;
  @ViewChild('snList', { static: true }) snList: ViewSharedSerialNumberListViewComponent; 

  @ViewChild('location', { static: true }) location: WorksheetLocationViewSharedComponent;
  @ViewChild('command', { static: true }) command: WorksheetCommandComponent;
  @ViewChild('contact', { static: true }) contact: WorksheetContactViewSharedComponent;

  minDate = DateUtils.getMinDate()
  hours: InboundTimeSelectViewModel[] = [];
  minutes: InboundTimeSelectViewModel[] = [];
  submit:boolean ;
  submitted: boolean;
  constructor(
    private sharedWorksheetService: SharedWorksheetService,
    private _sharedService: InboundSharedService,
    private locationMapper : SettingCalloutLocationMapper,
    private commandMapper : SettingCalloutCommandMapper,
    private contactMapper : SettingCalloutContactMapper,
    private dialogService: MyDialogService
    ) { }

  ngOnInit(): void {
      this.setInitial();
  }
 
  async setInitial() {  
    this.hours = await this._sharedService.hours();
    this.minutes = await this._sharedService.minutes();
    this.item = await this.sharedWorksheetService.getSettingcallout(this.id);
   
    this.info.loadInitial(this.item.worksheetInfo); 
    this.shop.loadInitial(this.item.shopDetailInfo);
    this.snList.loadInitial(this.item.edcs);  

    this.location.setInitial(this.locationMapper.mapTo(this.item));  
    this.command.setInitial(this.commandMapper.mapTo(this.item));   
    this.contact.setInitial(this.contactMapper.mapTo(this.item)); 
    // this.forMechanic.setInitial(this.item.mechanic)

    console.log(this.item)
  }
  get settingcalloutStatus() { 
    return statusConst.calloutStatus;
  }

  async clearDate() {
    this.item.callAgainDate =  null;
  }
  async setDate() {
    this.item.callAgainDate =  new Date; 
  }

}
