<div class="modal-header">
    <h4 class="modal-title">{{'ORDER.SHARED.ORDER_INFORMATION'|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="kt-form kt-form--label-right">
        <div class="kt-form__body">
            <div class="kt-section kt-section--first">
                <div class="kt-section__body">
                    <div class="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
                        id="kt_body">
                        <div class="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                            id="kt_content">
                            <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">

                                <div class="kt-portlet kt-portlet--mobile">
                                    <div class="kt-portlet__head kt-portlet__head--lg">
                                        <div class="kt-portlet__head-label">
                                            <h3 class="kt-portlet__head-title">
                                                {{'ORDER.SHARED.PARTITION'|translate}}
                                            </h3>
                                        </div>
                                    </div>

                                    <div class="kt-portlet__body">
                                        <div class="kt-form">
                                            <div class="table-responsive">
                                            <table
                                                class="table table-striped- table-bordered table-hover table-checkable ">
                                                <thead>
                                                    <tr align="center">
                                                        <th width="5%">{{'ORDER.SHARED.NO'|translate}}</th>
                                                        <th nowrap="" width="5%">{{'ORDER.SHARED.MAIN'|translate}}</th>
                                                        <th nowrap="" width="10%">{{'ORDER.SHARED.TERMINAL_ID'|translate}}</th>
                                                        <th nowrap="" >{{'ORDER.SHARED.MERCHANT_ID'|translate}}</th>
                                                        <th nowrap="" width="13%">{{'ORDER.SHARED.BANK/HOST'|translate}}</th>
                                                        <th nowrap="" width="2%" *ngFor="let function of functions; index as i">
                                                          <ng-container *ngIf="i+1 <= 4"><b>{{function.name}}</b></ng-container>
                                                          <ng-container *ngIf="i+1 > 4">{{function.name}}</ng-container>
                                                          </th>
                                                        <th nowrap="" width="10%">{{'ORDER.SHARED.BILLER_NAME'|translate}}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let items of item.partitions; index as i">
                                                        <td align="center">{{i + 1}}</td>
                                                        <td align="center">
                                                            <label class="kt-radio left-10-bottom-15">
                                                                <input type="radio" [checked]="items.isMain"
                                                                    [(ngModel)]="isMain"
                                                                    [ngModelOptions]="{standalone: true}" name="radio1">
                                                                <span></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label class="kt-font-primary">{{items.terminalId}} </label>
                                                        </td>
                                                        <td>
                                                            <label class="kt-font-primary">{{items.merchantId}} </label>
                                                        </td>
                                                        <td>
                                                            <label class="kt-font-primary">{{items.bankHost.name}}
                                                            </label>
                                                        </td>
                                                        <td align="center" *ngFor="let function of items.functions;">
                                                            <label class="kt-checkbox">
                                                                <input type="checkbox" [(ngModel)]="function.selected"
                                                                    disabled [ngModelOptions]="{standalone: true}">
                                                                <span></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label class="kt-font-primary">{{items.billerName}} </label>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
