<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ '_SHARED.WORKSHEET_INFORMATION' | translate }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <form class="kt-form">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.WORKSHEET_NO' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.worksheetNo}}</span> <br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.WORKSHEET_TYPE' | translate }} : </label>
                        <span class="badge badge-primary badge-custom"></span> 
                        <!-- <ng-container *ngIf="item.worksheetType?.id == stateInboundType.install">
                            <span class="badge badge-primary badge-custom"> {{item.worksheetType?.name}}</span>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="item.worksheetType?.id == stateInboundType.remove">
                            <span class="badge badge-warning badge-custom"> {{item.worksheetType?.name}}</span>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="item.worksheetType?.id == stateInboundType.reProgram">
                            <span class="badge badge-success  badge-custom"> {{item.worksheetType?.name}}</span>&nbsp;
                        </ng-container>  -->
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.WORKSHEET_DATE' | translate }} : </label>
                        <span
                            class="kt-subheader__breadcrumbs-separator"> {{item.worksheetDate|date:'dd/MM/yyyy'}}</span>
                        <br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.OPERATION_DATE' | translate }} : </label>
                        <span
                            class="kt-subheader__breadcrumbs-separator"> {{item.operationDate|date:'dd/MM/yyyy'}}</span>
                        <br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.ASSIGN_BY' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{item.assignBy}}</span> <br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.TECHNICIAN_NAME' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator"> {{item.technicianName}}</span> <br>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.STATUS' | translate }} : </label>&nbsp;
                        <!-- <span class="kt-subheader__breadcrumbs-separator">{{item.status}}</span> <br> -->
                        <!-- <ng-container *ngIf="item.status?.id == stateInboundStatus.pending">
                            <span class="badge badge-primary badge-custom"> {{item.status?.name}}</span>&nbsp;
                        </ng-container>
                        <ng-container *ngIf="item.status?.id == stateInboundStatus.inProgress">
                            <span class="badge badge-warning badge-custom"> {{item.status?.name}}</span>&nbsp;
                        </ng-container> -->
                        <ng-container >
                            <span class="badge badge-success  badge-custom"> {{item.status?.name}}</span>&nbsp;
                        </ng-container>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.FINISH_DATE' | translate }} : </label>
                        <span
                            class="kt-subheader__breadcrumbs-separator"> {{item.finishDate |date:'dd/MM/yyyy HH:mm'}}</span>
                        <br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.CALL' | translate }} : </label>
                        <span
                            class="kt-subheader__breadcrumbs-separator">{{item.call}}</span>
                        <br>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>