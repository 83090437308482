import { Component, OnInit, ViewChild } from '@angular/core';
import { CurrentChangeSerialNumberComponent } from './current-change-serial-number/current-change-serial-number.component';
import { NewChangeSerialNumberComponent } from './new-change-serial-number/new-change-serial-number.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ChangeSerialNumberViewModel } from 'src/app/core/view-models/_sprint3/_shared/change-serial-number-view-model';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { ChangeSerialNumberService } from 'src/app/core/services/_sprint3/_shared/change-serial-number.service';
import { UpdateChangeSerialNumberApiViewModel } from 'src/app/core/models/_sprint3/change-serial-number-api-view-model';
import { UpdateStatus } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { StoreTaskInformationService } from 'src/app/core/services/_sprint3/_shared/store-task-information.service';

@Component({
  selector: 'app-change-serial-number',
  templateUrl: './change-serial-number.component.html',
  styleUrls: ['./change-serial-number.component.scss']
})
export class ChangeSerialNumberComponent implements OnInit {
  items: ChangeSerialNumberViewModel = new ChangeSerialNumberViewModel();

  data:UpdateChangeSerialNumberApiViewModel = new UpdateChangeSerialNumberApiViewModel();

  form: FormGroup;
  submit: boolean;
  id:number;
  @ViewChild('current',{static:false}) current:CurrentChangeSerialNumberComponent
  @ViewChild('news',{static:false}) news:NewChangeSerialNumberComponent
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private dialogService: MyDialogService,
    private changeSerialNumberService: ChangeSerialNumberService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      remark: [null],
    });
    this.loadDataList();
  }
  async loadDataList() {
    this.items = await this.changeSerialNumberService.getChangeSn(this.id); 
    this.current.setInitial(this.items.current)
    this.news.setInitial(this.items.new)
    this.form.patchValue({
      remark: this.items.remark,
    });
    console.log(this.items)
  }
  onchangeToStatusSelect() {
    // this.pageRequest.criteria.sourceId = this.form.value.sourceId;
    this.loadDataList();
  }
 
  get f() {
    return this.form.controls;
  }
  isValid(submit: boolean) {
    this.submit = submit;
    
    let validateCurrent = this.current.isValid(submit); 
    let validateNew = this.news.isValid(submit);   

    
    if (this.submit && !this.form.valid || !validateCurrent|| !validateNew) {
      this.dialogService.warningValidate();
      return false;
    }
    this.items.remark = this.form.value.remark;  
    this.current.current.changeToStatus;
    this.news.news.changeToStatus;
    return true;
  }
  
    
  

  onSaveClick() {
    if (this.isValid(false)) {   
      this.dialogService.confirmSaveData(() => this.doCreate());
    } 
  }
  async doCreate() {
    const model: UpdateChangeSerialNumberApiViewModel = { 
        currentStatusId: this.current.current?.changeToStatus?.id, 
        newEdcInventoryId: this.news.edcId, 
        newStatusId: this.news.news?.changeToStatus?.id, 
        remark: this.form.value.remark
    };  
    const result = await this.changeSerialNumberService.saveChangeStatus(this.id,model);
    if (result) {
      this.dialogService.successProceed(() => this.activeModal.close());
    }
  }
}
