import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/core/http/loader.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class EmployeeLoginComponent implements OnInit {
  authForm: FormGroup;
  submitted = false;
  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private loaderService: LoaderService
    
  ) { }

  ngOnInit() {
    this.loaderService.disableAll();
    // use FormBuilder to create a form group
    this.authForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  get f() { return this.authForm.controls; }

  async submitForm() {
    this.submitted = true;
    if (this.authForm.invalid) {
        return;
    }
    const authFormValue = this.authForm.value;
    const result = await this.authService.loginByEmployee(authFormValue.username, authFormValue.password,0);
    if(result){
      window.location.href = '/admin/dashboard';
    //  this.router.navigate(['/admin/dashboard']);
    } 
  }

}
