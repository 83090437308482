import { Component, OnInit, Input } from '@angular/core';
import { WorksheetShopDetailViewModel } from 'src/app/core/view-models/_sprint3/_shared/worksheet-information-view-model';
import { SettingCalloutShopDetailInfo } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';

@Component({
  selector: 'app-view-shared-shop-detail',
  templateUrl: './view-shared-shop-detail.component.html',
  styleUrls: ['./view-shared-shop-detail.component.scss']
})
export class ViewSharedShopDetailComponent implements OnInit {
  orderId: number = 0;
  @Input()
  shop:SettingCalloutShopDetailInfo = new SettingCalloutShopDetailInfo;
  constructor() { }

  ngOnInit(): void {
  }
  async loadInitial(data:SettingCalloutShopDetailInfo) {  
    this.shop = data
  }
  public setInitial(item: SettingCalloutShopDetailInfo) {
    console.log(item); 
    this.shop = item;
    this.shop.shopCode = item.shopCode;
    this.shop.shopNameTH= item.shopNameTH;
    this.shop.shopNameEN = item.shopNameEN;
    this.shop.address = item.address;
    this.shop.contactName = item.contactName;
    this.shop.contactPhoneNumber = item.contactPhoneNumber;
    this.shop.contactFaxNumber = item.contactFaxNumber;
    this.shop.provinceName = item.provinceName;
    this.shop.districtName = item.districtName;
    this.shop.subdistrictName = item.subdistrictName;
    this.shop.zipCode = item.zipCode;
    this.shop.callPhoneNumber = item.callPhoneNumber;
    this.shop.orderNo = item.orderNo;
    this.shop.orderDate = item.orderDate;
    
}

}
