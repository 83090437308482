import { Injectable } from '@angular/core';
import { WorksheetMonitoringApiItemViewModel } from '../../models/_sprint3/worksheet/worksheet-monitoring-api-view-model';
import { WorkAreaWorkSheetListViewModel } from '../../view-models/_sprint3/store/work-area-worksheet.model';
import { Mapper } from '../mapper';

@Injectable({
    providedIn: 'root'
})
export class WorksheetMonitoringMapper implements Mapper<WorksheetMonitoringApiItemViewModel, WorkAreaWorkSheetListViewModel> {
    mapForm(param: WorksheetMonitoringApiItemViewModel): WorkAreaWorkSheetListViewModel {
        const item = new WorkAreaWorkSheetListViewModel();
        item.isOverdue = param.isOverdue;
        item.isView = param.canView;
        item.isEdit = param.canEdit;
        item.isResponsible = param.canSelect;
        item.workSheetNo = param.worksheetNo;
        item.id = param.id;
        item.zone = param.zone;
        item.actionState = param.actionState;
        item.status = param.status;
        item.type = param.workType;
        item.subType = param.subType;
        item.progressStatus = param.progresses;
        // item.numberOfMachines = param.;
        item.numberOfMachinesTotal = param.totalMachine;
        item.startDate = param.startDateTime;
        item.endDate = param.endDateTime;
        item.createName = param.createBy;
        item.shopName = param.shopName;
        item.location = param.location;
        item.actualDate = param.actualDateTime;
        item.ownerName = param.taskOwnerName;
        item.responsibleName = param.taskResponsibleName;
        item.canDelete = param.canDelete;
        item.worksheetStatus = param.worksheetStatus;
        item.receiveStatus = param.receiveStatus;
        item.bankName = param.bankName;
        item.mainEdcTid = param.mainEdcTid;
        item.referInboundTicketNo = param.referInboundTicketNo;
        item.operationDate = param.operationDate;
        item.canReviseWorksheet = param.canReviseWorksheet;
        item.canReviseCloseJob = param.canReviseCloseJob;
        item.tid = param.tid;
        item.provinceName = param.provinceName;
        item.technicianName = param.technicianName;
        item.technicianPlanningDate = param.technicianPlanningDate;
        item.onsiteTechnicianName = param.onsiteTechnicianName;
        item.hasPrintWorksheet = param.hasPrintWorksheet
        item.totalOnsiteCompleted = param.totalOnsiteCompleted;
        item.totalOnsiteUncompleted = param.totalOnsiteUncompleted;
        item.totalOnsitePostpone = param.totalOnsitePostpone;
        item.finishDateTime = param.finishDateTime
        item.startPromotionDate = param.startPromotionDate
        item.finishPromotionDate = param.finishPromotionDate
        item.postponeStatus = param.postponeStatus

        return item;
    }
    mapTo(param: WorkAreaWorkSheetListViewModel): WorksheetMonitoringApiItemViewModel {
        throw new Error("Method not implemented.");
    }
}
