import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CaseViewModel, Case } from 'src/app/core/view-models/_order/order-install.view-model';
import { OrderShareService } from 'src/app/core/services/order/order-share.service';
import { CommonService } from 'src/app/core/services/common.service';
import { ReprogramCase } from 'src/app/core/view-models/_sprint3/_shared/reprogram-case';
import { AvailableEdc, Connector } from 'src/app/core/view-models/_shared/worksheet.view-model';
import { Subject } from 'rxjs';
import { statusConst } from 'src/app/core/_static/status.const';
import { LocationEdcConnectorViewModel } from 'src/app/core/models/user/help-desk/worksheet-model';
import { WorksheetService } from 'src/app/core/services/worksheet/worksheet.service';

@Component({
  selector: 'app-case-edc-list',
  templateUrl: './case-edc-list.component.html',
  styleUrls: ['./case-edc-list.component.scss']
})
export class CaseEdcListComponent implements OnInit { 
  onSaveClickReturn: Subject<AvailableEdc> = new Subject<AvailableEdc>(); 
  item: AvailableEdc = new AvailableEdc
  caseList: ReprogramCase[] = [];
  formValidate: FormGroup;
  submit:boolean;
  isReaonly:boolean = false;
  worksheetId:number
  connectors: Connector[] = [];
  workTypeId: number;
  toggleOnsiteItemFeature:boolean;
  constructor(
    private formBuilder: FormBuilder,
    private dialogService: MyDialogService,
    private activeModal:NgbActiveModal,
    private service:OrderShareService,
    private commonService:CommonService,
    private worksheetService: WorksheetService,
  ) { }

  ngOnInit(): void {
    this.toggleOnsiteItemFeature = true;
    this.formValidate = this.formBuilder.group({
      reprogramCase: [null, Validators.required],
      remark: [null],
    });
    this.loadInitial();
  }
  
  async loadInitial() { 
    this.caseList = await this.commonService.getReprogramCase();

    this.connectors =  await this.worksheetService.connectors(this.worksheetId, this.item.edcId);;
    this.connectors.forEach(connector => {
      const item = this.item.connectors.find(x => x.connectorId == connector.connectorId)
      if (item != null) {
        connector.isActive = true;
      } else {
        connector.isActive = false;
      }
      connector.remark = item?.remark;
    });

    this.formValidate.patchValue({
      reprogramCase: this.item.reprogramCase?.id,
      remark: this.item.reprogramCaseRemark,
    });
  }
  get f() { return this.formValidate.controls; }
  get workTypeStatus() {
    return statusConst.workType;
  }
  isValid(submit: boolean) {
    this.submit = submit;  
  
    if (this.submit && !this.formValidate.valid  ) {
      this.dialogService.warningValidate();
      return false;
    }  
    
    return true;
  }
  onSaveClick() {  
    if (this.isValid(true)) {
      this.dialogService.confirmSaveData(() => { 
        this.item.connectors = this.connectors.filter(x => x.isActive);
        this.item.reprogramCase = this.caseList.find(x => x.id == this.formValidate.value.reprogramCase);
        this.item.reprogramCaseRemark = this.formValidate.value.remark;
        this.item.onsiteItemTypeId = this.workTypeId
        this.onSaveClickReturn.next(this.item);
        this.closeModal()
      }); 
    } 
  }
  closeModal() {
  
    this.activeModal.dismiss();
  }

}
