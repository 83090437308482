<div class="modal-header">
    <h5 class="modal-title">{{ '_SHARED.HISTORY' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModalClose.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__body">
            <div class="table-responsive form-group" style="    height: calc(100vh - 300px);
            overflow: auto;">
                <table class="table table-striped- table-bordered table-hover table-checkable tableFixHead" id="kt_table_1">
                    <thead>
                        <tr align="center">
                            <th width="2%" nowrap="">{{ '_SHARED.NO' | translate}}</th>
                            <th width="2%" nowrap="">{{'_SHARED.ACTION' | translate}}</th>
                            <th width="2%" nowrap="">{{ '_SHARED.STATUS_TYPE' | translate}}</th>
                            <th width="5%" nowrap="">{{ '_SHARED.WORKSHEET_NO' | translate}}</th>
                            <th width="5%" nowrap="">{{ 'ORDER.CS.REMOVE.S/N' | translate}}</th>
                            <th width="5%" nowrap="">{{ '_SHARED.TID' | translate}}</th> 
                            <th width="5%" nowrap="">{{ '_SHARED.SHOP_NAME_TH' | translate }}</th> 
                            <th width="5%" nowrap="">จังหวัด</th>
                            <th width="5%" nowrap="">Bank </th>
                            <th width="2%" nowrap="">Issue </th>
                            <th width="2%" nowrap="">วิธีการแก้ไข</th>
                            <th width="2%" nowrap="">วันที่สร้าง</th>
                            <th width="2%" nowrap="">ชื่อผู้เปิดงาน</th>
                            <th width="2%" nowrap="">ชื่อผู้ปิดงาน</th>
                            <th width="2%" nowrap="">ชื่อช่าง</th>
                            <th width="2%" nowrap="">ชื่อผู้แจ้ง</th>
                            <th width="2%" nowrap="">เบอร์โทร</th>
                            <th width="2%" nowrap="">Worksheet Status</th>
                            <th width="2%" nowrap="">Progress </th> 

                        </tr>
                        <tr align="center" colFilter (colFilterChange)="colFilterChange($event)">
                            <th align="center"></th> 
                            <th align="center"></th>
                            <th class="text-center">
                                <a href="javascript:;" [ngbTooltip]="'_SHARED.FILTER'|translate"
                                    (click)="onClickWorkTypeFilter()">
                                    <i class="fa fa-filter"></i>
                                </a>
                            </th>
                            <th align="center">
                                <input class="form-control" col-filter-name="taskNo" id="taskNo" name="taskNo"
                                [placeholder]="'_SHARED.WORKSHEET_NO' | translate" />
                            </th> 
                            <th align="center">
                                <input class="form-control" col-filter-name="serialNumber" id="serialNumber" name="serialNumber"
                                [placeholder]="'ORDER.CS.REMOVE.S/N' | translate" />
                            </th>
                            <th align="center">
                                <input class="form-control" col-filter-name="tid" id="tid" name="tid"
                                [placeholder]="'_SHARED.TID' | translate" />
                            </th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"> 
                                <input class="form-control" col-filter-name="openJobName" id="openJobName" name="openJobName"
                                placeholder="ชื่อผู้เปิดงาน" />
                            </th>
                            <th align="center">
                                <input class="form-control" col-filter-name="closeJobName" id="closeJobName" name="closeJobName"
                                placeholder="ชื่อผู้ปิดงาน" />
                            </th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                            <th align="center"></th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of items;index as i;">
                            <td align="center">{{pageResult?.startIndex+i}}</td>
                            <td nowrap="" class="text-left">
                                <a class="button-group" href="javascript:;" (click)="viewClick(item)">
                                    <span><i class="fas fa-eye text-primary"></i></span>
                                </a>&nbsp;
                                <a *ngIf="item.signaturePictureFile != null" class="button-group" href="javascript:;" (click)="signaturePictureFileClick(item)">
                                    <span><i class="fas fa-file text-primary"></i></span>
                                </a>
                            </td>
                             
                             
                            <td nowrap="" class="text-left">{{item.typeName}}</td> 
                            <td nowrap="" class="text-center">{{item.taskNo}}</td> 
                            <td nowrap="" class="text-center">{{item.serialNumber}}</td> 
                            <td nowrap="" class="text-center">{{item.tid}}</td> 
                            <td nowrap="" class="text-left">{{item.shopNameTH}}</td> 
                            <td nowrap="" class="text-left">{{item.provinceName}}</td> 
                            <td nowrap="" class="text-left">{{item.bankName}}</td>  

                            <td nowrap="" class="text-left white-space-custom">
                                <div placement="top" [ngbTooltip]="item.issue" class="white-space-custom-div">
                                    {{item.issue}}
                                </div>
                            </td>
                            <td nowrap="" class="text-left white-space-custom">
                                <div placement="top" [ngbTooltip]="item.solution" class="white-space-custom-div">
                                    {{item.solution}}
                                </div>
                            </td> 
                            <td nowrap="">{{item.createDateTime | date:'dd/MM/yyyy hh:mm'}}</td>  
                            <td nowrap="" class="text-left">{{item.openJobName}}</td>
 
                            <td nowrap="" class="text-left">{{item.closeJobName}}</td>
                            <td nowrap="" class="text-left">{{item.technicianName}}</td>  
                            <td nowrap="" class="text-left">{{item.contactName}}</td>
                            <td nowrap="">{{item.contactTel}}</td>
                            <td nowrap="">{{item.statusName}}</td>
                            <td nowrap="">
                                <ng-container *ngFor="let data of item?.progresses">
                                    <ng-container *ngIf="data.state == statusStateConst.todo">
                                        <span class="badge badge-info badge-custom">{{data?.name}}</span> &nbsp;
                                    </ng-container>
                                    <ng-container *ngIf="data.state == statusStateConst.doing">
                                        <span class="badge badge-warning badge-custom">{{data?.name}}</span> &nbsp;
                                    </ng-container>
                                    <ng-container *ngIf="data.state == statusStateConst.done">
                                        <span class="badge badge-success badge-custom">{{data?.name}}</span> &nbsp;
                                    </ng-container>
                                    <ng-container *ngIf="data.state == statusStateConst.waiting">
                                        <span class="badge badge-success badge-custom">{{data?.name}}</span> &nbsp;
                                    </ng-container>
                                </ng-container>
                            </td> 
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <app-admin-table-paginator #pagination (onPageChange)="onPageChange($event)"
                (onPageSizeChange)="onPageSizeChange($event)">
            </app-admin-table-paginator> -->
        </div>


    </div>
</div>

<div class="modal-footer">
    <div class="text-right">
        <button type="button" class="btn btn-dark waves-effect" (click)="activeModalClose.dismiss()">
            {{ '_SHARED.CLOSE' | translate}}
        </button>
    </div>
</div>