import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper'; 
import { ShopDetailInfoCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model'; 
import { SettingCalloutShopDetailInfo } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';

@Injectable({
    providedIn: "root",
}) 
export class AdminBKKClosejobShopMapper implements Mapper<ShopDetailInfoCloseJobApis, SettingCalloutShopDetailInfo> {
    mapForm(param: ShopDetailInfoCloseJobApis): SettingCalloutShopDetailInfo {
        const item = new SettingCalloutShopDetailInfo(); 
        item.shopCode = param.shopCode ;
        item.shopNameTH = param.shopNameTH;
        item.shopNameEN = param.shopNameEN;
        item.address = param.address;
        item.provinceName = param.provinceName;
        item.districtName = param.districtName;
        item.subdistrictName = param.subdistrictName;
        item.zipCode = param.zipCode;
        item.callPhoneNumber = param.callPhoneNumber;
        item.contactName = param.contactName;
        item.contactPhoneNumber = param.contactPhoneNumber;
        item.contactFaxNumber = param.contactFaxNumber;
        item.email = param.email;
        item.orderNo = null;
        item.orderDate = null;
        return item;
    }
    mapTo(param: SettingCalloutShopDetailInfo): ShopDetailInfoCloseJobApis {
        return null
    }
}
