import { Injectable } from '@angular/core';
import { Mapper } from '../mapper';
import { WorksheetLocaltionViewModel } from '../../models/user/help-desk/worksheet-model';
import { SaveWorksheetLocaltionModel } from '../../models/_inbound/onsite-info-apis-model';

@Injectable({
    providedIn: 'root'
})
export class SaveWorksheetLocaltionMapper implements Mapper<WorksheetLocaltionViewModel, SaveWorksheetLocaltionModel>{
    constructor() { }
    mapForm(param: WorksheetLocaltionViewModel): SaveWorksheetLocaltionModel {
        const item: SaveWorksheetLocaltionModel = {
            clientConvenientDate: param.clientConvenientDate,
            clientConvenientHour: param.clientConvenientHour,
            clientConvenientMiute: param.clientConvenientMiute,
            command: param.command,
            contactEmail: param.contactEmail,
            contactFaxNumber: param.contactFaxNumber,
            contactName: param.contactName,
            contactPhoneNumber: param.contactPhoneNumber,
            districtId: null,
            latitude: param.latitude,
            location: param.location,
            longitude: param.longitude,
            provinceId: null,
            reg: param.reg,
            remark: param.remark,
            shopAddress: param.shopAddress,
            shopNameEN: param.shopNameEN,
            shopNameTH: param.shopNameTH,
            subdistrictId: null,
            zipCode: param.zipCode,
            issue: param.issue,
            workCommand: param.workCommand,
            recommend: param.recommend,
            clientContactName: param.clientContactName,
            clientContactPhoneNumber : param.clientContactPhoneNumber
        };
        if (param.province != null) item.provinceId = param.province.id;
        if (param.district != null) item.districtId = param.district.id;
        if (param.subdistrict != null) item.subdistrictId = param.subdistrict.id;
        return item;
    }
    mapTo(param: SaveWorksheetLocaltionModel): WorksheetLocaltionViewModel {
        return null;
    }

}
