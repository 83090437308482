import { Component, OnInit, ViewChild, ComponentRef, ComponentFactoryResolver, Type, EventEmitter } from '@angular/core';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WorksheetViewDetailComponent } from './detail/detail.component';
import { WorksheetViewInformationComponent } from './information/information.component';
import { WorksheetAllViewModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { SharedWorksheetService } from 'src/app/core/services/_sprint3/worksheet/worksheet.service';
import { WorksheetAllTimeLineComponent } from './time-line/time-line.component';
import { CreatePmGetWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-pm-get-mapper';
import { ModalWorksheetRejectComponent } from '../../../shared-sp3/_modal/modal-worksheet-reject/modal-worksheet-reject.component';
import { ModalWorksheetCancelComponent } from '../../../shared-sp3/_modal/modal-worksheet-cancel/modal-worksheet-cancel.component';
import { SettingCalloutComponent } from './setting-callout/setting-callout.component';
 
@Component({
  selector: 'app-everything-worksheet-modal-view',
  templateUrl: './everything-worksheet-modal-view.component.html',
  styleUrls: ['./everything-worksheet-modal-view.component.scss']
})
export class EverythingWorksheetModalViewComponent implements OnInit {
  @ViewChild(SwitchTabDirective, { static: true }) switchTab: SwitchTabDirective;
  tabChildNumber: number = 1;
  componentRef: ComponentRef<any>;
  id: number;
  public onReturnData: EventEmitter<any> = new EventEmitter();
  item: WorksheetAllViewModel = new WorksheetAllViewModel();
  iscustomer:boolean = false;
  constructor(
    private activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private sharedWorksheetService: SharedWorksheetService,
    private modalService: NgbModal, 
  ) { }

  async ngOnInit() {
    await this.loadInitial();
    this.focusTabDetailType(this.tabChildNumber);
  }
  async loadInitial() {
    this.item = await this.sharedWorksheetService.getWorksheetAll(this.id);
  }
  async focusTabDetailType(type: number) {
    this.tabChildNumber = type;
    this.setComponentTab();
  }

  setComponentTab() {
    if (this.tabChildNumber == 1) this.loadComponentInformation(WorksheetViewInformationComponent);
    if (this.tabChildNumber == 2) this.loadComponentDetail(WorksheetViewDetailComponent);
    if (this.tabChildNumber == 3) this.loadComponentTimeline(WorksheetAllTimeLineComponent);
    if (this.tabChildNumber == 4) this.loadComponentSettingCallout(SettingCalloutComponent)
  }

  loadComponentInformation(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.item = this.item.infomation;
  }
  loadComponentDetail(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.item = this.item.onsite;
    this.componentRef.instance.iscustomer = this.iscustomer;
    this.componentRef.instance.id = this.id
  }
  loadComponentTimeline(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.items = this.item.timelines;
  }
  loadComponentSettingCallout(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef.instance.id = this.item.settingCallOutId; 
  }

  closeModal() {
    this.activeModal.dismiss();
  }
  onRejectClick() {
    const modalRef = this.modalService.open(ModalWorksheetRejectComponent, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    });
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.onReturnData.subscribe(_ => {
      this.activeModal.dismiss();
    })
  }
  onCancelClick() {
    const modalRef = this.modalService.open(ModalWorksheetCancelComponent, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    });
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.onReturnData.subscribe(_ => {
      this.activeModal.dismiss();
    })
  }
  async onFileClick() {
    const response = await this.sharedWorksheetService.saveKeep(this.id); 
    if(response){
      window.open(this.item.worksheetPaperDownloadUrl, '_blank');
    } 
  }
  onFileEdcClick() {
    window.open(this.item.worksheetEdcPaperDownloadUrl, '_blank');
  }
   
  openInNewTab() {
    var win = window.open(this.item.worksheetEdcPaperDownloadUrl, '_blank');
    win.focus();
  }
  onHistoryClick() {
    const modalRef = this.modalService.open(null, {
      backdrop: "static",
      keyboard: false,
      size: "lg",
    });
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.onReturnData.subscribe(result => {
      this.activeModal.dismiss();
    })
  }
}
