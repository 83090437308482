import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { StoreTaskInformationConst, StoreTaskInfoViewModelConst, StoreUpdateStatusConst, StoreTaskInfomationViewModelConst } from 'src/app/core/view-models/_sprint3/_mock/store-task-information-const';
import { StoreTaskInformationViewModel, StoreTaskInfoViewModel, UpdateStatus, StoreTaskInfomationViewModel, UpdateStoreWorkareaEdc, UpdateStoreWorkareaConnector } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { StoreTaskInfoViewApisModel } from 'src/app/core/models/_sprint3/store/work-area.model';
import { GetStoreEdcMapper } from 'src/app/core/models-mapper/_sprint3/store/work-area-edc-mapper';
import { CurrentStatus } from 'src/app/core/view-models/_sprint3/_shared/change-serial-number-view-model';

@Injectable({ 
  providedIn: 'root'
})
export class StoreTaskInformationService {
  constructor(
    private apiService:ApiService,
    private getStoreEdcMapper:GetStoreEdcMapper
    ) { }
/*  Apis */ 
  async getEdc(id:number): Promise<StoreTaskInfoViewModel> {
    const result = await this.apiService.get<StoreTaskInfoViewApisModel>(`/store/work-area/edcs/${id}`);
    return this.getStoreEdcMapper.mapForm(result);
  }
  async getperipheral(id:number): Promise<StoreTaskInfoViewModel> {
    const result = await this.apiService.get<StoreTaskInfoViewApisModel>(`/store/work-area/peripherals/${id}`);
    return this.getStoreEdcMapper.mapForm(result);
  }
 
  async saveEdc(id:number,updateRequest:UpdateStoreWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/store/work-area/edcs/${id}/take`,updateRequest);
    return result
  }
  async submitEdc(id:number,updateRequest:UpdateStoreWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/store/work-area/edcs/${id}/take/submit`,updateRequest);
    return result
  }
  async savePeripheral(id:number,updateRequest:UpdateStoreWorkareaConnector): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/store/work-area/peripherals/${id}/take`,updateRequest);
    return result
  }
  async submitPeripheral(id:number,updateRequest:UpdateStoreWorkareaConnector): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/store/work-area/peripherals/${id}/take/submit`,updateRequest);
    return result
  }
  /*  mock */ 
  // async getTask(): Promise<StoreTaskInfoViewModel> {
  //   const result = StoreTaskInfoViewModelConst as StoreTaskInfoViewModel;
  //   return result;
  // }
  async get(): Promise<StoreTaskInformationViewModel> {
    // const result = StoreTaskInformationConst as StoreTaskInformationViewModel;
    return null;
  }
  
  async getUpdateStatusSelect(): Promise<UpdateStatus[]> {
    const result = this.apiService.get<UpdateStatus[]>('/commons/work-area/store/take-status')
    return result;
  }
  async getTaskIformation(): Promise<StoreTaskInfomationViewModel> {
    // const result = StoreTaskInfomationViewModelConst as StoreTaskInfomationViewModel;
    return null;
  }
  async getStatusSelect(): Promise<CurrentStatus[]> {
    const result = this.apiService.get<CurrentStatus[]>('/commons/work-area/store/take-status')
    return result;
  }
}
