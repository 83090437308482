import { PageResponseModel } from "../../_shared/page.model";
import { StatusViewModel } from 'src/app/core/view-models/_shared/status.view-model';

export class OutboundModel {
  id: number;
  ticketId: string;
  statusType: string; //object
  topicType: string; //object
  ticketDate: string;
  ticketOwner: string;
  responsible: string;
  startDateTime: string;
  endDateTime: string;
  usingTime: string;
  worksheetNo: string;
  shopCode: string;
  shopNameEN: string;
  shopNameTH: string;
  address: string;
  contactName: string;
  phoneNumber: string;
  remark: string;
  canAssign: boolean;
  canView: boolean;
  canEdit: boolean;
  canOverdue: boolean;
  canPending: string;
  canInprogress: string;
  canComplete: string;
  canCallOut: boolean;
  canImportant: boolean;
}

export class statusSatisfactionType {
  code: string;
  id: number;
  kind: string;
  name: string;
}
export class SearchOutboundCriteria {
  ticketId: string = "";
  statusType: string = "";
  ticketDate: string = "";
  ticketOwnerName: string = "";
  responsibleName: string = "";
  startDateTime: string = "";
  endDateTime: string = "";
  shopCode: string = "";
  shopNameEN: string = "";
  shopNameTH: string = "";
  shopAddress: string = "";
  contactName: string = "";
  contactPhoneNumber: string = "";
  documentNo: string = "";
  statusName: string = "";
  topicName: string = "";
  ticketDateTime: string = "";
  startActionDateTime: string = "";
  finishActionDateTime: string = "";
  usingTime: string = "";
  worksheetDocumentNo: string = "";
}

// ======================== API ========================

export class SearchOutboundApiCriteria {
  keyword: string = "";
  taskDate: Date = new Date();
  isOverdue: boolean = true;
  isPending: boolean = true;
  isInProgress: boolean = true;
  isComplete: boolean = true;
  isCallOut: boolean = true;
  ticketId: string = "";
}

export class OutboundApiModel {
  id: number;
  canMarkImportant: boolean;
  canView: boolean;
  canEdit: boolean;
  canChangeResponsible: boolean;
  isImportant: boolean;
  documentNo: string;
  callMode: number;
  tier: string;
  actionState: number;
  isOverdue: boolean;
  status:Status;
  statusName: string;
  topicName: string;
  topicType: number;
  ticketDateTime: Date;
  ticketOwnerName: string;
  responsibleName: string;
  responsibleRemark: string;
  startActionDateTime: Date;
  finishActionDateTime: Date;
  usingTime: string;
  worksheetId: number;
  worksheetDocumentNo: string;
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  contactName: string;
  contactPhoneNumber: string;
  remark: string;
}
export class Status {
  id:number;
  name:string;
}
export class ImportantShowModel {
  isImportant: boolean;
}

export class CreateOutboundModel {
  inboundId: number;
}

export class ResponsibleOutboundModel {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  fullName:string;
}
export class ResponsibleOutboundApiModel {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  fullName:string;
}
export class CreateAssignResponseOutbound {
  responsibleId: number;
  remark: string;
}

export class SummariesModel {
  totalAll: number;
  totalOverdue: number;
  totalPending: number;
  totalInProgress: number;
  totalComplete: number;
  totalRemain: number;
}
export class OutboundAllModel {
  summary: SummariesModel;
  pageResult: PageResponseModel<OutboundApiModel>;
}

//--------------------------------------------------------------

export class OutboundSatisfactionTicketInfo {
  ticketNo: string;
  ticketDateTime: Date;
  creatorName: string;
  responsibleName: string;
  startDateTime: Date;
  finishDateTime: Date;
  usingTime: string;
  ticketReferenceId: number;
  ticketReferenceNo: string;
}

export class OutboundSatisfactionWorksheetInfo {
  worksheetId : number;
  worksheetNo: string;
  worksheetDate: Date;
  operationDate: Date;
  assignByName: string;
  technicianName: string;
  statusName: string;
  finishDateTime: Date;
  phoneNumber: string;
}
  
export class OutboundSatisfactionShopInfo {
  orderNo: string;
  orderDate: Date;
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  address: string;
  provinceName: string;
  districtName: string;
  subdistrictName: string;
  zipCode: string;
  contactName: string;
  contactPhoneNumber: string;
  contactFaxNumber: string;
  email: string;
}

export class OutboundSatisfactionChoice {
  choiceId: number;
  name: string;
}

export class OutboundSatisfactionQuestion {
  questionId: number;
  sequence: number;
  name: string;
  answerChoiceId: number;
}

export class OutboundSatisfactionViewModel {
  id: number;
  worksheetId: number;
  ticketInfo: OutboundSatisfactionTicketInfo = new OutboundSatisfactionTicketInfo;
  worksheetInfo: OutboundSatisfactionWorksheetInfo = new OutboundSatisfactionWorksheetInfo;
  shopInfo: OutboundSatisfactionShopInfo;
  choices: OutboundSatisfactionChoice[] = [];
  questions: OutboundSatisfactionQuestion[] = [];
  status: StatusViewModel = new StatusViewModel;
  callAgainType: number;
  callAgainDate: Date;
  callAgainHour: number;
  callAgainMinute: number;
  contactName: string;
  contactPhoneNumber: string;
  remark: string;
}


export class SaveOutboundSatisfactionAnswer {
  questionId: number;
  answerChoiceId: number;
}

export class SaveOutboundSatisfaction {
  contactName: string;
  statusId: number;
  callAgainType: number;
  callAgainDate: Date;
  callAgainHour: number;
  callAgainMinute: number;
  remark: string;
  answers: SaveOutboundSatisfactionAnswer[]= [];
  contactPhoneNumber: string;
}