import { Component, OnInit, ComponentRef, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { PackingTaskInformationComponent } from '../packing-task-information/packing-task-information.component';
import { SharedTaskWorkflowComponent } from '../../../shared-task-workflow/shared-task-workflow.component';
import { SwitchTabComponent } from 'src/app/shared/component/switch-tab.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PackingTaskInformationViewModel, UpdatePackingWorkareaEdc } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { PackingTaskService } from 'src/app/core/services/_sprint3/packing/task.service';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { UpdateSettingWorkareaEdc } from 'src/app/core/view-models/_sprint3/_setting/task-model';
import { PackingViewTaskInformationComponent } from '../packing-view-task-information/packing-view-task-information.component';

@Component({
  selector: 'app-packing-view-modal-work-area',
  templateUrl: './packing-view-modal-work-area.component.html',
  styleUrls: ['./packing-view-modal-work-area.component.scss']
})
export class PackingViewModalWorkAreaComponent implements OnInit {
  id:number
  feature: number;
  componentRef: ComponentRef<any>; 
  @ViewChild(SwitchTabDirective, { static: true }) switchTab: SwitchTabDirective;
  item: UpdatePackingWorkareaEdc = new UpdatePackingWorkareaEdc;
  constructor(
    private activeModal: NgbActiveModal,
    private componentFactoryResolver: ComponentFactoryResolver, 
    private service: PackingTaskService,
    private dialogService: MyDialogService
  ) { }

  async ngOnInit() {
    this.focusTabDetailType(1);
  }

  async focusTabDetailType(type: number) {
    this.feature = type;
    this.setComponentTab();
  }
  setComponentTab() {
    if (this.feature == 1) this.loadComponent(PackingViewTaskInformationComponent);
    if (this.feature == 2) this.loadComponent(SharedTaskWorkflowComponent);
  }

  loadComponent(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>this.componentRef.instance)
    this.componentRef.instance.id = this.id;
  }

  closeModal() {
    this.activeModal.dismiss();
  }

}
