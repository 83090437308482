import { Component, OnInit } from "@angular/core";
import { TimeLineService } from "src/app/core/services/_sprint3/_shared/time-line.service";
import { TimeLineViewModel } from "src/app/core/view-models/_sprint3/_shared/time-line-view-model";

@Component({
  selector: "app-tab-time-line",
  templateUrl: "./tab-time-line.component.html",
  styleUrls: ["./tab-time-line.component.scss"],
})
export class TabTimeLineComponent implements OnInit {
  items: TimeLineViewModel[] = [];
  edcId:number;
  id:number;
  constructor(private timelineService: TimeLineService) {}

  ngOnInit(): void {
    this.loadDataList();
  }
  async loadDataList() {
    this.items = await this.timelineService.get(this.edcId);
  }
}
