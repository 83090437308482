import { Injectable } from "@angular/core";
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { WorksheetPMViewModel } from '../../view-models/_sprint3/worksheet/worksheet-pm-model';
import { GetWorksheetPMApisModel } from '../../models/_sprint3/worksheet/worksheet-api-view-model';
import { Client, Province, District, SubDistrict } from '../../view-models/_shared/worksheet.view-model';
import { MechanicModel, CommandModel, ContactModel, FileUp } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { AvailableEdcMapper } from './available-edcs-mapper';
import { Subdistrict } from '../../models/_sprint3/setting/setting-task-model';

@Injectable({
  providedIn: "root",
})
export class CreatePmGetWorksheetMapper implements Mapper<GetWorksheetPMApisModel, WorksheetPMViewModel>{
  constructor(
    private availableEdcMapper: AvailableEdcMapper
  ) { }
  mapForm(param: GetWorksheetPMApisModel): WorksheetPMViewModel {
    const item = new WorksheetPMViewModel();
    item.id = param?.id;
    item.remark = param.remark;
    item.shop = new Client;
    // item.location = new Client;
    // item.mechanic = new MechanicModel;
    // item.command  = new CommandModel;
    // item.contact  = new ContactModel;
    item.shop = new Client();
    item.shop.id = param?.clientId;
    item.shop.code = param?.shopDetailInfo?.shopCode;
    item.shop.group = null;
    item.shop.branchName = '';
    item.shop.nameTH = param?.shopDetailInfo?.shopNameTH;
    item.shop.nameEN = param?.shopDetailInfo?.shopNameEN;
    item.shop.address = param?.shopDetailInfo?.address;
    item.shop.addressTH = param?.shopDetailInfo?.address;

    item.shop.province.nameTH = param?.shopDetailInfo?.provinceName;
    item.shop.district.nameTH = param?.shopDetailInfo?.districtName;
    item.shop.subDistrict.nameTH = param?.shopDetailInfo?.subdistrictName;

    item.shop.zipCode = param?.shopDetailInfo?.zipCode;
    item.shop.contactName = param?.shopDetailInfo?.contactName;
    item.shop.contactPhoneNumber = param?.shopDetailInfo?.contactPhoneNumber;
    item.shop.contactTel = param?.shopDetailInfo?.contactPhoneNumber;
    item.shop.contactFaxNumber = param?.shopDetailInfo?.contactFaxNumber;
    item.shop.email = param?.shopDetailInfo?.email;
    item.shop.placeInLocation = '';
    item.shop.location = param?.location;
    item.shop.reg = param?.reg;
    item.shop.bankPhoneNumber = param?.shopDetailInfo?.contactPhoneNumber;
    item.shop.bankBranchName = param?.shopDetailInfo?.bankBranchName;
    item.shop.bankTel = param?.shopDetailInfo?.bankTel;

    item.shop.provinceId = param?.province?.id;
    item.shop.districtId = param?.district?.id;
    item.shop.subdistrictId = param?.subdistrict?.id;
    item.shop.posCode = param?.zipCode;
    item.shop.locationCode = '';
    item.shop.provinceName = param?.shopDetailInfo?.provinceName;
    item.shop.districtName =param?.shopDetailInfo?.districtName;
    item.shop.subDistrictName = param?.shopDetailInfo?.subdistrictName;

    item.mechanic = new MechanicModel;
    item.mechanic.detail = '';
    item.mechanic.attachFile = param?.attachFile; 
    item.location = new Client;
    item.location.id = param?.id;
    item.location.code = "";
    item.location.group = null;
    item.location.branchName = '';
    item.location.nameTH = param?.shopNameTH;
    item.location.nameEN = param?.shopNameEN;
    item.location.address = param?.shopAddress;
    item.location.addressTH = param?.shopAddress;
    // item.location.province = new Province
    item.location.provinceId = param?.province?.id;
    // item.location.district = new District
    item.location.districtId = param?.district?.id;
    // item.location.subDistrict = new Subdistrict
    item.location.subdistrictId = param?.subdistrict?.id;
    item.location.provinceName = param?.province?.nameTH;
    item.location.districtName = param?.district?.nameTH;
    item.location.subDistrictName = param?.subdistrict?.nameTH;
    item.location.province = param?.province;
    item.location.district = param?.district;
    item.location.subDistrict = param?.subdistrict;
    item.location.zipCode = param?.zipCode;
    item.location.contactName = param?.contactName;
    item.location.contactPhoneNumber = param?.contactPhoneNumber;
    item.location.contactTel = param?.contactPhoneNumber;
    item.location.contactFaxNumber = param?.contactFaxNumber;
    item.location.email = param?.contactEmail;
    item.location.placeInLocation = '';
    item.location.location = param?.location;
    item.location.reg = param?.reg;
    item.location.bankPhoneNumber = '';
    item.location.bankName = '';
    item.location.posCode = param?.zipCode;
    item.location.locationCode = '';
    item.location.isHq = param.isHq;
    item.contact = new ContactModel;
    item.contact.commandFile = new FileUp
    item.contact.commandFile.attachFileId = param?.attachFile?.id;
    item.contact.commandFile.name = param?.attachFile?.originalName;
    item.contact.commandFile.url = param?.attachFile?.url;
    item.contact.contactName = param?.commandContactName;
    item.contact.contactPhone = param?.commandContactPhoneNumber;
    item.contact.serviceDate = DateUtils.toDate(param?.convenientDate);
    item.contact.serviceHour = param?.convenientHour;
    item.contact.serviceMinute = param?.convenientMinute;
    item.contact.attachFiles = param?.attachFiles 
    item.command = new CommandModel;
    item.command.command = param?.workCommand;
    item.command.issue = param?.issue;
    item.command.recommend = param?.recommend;
    if (param.availableEdcs) {
      item.availableEdcs = param.availableEdcs.map(map => this.availableEdcMapper.mapForm(map));
    }
    if (param.selectedEdcs) {
      item.selectedEdcs = param.selectedEdcs.map(map => this.availableEdcMapper.mapForm(map));
    }
    item.sendToType = param?.sendToType;
    item.eventTypeId = param.eventTypeId;
    // item.eventTypeId = param?.typeOfWork?.id;
    item.typeOfWorkId= param?.typeOfWork?.id;
    item.workType = param.workType;
    item.referWorksheetInfo = param.referWorksheetInfo;
    item.worksheetNo = param.worksheetNo;
    item.authorize = param.authorize
    return item;
  }
  mapTo(param: WorksheetPMViewModel): GetWorksheetPMApisModel {
    const item = new GetWorksheetPMApisModel();
    item.clientId = param.shop?.id;
    item.convenientDate = DateUtils.toFormat(param.contact?.serviceDate, DateUtils.YYYY_MM_DD);
    item.convenientHour = param.contact?.serviceHour;
    item.convenientMinute = param.contact?.serviceMinute;
    item.issue = param.command?.issue;
    item.recommend = param.command?.recommend;
    item.workCommand = param.command?.command;
    item.commandContactName = param.contact?.contactName;
    item.commandContactPhoneNumber = String(param.contact?.contactPhone);
    item.remark = param.remark
    item.shopNameTH = param.location?.nameTH;
    item.shopNameEN = param.location?.nameEN;
    item.shopAddress = param.location?.address;
    item.provinceId = param.location?.provinceId;
    item.districtId = param.location?.districtId;
    item.contactName = param.location?.contactName;
    item.contactPhoneNumber = String(param.location?.contactPhoneNumber);
    item.contactFaxNumber = param.location?.contactFaxNumber;
    item.subdistrictId = param.location?.subdistrictId;
    item.zipCode = param.location?.posCode;
    item.latitude = '';
    item.longitude = '';
    item.contactEmail = param.location?.email;
    item.location = param.location?.location;
    item.reg = param.location?.reg;

    item.attachFileId = param.contact?.commandFile?.attachFileId;

    item.edcIdList = param.selectedEdcs.map(x => x.edcId);

    item.typeOfWorkId = param.typeOfWorkId;
    // item.eventTypeId = param.eventType?.id;
    item.eventTypeId = param.eventTypeId;
    item.sendToType = param.sendToType;
    item.workType = param.workType; 
    item.attachFileId = param.contact?.commandFile?.attachFileId;  
    item.attachFileIdList = param.attachFileIdList;
    item.isHq = param.location.isHq
    return item;
  }

}