import { Component, OnInit, ComponentRef, ViewChild, ComponentFactoryResolver, Type } from '@angular/core';
import { SwitchTabComponent } from 'src/app/shared/component/switch-tab.component';
import { OrderEdcBankHostViewModel, FeatureConnectorTypeViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { OrderEdcViewModel, OrderEdcInformationViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { OrderReprogramViewModel } from 'src/app/core/view-models/_order/order-reprogram.view-model';
import { EventEmitter } from 'protractor';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { AppOrderReadonlyShopDetailComponent } from 'src/app/features/user/order/_shared/readonly-shop-detail/readonly-shop-detail.component';
import { ActivatedRoute } from '@angular/router';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { OrderShareService } from 'src/app/core/services/order/order-share.service';
import { statusConst } from 'src/app/core/_static/status.const';
import { SharedUserReadonlyCommunicationDetailComponent } from 'src/app/features/user/shared-user/edc-view/shared-readonly-communication-detail/shared-readonly-communication-detail.component';
import { SharedUserReadonlyOrderDetailInformationComponent } from 'src/app/features/user/shared-user/edc-view/shared-readonly-order-detail-information/shared-readonly-order-detail-information.component';
import { SharedUserReadonlyFeatureConnectorComponent } from 'src/app/features/user/shared-user/edc-view/shared-readonly-feature-connector/shared-readonly-feature-connector.component';
import { SharedUserReadonlyShopDetailComponent } from 'src/app/features/user/shared-user/edc-view/shared-readonly-shop-detail/shared-readonly-shop-detail.component';
import { ShopViewModel } from 'src/app/core/view-models/_shop/shop.view-model';
import { orderConst } from 'src/app/core/_static/order.const';
import { SharedUserReadonlySingleShopComponent } from 'src/app/features/user/shared-user/edc-view/shared-readonly-single-shop/shared-readonly-single-shop.component';
import { SharedUserReadonlyMulthiShopComponent } from 'src/app/features/user/shared-user/edc-view/shared-readonly-multhi-shop/shared-readonly-multhi-shop.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-edc',
  templateUrl: './view-edc.component.html',
  styleUrls: ['./view-edc.component.scss']
})
export class WorkSheetViewEdcComponent implements OnInit, SwitchTabComponent {
  isValid(): boolean {
    throw new Error("Method not implemented.");
  }
  submit: boolean;
  data: number;
  edcId: number = 0 ;  
  constructor(
    public activeModal: NgbActiveModal, 
  ) {

  }

  ngOnInit(): void {
  }


}
