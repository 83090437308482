import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/core/http/loader.service';
import { UserProfileModel } from 'src/app/core/models/_shared/user.model';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/core/services/account.service';
import { ApiService } from 'src/app/core/http/api.service';
import { ApiPath } from 'src/app/core/http/api-path';
import { LayoutConfigService } from 'src/app/core/_base/layout/services/layout-config.service';
import { UserLayoutConfig } from 'src/app/core/_base/config/user-layout.config';
import * as objectPath from 'object-path';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-customer',
  templateUrl: './login-customer.component.html',
  styleUrls: ['./login-customer.component.scss']
})
export class LoginCustomerComponent implements OnInit {
  authForm: FormGroup;
  submitted = false;
  user$: UserProfileModel;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private loaderService: LoaderService,
    private accountService: AccountService,
    private apiService: ApiService,
    private layoutConfigService: LayoutConfigService,
  ) { }

  ngOnInit() {
    this.bindBodyCssClass();
    this.loaderService.disableAll();
    // use FormBuilder to create a form group
    this.authForm = this.fb.group({
      'username': ['', Validators.required],
      'password': ['', Validators.required],
      'Internaluser': [1]

    });
    this.loadDetail();
  }
  bindBodyCssClass() {
    this.layoutConfigService.loadConfigs(new UserLayoutConfig().configs);
    const layoutConfig = this.layoutConfigService.getConfig();
    const selfBodyClass = (objectPath.get(layoutConfig, 'self.body.class')).toString();
    const bodyClassLive = (objectPath.get(layoutConfig, 'self.body.class-live')).toString();
    if (environment.production && selfBodyClass && bodyClassLive) {
      layoutConfig.self.body.class = bodyClassLive;
    }
    if (layoutConfig.self.body.class) {
      document.body.classList.add(layoutConfig.self.body.class);
    }
  }
  async loadDetail() {
    this.authForm.patchValue({
      Internaluser: 1,
    });
  }
  get f() { return this.authForm.controls; }

  async submitForm() {
    this.submitted = true;
    if (this.authForm.invalid) {
      return;
    }
    const authFormValue = this.authForm.value;
    console.log(authFormValue);
    
    var isLoginValid = false;
    // if (authFormValue.Internaluser == true) {
    //   isLoginValid = await this.authService.loginByEmployee(authFormValue.username, authFormValue.password);
    // } else {
      isLoginValid = await this.authService.login(authFormValue.username, authFormValue.password,authFormValue.Internaluser);
    // }

    if (isLoginValid) {
      const permissions = await this.apiService.get<Array<string>>(ApiPath.auth.permissions);
      const isAdmin = permissions.find(f => f === 'PMS-IAD-01') !== undefined;
      const isUser = permissions.find(f => f === 'PMS-IUS-01') !== undefined;

      if (isUser && isAdmin) this.router.navigate(['/u/dashboard']);
      else if (isUser) this.router.navigate(['/u/dashboard']);
      else if (isAdmin) window.location.href = '/admin/dashboard';//this.router.navigate(['/admin/dashboard']);
    }

  }

}
