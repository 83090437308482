import { Component, OnInit, Input } from '@angular/core';
import { Information } from 'src/app/core/view-models/_sprint3/packing/task-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeSerialNumberComponent } from '../change-serial-number/change-serial-number.component';

@Component({
  selector: 'app-shared-view-task-information',
  templateUrl: './shared-view-task-information.component.html',
  styleUrls: ['./shared-view-task-information.component.scss']
})
export class SharedViewTaskInformationComponent implements OnInit {
  item: Information = new Information;
  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }
  async loadInitial(data: Information) {
    this.item = data
  }

  onChangeSNClick() {
    const modalRef = this.modalService.open(ChangeSerialNumberComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
  }

}
