export class SettingCalloutGetDetailViewModel {
    id: number;
    shopDetailInfo: SettingCalloutShopDetailInfo;
    worksheetInfo: SettingCalloutWorksheetInfo;
    edcs: SettingCalloutEdcs[] = [];
    shopNameTH: string;
    shopNameEN: string;
    shopAddress: string;
    province: Province;
    district: Province;
    subdistrict: Subdistrict;
    zipCode: string;
    latitude: string;
    longitude: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    contactEmail: string;
    location: string;
    reg: string;
    issue: string;
    workCommand: string;
    recommend: string;
    commandContactName: string;
    commandContactPhoneNumber: string;
    attachFile: AttachFile;
    convenientDate: string;
    convenientHour: number;
    convenientMinute: number;
    remark: string;
    onCallContactName: string;
    onCallPhoneNumber: string;
    status: Status = new Status;
    callAgainType: number;
    callAgainDate: Date;
    callAgainHour: number;
    callAgainMinute: number;
    operationDate:Date
    onsiteOperationDate :Date = null;
    onsiteOperationHour: number;
    onsiteOperationMinute: number;
  }
  
  export class AttachFile {
    id: number;
    originalName: string;
    storeName: string;
    mimeType: string;
    sizeInBytes: number;
    type: number;
    url: string;

    getFileSizeInKB(){
        return this.sizeInBytes / 1000;
    }
  }
  
  export class FileStream {
    canRead: boolean;
    canSeek: boolean;
    canTimeout: boolean;
    canWrite: boolean;
    length: number;
    position: number;
    readTimeout: number;
    writeTimeout: number;
  }
  
  export class Subdistrict {
    kind: string;
    id: number;
    nameTH: string;
    nameEN: string;
    latitude: number;
    longitude: number;
    zipCode: number;
  }
  
  export class Province {
    kind: string;
    id: number;
    nameTH: string;
    nameEN: string;
  }
  
  export class SettingCalloutEdcs {
    reprogramType: ReprogramType;
    kind: string;
    isChecked: boolean;
    orderNo: string;
    orderDate: string;
    typeOfWork: TypeOfWork;
    edcId: number;
    backup: string;
    endDate: string;
    installDate: string;
    main: string;
    mid: string;
    modelName: string;
    multhi: boolean;
    serialNumber: string;
    standAlondTid: string;
    startDate: string;
    status: Status;
    tid: string;
    remark: string;
    hasCanceled: boolean;
    canceledAt: string;
    canceledName: string;
  }
  
  export class TypeOfWork {
    id: number;
    code: string;
    nameTH: string;
    nameEN: string;
    name: string;
    kind: string;
  }
  
  export class ReprogramType {
    id: number;
    name: string;
    detail: string;
  }
  
  export class SettingCalloutWorksheetInfo {
    worksheetNo: string;
    worksheetDate: string;
    operationDate: string;
    assignBy: string;
    technicianName: string;
    status: Status;
    finishDate: string;
    call: string;
  }
  
  export class Status {
    
    id: number; 
    name: string;
  }
  
  export class SettingCalloutShopDetailInfo {
    shopCode: string;
    shopNameTH: string;
    shopNameEN: string;
    address: string;
    provinceName: string;
    districtName: string;
    subdistrictName: string;
    zipCode: string;
    callPhoneNumber: string;
    contactName: string;
    contactPhoneNumber: string;
    contactFaxNumber: string;
    email: string;
    orderNo:string;
    orderDate:Date;
  }