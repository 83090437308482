import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallHistoryModel, SearchCallHistoryModel, StatusTypeInbound } from 'src/app/core/models/user/help-desk/call-history-model';
import { CallHistoryService } from 'src/app/core/services/helpDesk/call-history.service';
import { statusConst } from 'src/app/core/_static/status.const';
import { AdminPaginatorComponent } from 'src/app/shared/layouts/_theme/_admin/table/paginator/paginator.component';
import { PageRequestModel, PageResponseModel } from 'src/app/core/models/_shared/page.model';
import { CallHistoryViewComponent } from '../call-history-view/call-history-view.component';
import { CommonService } from 'src/app/core/services/common.service';
import { statusworksheetConst } from 'src/app/core/_static/worksheet-worksheet.const';
import { EverythingWorksheetModalViewComponent } from 'src/app/features/user/worksheet/shared/everything-worksheet-modal-view/everything-worksheet-modal-view.component';
import { FilterListModal, FilterTPLComponent } from 'src/app/features/user/shared-sp3/filter-tpl/filter-tpl.component';
import { CallHistoryViewSignatureComponent } from '../call-history-view-signature/call-history-view-signature.component';

@Component({
  selector: "app-call-history",
  templateUrl: "./call-history.component.html",
  styleUrls: ["./call-history.component.scss"],
})
export class CallHistoryModalComponent implements OnInit {
  @ViewChild("pagination", { static: false })
  pagination: AdminPaginatorComponent;

  id: number;
  clientId: number;
  isFromSupport: boolean = false;
  items: CallHistoryModel[] = [];
  searchFilter: SearchCallHistoryModel;
  pageResult: PageResponseModel<CallHistoryModel>;
  pageRequest: PageRequestModel<SearchCallHistoryModel>;
  statusType: StatusTypeInbound[] = [];
  statusId: number;
  itemSearch: FilterListModal[]
  constructor(
    public activeModalClose: NgbActiveModal,
    private modalService: NgbModal,
    private callHistoryService: CallHistoryService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    // this.searchFilter = new SearchCallHistoryModel();
    this.pageRequest = PageRequestModel.createFirst(SearchCallHistoryModel);
    this.loadFilter();
    // this.preparePage();

  }
  async loadInitial() {
    this.statusType = await this.commonService.getStatusInbound();
    if (this.isFromSupport) {
      this.pageResult = await this.callHistoryService.searchBySupport(this.id, this.pageRequest);
    } else {
      this.pageResult = await this.callHistoryService.search(this.id, this.pageRequest);
    }

    // this.pageResult = response.pageResult;
    this.items = this.pageResult.items;
    this.pagination.setPageResult(this.pageResult);
    console.log(this.items)
  }
  viewClick(item: CallHistoryModel) {
    if (item.historyType == 1) {
      const modalRef = this.modalService.open(CallHistoryViewComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'lg'
      });
      modalRef.componentInstance.item = item.inboundEdc
    }
    if (item.historyType == 2) {
      const modalRef = this.modalService.open(EverythingWorksheetModalViewComponent, {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'xl'
      });
      modalRef.componentInstance.id = item.taskId;
      modalRef.componentInstance.onReturnData.subscribe(_ => { this.loadInitial(); })
    }

  }
  signaturePictureFileClick(item: CallHistoryModel) { 
    const modalRef = this.modalService.open(CallHistoryViewSignatureComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.item = item.signaturePictureFile
  }
  get statusStateConst() {
    return statusworksheetConst.stateStatus
  }
  get inbound() {
    return statusConst.inboundStatus;
  }
  get support() {
    return statusConst.supportInbound;
  }
  get m() {
    return statusConst.callModel;
  }
  get v() {
    return statusConst.actionStateModel;
  }
  colFilterChange(cols: any) {
    this.pageRequest.pageNumber = 1
    this.pageRequest.criteria.taskNo = cols.taskNo;
    this.pageRequest.criteria.serialNumber = cols.serialNumber;
    this.pageRequest.criteria.tid = cols.tid;
    this.pageRequest.criteria.openJobName = cols.openJobName;
    this.pageRequest.criteria.closeJobName = cols.closeJobName;
    this.loadInitial();
  }
  onPageChange(page: number) {
    this.pageRequest.pageNumber = page;
    this.loadInitial();
  }
  onPageSizeChange(pageSize: number) {
    this.pageRequest.pageSize = pageSize;
    this.loadInitial();
  }

  async loadFilter() {
    this.pageRequest.criteria.typeIdList = (await this.commonService.getFilterWorkType()).map(x => x.id);
    this.loadInitial();
  }
  async onClickWorkTypeFilter() {
    this.itemSearch = await this.commonService.getFilterWorkType();
    this.itemSearch.filter(x => this.pageRequest.criteria.typeIdList.includes(x.id)).forEach(x => x.isCheck = true);
    this.statusSearch(this.itemSearch, 2);
  }
  statusSearch(itemSearch: FilterListModal[], type: number) {
    const modalRef = this.modalService.open(FilterTPLComponent, {
      backdrop: "static", keyboard: false, size: 'xs'
    });
    modalRef.componentInstance.itemSearch = itemSearch;
    modalRef.componentInstance.onSelected.subscribe((item: FilterListModal[]) => {
      if (type == 2) { this.onPageFilterWorkType(item); }

    });
  }
  onPageFilterWorkType(item: FilterListModal[]) {
    this.pageRequest.criteria.typeIdList = item.map(x => x.id)
    this.loadInitial();
  }
}
