import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { ApiPath } from '../../http/api-path';
import { FeatureConnectorViewModel, OrderEdcPantancateFormatViewModel, FeatureConnectorTypeViewModel } from '../../view-models/_order/order-share.view-model';
import { FeatureConnectorModel, OrderEdcPantancateFormatModel } from '../../models/_order/order-shared.model';
import { FeatureMockService } from '../_mock/_order/feature.service.mock';

@Injectable({
    providedIn: 'root'
})
export class FeatureService {

    constructor(
        private apiService: ApiService,
        private _mock: FeatureMockService
    ) { }

    async features(): Promise<FeatureConnectorViewModel[]> {
        const result = await this.apiService.get<FeatureConnectorModel[]>(`${ApiPath.edcFeature.features}`);
        const items: FeatureConnectorViewModel[] = [];
        result.forEach(row => {
            const item = new FeatureConnectorViewModel();
            item.id = row.id;
            item.name = row.name;
            items.push(item);
        });
        return items;
    }

    async pantancates(): Promise<OrderEdcPantancateFormatViewModel[]> {
        const result = await this.apiService.get<OrderEdcPantancateFormatModel[]>(`${ApiPath.edcFeature.pantancates}`);
        return result;
    }

    async connectors(orderId: number, edcId: number): Promise<Array<FeatureConnectorTypeViewModel>> {
        const result = await this.apiService.get<FeatureConnectorTypeViewModel[]>(`/order/installs/${orderId}/edcs/${edcId}/connectors`);
        return result;
    }

    async connectorsByOrderType(orderId: number, edcId: number, type: number): Promise<Array<FeatureConnectorTypeViewModel>> {
        if (type == 1) {
            const result = await this.apiService.get<FeatureConnectorTypeViewModel[]>(`/order/installs/${orderId}/edcs/${edcId}/connectors`);
            return result;
        }
        if (type == 2) {
            const result = await this.apiService.get<FeatureConnectorTypeViewModel[]>(`/order/re-programs/${orderId}/edcs/${edcId}/connectors`);
            return result;
        }
        return null;
    }


    async reprogramConnectors(orderId: number, edcId: number): Promise<Array<FeatureConnectorTypeViewModel>> {
        const result = await this.apiService.get<FeatureConnectorTypeViewModel[]>(`/order/re-programs/${orderId}/edcs/${edcId}/connectors`);
        return result;
    }
}
