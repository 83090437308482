import { Injectable } from '@angular/core';
import { SetTypeApiModel } from '../../models/_sprint3/set-type-api-model';
import { SetTypeViewModel } from '../../view-models/_sprint3/worksheet/set-type-view-model';
import { Mapper } from '../mapper';

@Injectable({
    providedIn: "root",
  })
export class SetTypeMapper implements Mapper<SetTypeApiModel,SetTypeViewModel> {
    mapForm(param: SetTypeApiModel): SetTypeViewModel {
        return{
            ...param
        }
    }
    mapTo(param: SetTypeViewModel): SetTypeApiModel {
        return{
            ...param
        }    }
}
