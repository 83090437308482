import { Injectable } from '@angular/core';
import { SourceViewModel, InputTypeViewModel, TopicHeaderViewModel, InboundBankHostViewModel, InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { ResponsibleModel } from 'src/app/core/models/user/help-desk/inbound-t1-model';

@Injectable({
  providedIn: "root",
})
export class InboundSharedMockService {
  constructor(

  ) { }

  sources(): SourceViewModel[] {
    return [
      {
        id: 1,
        name: "Guest"
      },
      {
        id: 2,
        name: "Customer"
      },
      {
        id: 3,
        name: "Internal"
      },
      {
        id: 4,
        name: "Other"
      }
    ];
  }
 responsibles(): ResponsibleModel[] {
    return [
    ];
  }
  inputTypes(): InputTypeViewModel[] {
    return [
      {
        id: 1,
        name: "Call"
      },
      {
        id: 2,
        name: "Email"
      },
      {
        id: 3,
        name: "Line"
      },
      {
        id: 4,
        name: "Other"
      }
    ];
  }

  topicHeaders(parent: number): TopicHeaderViewModel[] {
    return [
      {
        id: 1,
        name: "Information",
        isShowExternalBankHost: true,
        showNextStep: true
      },
      {
        id: 2,
        name: "Problem",
        isShowExternalBankHost: true,
        showNextStep: true
      },
      {
        id: 3,
        name: "Complaint",
        isShowExternalBankHost: true,
        showNextStep: true
      }
    ];
  }
  externalBankHosts(): InboundBankHostViewModel[] {
    return [
      {
        id: 1,
        name: "UOB",
        remark: "",
        selected: false
      },
      {
        id: 2,
        name: "BKK",
        remark: "",
        selected: false
      },
      {
        id: 3,
        name: "KTB",
        remark: "",
        selected: false
      }
    ];
  }
  times(): InboundTimeSelectViewModel[] {
    return [
      {
        value: 1,
        label: "09:00"
      },
      {
        value: 2,
        label: "10:00"
      },
      {
        value: 3,
        label: "11:00"
      },
      {
        value: 4,
        label: "12:00"
      }
    ];
  }
}
