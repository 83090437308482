<app-view-shared-work-sheet-information #info> </app-view-shared-work-sheet-information>

<app-view-shared-shop-detail #shop> </app-view-shared-shop-detail>

<app-view-shared-serial-number-list-view #snList> </app-view-shared-serial-number-list-view>

<app-worksheet-location-view-shared #location></app-worksheet-location-view-shared>

<shared-app-worksheet-command #command [isReadOnly]="true"></shared-app-worksheet-command>

<app-worksheet-contact-view-shared #contact></app-worksheet-contact-view-shared>

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                ชื่อผู้ติดต่อ
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <form class="kt-form">
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">ชื่อ นามสกุล : </label>
                        <input type="text" class="form-control" [(ngModel)]="item.onCallContactName" disabled
                            [ngModelOptions]="{standalone: true}" placeholder="ชื่อ นามสกุล">
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">เบอร์โทร : </label>
                        <input type="text" class="form-control" [(ngModel)]="item.onCallPhoneNumber" disabled
                            [ngModelOptions]="{standalone: true}" placeholder="เบอร์โทร">
                    </div>
                </div>

            </div>
        </form>
    </div>
</div>

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Status Type
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <form class="kt-form">
            <div class="col-sm-12">
                <div class="row form-group">
                    <div class="padding10-left">
                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                            <input type="radio" name="inboundStatus{{settingcalloutStatus.unreachable}}"
                                [value]="settingcalloutStatus.unreachable" [(ngModel)]="item.status.id" disabled
                                [ngModelOptions]="{standalone: true}">โทรไม่ติด/ปิดเครื่อง /ไม่รับสาย
                            <span></span>
                        </label>
                    </div>
                    <div class="padding10-left">
                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                            <input type="radio" name="inboundStatus{{settingcalloutStatus.callAgain}}"
                                [value]="settingcalloutStatus.callAgain" [(ngModel)]="item.status.id" disabled
                                [ngModelOptions]="{standalone: true}">Call Again  
                            <span></span>
                        </label>
                    </div>

                    <div class="padding10-left">
                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                            <input type="radio" name="inboundStatus{{settingcalloutStatus.complete}}"
                                [value]="settingcalloutStatus.complete" [(ngModel)]="item.status.id" disabled
                                [ngModelOptions]="{standalone: true}">Complete
                            <span></span>
                        </label>
                    </div>

                    <div class="padding10-left">
                        <label class="kt-radio kt-radio--bold kt-radio--brand">
                            <input type="radio" name="inboundStatus{{settingcalloutStatus.OnSite}}"
                                [value]="settingcalloutStatus.OnSite" [(ngModel)]="item.status.id" disabled
                                [ngModelOptions]="{standalone: true}">On Site
                            <span></span>
                        </label>
                    </div>
                </div>
                <div class="form-group text-center" *ngIf="item.status.id == settingcalloutStatus.callAgain">
                    <div class="row">
                        <div class="col-5 text-right">
                            <div class="kt-radio-inline">
                                <label class="kt-radio kt-radio--bold kt-radio--brand">
                                    <input type="radio" name="callAgainType0" (click)="clearDate()"
                                        [checked]="item.callAgainType == null" [value]="0"
                                        [(ngModel)]="item.callAgainType" disabled
                                        [ngModelOptions]="{standalone: true}">{{ 'INBOUND_T1.NO_DATE' | translate}}
                                    <span></span>
                                </label>
                                <label class="kt-radio kt-radio--bold kt-radio--brand">
                                    <input type="radio" (click)="setDate()" name="callAgainType1" [value]="1"
                                        [(ngModel)]="item.callAgainType" disabled
                                        [ngModelOptions]="{standalone: true}">{{ 'INBOUND_T1.SPECIFY_DATE' | translate}}
                                    <span></span>
                                </label>
                            </div>
                            <div *ngIf="submit && (item.callAgainType == null || item.callAgainType == -1)"
                                class="required">
                                <div [translate]="'FORM.REQUIRED'"></div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <div class="input-group">
                                    <input readonly class="form-control" ngbDatepicker [minDate]="minDate"
                                        [ngClass]="{'class-readonly': item.callAgainType == 0}" disabled
                                        [(ngModel)]="item.callAgainDate" #callAgainDate="ngbDatepicker"
                                        [ngModelOptions]="{standalone: true}">
                                    <a class="input-group-append" href="javascript:;" *ngIf="item.callAgainType == 1">
                                        <div (click)="callAgainDate.toggle()" class="input-group-text"><i
                                                class="far fa-calendar-alt"></i></div>
                                    </a>
                                </div>
                                <div *ngIf="submit && item.callAgainType == 1 && !item.callAgainDate" class="required">
                                    <div [translate]="'FORM.REQUIRED'">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1 col-sm-2">
                            <div class="form-group">
                                <select class="form-control" [(ngModel)]="item.callAgainHour"
                                    [ngModelOptions]="{standalone: true}" disabled
                                    [ngClass]="{'class-readonly': item.callAgainType == 0}"
                                    [disabled]="item.callAgainType != 1">
                                    <option [value]="null" disabled>{{ 'INBOUND_T1.CHOOSE' | translate}}
                                    </option>
                                    <option *ngFor="let row of hours" [value]="row.value">{{row.label}}</option>
                                </select>
                                <div *ngIf="submit && item.callAgainType == 1 && !item.callAgainHour" class="required">
                                    <div [translate]="'FORM.REQUIRED'">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1 col-sm-2">
                            <div class="form-group">
                                <select class="form-control" [(ngModel)]="item.callAgainMinute"
                                    [ngModelOptions]="{standalone: true}" disabled
                                    [ngClass]="{'class-readonly': item.callAgainType == 0}"
                                    [disabled]="item.callAgainType != 1">
                                    <option [value]="null" disabled>{{ 'INBOUND_T1.CHOOSE' | translate}}
                                    </option>
                                    <option *ngFor="let row of minutes" [value]="row.value">{{row.label}}
                                    </option>
                                </select>
                                <div *ngIf="submit  && item.callAgainType == 1 && !item.callAgainMinute"
                                    class="required">
                                    <div [translate]="'FORM.REQUIRED'">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                Remark
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="row form-group">
            <div class="col-md-12">
                <div class="form-group mb-0">
                    <label class="text-primary">Remark</label>
                    <textarea rows="4" class="form-control" [(ngModel)]="item.remark" disabled
                        [ngModelOptions]="{standalone: true}" placeholder="Remark"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>