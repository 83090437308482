<form  [formGroup]="form">
    <div class="kt-portlet__body">
    
    <div class="tab-content">
        <div class="tab-pane active" id="task1" role="tabpanel">
           
<app-shared-store-view-task-information #information></app-shared-store-view-task-information>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.CURRENT_STATUS' | translate }}: </label>
                        <br>
                        <span class="kt-subheader__breadcrumbs-separator">  {{items.currentStatus?.name}} </span>

                    </div>
                </div>
                <div class="col-sm-9">
                    <div class="form-group mb-0" >
                        <label class="kt-font-primary">{{ '_SHARED.UPDATE_STATUS' | translate }}: </label>
                        <br>
                        <select class="form-control col-sm-3" [(ngModel)]="updateStatusId" [ngModelOptions]="{standalone: true}"(change)="onUpdateStatusSelect()" disabled
                                >
                                <option *ngFor="let item of updateStatus" [value]="item.id" >{{item.name}}</option>
                              </select>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.REMARK' | translate }}: </label>
                        <br>
                        <textarea class="form-control" formControlName="remark" rows="5" disabled></textarea>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
</form>