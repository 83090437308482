import { Injectable } from '@angular/core';
import { OrderInstallViewModel, OrderEdcViewModel, OrderEdcMainPatitionViewModel, OrderDetailEdcMulthiViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { orderConst } from 'src/app/core/_static/order.const';
import { OrderDetailEdcViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { SaveOrderInstallRequestModel } from 'src/app/core/models/_order/order-install.model';
import { Modal } from '@amcharts/amcharts4/core';
import { partition } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrderInstallMockService {
    async get(id: number): Promise<OrderInstallViewModel> {
        const model = new OrderInstallViewModel();
      //  model.information.lotNumber = "50512326";
        model.information.jobNumber = "50512326";
        model.shop.branchName = "branchName";
        model.shop.nameTH = "Shop Name (TH)";
        model.shop.nameEN = "Shop Name (EN)";
        model.eventType = orderConst.eventType.permanence;
        model.authorize.isApprove = true;
        model.authorize.isReject = true;
        model.authorize.isSave = true;
        model.authorize.isSubmit = true;
        console.log(model);
        return model;
    }

    async getEdc(id: number): Promise<OrderEdcViewModel> {
        const model = new OrderEdcViewModel();
        model.information.serialNumber = "1000000000";
        model.information.modelName = "Model";
        model.information.standAlondTID = "Stand Alone TID ";
        model.information.startDate = new Date('2020/02/02');
        model.information.endDate = null;
        model.information.installDate = new Date('2020/02/02');

        model.shop.branchName = "branchName";
        model.shop.nameTH = "Shop Name (TH)";
        model.shop.nameEN = "Shop Name (EN)";
        model.communicationBase.backup.communicate.name = 'GPS 3G/4G';
        model.communicationBase.backup.provider.name = 'DTAC'
        model.communicationBase.backup.phoneNumber.phoneNumber = '094-4586609';
        model.communicationBase.backup.phoneNumber.code = '1820042530723';

        model.communicationBase.main.communicate.name = 'Lan';
        model.communicationBase.main.ipAddress = '192.168.0.124';
        model.communicationBase.main.subnetMark = 'F8-A3-D7-C1-D3-B6';
        model.communicationBase.main.gateway = '192.168.0.154';

        model.feature.pantancateFormat1.name = '1111';
        model.feature.pantancateFormat2.name = '1111';
        model.feature.pantancateFormat3.name = '1111';
        model.feature.pantancateFormat4.name = '1111';
        model.feature.connectorType.name = 'Rabit (BSS)';
        model.feature.serialNumber = '16168WL24558586';
       /* model.multhi = [{
            partitions: [
                {
                    isMain: true,
                    terminalId: "50427089 ",
                    merchantId: "991009757",
                    bankHostId: 1,
                    bankHost: {
                        id: 1,
                        name: 'BBL',
                    },
                    locationId: "string",
                    location: "string",
                    sp: "string",
                    billerName: "billerName",
                    functions: [{
                        function: {
                            id: 1,
                            name: 'Adjust'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 2,
                            name: 'Authorize'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 3,
                            name: 'Off-Line'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 4,
                            name: 'Refund'
                        },
                        selected: true
                    }
                        , {
                        function: {
                            id: 5,
                            name: 'Tip'
                        },
                        selected: false
                    }
                        , {
                        function: {
                            id: 6,
                            name: 'Key In'
                        },
                        selected: false
                    }],
                    get valid(): boolean {
                        if (this.bankHostId == null) return true;
                        return (this.terminalId != "") && (this.merchantId != "");
                    }
                },
                {
                    isMain: true,
                    terminalId: "50427089 ",
                    merchantId: "991009757",
                    bankHostId: 1,
                    bankHost: {
                        id: 1,
                        name: 'BBL',
                    },
                    locationId: "string",
                    location: "string",
                    sp: "string",
                    billerName: "billerName",
                    functions: [{
                        function: {
                            id: 1,
                            name: 'Adjust'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 2,
                            name: 'Authorize'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 3,
                            name: 'Off-Line'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 4,
                            name: 'Refund'
                        },
                        selected: true
                    }
                        , {
                        function: {
                            id: 5,
                            name: 'Tip'
                        },
                        selected: false
                    }
                        , {
                        function: {
                            id: 6,
                            name: 'Key In'
                        },
                        selected: false
                    }],
                    get valid(): boolean {
                        if (this.bankHostId == null) return true;
                        return (this.terminalId != "") && (this.merchantId != "");
                    }
                }
            ],
            id: 1,
            code: "string",
            group: null,
            nameTH: "เทวา สนุก เมกา บางนา",
            nameEN: " TAVA SANUK MEGA BANGNA",
            address: "39 ศูนย์การค้าเมกาบางนา ชั้น2 ห้อง OP2062 ม.6 ถ.บางนา-ตราด ต.บางแก้ว อ.บางพลี สมุทรปราการ 10540",
            province: null,
            district: null,
            subDistrict: null,
            posCode: "string",
            contactName: "string",
            contactPhoneNumber: "string",
            contactFaxNumber: "string",
            email: "string",
            location: "string",
            reg: "string",
            branchName: "string",
            bankName: "string",
            bankPhoneNumber: "string"
        }
        ];
        model.partitions = [
            {
                isMain: true,
                checked: false,
                terminalId: "50427089 ",
                merchantId: "991009757",
                bankHostId: 1,
                bankHost: {
                    id: 1,
                    name: 'BBL',
                },
                locationId: "string",
                location: "string",
                sp: "string",
                billerName: "billerName",
                functions: [{
                    function: {
                        id: 1,
                        name: 'Adjust'
                    },
                    selected: true
                }, {
                    function: {
                        id: 2,
                        name: 'Authorize'
                    },
                    selected: true
                }, {
                    function: {
                        id: 3,
                        name: 'Off-Line'
                    },
                    selected: true
                }, {
                    function: {
                        id: 4,
                        name: 'Refund'
                    },
                    selected: true
                }
                    , {
                    function: {
                        id: 5,
                        name: 'Tip'
                    },
                    selected: false
                }
                    , {
                    function: {
                        id: 6,
                        name: 'Key In'
                    },
                    selected: false
                }],
                get valid(): boolean {
                    if (this.bankHostId == null) return true;
                    return (this.terminalId != "") && (this.merchantId != "");
                }
            }
        ];*/
        return model;
    }
    
    async getEdcNew(id: number): Promise<OrderEdcViewModel> {
        const model = new OrderEdcViewModel();
        model.information.serialNumber = "158623524";
        model.information.modelName = "Model Name";
        model.information.standAlondTID = "Stand Alone TID ";
        model.information.startDate = new Date('2020/02/03');
        model.information.endDate = null;
        model.information.installDate = new Date('2020/02/03');

        model.shop.branchName = "branchName";
        model.shop.nameTH = "Shop Name (TH)";
        model.shop.nameEN = "Shop Name (EN)";
        model.communicationBase.backup.communicate.name = 'GPS 3G/4G';
        model.communicationBase.backup.provider.name = 'DTAC'
        model.communicationBase.backup.phoneNumber.phoneNumber = '094-4586609';
        model.communicationBase.backup.phoneNumber.code = '1820042530723';

        model.communicationBase.main.communicate.name = 'Lan';
        model.communicationBase.main.ipAddress = '192.168.0.124';
        model.communicationBase.main.subnetMark = 'F8-A3-D7-C1-D3-B6';
        model.communicationBase.main.gateway = '192.168.0.154';

        model.feature.pantancateFormat1.name = '2222';
        model.feature.pantancateFormat2.name = '2222';
        model.feature.pantancateFormat3.name = '2222';
        model.feature.pantancateFormat4.name = '2222';
        model.feature.connectorType.name = 'Rabit (BSS)';
        model.feature.serialNumber = '16168WL24558586';
      /*  model.multhi = [{
            partitions: [
                {
                    isMain: true,
                    terminalId: "50427089 ",
                    merchantId: "991009757",
                    bankHostId: 1,
                    bankHost: {
                        id: 1,
                        name: 'BBL',
                    },
                    locationId: "string",
                    location: "string",
                    sp: "string",
                    billerName: "billerName",
                    functions: [{
                        function: {
                            id: 1,
                            name: 'Adjust'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 2,
                            name: 'Authorize'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 3,
                            name: 'Off-Line'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 4,
                            name: 'Refund'
                        },
                        selected: true
                    }
                        , {
                        function: {
                            id: 5,
                            name: 'Tip'
                        },
                        selected: false
                    }
                        , {
                        function: {
                            id: 6,
                            name: 'Key In'
                        },
                        selected: false
                    }],
                    get valid(): boolean {
                        if (this.bankHostId == null) return true;
                        return (this.terminalId != "") && (this.merchantId != "");
                    }
                },
                {
                    isMain: true,
                    terminalId: "50427089 ",
                    merchantId: "991009757",
                    bankHostId: 1,
                    bankHost: {
                        id: 1,
                        name: 'BBL',
                    },
                    locationId: "string",
                    location: "string",
                    sp: "string",
                    billerName: "billerName",
                    functions: [{
                        function: {
                            id: 1,
                            name: 'Adjust'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 2,
                            name: 'Authorize'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 3,
                            name: 'Off-Line'
                        },
                        selected: true
                    }, {
                        function: {
                            id: 4,
                            name: 'Refund'
                        },
                        selected: true
                    }
                        , {
                        function: {
                            id: 5,
                            name: 'Tip'
                        },
                        selected: false
                    }
                        , {
                        function: {
                            id: 6,
                            name: 'Key In'
                        },
                        selected: false
                    }],
                    get valid(): boolean {
                        if (this.bankHostId == null) return true;
                        return (this.terminalId != "") && (this.merchantId != "");
                    }
                }
            ],
            id: 1,
            code: "string",
            group: null,
            nameTH: "เทวา สนุก เมกา บางนา",
            nameEN: " TAVA SANUK MEGA BANGNA",
            address: "39 ศูนย์การค้าเมกาบางนา ชั้น2 ห้อง OP2062 ม.6 ถ.บางนา-ตราด ต.บางแก้ว อ.บางพลี สมุทรปราการ 10540",
            province: null,
            district: null,
            subDistrict: null,
            posCode: "string",
            contactName: "string",
            contactPhoneNumber: "string",
            contactFaxNumber: "string",
            email: "string",
            location: "string",
            reg: "string",
            branchName: "string",
            bankName: "string",
            bankPhoneNumber: "string"
        }
        ];
        model.partitions = [
            {
                isMain: true,
                checked: false,
                terminalId: "50427089 ",
                merchantId: "991009757",
                bankHostId: 1,
                bankHost: {
                    id: 1,
                    name: 'BBL',
                },
                locationId: "string",
                location: "string",
                sp: "string",
                billerName: "billerName",
                functions: [{
                    function: {
                        id: 1,
                        name: 'Adjust'
                    },
                    selected: true
                }, {
                    function: {
                        id: 2,
                        name: 'Authorize'
                    },
                    selected: true
                }, {
                    function: {
                        id: 3,
                        name: 'Off-Line'
                    },
                    selected: true
                }, {
                    function: {
                        id: 4,
                        name: 'Refund'
                    },
                    selected: true
                }
                    , {
                    function: {
                        id: 5,
                        name: 'Tip'
                    },
                    selected: false
                }
                    , {
                    function: {
                        id: 6,
                        name: 'Key In'
                    },
                    selected: false
                }],
                get valid(): boolean {
                    if (this.bankHostId == null) return true;
                    return (this.terminalId != "") && (this.merchantId != "");
                }
            }
        ];*/
        return model;
    }
    async save(id: number, item: OrderInstallViewModel): Promise<boolean> {
        return true;
    }
    async submit(id: number, item: OrderInstallViewModel): Promise<boolean> {
        return true;
    }
    async approve(id: number, item: OrderInstallViewModel): Promise<boolean> {
        return true;
    }
    async reject(id: number, item: OrderInstallViewModel): Promise<boolean> {
        return true;
    }
}