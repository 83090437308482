import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { statusworksheetConst } from 'src/app/core/_static/worksheet-worksheet.const';
import { LoaderViewModal0workAreaComponent } from 'src/app/features/user/shared-sp3/_modal/_loader/loader-view-modal0work-area/loader-view-modal0work-area.component';
import { PackingViewModalWorkAreaComponent } from 'src/app/features/user/shared-sp3/_modal/_packing/packing-view-modal-work-area/packing-view-modal-work-area.component';
import { SettingViewModalWorkAreaComponent } from 'src/app/features/user/shared-sp3/_modal/_setting/setting-view-modal-work-area/setting-view-modal-work-area.component';
import { StoreViewModalWorkAreaComponent } from 'src/app/features/user/shared-sp3/_modal/_store/store-view-modal-work-area/store-view-modal-work-area.component';

@Component({
  selector: 'app-shared-work-area-modal',
  templateUrl: './shared-work-area-modal.component.html',
  styleUrls: ['./shared-work-area-modal.component.scss']
})
export class SharedWorkAreaModalComponent implements OnInit {
  component:any
  items: any
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.dismiss();
  }
  get statusStateConst() {
    return statusworksheetConst.stateStatus
  }
  onViewClick(item) {
    if(item.itemType == 1){ //store
      this.component = StoreViewModalWorkAreaComponent
    }
    if(item.itemType == 2){  //setting
      this.component = SettingViewModalWorkAreaComponent
    }
    if(item.itemType == 3){  //loader
      this.component = LoaderViewModal0workAreaComponent
    }
    if(item.itemType == 4){ //packing
      this.component = PackingViewModalWorkAreaComponent
    } 
    console.log(item);
    const modalRef = this.modalService.open(this.component, {
      backdrop: "static",
      keyboard: false,
      size: 'width70'
    });
    modalRef.componentInstance.id = item.taskId;
  }
}
