import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StockControlEdcViewModel } from 'src/app/core/view-models/_sprint3/store/stock-control-edc';
import { AdminPaginatorComponent } from 'src/app/shared/layouts/_theme/_admin/table/paginator/paginator.component';
import { Subject } from 'rxjs';
import { StockControlBankListViewModel } from 'src/app/core/view-models/_sprint3/store/stock-control.model';
import { PageResponseModel, PageRequestModel } from 'src/app/core/models/_shared/page.model';
import { SearchStockControlEdcCriteriaViewModel } from 'src/app/core/models/_sprint3/stock/stock-control-edc';
import { FilterListModal } from '../../../filter-tpl/filter-tpl.component';
import { StockControlService } from 'src/app/core/services/_sprint3/store/stock-control.service';
import { CommonService } from 'src/app/core/services/common.service';
import { Sort } from '@angular/material/sort';
import { StatusConstSp3 } from 'src/app/core/_static/sp3-const/status-const-sp3';
import { ChangeSerialNumberService } from 'src/app/core/services/_sprint3/_shared/change-serial-number.service';

@Component({
  selector: 'app-search-edc-change-serial-number',
  templateUrl: './search-edc-change-serial-number.component.html',
  styleUrls: ['./search-edc-change-serial-number.component.scss']
})
export class SearchEdcChangeSerialNumberComponent implements OnInit {

 
  @Output() public onReturnData: EventEmitter<StockControlEdcViewModel> = new EventEmitter();
  @ViewChild("pagination", { static: false }) pagination: AdminPaginatorComponent;
  onUpDate: Subject<any> = new Subject();
 
  items: StockControlEdcViewModel[] = [];
  pageResult: PageResponseModel<StockControlEdcViewModel>;
  pageRequest: PageRequestModel<SearchStockControlEdcCriteriaViewModel>;
  id: number;
  itemSearch: FilterListModal[] = [];
  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal, 
    private changeSerialNumberService: ChangeSerialNumberService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.pageRequest = PageRequestModel.createFirst(SearchStockControlEdcCriteriaViewModel);

    this.loadDataList();
  }

  async loadDataList() {
    this.pageResult = await this.changeSerialNumberService.searchEdc(this.id, this.pageRequest);
    this.items = this.pageResult.items;
    this.pagination.setPageResult(this.pageResult);
  }
  onSort(sort: Sort) {
    this.pageRequest.pageNumber = 1
    this.pageRequest.setSort(sort.active, sort.direction);
    this.loadDataList();
  }
  colFilterChange(cols: any) {
    this.pageRequest.pageNumber = 1
    this.pageRequest.criteria.serialNumber = cols.serialNumber;
    this.pageRequest.criteria.assetsNumber = cols.assetsNumber;
    this.pageRequest.criteria.modelName = cols.modelName;
    this.pageRequest.criteria.tid = cols.tid;
    this.pageRequest.criteria.mid = cols.mid;
    this.pageRequest.criteria.shopName = cols.shopName;
    this.pageRequest.criteria.sapMaterialId = cols.sapMaterialId;
    this.loadDataList();
  }
  get type() {
    return StatusConstSp3.type
  }
  get status() {
    return StatusConstSp3.stockControlStatus
  } 

  onPageChange(page: number) {
    this.pageRequest.pageNumber = page;
    this.loadDataList();
  }
  onPageSizeChange(pageSize: number) {
    this.pageRequest.pageSize = pageSize;
    this.loadDataList();
  }
  onGetData(item: StockControlEdcViewModel) {
    this.onReturnData.emit(item);
    this.onCloseModal();
  }
  onCloseModal() {
    this.activeModal.dismiss()
  }
}
