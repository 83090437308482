import { OrderEdcInformationModel, CommunicationMainModel, CommunicationProviderModel, CommunicationPhoneNumberModel, FeatureConnectorModel, ConnectorTypeModel, PantancateFormatModel, OrderCustomerModel } from './order-shared.model';
import { SaveShopRequestModel, ClientViewModel } from '../_shop/shop.model';
import { OrderEdcBankHostViewModel } from '../../view-models/_order/order-share.view-model';

export class SaveOrderInstallRequestModel {
    information: SaveOrderInformationRequestModel;
    eventType: number;
    shop: SaveShopRequestModel;
    remark: string;
    actionState: number;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    installDate: string;
    startPromotionDate: string;
    endPromotionDate: string;
}

export class SaveOrderImportMultiRequestModel {
   
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
     
    partitions: PartitionRequestModel[] = [];
}
export class SaveOrderReprogramModel {
    reProgramDate: Date;
    clientId: number;
    remark: string;
    actionState: number;
    information: SaveOrderInformationRequestModel = new SaveOrderInformationRequestModel();
    isJoin: boolean = false;
}



export class SaveOrderInformationRequestModel {
    jobNumber: string;
    lot: string;
    buId: number;
    branchId: number;
    officeCode: string;
    isUrgent: boolean;
    isVip: boolean;
    branchName: string;
    buName: string;
    bc: string;
    pfCode: string;
    officer: string;
    nii: string;
    attachFileId: number;
    hasLogo: boolean;
    logoPictureFileId: number;
    requestDate:Date
}
export class SaveOrderEdcInstallRequestModel {
    serialNumber: string;
    installDate: string;
    startPromotionDate: string;
    endPromotionDate: string;
    standaloneTId: string;
    placeLocation: string;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    reg: string;
    mainCommunication: MainCommunicationRequestModel;
    backupCommunication: MainCommunicationRequestModel;
    edcFeatureIdList: number[] = [];
    pantancateFormatId1: number;
    pantancateFormatId2: number;
    pantancateFormatId3: number;
    pantancateFormatId4: number;
    pantancateFormatId5: number;
    pantancateFormatId6: number;
    pantancateFormatId7: number;
    pantancateFormatId8: number;
    pantancateFormatOther: string;
    pantancateFormatOther2: string;  
    placeInLocation : string 
    edcConnectorId: number;
    edcConnectorSerialNumber: string;
    shop: SaveShopRequestModel;
    remark: string;
    detail: string;
    partitions: PartitionRequestModel[] = [];
    connectors: OrderConnectorTypeModel[] = [];
    checkList: OrderCheckListModel[] = [];
    edcInventoryId:number
}

export class OrderCheckListModel {
    id: number;
    remark: string;
}
export class PartitionRequestModel {
    isMain: boolean;
    terminalId: string;
    merchantId: string;
    bankHostId: number;
    functionIdList: number[] = [];
    billerName: string;
    location: string;
    locationId: string;
    sp: string;
}

export class MainCommunicationRequestModel {
    type: number;
    value: string;
    ipAddress: string;
    subnetMark: string;
    gateway: string;
    providerId: number;
    isUsePhoneNumber: boolean;
    simId: number;
}

export class OrderEdcDetailModel {
    eventType: number;
    id?: number;
    oldEdcId?: number;
    information: OrderEdcInformationModel = new OrderEdcInformationModel();
    shop: ClientViewModel = new ClientViewModel();
    communication: OrderEdcCommunicationModel = new OrderEdcCommunicationModel();
    features: OrderEdcFeaturesModel = new OrderEdcFeaturesModel();
    customer: OrderCustomerModel = new OrderCustomerModel();
    connectors: OrderConnectorTypeModel[] = [];
    remark: string;
    detail: string;
    checkList: OrderCheckListViewModel[] = [];
    partitions: OrderEdcPartitionModel[] = [];
    multiClients: OrderDetailEdcMulthiModel[] = [];
    orderId: number; 
    orderTypeId: number;
}
export class OrderCheckListViewModel{
    id : number;
    name: string;
    isChecked : boolean;
    remark : string;
 }
export class OrderConnectorTypeModel {
    connectorId: number;
    connectorName: string;
    serialNumber: string;
    remark: string;
    inventoryId: number;
    quantity:number;
    measure : number;
}
export class OrderEdcCommunicationModel {
    main: MainCommunicationModel = new MainCommunicationModel();
    backup: MainCommunicationModel = new MainCommunicationModel();
}
export class OrderEdcFeaturesModel {
    features: FeatureConnectorModel[] = [];
    serialNumber: string;
    pantancateFormatOther: string;
    pantancateFormatOther2: string;
    connectorType: ConnectorTypeModel;
    pantancateFormat1: PantancateFormatModel;
    pantancateFormat2: PantancateFormatModel;
    pantancateFormat3: PantancateFormatModel;
    pantancateFormat4: PantancateFormatModel;
    pantancateFormat5: PantancateFormatModel;
    pantancateFormat6: PantancateFormatModel;
    pantancateFormat7: PantancateFormatModel;
    pantancateFormat8: PantancateFormatModel;
}
export class MainCommunicationModel {
    type: number;
    communicationId: number;
    communication: CommunicationMainModel;
    ipAddress: string;
    subnetMark: string;
    gateway: string;
    providerId: number;
    provider: CommunicationProviderModel;
    hasPhoneNumber: boolean;
    simId: number;
    phonNumber: CommunicationPhoneNumberModel;
    value: string;
}

export class OrderEdcPartitionModel {
    kind: string;
    isMain: boolean;
    terminalId: string;
    merchantId: string;
    bankHost: OrderEdcBankHostViewModel;
    functions: FeatureConnectorModel[];
    billerName: string;
    location: string;
    locationId: string;
    sp: string;
}

export class OrderDetailEdcMulthiModel extends ClientViewModel {
    clientId: number;
    MainMid: number;
    MainTid: number;
    partitions: OrderEdcPartitionModel[] = [];
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    sequence: number;
}