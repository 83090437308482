<app-shared-view-task-information #taskInformation></app-shared-view-task-information>

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                รายการตรวจสอบข้อมูลการ  {{'_SHARED.LOAD_PROGRAM' | translate}}
               <!-- {{ '_SHARED.ASSESMENT_FORM' | translate }} -->
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="table-responsive">
            <!--                                                                <div class="table-responsive" style="max-height: calc(100vh - 296px);overflow-y: auto;">-->
            <table class="table table-striped- table-bordered table-hover table-checkable " id="kt_table_1">
                <thead>
                    <tr align="center">
                        <th width="5%" nowrap=""></th>
                        <th width="5%" nowrap="">{{'_SHARED.NO' | translate}}</th>
                        <th nowrap="" rowspan="2">{{'_SHARED.INSPECTION_TOPICS' | translate}}</th>
                        <!-- <th width="5%" nowrap=""> {{ '_SHARED.NO' | translate }}</th>
                        <th nowrap="" rowspan="2">{{ '_SHARED.ASSESMENT_TOPIC' | translate }}</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of item.checkList; let i = index">
                        <td nowrap="" class="text-center">
                            <label class="kt-checkbox kt-checkbox--bold kt-checkbox--brand">
                                <input type="checkbox" checked="" [(ngModel)]="row.isChecked" disabled>
                                <span></span>
                            </label>
                        </td>
                        <td nowrap="" class="text-center">{{i+1}}</td>

                        <td nowrap="">{{row.topicName}}
                        </td> 
                    </tr> 
                </tbody>
            </table>
        </div>
    </div>
</div>

<br>
<div class="row"[formGroup]="formValidate">
    <div class="col-sm-12">
        <div class="form-group mb-0">
            <label class="kt-font-primary">{{ '_SHARED.REMARK' | translate }}: </label>
            <br>
            <textarea class="form-control" rows="5" formControlName="remark" disabled></textarea>
        </div>
    </div>
</div>