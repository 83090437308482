import { Component, OnInit } from '@angular/core';
import { WorksheetLocaltionViewModel } from 'src/app/core/models/user/help-desk/worksheet-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProvinceModel, DistrictModel, SubdistrictModel } from 'src/app/core/models/_shared/fix.model';
import { InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { Subject } from 'rxjs';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { HdcWorksheetService } from 'src/app/core/services/helpDesk/worksheet.service';
import { MapComponent } from '../map/map.component';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent implements OnInit {

  id: number;
  worksheetId: number;
  item: WorksheetLocaltionViewModel = new WorksheetLocaltionViewModel();
  localtionValidate: FormGroup;
  
  onSaved: Subject<any> = new Subject();

  constructor(
    private modalService: NgbModal,
    private dialogService: MyDialogService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private _sharedService: InboundSharedService,
    public activeModalClose: NgbActiveModal,
    private _service: HdcWorksheetService
  ) { }

  ngOnInit(): void {
     
    this.loadInitial();
  }
  async loadInitial() { 
    this.item = await this._service.get(this.id, this.worksheetId);
     
  }
   
 
  OpenModalMap() {
    const modalRef = this.modalService.open(MapComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'xl'
    });
  }
 
   
  onClose(){ 
    this.activeModalClose.dismiss();
  } 
}
