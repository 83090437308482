export const ApiPath = {
    auth: {
        login: '/auth/login',
        loginByEmployee: '/auth/emp-login',
        logout: '/auth/logout',
        refreshToken: '/auth/refreshtoken',
        permissions: '/auth/permissions'
    },
    admin: {
        dashboard:{
            userSummary: '/dashboards/admin/user-roles'
        }
    },
    account: {
        profile: '/account/profile',
        notifications: '/account/notifications',
    },
    clientGroup:{
        base: '/client-groups',
        location: '/client-groups',
    },
    common: {
        base: '/commons',
        edcCommunication:'/commons/edc-communications',
        providers: '/commons/mobile-providers'
    },
    edcFeature:{
        features: '/edc-features',
        pantancates: '/commons/pantancate-formats'
    },
    uploads: {
        uploadFile: '/files/upload'
    },
    location:'/shop-locations'

}
