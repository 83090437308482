<div class="modal-header">
    <h4 class="modal-title">{{ '_SHARED.HISTORY_DETAIL' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-custom">
    <div>
        <div class="kt-portlet kt-portlet--mobile" data-ktportlet="true">
            <div class="kt-portlet__head kt-portlet__head--lg">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        {{ '_SHARED.TOPIC_TYPE' | translate}}
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div class="kt-form">
                    <div class="row form-group col-lg-12">
                        <div class="row col-lg-6">
                            <div class="col-4 text-right">
                                <label>{{ '_SHARED.HEADER_1' | translate}} :</label>
                            </div>
                            <div class="col-8 text-left">
                                <span class="kt-font-info">{{item?.topic1?.name}}</span>
                            </div>
                        </div>
                        <div class="row col-lg-6">
                            <div class="col-4 text-right">
                                <label>{{ '_SHARED.HEADER_2' | translate}} :</label>
                            </div>
                            <div class="col-8 text-left">
                                <span class="kt-font-info">{{item?.topic2?.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group col-lg-12">
                        <div class="row col-lg-6">
                            <div class="col-4 text-right">
                                <label>{{ '_SHARED.HEADER_3' | translate}} :</label>
                            </div>
                            <div class="col-8 text-left">
                                <span class="kt-font-info">{{item?.topic3?.name}}</span>
                            </div>
                        </div>
                        <div class="row col-lg-6">
                            <div class="col-4 text-right">
                                <label>{{ '_SHARED.HEADER_4' | translate}} :</label>
                            </div>
                            <div class="col-8 text-left">
                                <span class="kt-font-info">{{item?.topic4?.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    {{ '_SHARED.TICKET_DETAIL' | translate}}
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-form">
                <div class="form-group">
                    <div class="row col-lg-12">
                        <div class="col-2 text-right">
                            <label>{{ '_SHARED.SOLUTION' | translate }} :</label>
                        </div>
                        <div class="text-left">
                            <span class="kt-font-info">{{item.detail}}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row col-lg-12">
                        <div class="col-2 text-right">
                            <label>{{ '_SHARED.ISSUE' | translate }} :</label>
                        </div>
                        <div class="text-left">
                            <span class="kt-font-info">{{item.problem}}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row col-lg-12">
                        <div class="col-2 text-right">
                            <label>{{ '_SHARED.SOLUTION' | translate }} :</label>
                        </div>
                        <div class="text-left">
                            <span class="kt-font-info">{{item.solution}}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row col-lg-12">
                        <div class="col-2 text-right">
                            <label>{{ '_SHARED.REMARK' | translate }} :</label>
                        </div>
                        <div class="text-left">
                            <span class="kt-font-info">{{item.remark}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-portlet kt-portlet--mobile">
        <div class="kt-portlet__head kt-portlet__head--lg">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    {{ '_SHARED.STATUS' | translate}}
                </h3>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-portlet__body">
                <div class="kt-form">
                    <div class="form-group">
                        <div class="row col-lg-12">
                            <div class="col-2 text-right">
                                <label>{{ '_SHARED.STATUS' | translate}} :</label>
                            </div>
                            <div class="text-left">
                                <span class="kt-font-info">{{item.status?.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="text-center btn btn-outline-dark" (click)="activeModal.dismiss()"
        [translate]="'BUTTON.CLOSE'"></button>
</div>