import { OrderInformationViewModel, OrderDetailViewModel, FeatureConnectorViewModel, OrderEdcPantancateFormatViewModel, FeatureConnectorTypeViewModel, OrderEdcBankHostTypeViewModel, OrderEdcBankHostFunctionViewModel, CommunicationMainViewModel, CommunicationProviderViewModel, CommunicationPhoneNumberViewModel, OrderAuthorizeViewModel, OrderCustomerViewModel, OrderDetailEdcViewModel } from './order-share.view-model';
import { ShopViewModel } from '../_shop/shop.view-model';
import { Subject } from 'rxjs';
import { TypeOfWork } from '../_shared/worksheet.view-model';
import { OrderEdcDetailModel, SaveOrderEdcInstallRequestModel } from '../../models/_order/order-install.model';

export class OrderInstallViewModel {
    id: number;
    reProgramDate: Date;
    removeDate: Date;
    information: OrderInformationViewModel = new OrderInformationViewModel();
    shop: ShopViewModel = new ShopViewModel();
    eventType: number;
    orderDetail: OrderDetailViewModel = new OrderDetailViewModel();
    remark: string;
    authorize: OrderAuthorizeViewModel = new OrderAuthorizeViewModel();
    customer: OrderCustomerViewModel = new OrderCustomerViewModel();
}

export class OrderInstallSummaryViewModel {
    id: number;
    information: OrderInformationViewModel = new OrderInformationViewModel();
    shop: ShopViewModel = new ShopViewModel();
    eventType: number;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    installDate: Date;
    startDate: Date;
    endDate: Date;
    edcs: OrderDetailEdcSummaryViewModel[] = [
        new OrderDetailEdcSummaryViewModel
    ];
    functions: OrderEdcBankHostTypeViewModel[] = [];
    remark: string;
}
export class OrderDetailEdcSummaryViewModel {
    edcId: number;
    serialNumber: string;
    installDate: Date;
    startDate: Date;
    endDate: Date;
    modelName: string;
    standAlondTID: string;
    TID: string;
    MID: string;
    multhi: boolean;
    main: string;
    status: number;
    backup: string;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    functions: OrderEdcBankHostFunctionViewModel[] = [];
    information: OrderEdcInformationViewModel = new OrderEdcInformationViewModel();
    communicationBase: OrderEdcCommunicationBaseViewModel = new OrderEdcCommunicationBaseViewModel();
    feature: OrderEdcFeatureConnectorViewModel = new OrderEdcFeatureConnectorViewModel();
    partitions: OrderEdcMainPatitionViewModel[] = [
        new OrderEdcMainPatitionViewModel,
        new OrderEdcMainPatitionViewModel
    ];
    multhis: OrderDetailEdcMulthiViewModel[] = [];
    remark: string;
}

export class OrderEdcInformationViewModel {
    // edcId: number;
    eventType: number;
    serialNumber: string;
    installDate: Date;
    startDate: Date;
    endDate: Date;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    modelName: string;
    location: string;
    REG: string;
    reg : string;
    placeInLocation : string
    standAlondTID: string;
    edcInventoryId: number;
}

export class OrderEdcCommunicationViewModel {
    communicate: CommunicationMainViewModel;
    communication: CommunicationMainViewModel;
    ipAddress: string;
    subnetMark: string;
    gateway: string;
    provider: CommunicationProviderViewModel;
    hasPhoneNumber: boolean;
    phoneNumber: CommunicationPhoneNumberViewModel;
    phonNumber: CommunicationPhoneNumberViewModel;
    other: string;
    value: string;
}
export class ImportEdcCommunicationViewModel {
    communicationId:number;
    communicate: CommunicationMainViewModel;
    ipAddress: string;
    subnetMark: string;
    gateway: string;
    providerId:number;
    provider: CommunicationProviderViewModel;
    isUsePhoneNumber:boolean;
    simId:number;
    value:string;
    hasPhoneNumber: boolean;
    phoneNumber: CommunicationPhoneNumberViewModel;
    other: string;
}
export class OrderEdcCommunicationBaseViewModel {
    main: OrderEdcCommunicationViewModel = new OrderEdcCommunicationViewModel();
    backup: OrderEdcCommunicationViewModel = new OrderEdcCommunicationViewModel();
}


export class OrderEdcFeatureConnectorViewModel {
    features: FeatureConnectorViewModel[] = [];
    pantancateFormat1: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat2: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat3: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat4: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat5: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat6: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat7: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    pantancateFormat8: OrderEdcPantancateFormatViewModel = new OrderEdcPantancateFormatViewModel;
    connectorType: FeatureConnectorTypeViewModel = new FeatureConnectorTypeViewModel;
    serialNumber: string;
    pantancateFormatOther: string;
    pantancateFormatOther2: string;
    connectorItems: FeatureConnectorTypeViewModel[] = [];
}

export class OrderEdcMainPatitionViewModel {
    isMain: boolean;
    checked: boolean;
    terminalId: string = "";
    merchantId: string = "";
    bankHostId: number;
    bankHost: OrderEdcBankHostTypeViewModel;
    locationId: string;
    location: string;
    sp: string;
    billerName: string;
    functions: OrderEdcBankHostFunctionViewModel[] = [];
    get validTerminal(): boolean {
        return (this.terminalId != "") && this.validLengthTerminal;
    }
    get validLengthTerminal(): boolean{
        return this.terminalId.length == 8;
    }
    get validMerchant(): boolean {
        return (this.merchantId != "") && this.validLengthMerchant;
    }
    get validLengthMerchant(): boolean{
        return this.merchantId.length <= 15;
    }
    get valid(): boolean {
        if (this.bankHostId == null) return false;
        let validateFunction = this.functions.filter(x => x.selected).length > 0;
        return this.validTerminal && this.validMerchant &&  validateFunction;
    }
}

export class OrderDetailEdcMulthiViewModel {
    multiId: number;
    MainMid: number;
    MainTid: number;
    partitions: OrderEdcMainPatitionViewModel[] = [];
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    sequence: number;
    callbackSavePartition: Subject<boolean> = new Subject<boolean>();
}

export class ImportOrderDetailEdcMulthiViewModel {
    multiId: number;
    MainMid: number;
    MainTid: number;
    partitions: OrderEdcMainPatitionViewModel[] = [];
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    sequence: number;
    callbackSavePartition: Subject<boolean> = new Subject<boolean>();
    shop: ShopViewModel = new ShopViewModel();
    information: OrderEdcInformationViewModel = new OrderEdcInformationViewModel();
}

export class getEdcNewViewModel{
    clientId:number
    edcId:number
}
export class OrderEdcNewApisModel{
    installId:number;
    clientId:number;
    edcId:number;
    edcDetail:OrderEdcDetailModel = new OrderEdcDetailModel
}

export class OrderEdcNewViewModel{
    installId:number;
    clientId:number;
    edcId:number;
    edcDetail:OrderEdcViewModel = new OrderEdcViewModel
}
export class SaveTempEdcNewViewModel{ 
    clientId:number;
    edcId:number;
    edcDetail:OrderEdcViewModel = new OrderEdcViewModel
}
export class SaveTempEdcNewApisModel{ 
    clientId:number;
    edcId:number;
    edcDetail:SaveOrderEdcInstallRequestModel = new SaveOrderEdcInstallRequestModel
}
export class OrderEdcViewModel { 
    id:number;
    oldEdcId?:number;
    eventType: number;
    shop: ShopViewModel = new ShopViewModel();
    information: OrderEdcInformationViewModel = new OrderEdcInformationViewModel();
    communicationBase: OrderEdcCommunicationBaseViewModel = new OrderEdcCommunicationBaseViewModel(); 
    feature: OrderEdcFeatureConnectorViewModel = new OrderEdcFeatureConnectorViewModel();
    customer: OrderCustomerViewModel = new OrderCustomerViewModel();
    partitions: OrderEdcMainPatitionViewModel[] = [];
    multhi: OrderDetailEdcMulthiViewModel[] = [];
    connectors: OrderConnectorTypeViewModel[] = [];
    checkList: OrderCheckListModel[] = [];
    remark: string;
    detail: string; 
 
    orderId: number; 
    orderTypeId: number;
} 
export class ImportOrderEdcViewModel {
    typeOfWork:TypeOfWork;
    edcId:number;
    orderId:number;
    id:number;
    itemId:number;
    oldEdcId?:number;
    eventType: number;
    shop: ShopViewModel = new ShopViewModel();
    information: OrderEdcInformationViewModel = new OrderEdcInformationViewModel();
    communicationBase: OrderEdcCommunicationBaseViewModel = new OrderEdcCommunicationBaseViewModel();
    communication: OrderEdcCommunicationBaseViewModel = new OrderEdcCommunicationBaseViewModel();
    features: OrderEdcFeatureConnectorViewModel = new OrderEdcFeatureConnectorViewModel();
    feature: OrderEdcFeatureConnectorViewModel = new OrderEdcFeatureConnectorViewModel();
    customer: OrderCustomerViewModel = new OrderCustomerViewModel();
    partitions: OrderEdcMainPatitionViewModel[] = [];
    multhi: OrderDetailEdcMulthiViewModel[] = [];
    connectors: OrderConnectorTypeViewModel[] = [];
    checkList: OrderCheckListModel[] = [];
    remark: string;
    detail: string; 
    hasOldEdcFeature:boolean
} 
export class OrderImportEdcViewModel {
    id:number;
    lineSlip1: string;
    lineSlip2: string;
    lineSlip3: string;
    oldEdcId?:number;
    eventType: number;
    shop: ShopViewModel = new ShopViewModel();
    information: OrderEdcInformationViewModel = new OrderEdcInformationViewModel();
    communicationBase: OrderEdcCommunicationBaseViewModel = new OrderEdcCommunicationBaseViewModel();
    feature: OrderEdcFeatureConnectorViewModel = new OrderEdcFeatureConnectorViewModel();
    customer: OrderCustomerViewModel = new OrderCustomerViewModel();
    partitions: OrderEdcMainPatitionViewModel[] = [];
    multhi: OrderDetailEdcMulthiViewModel[] = [];
    connectors: OrderConnectorTypeViewModel[] = [];
    checkList: OrderCheckListModel[] = [];
    remark: string;
    detail: string;
} 
export class OrderCheckListModel {
    id: number;
    name: string;
    isChecked: boolean;
    remark: string;
}
export class OrderConnectorTypeViewModel{
    connectorId: number;
    connectorName: string;
    serialNumber: string;
    remark: string;
    inventoryId: number;
    quantity:number;
    measure : number; 
}
export class CancelRemark{ 
    remark: string;
}
 
export class CaseViewModel{ 
    case:Case;
    remark: string;
}
export class Case{ 
    id:number;
    name: string;
}