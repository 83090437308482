import { WorksheetInformationViewModel, WorksheetShopDetailViewModel, WorksheetLocationViewModel, WorksheetCommandViewModel } from '../_shared/worksheet-information-view-model';
import { Status } from 'src/app/core/models/user/help-desk/inbound-t1-model';
import { MechanicModel } from './worksheet-model';
import { AttachFile, EdcCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { SettingCalloutShopDetailInfo, SettingCalloutWorksheetInfo } from '../_setting/setting-callout-edit-model';
 

 
export class WorksheetListViewModel {
  worksheetNo: string;
  worksheetDate: string;
  operationDate: string;
  assignByName: string;
  technicianName: string;
  statusName: string;
  finishDateTime: string;
  phoneNumber: string;
}
  
 
export class SearchWorksheetListViewModel {
  worksheetNo: string = ''; 
  assignByName: string = ''; 
  technicianName: string = ''; 
  phoneNumber: string = ''; 
}


export class WorksheetCloseJobViewModel{
  id:number;
  worksheetInformation:SettingCalloutWorksheetInfo;
  shopDetail: SettingCalloutShopDetailInfo;
  location: WorksheetLocationViewModel;
  command: WorksheetCommandViewModel = new WorksheetCommandViewModel;
  mechanic:  MechanicModel = new MechanicModel;
  contactName: string;
  contactPhone: string;
  serviceDate: Date = new Date;
  serviceHour: number = null;
  serviceMinute: number = null;
  convenientDateNow: Date = new Date;
  attachFile: AttachFile;
  edcList : EdcCloseJobApis[] = [];
  attachFiles: AttachFile[] = [];
  worksheetRemark:string
  onsiteAttachFileIdList: number[]=[];
  onsiteAttachFiles: AttachFile[] = [];


  contactReviseName: string;
  contactRevisePhone: string;
  serviceReviseDate: Date = new Date;
  serviceReviseHour: number = null;
  serviceReviseMinute: number = null;
  reviseRemark: string
}

export class WorksheetCloseJobSNListViewModel {
  id:string;
  serialNumber:string;
  tid:string;
  mid:string;
  installDate:string;
  shopName:string; 
  multhi:boolean = false;
  main:string;
  backup:string;
  status:Status;
  remark:string; 
}