<div class="row">
    <div class="col-xl-1"></div>
    <div class="col-xl-10">
        <div class="kt-timeline-v1 kt-timeline-v1--justified">
            <div class="kt-timeline-v1__items" *ngFor="let data of items">
                <div class="kt-timeline-v1__marker"></div>
                <div class="kt-timeline-v1__item kt-timeline-v1__item--first">
                    <div class="kt-timeline-v1__item-circle">
                        <div class="kt-bg-danger"></div>
                    </div>
                    <span class="kt-timeline-v1__item-time kt-font-brand" style="width: 100%;">
                        {{data.taskDate | date:'dd/MM/yyyy'}}<span>{{data.taskDate | date:'HH:mm'}}</span> <span
                            *ngIf="data.isCurrent" class="isCurrent">( ปัจจุบัน )</span>
                    </span>
                    <div class="kt-timeline-v1__item-content card-border">
                        <div class="kt-timeline-v1__item-title">
                            <b>{{data.taskName}}</b>
                        </div>
                        <div class="kt-timeline-v1__item-body">
                            <h6>{{data.actorName}}</h6>
                            <h6>{{data.actionEmployeeName}}</h6>
                            <label class="kt-font-primary" *ngIf="data.finishDateTime">Finish DateTime : </label>
                            <span class="kt-subheader__breadcrumbs-separator">
                                {{data.finishDateTime | date:'dd/MM/yyyy HH:mm'}}
                            </span> <br>
                            <label class="kt-font-primary" *ngIf="data.remark">Remark : </label>
                            <span class="kt-subheader__breadcrumbs-separator">{{data.remark}} </span>
                        </div>
                        <div class="kt-timeline-v1__item-actions" *ngIf="data.hasMoreMaterialDetail">
                            <a href="javascript:;" (click)="OnViewRemarkClick(data)"
                                class="btn btn-sm btn-label-brand btn-bold">{{'_SHARED.READMORE' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-1"></div>
</div>