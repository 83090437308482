import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-worksheet-detail-by-mobile',
  templateUrl: './worksheet-detail-by-mobile.component.html',
  styleUrls: ['./worksheet-detail-by-mobile.component.scss']
})
export class WorksheetDetailByMobileComponent implements OnInit {

  remark:string;
  detail:string;

  constructor(
    public activeModalClose: NgbActiveModal, 
  ) {}

  ngOnInit(): void {
    // this.loadInitial();
  }

}
