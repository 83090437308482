<div class="kt-portlet kt-portlet--collapse" data-ktportlet="true" id="kt_portlet_tools_4" *ngFor="let item of data ;let i = index;" >
    <div class="kt-portlet__head danger">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                <a href="javascript:;" class="kt-media kt-media--xs kt-media--circle kt-media--primary">
                    <span>{{i+1}}</span>
                </a>
               {{item.lineSlip1}},
               {{item.lineSlip2}},
               {{item.lineSlip3}}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body" kt-hidden-height="298">
        <div class="kt-portlet__content">
            <p>{{item.nameTH}} ({{item.nameEN}})</p>
            <p> {{item.address}}</p>
            <shared-app-reaonly-partition [data]="item.partitions"></shared-app-reaonly-partition>
        </div>
    </div>
</div>
<div class="kt-pagination kt-pagination--brand">
    <div class="kt-pagination__toolbar">
        <span class="pagination__desc">{{'ORDER.SHARED.DISPLAYING'|translate}} {{data.length}} {{'ORDER.SHARED.MULTHI'|translate}}</span>
    </div>
</div>


<!-- <app-shop-detail-views [shop]=" " #shop ></app-shop-detail-views> -->

<!-- <div class="kt-pagination kt-pagination--brand">
    <div class="kt-pagination__toolbar">
        <span class="pagination__desc">Displaying {{data.length}} Multhi</span>
    </div>
</div> -->