<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        {{'BKK.WORK_AREA.TECHNICIAN_LIST'|translate}}
        <a class="btn btn-primary btn-icon-sm" href="javascript:void(0)" (click)="onHistoryClick()" data-toggle="modal">
            {{ '_SHARED.HISTORY' | translate }}
        </a>
    </kt-expansion-panel-header>
    <div class="tab-content">
        <div class="kt-widget4">
            <div class="kt-widget4__item" *ngFor="let item of item.technicians">
                <div class="kt-widget4__pic kt-widget4__pic--pic">
                    <img onError="this.src='assets/img/Default_person.png'" [attr.src]="item.profilePictureFile?.url">

                </div>
                <div class="kt-widget4__info">
                    <b>{{item.name}}</b>
                    <p class="kt-widget4__text">
                        {{item.phoneNumber}}
                    </p>
                    <p class="kt-widget4__text kt-widget4__text-custom">
                        {{item.source?.name}}
                    </p>
                </div>
            </div>

        </div>
    </div>
</kt-expansion-panel>

<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        {{'_SHARED.GET_JOB' | translate}}
    </kt-expansion-panel-header>
    <div class="row form-group mb-0">
        <div class="col-md-12">
            <div class="form-group mb-0">
                <label class="text-primary"> {{'_SHARED.OWNER_GET_JOB' | translate}} : </label>
                <span class="kt-subheader__breadcrumbs-separator">{{item.receiveJobEmployee == null ? '-' :
                    item.receiveJobEmployee?.fullName}}</span>

            </div>
        </div>
    </div>
    <div class="row">

        <div class="col-sm-6">
            <div class="form-group mb-0"><label class="kt-font-primary">Set Job Date Time : </label>
                <span class="kt-subheader__breadcrumbs-separator">{{item.receiveJobDate == null? '-' :
                    item.receiveJobDate | date:'dd/MM/yyyy'}}
                    <span class="kt-subheader__breadcrumbs-separator" *ngIf="item.receiveJobHour != null">
                        {{item.receiveJobHour}}:{{item.receiveJobMinute}}</span></span>
            </div>
        </div>
    </div>
</kt-expansion-panel>

<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        <h3 class="kt-portlet__head-title"> {{ '_SHARED.S_N_LIST' | translate }} </h3>
    </kt-expansion-panel-header>

    <div class="table-responsive">
        <table class="table table-striped- table-bordered table-hover table-checkable table-responsive">
            <thead>
                <tr align="center">
                    <th width="5%" nowrap=""> {{'_SHARED.NO' | translate }}</th>
                    <th width="" nowrap="">{{'_SHARED.ACTION' | translate }}</th>
                    <th width="" nowrap="">{{'_SHARED.STATUS' | translate }}</th>
                    <th width="15%" nowrap=""> {{'_SHARED.S_N' | translate }}</th>
                    <th width="15%" nowrap=""> {{'_SHARED.S_N_RE_NEW' | translate }}</th>
                    <th width="10%" nowrap=""> {{'_SHARED.TID' | translate }}</th>
                    <th width="10%" nowrap=""> {{'_SHARED.MID' | translate }}</th>
                    <th width="5%" nowrap=""> {{'_SHARED.INSTALL_DATE' | translate }}
                    </th>
                    <th width="10%" nowrap="">{{'_SHARED.SHOP_NAME' | translate }}</th>

                    <th width="10%" nowrap=""> {{'_SHARED.MULTHI' | translate }}</th>
                    <th width="10%" nowrap=""> {{'_SHARED.MAIN' | translate }}</th>
                    <th width="15%" nowrap=""> {{'_SHARED.BACKUP' | translate }}</th>
                    <th width="15%" nowrap=""> {{'ORDER.WORKSHEET.S/N_REPLACE'|translate}} </th>
                    <th width="10%" nowrap=""> {{'ORDER.WORKSHEET.TID_REPLACE'|translate}} </th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.serialNumber"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.S_N' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.changedSerialNumber"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.S_N_RE_NEW' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.tid"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.TID' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.mid"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.MID' | translate"></th>
                    <th> </th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.shopName"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.SHOP_NAME' | translate"></th>
                    <th> </th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.main"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.MAIN' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.backup"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.BACKUP' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.serialNumberReplace"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.S_N' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter2.tidReplace"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.TID' | translate"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of item.edcs | grdFilter: {  
                    serialNumber: searchFilter2.serialNumber,
                    tid: searchFilter2.tid,
                    mid: searchFilter2.mid,
                    shopName: searchFilter2.shopName,
                    main: searchFilter2.main,
                    backup: searchFilter2.backup,
                    serialNumberReplace: searchFilter2.serialNumberReplace,
                    tidReplace: searchFilter2.tidReplace,
                    changedSerialNumber:searchFilter2.changedSerialNumber
                };index as i">
                    <td align="center">{{i+1}}</td>
                    <td nowrap="" align="center">
                        <span><a href="javascript:;" (click)="OnSerialNumberClick(data)"><i
                                    class="fa fa-eye"></i></a></span> &nbsp;
                        <span> <a class="cursor-pointer" href="javascript:;" (click)="onCloseJobClick(data)"><i
                                    class="fa fa-cog"></i></a></span>
                    </td>
                    <td nowrap="" align="left"><span
                            class="badge badge-dark badge-custom">{{data.onsiteStatus?.name}}</span>
                    </td>
                    <td nowrap="" align="left">{{data.serialNumber}}</td>
                    <td nowrap="" align="left" class="bold-text-b">{{data.changedSerialNumber}}</td>
                    <td nowrap="" align="left"><a href="javascript:;" (click)="openTidHistory(data.tid)">{{ data.tid }}</a></td>
                    <td nowrap="" align="left">{{data.mid}}</td>
                    <td nowrap="" align="center">{{data.installDate | date : 'dd/MM/yyyy'}}</td>
                    <td nowrap="" align="left">{{data.shopName}}</td>
                    <td align="center">
                        <ng-container *ngIf="data.multhi">
                            <i class="flaticon2-check-mark multhi"></i>
                        </ng-container>
                    </td>
                    <td nowrap="" align="left">{{data.main}}</td>
                    <td nowrap="" align="left">{{data.backup}}</td>

                    <td nowrap="" align="left" class="bold-text">{{ data.replaceSerialNumber}}</td>
                    <td nowrap="" align="left" class="bold-text">{{ data.replaceTid }}</td>
                </tr>
            </tbody>
        </table>
    </div>

</kt-expansion-panel>

<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        <h3 class="kt-portlet__head-title"> {{ '_SHARED.S_N_LIST_NEW' | translate }} </h3>
    </kt-expansion-panel-header>

    <div class="table-responsive">
        <table class="table table-striped- table-bordered table-hover table-checkable table-responsive">
            <thead>
                <tr align="center">
                    <th width="5%" nowrap=""> {{'_SHARED.NO' | translate }}</th>
                    <th width="" nowrap="">{{'_SHARED.ACTION' | translate }}</th>
                    <th width="" nowrap="">{{'_SHARED.SUBJECT' | translate }}</th>
                    <th width="15%" nowrap=""> {{'_SHARED.S_N' | translate }}</th> 
                    <th width="10%" nowrap=""> {{'_SHARED.TID' | translate }}</th>
                    <th width="10%" nowrap=""> {{'_SHARED.MID' | translate }}</th>
                    <th width="5%" nowrap=""> {{'_SHARED.INSTALL_DATE' | translate }}
                    </th> 

                    <th width="10%" nowrap=""> {{'_SHARED.MULTHI' | translate }}</th>
                    <th width="10%" nowrap=""> {{'_SHARED.MAIN' | translate }}</th>
                    <th width="15%" nowrap=""> {{'_SHARED.BACKUP' | translate }}</th> 
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter3.serialNumber"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.S_N' | translate"></th> 
                    <th><input class="form-control" [(ngModel)]="searchFilter3.tid"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.TID' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter3.mid"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.MID' | translate"></th>
                    <th> </th> 
                    <th> </th>
                    <th><input class="form-control" [(ngModel)]="searchFilter3.main"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.MAIN' | translate"></th>
                    <th><input class="form-control" [(ngModel)]="searchFilter3.backup"
                            [ngModelOptions]="{standalone: true}" [placeholder]="'_SHARED.BACKUP' | translate"></th> 
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let data of item.addonEdcTasks | grdFilter: {  
                    serialNumber: searchFilter3.serialNumber,
                    tid: searchFilter3.tid,
                    mid: searchFilter3.mid, 
                    main: searchFilter3.main,
                    backup: searchFilter3.backup 
                };index as i">
                    <td align="center">{{i+1}}</td>
                    <td nowrap="" align="center">
                        <span><a href="javascript:;" (click)="OnSerialNumberClick(data)"><i
                                    class="fa fa-eye"></i></a></span> &nbsp;
                        <span> <a class="cursor-pointer" href="javascript:;" (click)="onDetailCloseJobClick(data)"><i
                                    class="fa fa-file"></i></a></span>
                    </td>
                    <td nowrap="" align="left"><span
                            class="badge badge-dark badge-custom">{{data.subjectName}}</span>
                    </td>
                    <td nowrap="" align="left">{{data.serialNumber}}</td> 
                    <td nowrap="" align="left"><a href="javascript:;" (click)="openTidHistory(data.tid)">{{ data.tid }}</a></td>
                    <td nowrap="" align="left">{{data.mid}}</td>
                    <td nowrap="" align="center">{{data.installDate | date : 'dd/MM/yyyy'}}</td> 
                    <td align="center">
                        <ng-container *ngIf="data.multhi">
                            <i class="flaticon2-check-mark multhi"></i>
                        </ng-container>
                    </td>
                    <td nowrap="" align="left">{{data.main}}</td>
                    <td nowrap="" align="left">{{data.backup}}</td> 
                </tr>
            </tbody>
        </table>
    </div>

</kt-expansion-panel>

<app-worksheet-for-mechanic-view #forMechanic></app-worksheet-for-mechanic-view>

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ '_SHARED.IPI' | translate }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <form>
            <div class="form-group mb-0">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{ '_SHARED.CONTACT_NAME' | translate }} : </label>
                            <span class="kt-subheader__breadcrumbs-separator">{{item.postponeForm?.contactName == null
                                ?'-' : item.postponeForm?.contactName}}</span>
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="form-group mb-0">
                            <label class="kt-font-primary">{{ '_SHARED.PHONE_NUMBER' | translate }} : </label>
                            <span class="kt-subheader__breadcrumbs-separator">{{item.postponeForm?.contactPhone == null
                                ?'-' : item.postponeForm?.contactPhone}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">

            <div class="col-sm-6">
                <div class="form-group mb-0"><label class="kt-font-primary">Service Date Time : </label>
                    <span class="kt-subheader__breadcrumbs-separator">{{item.postponeForm.convenientDate == null ?'-' :
                        item.postponeForm?.convenientDate | date:'dd/MM/yyyy'}}
                        <span class="kt-subheader__breadcrumbs-separator"
                            *ngIf="item.postponeForm?.convenientHour != null">{{item.postponeForm?.convenientHour
                            }}:{{item.postponeForm?.convenientMinute}}</span></span>
                </div>
            </div>
        </div>
    </div>
</div>