import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { Pictures } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
@Component({
  selector: 'app-modal-gallery-view',
  templateUrl: './modal-gallery-view.component.html',
  styleUrls: ['./modal-gallery-view.component.scss']
})
export class ModalGalleryViewComponent implements OnInit {
  pictures: Pictures[] = []
  canEdit: boolean = false;
  clientSignaturePictureFile : AttachFile = new AttachFile;
  constructor(
    public activeModal: NgbActiveModal,
    private dialogService: MyDialogService
  ) { }

  ngOnInit(): void {
    
  }
}
