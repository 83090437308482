export const pantancateConst = [
    {
        id: 1,
        name: "xxxx"
    },
    {
        id: 2,
        name: "yyyy"
    },
    {
        id: 3,
        name: "zzzz"
    }
];