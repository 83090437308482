import { Injectable } from '@angular/core';  
import { Mapper } from '../../mapper'; 
import { SettingTaskInformationListModel } from 'src/app/core/view-models/_sprint3/_setting/task-model';
import { Information } from 'src/app/core/view-models/_sprint3/packing/task-model';

@Injectable({
    providedIn: 'root'
})
export class SettingCallOutInforMapper implements Mapper<Information, SettingTaskInformationListModel>{
    constructor( 
    ) { }
    mapForm(param: Information): SettingTaskInformationListModel {
        const item = new SettingTaskInformationListModel(); 
        
        return item;
    }
    mapTo(param: SettingTaskInformationListModel): Information {
        const item = new Information(); 
        item.froms  = param.froms ;
        item.commandName = param.command?.name ;
        item.stateName = param.state?.name ;
        item.statusName = param.status?.name ;
       
        item.workTypeName = param.workType?.name ;
        item.workSubTypeName = param.subType?.name ;
        item.serialNumber = param.serialNumber ; 
        item.type = null ;
        item.tid = param.tid ; 
        item.location  = param.location ;
        item.worksheetNo  = param.worksheetNo ;
        item.startDate = param.startDateTime ;
        item.EndDate = param.endDateTime ;
        item.sapMaterialId = param.sapMaterialId ;
        item.nextTos = param.nextTos ;
        item.modalName = param.modelName ;
        item.box  = param.box ;
        item.location  = param.location ;
        item.actualDate = param.actualDateTime ;
        item.taskOwnerName = param.taskOwnerName ;
        item.taskResponsible = param.taskResponsibleName ;

        
        item.quantity  = param.quantity ;
        item.measure  = param.measure ;
        item.requestRemark  = param.requestRemark ;
        return item;
    }

}