import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { DashboardInventoryViewModel, DashboardCSOrderViewModel, DashboardCustomerOrderViewModel, DashboardWorkSheetViewModel, DashboadStorePieModel } from '../../models/_shared/dashboard.model';

@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {

  constructor(
    private apiService: ApiService
  ) { }

  // cs
  async getInventoryCs(): Promise<DashboardInventoryViewModel[]> {
    const resp = await this.apiService.get<DashboardInventoryViewModel[]>(`/dashboards/cs/inventories`);
    return resp;
  }
  async getChartBankAllEdcs(customerId: number): Promise<DashboadStorePieModel[]> {
    var query = {
      customerId: customerId
    }
    const resp = await this.apiService.get<DashboadStorePieModel[]>(`/dashboards/store/stock-controls`,query);
    return resp;
  }
  async getChartBankAllModel(customerId: number): Promise<DashboadStorePieModel[]> {
    var query = {
      customerId: customerId
    }
    const resp = await this.apiService.get<DashboadStorePieModel[]>(`/dashboards/store/stock-controls/by-models`,query);
    return resp;
  }
  async getOrderCs(): Promise<DashboardCSOrderViewModel[]> {
    const resp = await this.apiService.get<DashboardCSOrderViewModel[]>(`/dashboards/cs/orders`);
    return resp;
  }

  async getStaticsOrderCS(): Promise<DashboardWorkSheetViewModel[]> {
    const resp = await this.apiService.get<DashboardWorkSheetViewModel[]>(`/dashboards/cs/worksheets`);
    return resp;
  }
  // customer
  async getInventoryCustomer(): Promise<DashboardInventoryViewModel[]> {
    const resp = await this.apiService.get<DashboardInventoryViewModel[]>(`/dashboards/customer/inventories`);
    return resp;
  }

  async getOrderCustomer(): Promise<DashboardCustomerOrderViewModel[]> {
    const resp = await this.apiService.get<DashboardCustomerOrderViewModel[]>(`/dashboards/customer/orders`);
    return resp;
  }



}
