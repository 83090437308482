import { Injectable } from '@angular/core';
import { OrderEdcViewModel, OrderInstallViewModel, OrderEdcCommunicationViewModel, OrderEdcMainPatitionViewModel, OrderEdcCommunicationBaseViewModel, OrderEdcFeatureConnectorViewModel, OrderDetailEdcMulthiViewModel, ImportOrderDetailEdcMulthiViewModel, ImportEdcCommunicationViewModel, ImportOrderEdcViewModel, OrderConnectorTypeViewModel } from '../view-models/_order/order-install.view-model';
import { SaveOrderEdcInstallRequestModel, SaveOrderInstallRequestModel, SaveOrderInformationRequestModel, MainCommunicationRequestModel, PartitionRequestModel, OrderEdcDetailModel, OrderEdcCommunicationModel, MainCommunicationModel, OrderEdcFeaturesModel, OrderEdcPartitionModel, OrderDetailEdcMulthiModel, SaveOrderReprogramModel, OrderConnectorTypeModel, SaveOrderImportMultiRequestModel } from '../models/_order/order-install.model';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { OrderInformationViewModel, OrderAuthorizeViewModel, OrderDetailViewModel, OrderDetailEdcViewModel, CommunicationPhoneNumberViewModel, CommunicationMainViewModel, FeatureConnectorViewModel, OrderEdcBankHostTypeViewModel, OrderEdcBankHostFunctionViewModel, FeatureConnectorTypeViewModel } from '../view-models/_order/order-share.view-model';
import { ShopViewModel } from '../view-models/_shop/shop.view-model';
import { SaveShopRequestModel, SaveShopMulthiRequestModel } from '../models/_shop/shop.model';
import { OrderModel, OrderAuthorizeModel, OrderDetailEdcModel, CommunicationPhoneNumberModel, CommunicationMainModel, FeatureConnectorModel } from '../models/_order/order-shared.model';
import { FeatureService } from '../services/shared/feature.service';
import { OrderReprogramViewModel } from '../view-models/_order/order-reprogram.view-model';
import { Subject } from 'rxjs';
import { ForMechanicModel, Pictures } from '../view-models/_sprint3/admin-bkk/work-area-model';
import { MechanicModel } from '../view-models/_sprint3/worksheet/worksheet-model';
import { ReferWorksheet } from '../models/_sprint3/support-apis-model';
import { WorkAreaWorkSheetListViewModel } from '../view-models/_sprint3/store/work-area-worksheet.model';
import { Modal } from '@amcharts/amcharts4/core';
import { AttachFile } from '../models/_sprint3/admin-bkk/close-job-model';

@Injectable({
    providedIn: 'root'
})
export class TransformOrderService {

    constructor(
        private _featureService: FeatureService
    ) { }

    transformOrderViewModel(item: OrderModel): OrderInstallViewModel {
        const model = new OrderInstallViewModel();
        model.id = item.id;
        model.remark = item.remark;
        if (item.customer != null) model.customer = item.customer;
        if (item.authroize != null) model.authorize = this.transformOrderAuthorizeViewModel(item.authroize);
        if (item.shop != null) model.shop = item.shop;
        model.eventType = item.eventType;
        if (item.information != null) model.information = item.information;
        model.orderDetail = new OrderDetailViewModel();
        model.orderDetail.lineSlip1 = item.lineSlip1;
        model.orderDetail.lineSlip2 = item.lineSlip2;
        model.orderDetail.lineSlip3 = item.lineSlip3;
        if (item.installDate != null) model.orderDetail.installDate = new Date(item.installDate);
        if (item.endPromotionDate != null) model.orderDetail.endDate = new Date(item.endPromotionDate);
        if (item.startPromotionDate != null) model.orderDetail.startDate = new Date(item.startPromotionDate);
        if (item.removeDate != null) model.removeDate = new Date(item.removeDate); 
        return model;
    }

    transformOrderReprogramViewModel(item: OrderModel): OrderReprogramViewModel {
        const model = new OrderReprogramViewModel();
        model.id = item.id;
        model.remark = item.remark;
        model.isJoin = item.isJoin;
        if (item.customer != null) model.customer = item.customer;
        if (item.authroize != null) model.authorize = this.transformOrderAuthorizeViewModel(item.authroize);
        if (item.shop != null) model.shop = item.shop;
        if (item.reProgramDate != null) model.reProgramDate = new Date(item.reProgramDate);
        if (item.information != null) model.information = item.information;
        return model;
    }


    transformOrderDetailEdcViewModel(item: OrderDetailEdcModel): OrderDetailEdcViewModel {
        const model: OrderDetailEdcViewModel = {
            ...item,
        };
        return model;
    }
    transformOrderAuthorizeViewModel(item: OrderAuthorizeModel): OrderAuthorizeViewModel {
        const model = new OrderAuthorizeViewModel();
        model.isApprove = item.isApprove;
        model.isReject = item.isReject;
        model.isSave = item.isSave;
        model.isSubmit = item.isSubmit;
        model.isCancel = item.isCancel;
        return model;
    }
    transformEdcModel(item: OrderEdcViewModel): SaveOrderEdcInstallRequestModel {
        const model = new SaveOrderEdcInstallRequestModel();
        model.installDate = DateUtils.toFormat(item.information?.installDate, DateUtils.YYYY_MM_DD);
        model.endPromotionDate = DateUtils.toFormat(item.information?.endDate, DateUtils.YYYY_MM_DD);
        model.startPromotionDate = DateUtils.toFormat(item.information?.startDate, DateUtils.YYYY_MM_DD);
        model.pantancateFormatId1 = item.feature.pantancateFormat1.id;
        model.pantancateFormatId2 = item.feature.pantancateFormat2.id;
        model.pantancateFormatId3 = item.feature.pantancateFormat3.id;
        model.pantancateFormatId4 = item.feature.pantancateFormat4.id;
        model.pantancateFormatId5 = item.feature.pantancateFormat5.id;
        model.pantancateFormatId6 = item.feature.pantancateFormat6.id;
        model.pantancateFormatId7 = item.feature.pantancateFormat7.id;
        model.pantancateFormatId8 = item.feature.pantancateFormat8.id;
        model.placeInLocation = item.information.placeInLocation
        model.reg = item.information.reg
        model.pantancateFormatOther = item.feature.pantancateFormatOther;
        model.pantancateFormatOther2 = item.feature.pantancateFormatOther2;
        model.lineSlip1 = (item.information.lineSlip1 + "").toLocaleUpperCase();
        model.lineSlip2 = (item.information.lineSlip2 + "").toLocaleUpperCase();
        model.lineSlip3 = (item.information.lineSlip3 + "").toLocaleUpperCase();
        model.placeLocation = item.shop?.location;
        // model.reg = item.shop?.reg;
        model.remark = item.remark;
        model.detail = item.detail;
        model.serialNumber = item.information.serialNumber;
        model.standaloneTId = item.information.standAlondTID;
        model.edcConnectorSerialNumber = item.feature.serialNumber;
        model.edcConnectorId = item.feature.connectorType?.id;
        model.mainCommunication = this.transformEdcCommunicationModel(item.communicationBase.main);
        model.backupCommunication = this.transformEdcCommunicationModel(item.communicationBase.backup);
        model.edcFeatureIdList = item.feature.features.filter(x => x.selected).map(x => x.id);
        model.partitions = item.partitions.map(x => this.transformEdcPartitionModel(x));
        model.shop = this.transformShopSaveModel(item.shop);
        model.connectors = item.feature.connectorItems.map(x => this.transformOrderOrderConnectorTypeModel(x));
        model.checkList = item.checkList.filter(x => x.isChecked);
        return model;
    }
    transformImportEdcModel(item: ImportOrderEdcViewModel): SaveOrderEdcInstallRequestModel {
        console.log(item);
        const model = new SaveOrderEdcInstallRequestModel();
        model.installDate = DateUtils.toFormat(item.information?.installDate, DateUtils.YYYY_MM_DD);
        model.endPromotionDate = DateUtils.toFormat(item.information?.endDate, DateUtils.YYYY_MM_DD);
        model.startPromotionDate = DateUtils.toFormat(item.information?.startDate, DateUtils.YYYY_MM_DD);
        model.edcInventoryId = item.information.edcInventoryId
        model.placeInLocation = item.information.placeInLocation
        model.reg = item.information.reg
        model.pantancateFormatId1 = item.feature.pantancateFormat1.id;
        model.pantancateFormatId2 = item.feature.pantancateFormat2.id;
        model.pantancateFormatId3 = item.feature.pantancateFormat3.id;
        model.pantancateFormatId4 = item.feature.pantancateFormat4.id;
        model.pantancateFormatId5 = item.feature.pantancateFormat5.id;
        model.pantancateFormatId6 = item.feature.pantancateFormat6.id;
        model.pantancateFormatId7 = item.feature.pantancateFormat7.id;
        model.pantancateFormatId8 = item.feature.pantancateFormat8.id; 
        model.pantancateFormatOther = item.feature.pantancateFormatOther;
        model.pantancateFormatOther2 = item.feature.pantancateFormatOther2;
        model.lineSlip1 = (item.information.lineSlip1 + "").toLocaleUpperCase();
        model.lineSlip2 = (item.information.lineSlip2 + "").toLocaleUpperCase();
        model.lineSlip3 = (item.information.lineSlip3 + "").toLocaleUpperCase();
        // model.placeLocation = item.shop?.location;
        // model.reg = item.shop?.reg;
        model.remark = item.remark;
        model.detail = item.detail;
        model.serialNumber = item.information?.serialNumber;
        model.standaloneTId = item.information?.standAlondTID;
        model.edcConnectorSerialNumber = item.feature?.serialNumber;
        model.edcConnectorId = item.feature.connectorType?.id;
        model.mainCommunication = this.transformEdcCommunicationModel(item.communicationBase.main);
        model.backupCommunication = this.transformEdcCommunicationModel(item.communicationBase.backup);
        model.edcFeatureIdList = item.feature.features.filter(x => x.selected).map(x => x.id);
        model.partitions = item.partitions.map(x => this.transformEdcPartitionModel(x));
        model.shop = this.transformShopSaveModel(item.shop);
        model.connectors = item.feature.connectorItems.map(x => this.transformOrderOrderConnectorTypeModel(x));
        // model.checkList = item.checkList.filter(x => x.isChecked);
        return model;
    }
    transformOrderOrderConnectorTypeModel(item: FeatureConnectorTypeViewModel): OrderConnectorTypeModel {
        const model = new OrderConnectorTypeModel();
        model.remark = item.remark;
        model.inventoryId = item.inventoryId;
        model.serialNumber = item.serialNumber;
        model.connectorId = item.id;
        model.measure = item.measure;
        model.quantity = item.quantity
        return model;
    }
    transformOrderSaveModel(item: OrderInstallViewModel): SaveOrderInstallRequestModel {
        const model = new SaveOrderInstallRequestModel();
        model.information = this.transformInformationSaveModel(item.information);
        model.shop = this.transformShopSaveModel(item.shop);
        model.eventType = item.eventType;
        model.remark = item.remark;

        model.lineSlip1 = (item.orderDetail.lineSlip1 + "").toLocaleUpperCase();
        model.lineSlip2 = (item.orderDetail.lineSlip2 + "").toLocaleUpperCase();
        model.lineSlip3 = (item.orderDetail.lineSlip3 + "").toLocaleUpperCase();
        model.installDate = DateUtils.toFormat(item.orderDetail?.installDate, DateUtils.YYYY_MM_DD);
        model.endPromotionDate = DateUtils.toFormat(item.orderDetail?.endDate, DateUtils.YYYY_MM_DD);
        model.startPromotionDate = DateUtils.toFormat(item.orderDetail?.startDate, DateUtils.YYYY_MM_DD);
        return model;
    }
    transformOrderImportSaveModel(item: OrderInstallViewModel): SaveOrderInstallRequestModel {
        const model = new SaveOrderInstallRequestModel();
        model.information = this.transformInformationSaveModel(item.information);
        model.shop = this.transformShopSaveModel(item.shop); 
        model.remark = item.remark; 
        return model;
    }

    transformOrderImportSaveMultiModel(item: ImportOrderDetailEdcMulthiViewModel): SaveOrderImportMultiRequestModel {
        const model = new SaveOrderImportMultiRequestModel();
        model.lineSlip1 = item.lineSlip1;
        model.lineSlip2 = item.lineSlip2;
        model.lineSlip3 = item.lineSlip3; 
        model.partitions = item.partitions.map(x => this.transformEdcPartitionModel(x));
        return model;
    }
    transformOrderSaveReprogramModel(item: OrderReprogramViewModel): SaveOrderReprogramModel {
        const model = new SaveOrderReprogramModel();
        model.remark = item.remark;
        model.reProgramDate = item.reProgramDate;
        model.clientId = item.shop?.id;
        model.isJoin = item.isJoin
        if (item.information != null) model.information = this.transformInformationSaveModel(item.information);
        return model;
    }

    transformInformationSaveModel(item: OrderInformationViewModel): SaveOrderInformationRequestModel {
        const model = new SaveOrderInformationRequestModel();
        model.branchId = item.branch?.id;
        model.buId = item.businessUnit?.id;
        model.isUrgent = item.isUrgent;
        model.jobNumber = item.jobNumber;
        model.logoPictureFileId = item.logoPictureFile?.id;
        model.attachFileId = item.attachFile?.id;
        model.hasLogo = item.hasLogo;
        model.isVip = item.isVip;
        model.lot = item.lot;
        model.officeCode = item.officeCode;
        model.branchName = item.branchName;
        model.bc = item.bc;
        model.pfCode = item.pfCode;
        model.officer = item.officer;
        model.nii = item.nii;
        model.buName = item.buName;
        model.requestDate  = item.requestDate;
        return model;
    }

    transformShopMulthiSaveModel(item: OrderDetailEdcMulthiViewModel): SaveShopMulthiRequestModel {
        const model = new SaveShopMulthiRequestModel();
        /*    model.id = item.id;
            model.nameEN = item.nameEN;
            model.nameTH = item.nameTH;
            model.placeInLocation = item.location;
            model.address = item.address;
            model.branchName = item.branchName;
            model.locationCode = item.locationCode;
            model.bankTel = item.bankPhoneNumber;
            model.bankName = item.bankName;
            model.code = item.code;
            model.contactFaxNumber = item.contactFaxNumber;
            model.contactName = item.contactName;
            model.contactPhoneNumber = item.contactPhoneNumber;
            model.districtId = item.district?.id;
            model.groupId = item.group?.id;
            model.subdistrictId = item.subDistrict?.id;
            model.provinceId = item.province?.id;
            model.posCode = item.posCode;
            model.email = item.email;
            model.reg = item.reg;*/
        model.sequence = item.sequence;
        model.lineSlip1 = (item.lineSlip1 + "").toLocaleUpperCase();
        model.lineSlip2 = (item.lineSlip2 + "").toLocaleUpperCase();
        model.lineSlip3 = (item.lineSlip3 + "").toLocaleUpperCase();
        return model;
    }
    transformShopSaveModel(item: ShopViewModel): SaveShopRequestModel {
        const model = new SaveShopRequestModel();
        model.id = item.id;
        model.nameEN = item.nameEN;
        model.nameTH = item.nameTH;
        model.placeInLocation = item.location;
        model.address = item.address;
        model.branchName = item.branchName;
        model.locationCode = item.locationCode;
        model.bankTel = item.bankPhoneNumber;
        model.bankName = item.bankName;
        model.code = item.code;
        model.contactFaxNumber = item.contactFaxNumber;
        model.contactName = item.contactName;
        model.contactPhoneNumber = item.contactPhoneNumber;
        model.districtId = item.district?.id;
        model.groupId = item.group?.id;
        model.subdistrictId = item.subDistrict?.id;
        model.provinceId = item.province?.id;
        model.email = item.email;
        model.posCode = item.posCode;
        model.reg = item.reg; 
        model.locationId = item.shopLocation?.id
        return model;
    }

    transformEdcCommunicationModel(item: OrderEdcCommunicationViewModel): MainCommunicationRequestModel {
        const model = new MainCommunicationRequestModel();
        model.gateway = item.gateway;
        model.ipAddress = item.ipAddress;
        model.isUsePhoneNumber = item.hasPhoneNumber;
        model.providerId = item.provider?.id;
        model.simId = item.phoneNumber?.id;
        model.subnetMark = item.subnetMark;
        model.type = item.communicate?.id;
        model.value = item.other;
        return model;
    }
    transformEdcPartitionModel(item: OrderEdcMainPatitionViewModel): PartitionRequestModel {
        const model = new PartitionRequestModel();
        model.bankHostId = item.bankHostId;
        model.billerName = item.billerName;
        model.isMain = item.isMain;
        model.location = item.location;
        model.locationId = item.locationId;
        model.merchantId = item.merchantId;
        model.sp = item.sp;
        model.terminalId = item.terminalId;
        model.functionIdList = item.functions.filter(x => x.selected).map(x => x.function.id);
        return model;
    }

    transformOrderEdcModel(item: OrderEdcDetailModel): OrderEdcViewModel {
        const model = new OrderEdcViewModel();
        model.orderId = item.orderId;
        model.orderTypeId= item.orderTypeId;
        model.id = item.id;
        model.oldEdcId = item.oldEdcId;
        model.shop = item.shop;
        model.remark = item.remark;
        model.detail = item.detail;
        model.eventType = item.eventType;
        if (item.information != null) model.information = {
            ...item.information,
            REG: item.information.reg,
        };
        if (item.customer != null) model.customer = item.customer;
        if (item.checkList) model.checkList = item.checkList;
        if (item.information.installDate != null) model.information.installDate = new Date(item.information.installDate);
        if (item.information.startDate != null) model.information.startDate = new Date(item.information.startDate);
        if (item.information.endDate != null) model.information.endDate = new Date(item.information.endDate);
        if (item.communication != null) model.communicationBase = this.transformCommunicationEdcMainModel(item.communication);
        if (item.features != null) model.feature = this.transformOrderEdcFeatureConnectorViewModel(item.features);
        if (item.connectors != null) model.connectors = item.connectors;
        return model;
    }

    transformOrderConnectorModel(item: OrderConnectorTypeViewModel): FeatureConnectorTypeViewModel {
        const model = new FeatureConnectorTypeViewModel();
        model.id = item.connectorId;
        model.name = item.connectorName;
        model.serialNumber = item.serialNumber;
        model.remark = item.serialNumber; 
        model.quantity = item.quantity;
        model.measure = item.measure;
       

        return model;
    }

    transformOrderEdcMainPatitionViewModel(item: OrderEdcPartitionModel, functions: OrderEdcBankHostTypeViewModel[]): OrderEdcMainPatitionViewModel {
        const model = new OrderEdcMainPatitionViewModel();
        model.isMain = item.isMain;
        model.location = item.location;
        model.locationId = item.locationId;
        model.merchantId = item.merchantId;
        model.sp = item.sp;
        model.terminalId = item.terminalId;
        model.billerName = item.billerName;
        model.bankHost = item.bankHost;
        model.bankHostId = item.bankHost?.id;
        model.functions = [];
        functions.forEach(row => {
            const tmp = new OrderEdcBankHostFunctionViewModel();
            tmp.function = row;
            tmp.selected = item.functions.filter(x => x.id == row.id).length > 0;
            model.functions.push(tmp);
        });
        return model;
    }
    transformOrderEdcImportPatitionViewModel(item: OrderEdcMainPatitionViewModel, functions: OrderEdcBankHostTypeViewModel[]): OrderEdcMainPatitionViewModel {
        const model = new OrderEdcMainPatitionViewModel();
        model.isMain = item.isMain;
        model.location = item.location;
        model.locationId = item.locationId;
        model.merchantId = item.merchantId;
        model.sp = item.sp;
        model.terminalId = item.terminalId;
        model.billerName = item.billerName;
        model.bankHost = item.bankHost;
        model.bankHostId = item.bankHost?.id;
        model.functions = []; 
        functions.forEach(row => {
            const tmp = new OrderEdcBankHostFunctionViewModel();
            tmp.function = row;
            tmp.selected = item.functions.filter(x => x.id == row.id).length > 0;
            model.functions.push(tmp);
        }); 
        return model;
    }
    transformOrderDetailEdcMulthiViewModel(item: OrderDetailEdcMulthiModel, functions: OrderEdcBankHostTypeViewModel[]): OrderDetailEdcMulthiViewModel {
        const model: OrderDetailEdcMulthiViewModel = {
            ...item,
            multiId: item.id,
            callbackSavePartition: new Subject<boolean>(),
            partitions: item.partitions.map(x => this.transformOrderEdcMainPatitionViewModel(x, functions))
        };
        // model.id = item.clientId;
        return model;
    }

    transformOrderEdcFeatureConnectorViewModel(item: OrderEdcFeaturesModel): OrderEdcFeatureConnectorViewModel {
        const model = new OrderEdcFeatureConnectorViewModel();
        model.pantancateFormat1 = item.pantancateFormat1;
        model.pantancateFormat2 = item.pantancateFormat2;
        model.pantancateFormat3 = item.pantancateFormat3;
        model.pantancateFormat4 = item.pantancateFormat4;
        model.pantancateFormat5 = item.pantancateFormat5;
        model.pantancateFormat6 = item.pantancateFormat6;
        model.pantancateFormat7 = item.pantancateFormat7;
        model.pantancateFormat8 = item.pantancateFormat8;
        model.serialNumber = item.serialNumber;
        model.pantancateFormatOther = item.pantancateFormatOther;
        model.pantancateFormatOther2 = item.pantancateFormatOther2;
        model.features = item.features.map(x => this.transformFeatureConnectorViewModel(x));
        return model;
    }
    transformFeatureConnectorViewModel(item: FeatureConnectorModel): FeatureConnectorViewModel {
        const model = new FeatureConnectorViewModel();
        model.id = item.id;
        model.name = item.name;
        return model;
    }
    transformCommunicationEdcMainModel(item: OrderEdcCommunicationModel): OrderEdcCommunicationBaseViewModel {
        const model = new OrderEdcCommunicationBaseViewModel();

        if (item.main != null) model.main = this.transformCommunicationEdcModel(item.main);
        if (item.backup != null) model.backup = this.transformCommunicationEdcModel(item.backup);

        return model;
    }
    transformImportCommunicationEdcMainModel(item: OrderEdcCommunicationBaseViewModel): OrderEdcCommunicationBaseViewModel {
        const model = new OrderEdcCommunicationBaseViewModel();

        if (item.main != null) model.main = this.transformImportCommunicationEdcModel(item.main);
        if (item.backup != null) model.backup = this.transformImportCommunicationEdcModel(item.backup);

        return model;
    }
    transformImportCommunicationEdcModel(item: OrderEdcCommunicationViewModel): OrderEdcCommunicationViewModel {
        const model = new OrderEdcCommunicationViewModel();
        if (item.communication != null) model.communicate = item.communication;
        model.gateway = item.gateway;
        model.hasPhoneNumber = item.hasPhoneNumber;
        model.ipAddress = item.ipAddress;
        model.other = item.value;
        if (item.phonNumber != null) model.phoneNumber = this.transformCommunicationProviderModel(item.phonNumber);
        model.provider = item.provider;
        model.subnetMark = item.subnetMark;
        return model;
    }
    transformCommunicationEdcModel(item: MainCommunicationModel): OrderEdcCommunicationViewModel {
        const model = new OrderEdcCommunicationViewModel();
        if (item.communication != null) model.communicate = item.communication;
        model.gateway = item.gateway;
        model.hasPhoneNumber = item.hasPhoneNumber;
        model.ipAddress = item.ipAddress;
        model.other = item.value;
        if (item.phonNumber != null) model.phoneNumber = this.transformCommunicationProviderModel(item.phonNumber);
        model.provider = item.provider;
        model.subnetMark = item.subnetMark;
        return model;
    }


    transformCommunicationMainViewModel(item: CommunicationMainModel): CommunicationMainViewModel {
        const model = new CommunicationMainViewModel();
        model.id = item.id;
        model.name = item.name;
        model.type = item.type;
        return model;
    }

    transformCommunicationProviderModel(item: CommunicationPhoneNumberModel): CommunicationPhoneNumberViewModel {
        const model = new CommunicationPhoneNumberViewModel();
        model.id = item.id;
        model.code = item.code;
        model.phoneNumber = item.phoneNumber;
        return model;
    }
    transformForMechanicModel(item: ForMechanicModel,pictures:Pictures[],clientSignaturePictureFile:AttachFile): MechanicModel {
        const model = new MechanicModel();
        model.detail = item.detail
        model.attachFile= item.attachFile;
        model.startJobDate=  item.startJobDate;
        model.startJobHour= item.startJobHour;
        model.startJobMinute= item.startJobMinute;
        model.finishJobDate=  item.finishJobDate;
        model.finishJobHour= item.finishJobHour;
        model.finishJobMinute= item.finishJobMinute;
        model.attachFiles = item.attachFiles;
        model.attachFileIdList = item.attachFileIdList;
        model.pictures = pictures;
        model.clientSignaturePictureFile = clientSignaturePictureFile;
 
        model.actualOperationDate= item.actualOperationDate ;
        model.actualOperationHour= item.actualOperationHour;
        model.actualOperationMinute= item.actualOperationMinute;

        return model;
    }


    transformWorksheetReferModel(item: ReferWorksheet): WorkAreaWorkSheetListViewModel {
        const model = new WorkAreaWorkSheetListViewModel(); 
        model.id = item?.id; 
        model.operationDate = item?.operationDate;
        model.ownerName = item?.taskOwnerName;
        model.responsibleName = item?.taskResponsibleName; 
        model.startDate =  item?.worksheetDate ;
        model.workSheetNo =  item?.worksheetNo ;
        model.worksheetId = item?.id;
        model.type = item?.workType; 
        return model;
    }
}
