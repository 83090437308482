import { Component, OnInit, Input } from '@angular/core';
import { OrderEdcInformationViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { orderConst } from 'src/app/core/_static/order.const';

@Component({
  selector: 'app-view-shared-edc-information',
  templateUrl: './view-shared-edc-information.component.html',
  styleUrls: ['./view-shared-edc-information.component.scss']
})
export class ViewSharedEdcInformationComponent implements OnInit {

  
  @Input()
  information: OrderEdcInformationViewModel = new OrderEdcInformationViewModel();
  orderId: number = 0;
  constructor() { }

  ngOnInit(): void {
  }
  public setInitial(item: OrderEdcInformationViewModel) {
    this.information = item;
  } 
  get eventType(){
    return orderConst.eventType;
  }
}
