import { Component, OnInit, Input } from '@angular/core';
import { OrderEdcMainPatitionViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { OrderEdcBankHostViewModel, OrderEdcBankHostTypeViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { BankHostViewService } from 'src/app/core/services/shared/bank-host.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { orderConst } from 'src/app/core/_static/order.const';
import { AppOrderSharedPartitionDetailModalComponent } from '../../../order/_shared/_modal/partition-detail-modal/partition-detail-modal.component';

@Component({
  selector: 'shared-app-reaonly-partition',
  templateUrl: './reaonly-partition.component.html',
  styleUrls: ['./reaonly-partition.component.scss']
})
export class ReaonlyPartitionComponent implements OnInit {
  @Input()
  data: OrderEdcMainPatitionViewModel[] = [];
  bankHosts: OrderEdcBankHostViewModel[] = [];
  functions: OrderEdcBankHostTypeViewModel[] = [];
  isMain: number;
  terminalId: string;
  rows: number = 1;
  submit: boolean;

  constructor(
    private _bankHostService: BankHostViewService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.loadInitial();
  }

  async loadInitial() {
    this.functions = await this._bankHostService.bankHostFunctions();
  }
  get orderBankHostType() {
    return orderConst.bankHostType;
  }
  showSettingBilling(item: OrderEdcMainPatitionViewModel): boolean {
    return item.bankHost != null && item.bankHost?.type != this.orderBankHostType.none && item.bankHost?.type != null;
  }
  doChangeBilling(item: OrderEdcMainPatitionViewModel) {
    const modalRef = this.modalService.open(AppOrderSharedPartitionDetailModalComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'md'
    });
    modalRef.componentInstance.item = item;
  }
  isValid(): boolean {
    return true;
  }
}