import { ChangeBank } from 'src/app/core/view-models/_sprint3/store/stock-control-edc';

export class StockControlEdcApiViewModel {
    edcId: number;
    connectorId: number;
    status: Status;
    serialNumber: string;
    assetsNumber: string;
    modelName: string;
    tid: string;
    mid: string;
    shopName: string;
    typeOfWork: TypeOfWork;
    subType: TypeOfWork;
    canView: boolean;
    canEdit: boolean;
    canSetting: boolean;
    sapMaterialId:string;
  box:string;
  location:ChangeBank;
  bankName:string;
  selected:boolean
  }
  
  export class TypeOfWork {
    id: number;
    code: string;
    nameTH: string;
    nameEN: string;
    name: string;
    kind: string;
  }
  export class Status {
  id: number;
  code: string;
  name: string;
}


export class SearchStockControlEdcCriteriaViewModel {
    statusIdList: number[] = [];
    modelIdList: number[] = [];
    serialNumber: string = "";
    serialNumbers: string[]=[];
    assetsNumber: string = "";
    modelName: string = "";
    tid: string = "";
    mid: string = "";
    shopName: string = "";
    sapMaterialId: string = "";
    box: string = "";
    location: string = "";
    bankIdList: number[] = [];
  }
export class UpdateStockControlEdcApiViewModel {
  
  assetsNumber: string;
  serialNumber: string;
  modelId: number;
  project: string;
  locationId: number;
  statusId: number;
  remark: string;
  sapMaterialId: string;
  box:string;
  receiveDate:Date
  }