import { ShopViewModel } from '../../_shop/shop.view-model';
import { Information } from '../packing/task-model';
import { Status } from 'src/app/core/models/user/help-desk/inbound-t1-model';
import { ClientDetailListInWorksheet, Client } from '../../_shared/worksheet.view-model';
import { AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { Pictures } from '../admin-bkk/work-area-model';


export class WorksheetGeneralViewModel {
  id: number;
  shop: Client = new Client;
  location: Client = new Client;
  mechanic:  MechanicModel = new MechanicModel;
  command: CommandModel = new CommandModel;
  contact: ContactModel = new ContactModel;
  remark: string = '';
  topicId: number;
  sendToStatus: number;
  inTernalStatus: number
  sendToType:number;
  eventTypeId:number = 0;
  sentToStore:boolean = false;
  typeOfWorkId:number;
}
export class MechanicModel {
  detail:string
  attachFile: AttachFile;
  startJobDate: Date;
  startJobHour: number;
  startJobMinute: number;
  finishJobDate: Date;
  finishJobHour: number;
  finishJobMinute: number;

  actualOperationDate: Date;
  actualOperationHour: number;
  actualOperationMinute: number;

  attachFiles: AttachFile[] = [];
  attachFileIdList:number[]=[];
  pictures: Pictures[] = []; 
  clientSignaturePictureFile : AttachFile = new AttachFile;
}
export class CommandModel {
  issue: string;
  command: string;
  recommend: string;
}
export class ContactModel {

  contactName: string;
  contactPhone: string;
  serviceDate: Date = new Date;
  serviceHour: number = null;
  serviceMinute: number = null;
  commandFile?: FileUp = new FileUp;
  attachFiles: AttachFile[] = [];
  attachFileIdList:number[]=[];
}
export class FileUp {
  attachFileId: number;
  name: string;
  url: string;
}
export class MechanicGallery {
  id: number;
  name: string;
  gallerList: GallerList[] = []
  totalGallery:number;
}
export class GallerList {
  id: number;
  name: string;
  url: string;
}

export class SimInformationViewModel {
  id: number;
  info : Information;
  status:Status;
  remark:string;
}
