import { Injectable } from "@angular/core"; 
import { Mapper } from '../mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { WorksheetPMViewModel } from '../../view-models/_sprint3/worksheet/worksheet-pm-model'; 
import { Client, Province, District } from '../../view-models/_shared/worksheet.view-model';
import { MechanicModel, CommandModel, ContactModel, FileUp } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { AvailableEdcMapper } from './available-edcs-mapper';
import { Subdistrict } from '../../models/_sprint3/setting/setting-task-model'; 
import { GetWorksheetBkkSetJobApisModel } from '../../models/_sprint3/worksheet/worksheet-api-view-model';
import { WorksheetBKKSetJobViewModel } from '../../view-models/_sprint3/admin-bkk/work-area-model';

@Injectable({ 
  providedIn: "root", 
})
export class CreateBKKSetJobGetWorksheetMapper implements Mapper<GetWorksheetBkkSetJobApisModel, WorksheetBKKSetJobViewModel>{
  constructor( 
    private availableEdcMapper : AvailableEdcMapper
  ) { }
  mapForm(param: GetWorksheetBkkSetJobApisModel): WorksheetBKKSetJobViewModel { 
     return null
  }
  mapTo(param: WorksheetBKKSetJobViewModel): GetWorksheetBkkSetJobApisModel { 
    return null
  }

}