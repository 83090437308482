import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { ApiPath } from '../http/api-path';
import { AuthService } from './auth.service';
import { UserProfileModel } from '../models/_shared/user.model';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  async loadUserProfile(): Promise<UserProfileModel> {
    const user = await this.apiService.get<UserProfileModel>(ApiPath.account.profile);
    if(user){
      this.authService.setUserProfile(user);
    }
    return user;
  }
}
