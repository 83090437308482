<div class="modal-header">
    <h4 class="modal-title">{{'STORE.WORKSHEET.WORKSHEET' | translate}}</h4>
    <button type="button" class="close" (click)="onCloseModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-custom2">
    <div class="tab-pane" id="kt_portlet_base_demo_3_2_tab_content" role="tabpanel">
        <table class="table table-striped- table-bordered table-hover table-checkable table-responsive" id="kt_table_1" matSort (matSortChange)="onSort($event)">
            <thead>
                <tr>
                    <th nowrap="" width="5%" mat-sort-header="no">  {{'STORE.STOCK_CONTROL.NO' | translate}}</th> 
                    <!-- <th nowrap="" width="5%" mat-sort-header="status">{{'STORE.STOCK_CONTROL.STATUS' | translate}}</th> -->
                    <th nowrap="" width="10%" mat-sort-header="serialNumber">{{'STORE.STOCK_CONTROL.S_N' | translate}}</th>
                    <th nowrap="" width="10%" mat-sort-header="sapMaterialId">{{'STORE.STOCK_CONTROL.SAPMATERIAL' | translate}} </th>
                    <th nowrap="" width="10%" mat-sort-header="assetsNumber">{{'STORE.STOCK_CONTROL.A_N' | translate}} </th>
                    <th nowrap="" width="10%" mat-sort-header="mode;">{{'STORE.STOCK_CONTROL.MODEL' | translate}}</th>
                    <th width="8%" nowrap="" mat-sort-header="tid">{{'STORE.STOCK_CONTROL.TID' | translate}}</th>
                    <th width="8%" nowrap="" mat-sort-header="mid">{{'STORE.STOCK_CONTROL.MID' | translate}}</th>
                    <th nowrap="" width="" mat-sort-header="shopName">{{'STORE.STOCK_CONTROL.SHOP_NAME' | translate}}</th>
                </tr>
                <tr colFilter (colFilterChange)="colFilterChange($event)"> 
                    <th></th>
                    <!-- <th class="text-center">
                         
                    </th> -->
                    <th><input class="form-control" col-filter-name="serialNumber" [placeholder]="'STORE.STOCK_CONTROL.S_N' | translate"></th>
                    <th><input class="form-control" col-filter-name="sapMaterialId" [placeholder]="'STORE.STOCK_CONTROL.SAPMATERIAL' | translate"></th>
                    <th><input class="form-control" col-filter-name="assetsNumber" [placeholder]="'STORE.STOCK_CONTROL.A_N' | translate"></th>
                    <th><input class="form-control" col-filter-name="modelName" [placeholder]="'STORE.STOCK_CONTROL.MODEL' | translate"></th>
                    <th><input class="form-control" col-filter-name="tid" [placeholder]="'STORE.STOCK_CONTROL.TID' | translate">
                    <th><input class="form-control" col-filter-name="mid" [placeholder]="'STORE.STOCK_CONTROL.MID' | translate">
                    <th><input class="form-control" col-filter-name="shopName" [placeholder]="'STORE.STOCK_CONTROL.SHOP_NAME' | translate"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items;index as i;" (click)="onGetData(item)">
                    <td align="center">{{i+1}}</td>  
                    <!-- <td nowrap="" align="left">
                        <span class="badge badge-primary badge-custom"
                            *ngIf="item.status.id == status.installed">{{'STORE.STOCK_CONTROL.INSTALLED' | translate}}</span>
                        <span class="badge badge-success badge-custom"
                            *ngIf="item.status.id == status.available">{{'STORE.STOCK_CONTROL.AVAILABLE' | translate}}</span>
                        <span class="badge badge-dark badge-custom"
                            *ngIf="item.status.id != status.installed && item.status.id != status.available">{{item?.status?.name}}</span>
                    </td> -->
                    <td nowrap="">{{item.serialNumber}}</td>
                    <td nowrap="">{{item.sapMaterialId}}</td>
                    <td nowrap="">{{item.assetsNumber}}</td>
                    <td nowrap="">{{item.model}}</td>
                    <td align="center">{{item.tid}}</td>
                    <td align="center">{{item.mid}}</td>
                    <td nowrap="">{{item.shopName}}</td>
                    <!-- <td nowrap="" align="left">
                        <ng-container *ngFor="let data of item.type">
                        <span class="badge  badge-info badge-custom" *ngIf="data.id == type.install">{{ '_SHARED.INSTALL' | translate }}</span>
                        <span class="badge  badge-info badge-custom" *ngIf="data.id == type.service">{{ '_SHARED.SERVICE' | translate }}</span>&nbsp;
                        <span class="badge  badge-dark badge-custom" *ngIf="data.id == type.permanence">{{ '_SHARED.PERMANENCE_B' | translate }}</span>
                        <span class="badge  badge-dark badge-custom" *ngIf="data.id == type.onsite">{{ '_SHARED.ONSITE_B' | translate }}</span>
                    </ng-container>
                    </td> -->
    
                </tr>
            </tbody>
        </table>
        <app-admin-table-paginator 
        #pagination (onPageChange)="onPageChange($event)" 
        (onPageSizeChange)="onPageSizeChange($event)">
    </app-admin-table-paginator>
    </div>
</div>
<div class="modal-footer">
    <div class="col-lg-12 text-right">
        <button type="button" class="btn btn-dark waves-effect" (click)="onCloseModal()">Close</button>
    </div>
</div>