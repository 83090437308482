export class SearchTableModelWorkSheet {
    orderNo: string;
    orderDate: string;
    serialNumber:string;
    tid:string;
    mid:string;
    installDate:string;
    multhi:string;
    main:string;
    backup:string;
    serialNumberReplace:string;
    tidReplace:string;
    changedSerialNumber:string;
}