<div class="modal-header">
    <h5 class="modal-title">{{'_SHARED.CASE' | translate }}</h5>
    <button type="button" class="close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">×</span>
    </button>
</div>
<div class="modal-body modal-body-custom2" [formGroup]="formValidate">
    <div class="row">
        <div class="col-6" [ngClass]="{'disbled-select-view': isReaonly}">
            <div class="form-group">
                <label for="recipient-name" class="form-control-label"> {{'_SHARED.CASE' | translate }}</label>
                <select class="form-control"   formControlName="reprogramCase" 
                [ngClass]="{ 'is-invalid': submit && f.reprogramCase.errors }">
                <option [ngValue]="null" disabled>{{ '_SHARED.CHOOSE' | translate}}</option>
                <option *ngFor="let row of caseList" [ngValue]="row.id" >{{row.name}}</option>
              </select>
            </div>
        </div>
    </div>  
    <br>
    <form  *ngIf="formValidate.value.reprogramCase == 3 && toggleOnsiteItemFeature">
        <div class="form-group text-left">
            <label class="kt-font-primary"><i class="required">*</i> {{ '_SHARED.WORK_TYPE' | translate }} </label>
            <div class="kt-radio-inline">
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" [(ngModel)]="workTypeId" [ngModelOptions]="{standalone: true}"
                        [value]="workTypeStatus.withdrawConnector"> {{ '_SHARED.TAKE_PERIPHERALS'  | translate }}
                    <span></span>
                </label>
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" [(ngModel)]="workTypeId" [ngModelOptions]="{standalone: true}"
                        [value]="workTypeStatus.withdraEdc"> {{ '_SHARED.TAKE_EDC_MACHINE'  | translate }}
                    <span></span>
                </label>
                
                <label class="kt-radio kt-radio--bold kt-radio--brand">
                    <input type="radio" name="workTypeId" [(ngModel)]="workTypeId" [ngModelOptions]="{standalone: true}"
                        [value]="workTypeStatus.withdrawEdcAndConnector">
                    {{ '_SHARED.TAKE_PERIPHERALS_AND_EDC'  | translate }}
                    <span></span>
                </label>
            </div>

        </div> 
        <!-- <div class="form-group">
            <div class="row">
                <div class="col-sm-12">
                    <label>{{ '_SHARED.REMARK'  | translate }}</label>
                    <textarea class="form-control" [placeholder]="'_SHARED.REMARK'  | translate"
                        formControlName="remark" rows="4"></textarea>
                </div>
            </div>
        </div> -->
    </form>
    <ng-container *ngIf="workTypeId == workTypeStatus.withdrawConnector || workTypeId == workTypeStatus.withdrawEdcAndConnector"> 
        <label class="kt-font-primary"> {{ '_SHARED.PERIPHERALS' | translate }} </label>
        <div class="table-responsive form-group" >
            <table class="table table-striped- table-bordered table-hover table-checkable">
                <thead>
                    <tr align="center">
                        <th width="1%" nowrap="">{{ '_SHARED.NO'  | translate }}</th>
                        <th width="1%" nowrap="">{{ '_SHARED.SELECT'  | translate }}</th>
                        <th width="2%" nowrap="">{{ '_SHARED.NAME'  | translate }}</th>
                        <th width="5%" nowrap="">{{ '_SHARED.REMARK'  | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="ng-star-inserted" *ngFor="let row of connectors;index as i">
                        <td align="center">{{i + 1}}</td>
                        <td align="center">
                            <label class="kt-checkbox kt-checkbox--single kt-checkbox--all kt-checkbox--brand">
                                <input type="checkbox" [(ngModel)]="row.isActive" [ngModelOptions]="{standalone: true}">
                                <span></span>
                            </label>
                        </td>
                        <td align="left" nowrap="">
                            {{row.connectorName}}
                        </td>

                        <td align="center" nowrap="">
                            <input class="form-control" [(ngModel)]="row.remark" [ngModelOptions]="{standalone: true}">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </ng-container>
    
    <div class="row" >
        <div class="col-sm-12">
            <div class="form-group mb-0">
                <label class="kt-font-primary">{{ '_SHARED.REMARK' | translate }}</label>
                <br>
                <textarea class="form-control" rows="5" formControlName="remark" [readonly]="isReaonly"></textarea>
            </div>
        </div>
    </div>
    
</div>
<div class="modal-footer">
    <div class="col-lg-12 text-right">
        <button type="button" class="btn btn-primary waves-effect" *ngIf="isReaonly == false"
            (click)="onSaveClick()">{{ '_SHARED.SAVE' | translate }}</button>&nbsp;
        <button type="button" class="btn btn-dark waves-effect"
            (click)="closeModal()">{{ '_SHARED.CLOSE' | translate }}</button>
    </div>
</div>