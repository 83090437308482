import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/http/api.service';
import { TimeLineConst } from 'src/app/core/view-models/_sprint3/_mock/time-line-const';
import { TimeLineViewModel } from 'src/app/core/view-models/_sprint3/_shared/time-line-view-model';

@Injectable({
  providedIn: 'root'
})
export class TimeLineService {

  constructor(private apiService:ApiService) { }

  /*  mock */ 
  // async get(): Promise<TimeLineViewModel[]> {
  //   const result = TimeLineConst as TimeLineViewModel[];
  //   return result;
  // }

  async get(id:number): Promise<TimeLineViewModel[]> {
    const result = await this.apiService.get<TimeLineViewModel[]>(`/inventory/edcs/${id}/timeline`);
    return result;
  }
}
