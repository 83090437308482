import { Component, OnInit } from '@angular/core';
import { WorksheetInformationViewModel } from 'src/app/core/view-models/_sprint3/_shared/worksheet-information-view-model';
import { StatusConstSp3 } from 'src/app/core/_static/sp3-const/status-const-sp3';
import { SettingCalloutWorksheetInfo } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';

@Component({
  selector: 'app-view-shared-work-sheet-information',
  templateUrl: './view-shared-work-sheet-information.component.html',
  styleUrls: ['./view-shared-work-sheet-information.component.scss']
})
export class ViewSharedWorkSheetInformationViewComponent implements OnInit {

  item:SettingCalloutWorksheetInfo = new SettingCalloutWorksheetInfo;
  constructor() { }

  ngOnInit(): void {
  }
  async loadInitial(data:SettingCalloutWorksheetInfo) {  
    this.item = data
  }
  get stateInboundStatus(){
    return StatusConstSp3.stateInboundStatus
  }
  get stateInboundType(){
    return StatusConstSp3.type
  }
}
