
import { Injectable } from '@angular/core';
import { Mapper } from '../mapper';
import { TopicHeaderModel } from '../../models/_shared/inbound-shared.model';
import { TopicHeaderViewModel } from '../../view-models/_inbound/inbound.view-model';

@Injectable({
    providedIn: 'root'
})
export class InboundTopicHeaderMapper implements Mapper<TopicHeaderModel, TopicHeaderViewModel>{
    constructor(){}
    mapForm(param: TopicHeaderModel): TopicHeaderViewModel {
        return {
            ...param
        };
    }
    mapTo(param: TopicHeaderViewModel): TopicHeaderModel {
        return {
            ...param
        };
    }

}
