import { Injectable } from '@angular/core';
import { InboundSupportListViewModel, SearchInboundSupportCriteria, InboundSupportViewModel, OnSiteSupportViewModel, ImportantShowModel } from 'src/app/core/view-models/_sprint3/support/inbound-model';
import { dataInbound } from 'src/app/core/view-models/_sprint3/_mock/admin-tpl-inbound-const';
import { ApiService } from 'src/app/core/http/api.service';
import { PageRequestModel, PageResponseModel } from 'src/app/core/models/_shared/page.model';
import { OnSiteSupport } from 'src/app/core/view-models/_sprint3/_mock/on-site-support';
import { WorksheetGeneralViewModel, SimInformationViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
import { dataWroksheet, dataWroksheetPM, dataWroksheetSupport, dataWroksheetClosejob, seriallistWorksheetConst, simData } from 'src/app/core/view-models/_sprint3/_mock/worksheet-const';
import { WorksheetPMViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-pm-model';
import { WorksheetListViewModel, SearchWorksheetListViewModel, WorksheetCloseJobViewModel, WorksheetCloseJobSNListViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-list-share.view-model';
import { worksheetdataList } from 'src/app/core/view-models/_sprint3/_mock/worksheet-list-const';
import { WorksheetSupportViewModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-support-model';
import { SetTypeConst } from 'src/app/core/view-models/_sprint3/_mock/set-type-const';
import { CreateGeneralWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-general-mapper';
import { CreatePmWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-pm-mapper';
import { CreateSupportWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-support-mapper';
import { WorksheetPMApisModel, GetWorksheetPMApisModel, GetWorksheetBkkSetJobApisModel } from 'src/app/core/models/_sprint3/worksheet/worksheet-api-view-model';
import { CreatePmGetWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-pm-get-mapper';
import { AvailableEdc } from 'src/app/core/view-models/_shared/worksheet.view-model';
import { SetTypeMapper } from 'src/app/core/models-mapper/_shared/set-type-mapper';
import { SetTypeViewModel } from 'src/app/core/view-models/_sprint3/worksheet/set-type-view-model';
import { CreateReprogramWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-reprogram-mapper';
import { AdminBKKClosejobMapper } from 'src/app/core/models-mapper/_sprint3/admin-bkk/close-job-mapper';
import { WorksheetCloseJobApisModel, SaveCloseModel, SaveCloseReviseModel } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { UpdateStoreWorkareaEdc } from 'src/app/core/view-models/_sprint3/_shared/store-task-information-view-model';
import { WorksheetBKKSetJobViewModel, SaveWorksheetBKKSetJobModel, WorksheetAllViewModel, WorksheetBKKRefrestJobViewModel, WorksheetBKKRefrestJobSaveModel, HistoryTechnician } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { CreateBKKSetJobGetWorksheetMapper } from 'src/app/core/models-mapper/worksheet/create-bkk-set-job-get-mapper';
import { SearchWorksheetMonitoringCriteriaViewModel, WorksheetMonitoringApiItemViewModel } from 'src/app/core/models/_sprint3/worksheet/worksheet-monitoring-api-view-model';
import { WorkAreaWorkSheetListViewModel } from 'src/app/core/view-models/_sprint3/store/work-area-worksheet.model';
import { WorksheetMonitoringMapper } from 'src/app/core/models-mapper/worksheet/worksheet-monitoring-mapper';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { SaveRejectModel } from 'src/app/core/models/user/worksheet/worksheet-store';
import { SettingCalloutGetDetailViewModel } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';
import { SettingCalloutGetDetailApisModel } from 'src/app/core/models/_sprint3/setting/setting-callout-model';
import { GetSettingCalloutMapper } from 'src/app/core/models-mapper/setting/setting-get-callout-mapper';

@Injectable({
  providedIn: "root",
})
export class SharedWorksheetService {


  constructor(
    private apiService: ApiService,
    private _generalMapper: CreateGeneralWorksheetMapper,
    private _pmMapper: CreatePmWorksheetMapper,
    private _supportMapper: CreateSupportWorksheetMapper,
    private _pmGetMapper: CreatePmGetWorksheetMapper,
    private setTypeMapper: SetTypeMapper,
    private _reprogramMapper: CreateReprogramWorksheetMapper,
    private _closejobMapper : AdminBKKClosejobMapper,
    private _setJobGetMapper: CreateBKKSetJobGetWorksheetMapper,
    private worksheetMonitoringMapper: WorksheetMonitoringMapper,
    private _settingCalloutGetmapper: GetSettingCalloutMapper,
  ) { }


  // async getListworksheet(pageRequest: PageRequestModel<SearchWorksheetListViewModel>): Promise<WorksheetListViewModel[]> {
  //   const result = worksheetdataList as WorksheetListViewModel[];
  //   // const result = await this.apiService.post<InboundSupportListViewModel>("/inbounds/t1/search-daily-tasks", pageRequest);
  //   return result;
  //   // return this._mapper.mapForm(result);
  // }
  async getListworksheet(pageRequest: PageRequestModel<SearchWorksheetMonitoringCriteriaViewModel>): Promise<PageResponseModel<WorkAreaWorkSheetListViewModel>> {
    const dataResponse: PageResponseModel<WorkAreaWorkSheetListViewModel> = new PageResponseModel<WorkAreaWorkSheetListViewModel>();
    const result = await this.apiService.post<PageResponseModel<WorksheetMonitoringApiItemViewModel>>(`/support/inbounds/worksheet/search`, pageRequest);
    dataResponse.pageCount = result.pageCount;
    dataResponse.pageNumber = result.pageNumber;
    dataResponse.pageSize = result.pageSize;
    dataResponse.totalItemCount = result.totalItemCount;
    dataResponse.items = result.items.map(map => this.worksheetMonitoringMapper.mapForm(map));

    return dataResponse;
  }

  async temp(item: workType): Promise<workSheet> {
    return await this.apiService.patch<workSheet>(`/worksheets/create-temp`, item);
  }
  async tempOrder(item: OrderType): Promise<workSheetOrderType> {
    return await this.apiService.patch<workSheetOrderType>(`/worksheets/create-temp-order`, item);
  }
  async getChangeTechnician(id :number): Promise<HistoryTechnician[]> { 
    const result = await this.apiService.get<HistoryTechnician[]>(`/worksheets/${id}/technician-history`)
    return result
  }
  async getInstall(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/install/${id}`);
    return this._pmGetMapper.mapForm(result);
  }
  async getAvailableEdcs(id: number, clientId: number): Promise<AvailableEdc[]> {
    const result = await this.apiService.get<AvailableEdc[]>(`/worksheet/install/${id}/available-edcs?clientId=${clientId}`);
    return result;
  }
  async saveInstall(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/install/${id}`, request);
  }
  async submitInstall(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/install/${id}/submit`, request);
  }


  async getReprogram(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/reprogram/${id}`);
    return this._pmGetMapper.mapForm(result);
  }
  async getAvailableEdcsReprogram(id: number, clientId: number): Promise<AvailableEdc[]> {
    var query = {
      clientId: clientId
    }
    const result = await this.apiService.get<AvailableEdc[]>(`/worksheet/reprogram/${id}/available-edcs`, query);
    return result;
  }
  async saveReprogram(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._reprogramMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/reprogram/${id}`, request);
  }
  async submitReprogram(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._reprogramMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/reprogram/${id}/submit`, request);
  }


  async getRemove(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/remove/${id}`);
    return this._pmGetMapper.mapForm(result);
  }
  async getAvailableEdcsRemove(id: number, clientId: number): Promise<AvailableEdc[]> {
    const result = await this.apiService.get<AvailableEdc[]>(`/worksheet/remove/${id}/available-edcs?clientId=${clientId}`);
    return result;
  }
  async saveRemove(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/remove/${id}`, request);
  }
  async submitRemove(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/remove/${id}/submit`, request);
  }
  async getService(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/services/${id}`);
    return this._pmGetMapper.mapForm(result);
  }

  async getPm(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/pm/${id}`);
    return this._pmGetMapper.mapForm(result);
  }
  async getAvailableEdcsPm(id: number, clientId: number): Promise<AvailableEdc[]> {
    const result = await this.apiService.get<AvailableEdc[]>(`/worksheet/pm/${id}/available-edcs?clientId=${clientId}`);
    return result;
  }
  async savePm(id: number, item: WorksheetPMViewModel): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/pm/${id}`, request);
  }
  async submitPm(id: number, item: WorksheetPMViewModel): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/pm/${id}/submit`, request);
  }


 

  async getGeneral(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/general/${id}`);
    return this._pmGetMapper.mapForm(result);
  }

  async saveGeneral(id: number, item: WorksheetPMViewModel): Promise<boolean> {
    const request = this._pmGetMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/general/${id}`, request);
  }
  async submitGeneral(id: number, item: WorksheetPMViewModel): Promise<boolean> {
    const request = this._pmGetMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/worksheet/general/${id}/submit`, request);
  }
 
  async getSupport(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/worksheet/support/${id}`);
    return this._pmGetMapper.mapForm(result);
  }
   async saveSupport(id: number,item: WorksheetSupportViewModel): Promise<boolean> {
    const request = this._supportMapper.mapTo(item);
    return await this.apiService.post<boolean>(`/worksheet/support/${id}`, request);
  }
  async submitSupport(id: number,item: WorksheetSupportViewModel): Promise<boolean> {
    const request = this._supportMapper.mapTo(item);
    return await this.apiService.post<boolean>(`/worksheet/support/${id}/submit`, request);
  }
  async getCloseJob(id:number): Promise<WorksheetCloseJobViewModel> {
    const result = await this.apiService.get<WorksheetCloseJobApisModel>(`/worksheets/onsite/${id}`); 
    return  this._closejobMapper.mapForm(result);
  }
  async getRecheckCloseJob(id:number): Promise<WorksheetCloseJobViewModel> {
    const result = await this.apiService.get<WorksheetCloseJobApisModel>(`/worksheets/recheck-close-job/${id}`); 
    return  this._closejobMapper.mapForm(result);
  } 
  async submitRecheckCloseJob(id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheets/recheck-close-job/${id}`);
    return result;

  }
  async saveCloseJob(id: number ,updateRequest: SaveCloseModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheets/onsite/${id}`, updateRequest);
    return result;

  }
  async submitCloseJob(id: number , updateRequest: SaveCloseModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheets/onsite/${id}/submit`, updateRequest);
    return result;

  }

  async getSetJob(id:number): Promise<WorksheetBKKSetJobViewModel> {
    const result = await this.apiService.get<GetWorksheetBkkSetJobApisModel>(`/worksheets/onsite/${id}`); 
    return  this._setJobGetMapper.mapForm(result);
  }

  async saveSetJob(id: number ,updateRequest: WorksheetBKKSetJobViewModel): Promise<boolean> {
    const data : SaveWorksheetBKKSetJobModel ={
      employeeId : updateRequest.employeeId,
      receiveJobDate :  DateUtils.toDate(updateRequest.receiveJobDate),
      receiveJobHour : updateRequest.receiveJobHour,
      receiveJobMinute : updateRequest.receiveJobMinute,
      remark : updateRequest.receiveJobRemark 
    }
    const result = await this.apiService.patch<boolean>(`/worksheets/receive-job/${id}`, data);
    return result;

  }
  async saveRefreshJob(id: number ,updateRequest: WorksheetBKKRefrestJobSaveModel): Promise<boolean> { 
    const result = await this.apiService.patch<boolean>(`/worksheets/broken/${id}/save`, updateRequest);
    return result; 
  }
  async submitSetJob(id: number , updateRequest: WorksheetBKKSetJobViewModel): Promise<boolean> {
    const data : SaveWorksheetBKKSetJobModel ={
      employeeId : updateRequest.employeeId,
      receiveJobDate : DateUtils.toDate(updateRequest.receiveJobDate) ,
      receiveJobHour : updateRequest.receiveJobHour,
      receiveJobMinute : updateRequest.receiveJobMinute,
      remark : updateRequest.receiveJobRemark 
    }
    const result = await this.apiService.patch<boolean>(`/worksheets/receive-job/${id}/submit`, data);
    return result;

  }
  async seriallist(): Promise<WorksheetCloseJobSNListViewModel[]> {
    const result = seriallistWorksheetConst as WorksheetCloseJobSNListViewModel[]
    return result
  }

  async setTypeSelect(id: number, edcId: number, updateRequest: SetTypeViewModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/order/re-programs/${id}/edcs/${edcId}/type`, updateRequest);
    return result;

  }
  async getSim(id:number): Promise<SimInformationViewModel> {
    const result = await this.apiService.get<SimInformationViewModel>(`/store/work-area/sims/${id}`);
    return result
  }
  async saveSim(id:number,request:UpdateStoreWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/store/work-area/sims/${id}/take`,request);
    return result
  }
  async submitSim(id:number,request:UpdateStoreWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/store/work-area/sims/${id}/take/submit`,request);
    return result
  }
  async deleteWorksheet(id: number): Promise<boolean> {
    const result = await this.apiService.delete<boolean>(`/worksheets/${id}`);
    return result;
  } 


  async getWorksheetAll(id: number): Promise<WorksheetAllViewModel> {
    const result = await this.apiService.get<WorksheetAllViewModel>(`/worksheets/${id}/overview`);
    return result;
  }
  async getSettingcallout(id:number): Promise<SettingCalloutGetDetailViewModel> { 
    const result = await this.apiService.get<SettingCalloutGetDetailApisModel>(`/setting/call-out/${id}`);
    return this._settingCalloutGetmapper.mapForm(result);
  }   
  
  async saveKeep( id: number): Promise<boolean> { 
    return await this.apiService.patch<boolean>(`/worksheets/${id}/keep-print-log`);
  }
  async getRevise(id: number): Promise<WorksheetPMViewModel> {
    const result = await this.apiService.get<GetWorksheetPMApisModel>(`/work-area/worksheets/${id}/revise`);
    return this._pmGetMapper.mapForm(result);
  }  
  async saveRevise(item: WorksheetPMViewModel, id: number): Promise<boolean> {
    const request = this._pmMapper.mapTo(item);
    return await this.apiService.patch<boolean>(`/work-area/worksheets/${id}/revise`, request);
  } 
  async saveReject(item: SaveRejectModel, id: number): Promise<boolean> { 
    return await this.apiService.patch<boolean>(`/work-area/worksheets/${id}/revise`, item);
  } 
  async saveCancelEdc(item: SaveRejectModel,id: number): Promise<boolean> { 
    return await this.apiService.patch<boolean>(`/worksheets/${id}/cancel`, item);
  } 


  async getCloseJobRevise(id:number): Promise<WorksheetCloseJobViewModel> {
    const result = await this.apiService.get<WorksheetCloseJobApisModel>(`/worksheets/${id}/onsite`); 
    return  this._closejobMapper.mapForm(result);
  }
  async saveCloseJobRevise(id: number ,updateRequest: SaveCloseReviseModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheets/${id}/onsite/revise`, updateRequest);
    return result;

  }
}

export class workSheet {
  worksheetId: number
}
export class workType {
  workTypeId: number
}
export class workSheetOrderType {
  worksheetId: number;
  workTypeId: number;
}
export class OrderType {
  orderId: number
}