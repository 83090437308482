import { Component, OnInit } from '@angular/core';
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { ChartService } from 'src/app/core/services/shared/chart.service';
import { ChartDashboardService } from 'src/app/core/services/shared/chart-dashboard.service';
import { UserDashboardService } from 'src/app/core/services/dashboard/user-dashboard.service';
import { DashboardCSOrderViewModel } from 'src/app/core/models/_shared/dashboard.model';

am4core.useTheme(am4themes_animated);

@Component({
  selector: 'app-bank-order-chart',
  templateUrl: './bank-order-chart.component.html',
  styleUrls: ['./bank-order-chart.component.scss']
})
export class BankOrderChartComponent implements OnInit {

  data: DashboardCSOrderViewModel[] = [];

  constructor(
    public chartService: UserDashboardService,
  ) { }
  ngOnInit(): void {

  }


  async ngAfterViewInit() {

    let chart = am4core.create("bankChart", am4charts.XYChart);


    chart.colors.list = [
      am4core.color("#90EE80"),
      am4core.color("#C3FACC"),
      am4core.color("#3FD9FF"),
      am4core.color("#1F97E7"),
      am4core.color("#5845B1"),
    ];

    this.data = await this.chartService.getOrderCs();
    // Add data
    chart.data = this.data;

    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "label";
    // categoryAxis.title.text = "Local country offices";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    // valueAxis.title.text = "Expenditure (M)";

    // Create series
    function createSeries(field, name, stacked) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "label";
      series.name = name;
      series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
      series.stacked = stacked;
      series.columns.template.width = am4core.percent(95);
    }

    createSeries("install", "Install", true);
    createSeries("reprogram", "Reprogram", true);
    createSeries("remove", "Remove", true);


    // Add legend
    chart.legend = new am4charts.Legend();

  }

}


