import { Component, OnInit, Input } from '@angular/core';
import { ShopViewModel } from 'src/app/core/view-models/_shop/shop.view-model';

@Component({
  selector: 'app-shared-readonly-shop-detail',
  templateUrl: './shared-readonly-shop-detail.component.html',
  styleUrls: ['./shared-readonly-shop-detail.component.scss']
})
export class SharedUserReadonlyShopDetailComponent implements OnInit {
  @Input()
  shop: ShopViewModel = new ShopViewModel();
  orderId: number = 0;
  constructor() { }

  ngOnInit(): void {
  }
  public setInitial(item: ShopViewModel) {
    console.log(item); 
    this.shop = item;
    this.shop.id = item.id;
    this.shop.code= item.code;
    this.shop.group = item.group;
    this.shop.nameTH = item.nameTH;
    this.shop.nameEN = item.nameEN;
    this.shop.address = item.address;
    this.shop.province = item.province;
    this.shop.district = item.district;
    this.shop.subDistrict = item.subDistrict;
    this.shop.posCode = item.posCode;
    this.shop.contactName = item.contactName;
    this.shop.contactPhoneNumber = item.contactPhoneNumber;
    this.shop.contactFaxNumber = item.contactFaxNumber;
    this.shop.email = item.email;
    this.shop.location = item.location;
    this.shop.reg = item.reg;
    this.shop.branchName = item.branchName;
    this.shop.bankName = item.bankName;
    this.shop.bankPhoneNumber = item.bankPhoneNumber;
    this.shop.code = item.code;
  } 
}
