<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'_SHARED.INFORMATION' | translate }}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="kt-form">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.FORM' | translate }} : </label>
                            <ng-container *ngFor="let data of item?.froms">
                                <span class="badge badge-dark badge-custom" >{{data?.name}}</span>&nbsp;
                            </ng-container>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.COMMAND' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{item?.commandName}}</span>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.STATE' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{item?.stateName}}</span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.STATUS' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{item?.statusName}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TYPE' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{item?.workTypeName}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.SUB_TYPE' | translate }} : </label>
                            <span class="badge badge-dark badge-custom">{{item?.workSubTypeName}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.S_N' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">
                                 <a href="#" data-toggle="modal"
                                    data-target="#worksheet_view"
                                    class="font-bold link">{{item?.serialNumber}} </a>

                            </span> 
                            <!-- <a  href="javascript:;" ><i class="fa fa-exchange-alt text-danger"></i></a> -->
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TYPE' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.workTypeName}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TID' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.tid}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.WORKSHEET_NO' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">
                                <a href="#" data-toggle="modal"
                                   data-target="#worksheet_view"
                                   class="font-bold link">{{item?.worksheetNo}}</a>
                                </span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.START_DATE_TIME' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.startDate | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.PLACE_IN' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.EndDate | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.NEXT_TO' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator" *ngFor="let data of item.nextTos">{{data.name}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.MODEL' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.modalName}}</span>
                            <br>
                        </div>
                    </div> 
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.BOX' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.box}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.LOCATION' | translate }} : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.location}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.ACTUAL_DATE_TIME' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.actualDate | date: 'dd/MM/yyyy HH:mm'}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TASK_OWNER' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.taskOwnerName}}</span>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">{{'_SHARED.TASK_RESPONSIBLE' | translate }}
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.taskResponsible}}</span>
                            <br>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group mb-0">
                            <label class="col-sm-5 kt-font-primary">Sap Mat Code
                                : </label>
                            <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item?.materialCode}}</span>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
