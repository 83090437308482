import { Component, OnInit } from '@angular/core';
import { NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyParserFormatter } from 'src/app/shared/ngb/CustomDateFormatter';
import { ContactModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { FileModel } from 'src/app/core/models/_shared/file.model';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { CommonService } from 'src/app/core/services/common.service';
import { FileUtils } from 'src/app/shared/utils/file-utils.service';
import { ModalMultiFileComponent } from '../_modal/modal-multi-file/modal-multi-file.component';
import { AttachFile } from 'src/app/core/models/_sprint3/setting/setting-task-model';

@Component({
  selector: 'app-worksheet-contact-view-shared',
  templateUrl: './worksheet-contact-view-shared.component.html',
  styleUrls: ['./worksheet-contact-view-shared.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: MyParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorksheetContactViewSharedComponent implements OnInit {
  item: ContactModel = new ContactModel; 
  fileToUpload: File; 
  fileSaveData: FileModel = new FileModel();
  constructor(
    private fb: FormBuilder, 
    private commonService: CommonService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void { 
  } 
  public async setInitial(item: ContactModel) { 
    this.item = item 
    if(item.commandFile != null){
      this.fileSaveData.id = item.commandFile?.attachFileId
      this.fileSaveData.url = item.commandFile?.url
    }
    this.fileSaveData.originalName = item.commandFile?.name;
  }
 

  fileName: string;
  File: any = new FormData();
  tempFile: any = null;

  async fileEvent(fileInput:FileList){
    const fileToUpload:File = fileInput.item(0);
    const ext = FileUtils.getFileName(fileToUpload.name);
    this.fileToUpload = fileToUpload;
    this.fileName = this.fileToUpload.name;
    if(this.fileToUpload != null){
      const resp = await this.commonService.uploadFile(this.fileToUpload);  
      this.fileSaveData = resp
      
    }
  } 

  onFileClick(){
    window.open(this.fileSaveData.url,'_blank')
  }

  fileInputclick() {
    const modalRef = this.modalService.open(ModalMultiFileComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.isView = true;
    modalRef.componentInstance.attachFiles = this.item.attachFiles;
    modalRef.componentInstance.onReturn.subscribe((result: AttachFile[]) => {
      this.item.attachFiles = result;
      this.item.attachFileIdList = result.filter(x => x.id).map(x => x.id);
      console.log(this.item);
      console.log(this.item.attachFileIdList);
    });
  }
}
