<div class="modal-header">
    <h4 class="modal-title">{{'INBOUND_T1.REMARK' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModalClose.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body ">
    <!-- <textarea>{{responsibleRemark}}</textarea> -->
    <div class="row form-group">
        <div class="col-12">
            <textarea class="form-control" rows="5" readonly>{{remark}}</textarea>
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="text-right">
        <button type="button" class="btn btn-dark waves-effect" (click)="activeModalClose.dismiss()">{{ 'INBOUND_T1.CLOSE' | translate}}</button>
        <!--                <a href="appointment.php" class="btn btn-primary waves-effect">Yes</a>-->
    </div>
</div>