import { Component, OnInit, Input } from '@angular/core'; 
import { FileModel } from 'src/app/core/models/_shared/file.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { CommonService } from 'src/app/core/services/common.service';
import { ModalGalleryViewComponent } from '../_modal/modal-gallery-view/modal-gallery-view.component';
import { FileUtils } from 'src/app/shared/utils/file-utils.service';
import { InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { MechanicModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
import { MyParserFormatter } from 'src/app/shared/ngb/CustomDateFormatter';
import { ModalMultiFileComponent } from '../_modal/modal-multi-file/modal-multi-file.component';
import { AttachFile } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';

@Component({
  selector: 'app-worksheet-for-mechanic-view',
  templateUrl: './worksheet-for-mechanic-view.component.html',
  styleUrls: ['./worksheet-for-mechanic-view.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: MyParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorksheetForMechanicViewComponent implements OnInit {
  @Input() canEdit :boolean = false
  item: MechanicModel = new MechanicModel
  submitted:boolean;
  
  fileToUpload: File; 
  fileSaveData: FileModel = new FileModel();
  
  Validate: FormGroup;
  minDate = DateUtils.getMinDate()
  hours: InboundTimeSelectViewModel[] = [];
  minutes: InboundTimeSelectViewModel[] = [];
  constructor(
    private modalService: NgbModal,
    private dialogService: MyDialogService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private _sharedService: InboundSharedService,
    ) { }

  ngOnInit(): void {
  }

  public async setInitial(item: MechanicModel) {
    this.hours = await this._sharedService.hours();
    this.minutes = await this._sharedService.minutes();
    this.item = item
    if(item.attachFile != null){
      this.fileSaveData.id = item.attachFile?.id
      this.fileSaveData.url = item.attachFile?.url
    }
    this.fileSaveData.originalName = item.attachFile?.originalName;
    if(item.attachFile == null || item.attachFile == undefined){
      this.fileSaveData = null;
    } 
    console.log(this.item);
  }

  public isValid(submit: boolean): boolean {
     
    this.submitted = submit;
    if (this.submitted) {
      // this.dialogService.warningValidate();
      // alert('aaaaaa');
      return false;
    }
    return true;
  }
  OnGalleryClick(item: MechanicModel) {

    item.pictures
    const modalRef = this.modalService.open(ModalGalleryViewComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.pictures = item.pictures;
    modalRef.componentInstance.clientSignaturePictureFile = this.item.clientSignaturePictureFile
    modalRef.componentInstance.canEdit = this.canEdit;
    
    // modalRef.componentInstance.onChooseItem.subscribe(item => {    

    // }); 
  }
  fileName: string;
  File: any = new FormData();
  tempFile: any = null;

  async fileEvent(fileInput:FileList){
    const fileToUpload:File = fileInput.item(0);
    const ext = FileUtils.getFileName(fileToUpload.name);
    this.fileToUpload = fileToUpload;
    this.fileName = this.fileToUpload.name;
    if(this.fileToUpload != null){
      const resp = await this.commonService.uploadFile(this.fileToUpload);  
      this.fileSaveData = resp
        
    }
  } 

  onFileClick(){
    window.open(this.fileSaveData.url,'_blank')
  }


  fileInputclick() {
    const modalRef = this.modalService.open(ModalMultiFileComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.isView = true;
    modalRef.componentInstance.attachFiles = this.item.attachFiles;
    modalRef.componentInstance.onReturn.subscribe((result: AttachFile[]) => {
      this.item.attachFiles = result;
      this.item.attachFileIdList = result.filter(x => x.id).map(x => x.id);
      console.log(this.item);
      console.log(this.item.attachFileIdList);
    });
  }
}
