import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { ApiPath } from '../../http/api-path';
import { PageRequestModel, PageResponseModel } from '../../models/_shared/page.model';
import { SearchWorksheetModelCriteria, WorksheetModel, SearchWorksheetCsCriteria, SearchWorksheetOpCriteria, SearchWorksheetStoreCriteria, WorksheetViewModel, SearchWorksheetViewDetailCriteria, WorksheetViewDetailModel, OpWorksheetListViewModel, OpWorksheetUpdateViewModel, UpdateOpWorksheetRequestModel } from '../../models/user/worksheet/worksheet';
import { TextSearchViewModel, SearchOrderInWorkSheetViewModel, OrderDetailListInWorksheet, CreateUpdateWorkSheetViewModel, WorkSheetUpdateDetail, AvailableEdc, ClientDetailListInWorksheet, Connector } from '../../view-models/_shared/worksheet.view-model';
import { StoreSearchWorksheetCriteria, WorkSheetStoreListViewModel, WorkSheetSearchEdcModel, SimWorkSheet } from '../../models/user/worksheet/worksheet-store';
import { LocationEdcConnectorViewModel } from '../../models/user/help-desk/worksheet-model';

@Injectable({
  providedIn: "root"
})
export class WorksheetService {
  constructor(private apiService: ApiService) { }

  async createWorksheet(): Promise<any> {
    const result = 2;
    // const result = await this.apiService.get<Array<ClientGroupModel>>(`${ApiPath.clientGroup.base}`);
    return result;
  }

  async search(
    pageRequest: PageRequestModel<SearchWorksheetModelCriteria>
  ): Promise<PageResponseModel<WorksheetModel>> {
    const result = await this.apiService.post<any>(
      "/worksheet/search",
      pageRequest
    );
    const pageResult = PageResponseModel.transform<WorksheetModel>(result);
    return pageResult;
  }
  async getById(id: number): Promise<WorksheetViewModel> {
    const result = await this.apiService.get<WorksheetViewModel>('/worksheet/' + id + '/edcs-sims');
    return result;
  }
  async searchSims(id: number, pageRequest: PageRequestModel<SearchWorksheetViewDetailCriteria>): Promise<PageResponseModel<WorksheetViewDetailModel>> {
    const result = await this.apiService.post<any>("/worksheet/" + id + "/search-sims", pageRequest);
    const pageResult = PageResponseModel.transform<WorksheetViewDetailModel>(result)
    return pageResult
  }
  // -----------------------------------------------------------

  async searchCs(
    pageRequest: PageRequestModel<SearchWorksheetCsCriteria>
  ): Promise<PageResponseModel<WorksheetModel>> {
    const result = await this.apiService.post<any>(
      "/worksheet/cs-search",
      pageRequest
    );
    const pageResult = PageResponseModel.transform<WorksheetModel>(result);
    return pageResult;
  }
  async searchOp(
    pageRequest: PageRequestModel<SearchWorksheetOpCriteria>
  ): Promise<PageResponseModel<OpWorksheetListViewModel>> {
    const result = await this.apiService.post<any>(
      "/worksheet/op-search",
      pageRequest
    );
    const pageResult = PageResponseModel.transform<OpWorksheetListViewModel>(result);
    return pageResult;
  }
  async getOpUpdate(id: number): Promise<OpWorksheetUpdateViewModel> {
    const result = await this.apiService.get<OpWorksheetUpdateViewModel>("/worksheet/op-edcs/"+id);
    return result;
  }

  async updateByOp(id: number,request: UpdateOpWorksheetRequestModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>("/worksheet/op-edcs/"+id,request);
    return result;
  }

  async searchStore(
    pageRequest: PageRequestModel<SearchWorksheetStoreCriteria>
  ): Promise<PageResponseModel<WorksheetModel>> {
    const result = await this.apiService.post<any>(
      "/worksheet/store-search",
      pageRequest
    );
    const pageResult = PageResponseModel.transform<WorksheetModel>(result);
    return pageResult;
  }

  // open service ฝั่ง อาร์ม อัครพงศ์ กรินรักษ์
  async serchOrderList(model: PageRequestModel<TextSearchViewModel>): Promise<PageResponseModel<SearchOrderInWorkSheetViewModel>> {
    const result = await this.apiService.post<PageResponseModel<SearchOrderInWorkSheetViewModel>>(`/worksheet/search-order`, model);
    return PageResponseModel.transform<SearchOrderInWorkSheetViewModel>(result);
  }
  async getOrderDetail(orderId: number): Promise<WorkSheetUpdateDetail> {
    const result = await this.apiService.get<WorkSheetUpdateDetail>(`/worksheet/order/${orderId}`);
    return result;
  }

  async get(id: number): Promise<WorkSheetUpdateDetail> {
    const result = await this.apiService.get<WorkSheetUpdateDetail>(`/worksheet/${id}`);
    return result;
  }

  async searchOrderEdc(eventType: number, clientId: number, typeOfWork: number): Promise<AvailableEdc[]> {
    const request = {
      typeOfWork: typeOfWork,
      clientId: clientId,
      eventType: eventType
    };
    const result = await this.apiService.post<AvailableEdc[]>(`/worksheet/search-order/`, request);
    return result;
  }


  async createCSWorksheet(model: CreateUpdateWorkSheetViewModel): Promise<boolean> {
    const result = await this.apiService.post<boolean>(`/worksheet`, model);
    return result;
  }

  // Update Zone

  async getCSWorksheetDetail(id: number): Promise<WorkSheetUpdateDetail> {
    const result = await this.apiService.get<WorkSheetUpdateDetail>(`/worksheet/${id}`);
    return result;
  }


  async updateCSWorksheet(worksheetId: number, model: CreateUpdateWorkSheetViewModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheet/${worksheetId}`, model);
    return result;
  }
  // cloase service ฝั่ง อาร์ม อัครพงศ์ กรินรักษ์

  //ทำหน้า u/worksheet/store
  async searchStoreWorkSheet(pageRequest: PageRequestModel<StoreSearchWorksheetCriteria>
  ): Promise<PageResponseModel<WorksheetModel>> {
    const result = await this.apiService.post<any>(
      "/worksheet/store-search",
      pageRequest
    );
    const pageResult = PageResponseModel.transform<WorksheetModel>(result);
    return pageResult;
  }
  async delete(id: number): Promise<boolean> {
    const result = await this.apiService.delete<boolean>(`/worksheet/${id}`);
    return result;
  }
  // store
  async getAllWorkSheetEdcSims(id: number): Promise<WorkSheetStoreListViewModel[]> {
    const result = await this.apiService.get<WorkSheetStoreListViewModel[]>(`/worksheet/${id}/edcs-sims`);
    return result;
  }

  async searchModelStoreWorkSheet(id: number, pageRequest: PageRequestModel<WorkSheetSearchEdcModel>
  ): Promise<PageResponseModel<SimWorkSheet>> {
    const result = await this.apiService.post<any>(
      `/worksheet/${id}/search-sims`,
      pageRequest
    );
    const pageResult = PageResponseModel.transform<SimWorkSheet>(result);
    return pageResult;
  }

  async updateEachSims(id: number, edcId: number, model): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheet/${id}/edcs/${edcId}/sim`, model)
    return result;
  }

  async generateEdcSims(id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheet/${id}/edcs-sims/generate`)
    return result;
  }

  async saveWorkSheetSims(id: number, model): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheet/${id}/edcs-sims`, model)
    return result;
  }

  async rejectByStore(id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/worksheet/${id}/edcs-sims-reject`)
    return result;
  }
  
  async searchAll(
    pageRequest: PageRequestModel<SearchWorksheetCsCriteria>
  ): Promise<PageResponseModel<WorksheetModel>> {
    const result = await this.apiService.post<any>(
      "/worksheet/cs-search",
      pageRequest
    );
    const pageResult = PageResponseModel.transform<WorksheetModel>(result);
    return pageResult;
  }

  async connectors(worksheetId: number, edcId: number): Promise<Connector[]> {
    return await this.apiService.get<Connector[]>(`/worksheet/reprogram/${worksheetId}/edcs/${edcId}/connectors`);
  }
}
