
import { Injectable } from '@angular/core';
import { Mapper } from '../mapper';
import { InputTypeViewModel } from '../../view-models/_inbound/inbound.view-model';
import { InputTypeModel } from '../../models/_shared/inbound-shared.model';

@Injectable({
    providedIn: 'root'
})
export class InboundInputTypeMapper implements Mapper<InputTypeModel, InputTypeViewModel>{
    constructor(){}
    mapForm(param: InputTypeModel): InputTypeViewModel {
        return {
            ...param
        };
    }
    mapTo(param: InputTypeViewModel): InputTypeModel {
        return {
            ...param
        };
    }

}