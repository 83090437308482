import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: 'input[notThaiChar]'
})
export class NotThaiCharDirective {
  private thaiCharRegex: RegExp = new RegExp(/[ก-๙ ]/g);
  // Allow key codes for special events
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  constructor(
    private _el: ElementRef
  ) { }
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this._el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && String(next).match(this.thaiCharRegex)) {
        event.preventDefault();
    }
  }
  @HostListener('input', ['$event']) onInputChange(event: KeyboardEvent) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(this.thaiCharRegex, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
