import { Component, OnInit, ViewChild } from '@angular/core';
import { InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model'; 
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { WorksheetForMechanicViewComponent } from 'src/app/features/user/shared-sp3/worksheet-for-mechanic-view/worksheet-for-mechanic-view.component';
import { SearchEdcListModel } from 'src/app/core/view-models/_order/order-reprogram.view-model'; 
import { SerialNumberModalComponent } from 'src/app/features/user/shared-sp3/serial-number-modal/serial-number-modal.component';
import { EdcCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';
import { WorksheetAllViewModel, WorksheetAllDetailViewModel, AddOnEdcJobApis } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalJobEdcStatusComponent } from 'src/app/features/user/shared-sp3/_modal/modal-job-edc-status/modal-job-edc-status.component';
import { DateUtils } from 'src/app/shared/utils/date-utils';
import { TransformOrderService } from 'src/app/core/models-mapper/transform.order';
import { HistoryTechnicianComponent } from 'src/app/features/user/shared-sp3/_modal/history-technician/history-technician.component';
import { ModalTidHistoryComponent } from 'src/app/features/user/shared-sp3/_modal/modal-tid-history/modal-tid-history.component';
import { WorksheetDetailByMobileComponent } from 'src/app/features/user/shared-sp3/_modal/worksheet-detail-by-mobile/worksheet-detail-by-mobile.component';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class WorksheetViewDetailComponent implements OnInit {
  minDate = DateUtils.getMinDate()
  hours: InboundTimeSelectViewModel[] = [];
  minutes: InboundTimeSelectViewModel[] = [];  
  iscustomer:boolean;
  @ViewChild('forMechanic', { static: true }) forMechanic: WorksheetForMechanicViewComponent;
  id:number;
  item :WorksheetAllDetailViewModel = new WorksheetAllDetailViewModel(); 
  searchFilter2: SearchEdcListModel = new SearchEdcListModel();  
  searchFilter3: SearchEdcListModel = new SearchEdcListModel();  
  constructor(
    private _sharedService: InboundSharedService,
    private modalService: NgbModal,
    private transformForMechanicModel: TransformOrderService
    ) { }

  ngOnInit(): void {
    this.loadInitial();  
  }
  async loadInitial() {  
    console.log(this.item);
    this.forMechanic.setInitial(this.transformForMechanicModel.transformForMechanicModel(this.item.technicianForm,this.item.pictures,this.item.clientSignaturePictureFile))
  }

  OnSerialNumberClick(item: EdcCloseJobApis) {
    const modalRef = this.modalService.open(SerialNumberModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xl'
    });
    modalRef.componentInstance.orderId = this.id;
    modalRef.componentInstance.edcId = item.edcId;
  }

  onCloseJobClick(item: EdcCloseJobApis) {
    const modalRef = this.modalService.open(ModalJobEdcStatusComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.item = item;
    modalRef.componentInstance.isView = true;
    modalRef.componentInstance.iscustomer = this.iscustomer;
    
  }
  onDetailCloseJobClick(item: AddOnEdcJobApis) {
    const modalRef = this.modalService.open(WorksheetDetailByMobileComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.remark = item.remark;
    modalRef.componentInstance.detail = item.detail; 
    
  }
  onHistoryClick(){
    const modalRef = this.modalService.open(HistoryTechnicianComponent,{
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg'
    });
    modalRef.componentInstance.id = this.id
  }
  openTidHistory(tid: number) {

    const modalRef = this.modalService.open(ModalTidHistoryComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'xl'
    });
    modalRef.componentInstance.tid = tid;  
  }
}
