import { Directive, Output, EventEmitter, ViewChildren, Input, QueryList, ElementRef, ContentChild, ViewContainerRef, Optional, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, fromEvent, Subject } from 'rxjs';
import { startWith, debounceTime, map } from 'rxjs/operators';


@Directive({
  selector: '[col-filter-name]'
})
export class ColFilterNameDirective {
  @Input('col-filter-name') name: string;
  onColumnChange: Subject<string>;
  constructor(
    public viewContainerRef: ViewContainerRef,
    private element: ElementRef,
    @Optional() parent: ColFilterDirective
  ) {
    if (parent) {
      parent.registerRef(this);
    }
    this.onColumnChange = new Subject<string>();

    fromEvent(element.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        map(e => this.emitKeyUp(element.nativeElement.value)))
      .subscribe();
  }

  emitKeyUp(value: string): any {
    this.onColumnChange.next(value);
  }

}

@Directive({
  selector: 'tr[colFilter]'
})
export class ColFilterDirective {
  @Output() colFilterChange = new EventEmitter<any>();
  private nameDirectives: ColFilterNameDirective[] = [];
  private cols: any = {};

  constructor(
    private el: ElementRef
  ) {

  }
  ngAfterContentInit(): void {
    this.nameDirectives.forEach(n => {
      this.cols[n.name] = '';
      n.onColumnChange.subscribe(value => {
        this.cols[n.name] = value;
        this.colFilterChange.emit(this.cols);
      });
    });
  }

  registerRef(ref: ColFilterNameDirective) {
    this.nameDirectives.push(ref);
  }
}



