import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { EdcSelectModel, SearchEdcClientCriteriaModel, EdcClientsApiViewModel, EdcTidHistoryApiViewModel, SearchEdcTidHistoryCriteriaModel } from '../../models/user/edc-models/edc-models';
import { PageRequestModel, PageResponseModel } from '../../models/_shared/page.model';


@Injectable({
  providedIn: 'root'
})
export class EdcModelsService {

  constructor(
    private apiService: ApiService
  ) { }
  
  async getBySelect(): Promise<Array<EdcSelectModel>>{
    const result = await this.apiService.get<Array<EdcSelectModel>>("/commons/edc-models");
    return result;
  }
  
  async searchEdc(id:number,pageRequest: PageRequestModel<SearchEdcClientCriteriaModel>): Promise<PageResponseModel<EdcClientsApiViewModel>> {
    const dataResponse: PageResponseModel<EdcClientsApiViewModel> = new PageResponseModel<EdcClientsApiViewModel>();
    const result = await this.apiService.post<PageResponseModel<EdcClientsApiViewModel>>(`/clients/${id}/search-edc`, pageRequest);
    dataResponse.pageCount = result.pageCount;
    dataResponse.pageNumber = result.pageNumber;
    dataResponse.pageSize = result.pageSize;
    dataResponse.totalItemCount = result.totalItemCount;
    dataResponse.items = result.items
    return dataResponse;
  }
  async searchEdcTidHistory(id:number,pageRequest: PageRequestModel<SearchEdcTidHistoryCriteriaModel>): Promise<PageResponseModel<EdcTidHistoryApiViewModel>> {
    const dataResponse: PageResponseModel<EdcTidHistoryApiViewModel> = new PageResponseModel<EdcTidHistoryApiViewModel>();
    const result = await this.apiService.post<PageResponseModel<EdcTidHistoryApiViewModel>>(`/terminal-id/${id}/history`, pageRequest);
    dataResponse.pageCount = result.pageCount;
    dataResponse.pageNumber = result.pageNumber;
    dataResponse.pageSize = result.pageSize;
    dataResponse.totalItemCount = result.totalItemCount;
    dataResponse.items = result.items
    return dataResponse;
  }
}
