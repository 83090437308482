import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper';
import { SettingCalloutShopDetailInfo } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';
import { Client } from 'src/app/core/view-models/_shared/worksheet.view-model';
import { ShopDetailAllViewModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';

@Injectable({
    providedIn: "root",
})
export class AdminAllWorksheetShopShopMapper implements Mapper<Client, ShopDetailAllViewModel> {
    mapForm(param: Client): ShopDetailAllViewModel {
        const item = new ShopDetailAllViewModel();
        item.shopCode = param.code;
        item.shopNameTH = param.nameTH;
        item.shopNameEN = param.nameEN;
        item.address = param.address;
        item.provinceName = param.provinceName;
        item.districtName = param.districtName;
        item.subdistrictName = param.subDistrictName;
        item.zipCode = param.zipCode;
        item.callPhoneNumber = param.contactPhoneNumber;
        item.contactName = param.contactName;
        item.contactPhoneNumber = param.contactPhoneNumber;
        item.contactFaxNumber = param.contactFaxNumber;
        item.email = param.email;
        return item;
    }
    mapTo(param: ShopDetailAllViewModel): Client {
        const item = new Client(); 
        item.id = param.id;
        item.code = ''
        item.group = null ;
        item.branchName =  ''
        item.nameTH = param.shopNameTH
        item.nameEN = param.shopNameEN
        item.address = param.address
        item.addressTH = param.address
        
        item.zipCode = param.zipCode
        item.contactName = param.contactName
        item.contactPhoneNumber = param.contactPhoneNumber
        item.contactTel = param.contactPhoneNumber
        item.contactFaxNumber = param.contactFaxNumber
        item.email = param.email
        item.placeInLocation = param.location
        item.location = param.location
        item.reg = param.reg
        item.bankPhoneNumber =  ''
        item.bankName =  ''
        
        item.posCode = param.zipCode
        item.locationCode = ''
        item.bankTel =  ''
        item.bankBranchName =  ''
        item.provinceName = param.provinceName
        item.districtName = param.districtName
        item.subDistrictName = param.subdistrictName
        return item;
    }
}
