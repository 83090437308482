import { Component, OnInit, Input } from '@angular/core';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorksheetGeneralViewModel, CommandModel } from 'src/app/core/view-models/_sprint3/worksheet/worksheet-model';
import { InboundTimeSelectViewModel } from 'src/app/core/view-models/_inbound/inbound.view-model';
import { InboundSharedService } from 'src/app/core/services/shared/inbound-shared.service';
import { NgbDateParserFormatter, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { MyParserFormatter } from 'src/app/shared/ngb/CustomDateFormatter';
import { DateUtils } from 'src/app/shared/utils/date-utils';

@Component({
  selector: 'shared-app-worksheet-command',
  templateUrl: './worksheet-command.component.html',
  styleUrls: ['./worksheet-command.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: MyParserFormatter },
    { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter }]
})
export class WorksheetCommandComponent implements OnInit {
  @Input() isReadOnly : boolean = false
  item: CommandModel = new CommandModel;
  commandValidate: FormGroup;
  submitted: boolean;
  minDate = DateUtils.getMinDate()
  hours: InboundTimeSelectViewModel[] = [];
  minutes: InboundTimeSelectViewModel[] = [];

  constructor(
    private fb: FormBuilder,
    private dialogService: MyDialogService,
    private _sharedService: InboundSharedService,
  ) { }

  ngOnInit(): void {
    this.commandValidate = this.fb.group({
      issue: [''],
      command: [''],
      recommend: [''], 
    });
    this.loadInitial();
  }
  async loadInitial() {
    this.hours = await this._sharedService.hours();
    this.minutes = await this._sharedService.minutes();
  }
  public async setInitial(item: CommandModel) {
    this.commandValidate.patchValue({
      issue: item.issue,
      command: item.command,
      recommend: item.recommend, 
    })
    // if (item.serviceDate == null) {
    //   item.serviceDate = new Date;
    // } else {
    // } 
  }

  public isValid(submit: boolean): boolean {
    this.item.issue = this.commandValidate.value.issue
    this.item.command = this.commandValidate.value.command
    this.item.recommend = this.commandValidate.value.recommend
   
    this.submitted = submit;
    if (this.submitted && !this.commandValidate.valid ) {
      this.dialogService.warningValidate();
      return false;
    }
    return true;
  } 
}
