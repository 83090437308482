import { Component, OnInit } from '@angular/core';
import { SettingCalloutEdcs } from 'src/app/core/view-models/_sprint3/_setting/setting-callout-edit-model';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalSettingStatusCalloutComponent } from '../_modal/modal-setting-status-callout/modal-setting-status-callout.component';
import { ModalRemarkSharedComponent } from '../_modal/modal-remark-shared/modal-remark-shared.component';
import { WorkSheetViewEdcComponent } from '../../worksheet/cs/view/view-edc/view-edc.component';
 

@Component({
  selector: 'app-view-shared-serial-number-list-view',
  templateUrl: './view-shared-serial-number-list-view.component.html',
  styleUrls: ['./view-shared-serial-number-list-view.component.scss']
})
export class ViewSharedSerialNumberListViewComponent implements OnInit {
  items: SettingCalloutEdcs[] = [] ; 
  submitted:boolean;
  constructor(
    private dialogService: MyDialogService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }
  async loadInitial(data:SettingCalloutEdcs[]) {   
    this.items = data
  }
  onRemarkClick(item:SettingCalloutEdcs) {
    const modalRef = this.modalService.open(ModalRemarkSharedComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xl'
    });
    modalRef.componentInstance.remark = item.remark;
  }
  OnViewClick(item: SettingCalloutEdcs) {
    console.log(item);
    const modalRef = this.modalService.open(WorkSheetViewEdcComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'width70'
    });
    modalRef.componentInstance.edcId = item.edcId;
  }
}
