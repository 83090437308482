import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderEdcMainPatitionViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { orderConst } from 'src/app/core/_static/order.const';

@Component({
  selector: 'app-order-shared-modal-partition-detail-modal',
  templateUrl: './partition-detail-modal.component.html',
  styleUrls: ['./partition-detail-modal.component.scss']
})
export class AppOrderSharedPartitionDetailModalComponent implements OnInit {
  @Input()
  item: OrderEdcMainPatitionViewModel = new OrderEdcMainPatitionViewModel();
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }
  get orderBankHostType() {
    return orderConst.bankHostType;
  }
}
