import { UpdateStatus } from 'src/app/core/models/_sprint3/store/work-area.model';

 
  export class LoaderTaskInformationViewModel{  
    id:number;
    authrorize:LoaderAuthrorize;
    info:LoaderTaskInformationListModel;
    checkList:CheckList[];
    remark:string = '';
    currentStatus:Status;
    updateStatus:UpdateStatus;
     
  }
  export class LoaderAuthrorize{  
    canSave:boolean;
    canSubmit:boolean;
    canRejectStore:boolean;
  }
  export class CheckList{ 
    id: number;
    canCheck: boolean;
    isChecked: boolean;
    topicName: string;
    detail: string;
  }
  export class LoaderTaskInformationListModel {
    froms: State[]; 
    command: Status;
    state: Status;
    status: Status;
    workType: WorkType;
    subType: Status;
    nextTos: State[]; 
    edcId:number;
    serialNumber: string;
    materialTypeName: string;
    tid: string;
    worksheetId:number;
    sapMaterialId:number;
    worksheetNo: string;
    startDateTime: Date;
    endDateTime: Date;
    modelName: string;
    box: string;
    location: string;
    actualDateTime: Date;
    taskOwnerName: string;
    taskResponsibleName: string; 
    quantity: string; 
    measure: string; 
    requestRemark: string;   
  }
  export class WorkType{  
    id:number;
    name:string;
    description:string;
  }
  export class State{  
    state:number;
    name:string;
  }
  export class Status{  
    id:number;
    name:string;
  } 