import { Component, OnInit, ViewContainerRef } from '@angular/core';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { UserDashboardService } from 'src/app/core/services/dashboard/user-dashboard.service';
import { DashboardCSOrderViewModel, DashboadStorePieFilterModel } from 'src/app/core/models/_shared/dashboard.model';

@Component({
  selector: 'app-store-pie-dashboard',
  templateUrl: './store-pie-dashboard.component.html',
  styleUrls: ['./store-pie-dashboard.component.scss']
})
export class StorePieDashboardComponent implements OnInit {

  data: DashboardCSOrderViewModel[] = [];
  filter: DashboadStorePieFilterModel = new DashboadStorePieFilterModel()
  url:any;

  constructor(
    public chartService: UserDashboardService,
    public viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    var chart = am4core.create("storePieChart", am4charts.PieChart);
    console.log(this.url)
    // Add data
    chart.data = [{
      "country": "Lithuania",
      "litres": 501.9,
      "url":"https://en.wikipedia.org/wiki/"
    }, {
      "country": "Czechia",
      "litres": 301.9,
      "url":"https://www.amcharts.com/docs/v4/tutorials/category/pie/page/2/"
    }, {
      "country": "Ireland",
      "litres": 201.1,
      "url":"http://api.hitech-feature.unigainfo.com/docs/index.html#/ManpowerPlanning/SummaryManpowerPlanning"
    }, {
      "country": "Germany",
      "litres": 165.8,
      "url":"http://api.hitech-feature.unigainfo.com/docs/index.html#/ManpowerPlanning/SummaryManpowerPlanning"
    }, {
      "country": "Australia",
      "litres": 139.9,
      "url":"http://api.hitech-feature.unigainfo.com/docs/index.html#/ManpowerPlanning/SummaryManpowerPlanning"
    }, {
      "country": "Austria",
      "litres": 128.3,
      "url":"http://api.hitech-feature.unigainfo.com/docs/index.html#/ManpowerPlanning/SummaryManpowerPlanning"
    }, {
      "country": "UK",
      "litres": 99,
      "url":"https://www.amcharts.com/docs/v4/getting-started/"
    }, {
      "country": "Belgium",
      "litres": 60,
      "url":"http://localhost:4200/u/dashboard"
    }, {
      "country": "The Netherlands",
      "litres": 50,
      "url":"https://www.youtube.com/watch?v=Gq9XTcnag_A"
    }];


    function setDateChart(){
      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "litres";
      pieSeries.dataFields.category = "country";

      // Set up tooltips
      // pieSeries.tooltip.label.interactionsEnabled = true;
      // pieSeries.tooltip.keepTargetHover = true;
      // pieSeries.slices.template.tooltipHTML = '<b>{category}</b><br><a href="{url}">More info</a>';

      //click Event
      pieSeries.slices.template.events.on('hit',ev => {
        // const a = "https://en.wikipedia.org/wiki/"
        // var win = window.open(a, '_blank');
      },this)
    }
    setDateChart()
 }


}
