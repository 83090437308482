

<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{ '_SHARED.SHOP_DETAIL' | translate }}

            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <form class="kt-form">
            <div class="row"> 
                <!-- <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.ORDER_NO' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{item.orderNo}}</span> <br>
                    </div>
                </div> 
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.ORDER_DATE' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{item.orderDate | date:'dd/MM/yyyy'}} </span> <br>
                    </div>
                </div> -->
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.SHOP_CODE' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.shopCode}} </span> <br>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.SHOP_NAME_TH' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.shopNameTH}} </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.SHOP_NAME_EN' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.shopNameEN}} </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.ADDRESS' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.address}} </span>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.PROVINCE' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.provinceName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.DISTRICT' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.districtName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.SUB_DISTRICT' | translate }} : </label>  
                       
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.subdistrictName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.POST_CODE' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.zipCode}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.CONTACT_NAME' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.contactName}} </span>
                    </div> 
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.CONTACT_PHONE_NUMBER' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.contactPhoneNumber}} </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.CONTACT_FAX_NUMBER' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.contactFaxNumber}} </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{ '_SHARED.EMAIL' | translate }} : </label>
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.email}}</span>
                    </div>
                </div>

            </div>


        </form>
    </div>
</div>