<div class="modal-header">
    <h4 class="modal-title">{{'_SHARED.EDC_DETAIL' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-body-custom2">
    <div class="kt-form kt-form--label-right">
        <div class="kt-form__body">
            <app-view-edc-content-body #content [edcId]="edcId"></app-view-edc-content-body>         
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-dark"
    (click)="activeModal.dismiss()">{{'ORDER.WORKSHEET.CLOSE'|translate}}</button>
</div>