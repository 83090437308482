import { Component, OnInit } from '@angular/core';
import { statusConst } from 'src/app/core/_static/status.const';
import { LocationEdcItemViewModel, LocationEdcConnectorViewModel } from 'src/app/core/models/user/help-desk/worksheet-model';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { HdcWorksheetService } from 'src/app/core/services/helpDesk/worksheet.service';

@Component({
  selector: 'app-work-type-modal-view',
  templateUrl: './work-type-modal-view.component.html',
  styleUrls: ['./work-type-modal-view.component.scss']
})
export class WorkTypeModalViewComponent implements OnInit {
  item: LocationEdcItemViewModel = new LocationEdcItemViewModel();
  submit: boolean;
  onSaved: Subject<LocationEdcItemViewModel> = new Subject();
  formWorkType: FormGroup;
  connectors: LocationEdcConnectorViewModel[] = [];
  id: number;
  worksheetId: number;
  constructor(
    public activeModalClose: NgbActiveModal,
    private dialogService: MyDialogService,
    private _service: HdcWorksheetService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formWorkType = this.fb.group({
      workTypeId: [null, [Validators.required, Validators.min(1)]],
      remark: [this.item.remark]
    });

    if (this.item.edcServiceWorkType != null) this.formWorkType.patchValue({ workTypeId: this.item.edcServiceWorkType.id });
    this.loadInitial();
  }
  async loadInitial() {
    this.connectors = await this._service.connectors(this.id, this.worksheetId, this.item.edcId);
    this.connectors.forEach(connector => {
      const item = this.item.connectors.find(x => x.connectorId == connector.connectorId)
      if (item != null) {
        connector.connector.isActive = true;
      } else {
        connector.connector.isActive = false;
      }
      connector.remark = item?.remark;
    });
  }

  get f() { return this.formWorkType.controls; }

  get workTypeStatus() {
    return statusConst.workType;
  } 
}
