import { Injectable } from '@angular/core';
import { ApiService } from '../../http/api.service';
import { SourceViewModel, InputTypeViewModel, TopicHeaderViewModel, InboundBankHostViewModel, InboundBankHostCriteriaModel, InboundTimeSelectViewModel,returnDetailModel } from '../../view-models/_inbound/inbound.view-model';
import { SourceModel, InputTypeModel, TopicHeaderModel } from '../../models/_shared/inbound-shared.model';
import { InboundSourceMapper } from '../../models-mapper/help-desk/inbound-source.mapper';
import { InboundInputTypeMapper } from '../../models-mapper/help-desk/inbound-input-type.mapper';
import { InboundTopicHeaderMapper } from '../../models-mapper/help-desk/inbound-topic-header.mapper';
import { InboundSharedMockService } from '../_mock/_helpDesk/inbound-shared.mock.service';
import { PageRequestModel, PageResponseModel } from '../../models/_shared/page.model';
import { InboundBankHostSearchApiModel, ResponsibleApiModel } from '../../models/_inbound/onsite-info-apis-model';
import { InboundBankHostSearchMapper } from '../../models-mapper/help-desk/inbound-bank-host-search.mapper';
import { AssignResponsible } from '../../models-mapper/help-desk/assign-responsible.mapper';
import { ResponsibleModel } from '../../models/user/help-desk/inbound-t1-model';

@Injectable({
    providedIn: 'root'
})
export class InboundSharedService {


    constructor(
        private apiService: ApiService,
        private _mock: InboundSharedMockService,
        private _sourceMapper: InboundSourceMapper,
        private _inputTypeMapper: InboundInputTypeMapper,
        private _topicHeaderMapper: InboundTopicHeaderMapper,
        private _bankHostMapper: InboundBankHostSearchMapper,
        private _responsibleMapper: AssignResponsible
    ) { }

    async sources(): Promise<SourceViewModel[]> {
        // return this._mock.sources();
        const result = await this.apiService.get<SourceModel[]>(`/commons/inbound/sources`);
        return result.map(x => this._sourceMapper.mapForm(x));
    }

    async inputTypes(): Promise<InputTypeViewModel[]> {
        //  return this._mock.inputTypes();
        const result = await this.apiService.get<InputTypeModel[]>(`/commons/inbound/channels`);
        return result.map(x => this._inputTypeMapper.mapForm(x));
    }

    async topicHeaders(parent: number): Promise<TopicHeaderViewModel[]> {
        //  return this._mock.topicHeaders(parent);
        const result = await this.apiService.get<TopicHeaderModel[]>(`/commons/inbound/topics/${parent}/childs`);
        return result.map(x => this._topicHeaderMapper.mapForm(x));
    }

    async externalBankHosts(request: PageRequestModel<InboundBankHostCriteriaModel>): Promise<PageResponseModel<InboundBankHostViewModel>> {
        const result = await this.apiService.post<PageResponseModel<InboundBankHostSearchApiModel>>(`/bank-hosts/search`, request);
        const response = PageResponseModel.transform<InboundBankHostViewModel>(result);
        response.items = result.items.map(x => this._bankHostMapper.mapForm(x));
        return response;
    }

    async hours(): Promise<InboundTimeSelectViewModel[]> {
        return await this.apiService.get<InboundTimeSelectViewModel[]>(`/commons/hours`);
    }
    async minutes(): Promise<InboundTimeSelectViewModel[]> {
        return await this.apiService.get<InboundTimeSelectViewModel[]>(`/commons/minutes`);
    }

    async returnDetail(model:returnDetailModel) : Promise<returnDetailModel>{
        return  
    }
    // async getById(id: number): Promise<TopciHeadDetailApisModel> {
    //     const result = await this.apiService.get<TopciHeadDetailApisModel>('/inbound/topics/' + id);
    //     return result;
    // }
    async  responsibles(): Promise<ResponsibleModel[]> {
        const result = await this.apiService.get<ResponsibleApiModel[]>(`/commons/employee/HDC-T2`);
        return result.map(x => this._responsibleMapper.mapForm(x));
    }
}