import { Injectable } from '@angular/core';
import { ApiService } from '../http/api.service';
import { ApiPath } from '../http/api-path';
import { ProvinceModel, DistrictModel, SubdistrictModel, ClientGroupModel, CustomerModel } from '../models/_shared/fix.model';
import { FileModel } from '../models/_shared/file.model';
import { RoleModel, RolesModel, PositionsModel } from '../models/_shared/role.model';
import { StatusViewModel } from '../view-models/_shared/status.view-model';
import { HourViewModel, InboundEdcStatusModel, ResponsiblesModel } from '../view-models/_shared/common.view-model';
import { InboundTopicHeaderModel } from '../view-models/_shared/inboundHeaderTopic-model';
import { ModelGroupModel } from '../models/master/edc-model/edc-model.model';
import { ResponsibleModel, CreateAssignResponse } from '../models/user/help-desk/inbound-t1-model';
import { CreateAssignResponseOutbound, ResponsibleOutboundModel } from '../models/user/help-desk/outbound-model';
import { StatusTypeInbound, Status } from '../models/user/help-desk/call-history-model';
import { SourceModel } from '../models/_shared/inbound-shared.model';
import { FilterListModal, FilterTeleportModel } from 'src/app/features/user/shared-sp3/filter-tpl/filter-tpl.component';
import { EdcModel } from '../models/_shared/edc-model.model';
import { SimStatus } from '../models/_shared/sim-status.model';
import { Provider } from '../models/_shared/provider';
import { WorkTypeListModel } from '../view-models/_sprint3/store/work-area-worksheet.model';
import { SetTypeModel } from '../view-models/_sprint3/_shared/set-type-model';
import { ReprogramCase } from '../view-models/_sprint3/_shared/reprogram-case';
import { CurrentStatus } from '../view-models/_sprint3/_shared/change-serial-number-view-model';
import { AssignResponsible } from '../models-mapper/help-desk/assign-responsible.mapper';
import { ResponsibleApiModel } from '../models/_sprint3/support-apis-model';
import { SaveAssignResponse, TPLListModal, SaveChangeTplModel } from '../models/user/worksheet/worksheet-store';
import { TakeReturnEdcStatus } from '../models/_sprint3/store/take-return-api-view-model';
import { StockControlLocationEdcView } from '../view-models/_sprint3/store/stock-control-view-information-view-model';
import { customerSelectModel } from '../models/user/customer-select/customer-select';
import { FilterMapper } from '../models-mapper/_sprint3/report/filter-mapper';
import { Districts } from 'src/app/features/user/shared-sp3/filter-select-search/filter-select-search.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private apiService: ApiService,
    private _responseMapper: AssignResponsible,
    private filterMapper: FilterMapper
  ) { }

  async uploadFile(file: File): Promise<FileModel> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileType', '1');
    const fileApiModel = await this.apiService.post<FileModel>(`${ApiPath.uploads.uploadFile}`, formData);
    let fileModel: FileModel = null;
    // if(fileApiModel){
    //   fileModel = this.mapper.mapFrom(fileApiModel);
    // }
    return fileApiModel;
  }
  async getAllRole(): Promise<Array<RoleModel>> {
    const result = await this.apiService.get<Array<RoleModel>>(`${ApiPath.common.base}/employee-roles`);
    return result;
  }

  async getAllRoles(): Promise<Array<RolesModel>> {
    const result = await this.apiService.get<Array<RolesModel>>(`/roles`);
    return result;
  }
  async getAllPositions(): Promise<Array<PositionsModel>> {
    const result = await this.apiService.get<Array<PositionsModel>>(`/commons/employee-positions`);
    return result;
  }
  async getAllClientGroup(): Promise<Array<ClientGroupModel>> {
    const result = await this.apiService.get<Array<ClientGroupModel>>(`${ApiPath.clientGroup.base}`);
    return result;
  }

  async getAllCustomer(): Promise<Array<CustomerModel>> {
    const result = await this.apiService.get<Array<CustomerModel>>(`/customer`);
    return result;
  }

  async getAllProvinces(): Promise<Array<ProvinceModel>> {
    const result = await this.apiService.get<Array<ProvinceModel>>(`${ApiPath.common.base}/provinces`);
    return result;
  }
  async getAllModelGroup(): Promise<Array<ModelGroupModel>> {
    const result = await this.apiService.get<Array<ModelGroupModel>>(`/commons/edc-model/groups`);
    return result;
  }
  async getAllDistricts(provinceId: number): Promise<Array<DistrictModel>> {
    const result = await this.apiService.get<Array<DistrictModel>>(`${ApiPath.common.base}/provinces/${provinceId}/districts`);
    return result;
  }
  async getAllDistrictsList(): Promise<Array<Districts>> {
    const result = await this.apiService.get<Array<Districts>>(`${ApiPath.common.base}/districts`);
    return result;
  }
  async getAllProvincesList(): Promise<Array<Districts>> {
    const result = await this.apiService.get<Array<Districts>>(`${ApiPath.common.base}/provinces`);
    return result;
  }
  async getAllSubdistricts(provinceId: number, districtId: number): Promise<Array<SubdistrictModel>> {
    const result = await this.apiService.get<Array<SubdistrictModel>>
      (`${ApiPath.common.base}/provinces/${provinceId}/districts/${districtId}/sub-districts`);
    return result;
  }

  async getWorksheetOpUpdateStatusList(): Promise<Array<StatusViewModel>> {
    const result = await this.apiService.get<Array<StatusViewModel>>
      (`/commons/status/worksheet-op-update`);
    return result;
  }

  async getAllOperationHour(): Promise<Array<HourViewModel>> {
    return await this.apiService.get<HourViewModel[]>(`/commons/hours`);

  }
  async getAllOperationMinute(): Promise<Array<HourViewModel>> {
    return await this.apiService.get<HourViewModel[]>(`/commons/minutes`);
  }
  async getInboundTopic(id: number): Promise<InboundTopicHeaderModel[]> {
    const result = await this.apiService.get<InboundTopicHeaderModel[]>('/commons/inbound/topics/' + id + '/childs')
    return result
  }

  async getResponse(request: CreateAssignResponse, id: number): Promise<ResponsibleModel> {
    const result = await this.apiService.patch<ResponsibleModel>(`/inbounds/${id}/responsibles`, request)
    return result
  }
  async getOutboundResponse(request: CreateAssignResponseOutbound, id: number): Promise<ResponsibleOutboundModel> {
    const result = await this.apiService.patch<ResponsibleOutboundModel>(`/outbounds/${id}/responsibles`, request)
    return result
  }

  async getEdcStatus(): Promise<InboundEdcStatusModel[]> {
    const result = await this.apiService.get<InboundEdcStatusModel[]>(`/commons/inbound/edc-statuses`)
    return result
  }
  async getStatusInbound(): Promise<StatusTypeInbound[]> {
    const result = await this.apiService.get<StatusTypeInbound[]>(`/commons/inbound/statuses`)
    return result
  }
  async getSource(): Promise<SourceModel[]> {
    const result = await this.apiService.get<SourceModel[]>(`/commons/inbound/sources`)
    return result
  }

  async getFilterEdcList(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/status/edc')
    return result
  }
  async getFilterSimStatus(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/status/sim')
    return result
  }
  async getFilterPeripheralsStatus(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/status/connector-inventory')
    return result
  }
  async getFilterTeleportList(): Promise<FilterListModal[]> {
    const result = await this.apiService.post<FilterListModal[]>('/teleport-site')
    return result
  }
  async getFilterSimList(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/status/sim')
    return result
  }
  async getFilterWorkType(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/worksheet/types')
    return result
  }
  async getFilterReportWorkType(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/cash-reports/bbl/commons/work-types')
    return result
  }
  async getFilterTechnicianGroup(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/technician-group')
    return result
  }
  async getFilterZone(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/shop-zones')
    return result
  }
  async getFilterClientGroups(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/client-groups')
    return result
  }
  async getFilterProvince(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<ProvinceModel[]>(`${ApiPath.common.base}/provinces`)
    return result.map(map => this.filterMapper.mapForm(map));
  }
  async getFilterLocations(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/shop-locations`)
    return result;
  }
  async getFilterSubType(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/worksheet/sub-types')
    return result;
  }
  async getFilterState(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/action-states')
    return result
  }
  async getFilterStatus(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/status/task')
    return result
  }
  async getFilterSettingStatus(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/setting-callout/status')
    return result
  }
  async getFilterWorksheet(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/worksheet/status')
    return result
  }

  async getFilterModel(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>('/commons/edc-models')
    return result
  }
  async getFilterCustomer(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/customer`);
    return result;
  }
  async getFilterPostpone(): Promise<FilterListModal[]> {
    return [{
      code: '0',
      id: 0,
      isCheck: null,
      name: "N"
    }, {
      code: '1',
      id: 1,
      isCheck: null,
      name: "Y"
    }
    ];
  }
  async getFilterCommand(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/commons/work-area/command/store`);
    return result;
  }

  async getFilterImportCustomer(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/order/imports/customers`);
    return result;
  }
  async getFilterPeripheralModelStatus(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/edc-connectors`);
    return result;
  }
  async getFilterBankHosts(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/bank-hosts`);
    return result;
  }
  async getFilterEdcFeatures(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/edc-features`);
    return result;
  }
  async getFilterFunctions(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/bank-host-functions`);
    return result;
  }
  async getFilterCommunications(): Promise<FilterListModal[]> {
    const result = await this.apiService.get<FilterListModal[]>(`/commons/edc-communications`);
    return result;
  }

  async getEdcModel(): Promise<EdcModel[]> {
    const result = await this.apiService.get<EdcModel[]>('/commons/edc-models')
    return result
  }
  async getSimStatusModel(): Promise<SimStatus[]> {
    const result = await this.apiService.get<SimStatus[]>('/commons/status/sim')
    return result
  }
  async getProviderModel(): Promise<Provider[]> {
    const result = await this.apiService.get<Provider[]>('/commons/mobile-providers')
    return result
  }

  async geWorkTypes(): Promise<WorkTypeListModel[]> {
    const result = await this.apiService.get<WorkTypeListModel[]>(`/commons/worksheet/types`)
    return result
  }
  async geworksheetWorkTypes(): Promise<WorkTypeListModel[]> {
    const result = await this.apiService.get<WorkTypeListModel[]>(`/commons/worksheet/create-types`)
    return result
  }
  async geGeneralWorkTypes(): Promise<WorkTypeListModel[]> {
    const result = await this.apiService.get<WorkTypeListModel[]>(`/commons/worksheet/general-types`)
    return result
  }

  async getSetTypes(): Promise<SetTypeModel[]> {
    const result = await this.apiService.get<SetTypeModel[]>(`/commons/reprogram-types`)
    return result
  }
  async getReprogramCase(): Promise<ReprogramCase[]> {
    const result = await this.apiService.get<ReprogramCase[]>(`/commons/reprogram-cases`)
    return result
  }

  async getSettingCalloutEdc(): Promise<Status[]> {
    const result = await this.apiService.get<Status[]>(`/commons/setting-callout/edc`)
    return result
  }

  async getEdcOnsite(): Promise<Status[]> {
    const result = await this.apiService.get<Status[]>(`/commons/worksheet/edc-onsite`)
    return result
  }

  async getEdcList(): Promise<CurrentStatus[]> {
    const result = await this.apiService.get<CurrentStatus[]>('/commons/status/edc')
    return result
  }
  async worksheetChangeResponse(id: number): Promise<ResponsibleModel[]> {
    const result = await this.apiService.get<ResponsibleModel[]>(`/work-area/worksheets/${id}/responsibles`);
    return result;
  }
  async seettingChangeResponse(id: number): Promise<ResponsibleModel[]> {
    const result = await this.apiService.get<ResponsibleModel[]>(`/setting/call-out/${id}/responsibles`);
    return result;
  }

  async edcChangeResponse(id: number): Promise<ResponsibleModel[]> {
    const result = await this.apiService.get<ResponsibleModel[]>(`/work-area/edcs/${id}/responsibles`);
    return result;
  }
  async peripheralChangeResponse(id: number): Promise<ResponsibleModel[]> {
    const result = await this.apiService.get<ResponsibleModel[]>(`/work-area/connecctors/${id}/responsibles`);
    return result;
  }
  async simChangeResponse(id: number): Promise<ResponsibleModel[]> {
    const result = await this.apiService.get<ResponsibleModel[]>(`/work-area/sims/${id}/responsibles`);
    return result;
  }
  async takeReturnEdcStatus(): Promise<TakeReturnEdcStatus[]> {
    const result = await this.apiService.get<TakeReturnEdcStatus[]>(`/commons/take-return/edc-status`);
    return result;
  }
  async seettingChangeResponseSave(request: SaveAssignResponse, id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/setting/call-out/${id}/responsibles`, request)
    return result
  }

  async worksheetChangeResponseSave(request: SaveAssignResponse, id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/work-area/worksheets/${id}/responsibles`, request)
    return result
  }
  async peripheralChangeResponseSave(request: SaveAssignResponse, id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/work-area/connecctors/${id}/responsibles`, request)
    return result
  }
  async edcChangeResponseSave(request: SaveAssignResponse, id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/work-area/edcs/${id}/responsibles`, request)
    return result
  }
  async simChangeResponseSave(request: SaveAssignResponse, id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/work-area/sims/${id}/responsibles`, request)
    return result
  }

  async getTPL(): Promise<TPLListModal[]> {
    const result = await this.apiService.post<TPLListModal[]>('/teleport-site')
    return result
  }
  async getFilterTPL(): Promise<FilterListModal[]> {
    const result = await this.apiService.post<FilterListModal[]>('/teleport-site')
    return result
  }
  async ChangeTplSave(request: SaveChangeTplModel, id: number): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/admin-upc/work-area/worksheets/${id}/change-teleport`, request)
    return result
  }

  async getLocation(): Promise<StockControlLocationEdcView[]> {
    const result = await this.apiService.get<StockControlLocationEdcView[]>('/inventory-locations')
    return result
  }
  async getstatusType(): Promise<Array<customerSelectModel>> {
    const result = await this.apiService.get<Array<customerSelectModel>>("/commons/hdc/inbound-status");
    return result;
  }

  async getEdcResponsibles(id: number): Promise<ResponsiblesModel[]> {
    const result = await this.apiService.get<ResponsiblesModel[]>('/work-area/edcs/' + id + '/responsibles')
    return result
  }
  async getPeripheralResponsibles(id: number): Promise<ResponsiblesModel[]> {
    const result = await this.apiService.get<ResponsiblesModel[]>('/work-area/connectors/' + id + '/responsibles')
    return result
  }
}
