<div class="modal-header">
    <h5 class="modal-title">{{'INBOUND_T2.ASSIGN_RESPONSIBLE' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form"> 
        <div class="row form-group">
            <div class="col-12">
                <label for="remark" class="form-control-label"><i class="required">*</i>
                    {{'INBOUND_T2.REMARK' | translate}}</label>
                <textarea class="form-control" rows="5" [placeholder]="'_SHARED.REMARK' | translate"
                    formControlName="remark" [ngClass]="{ 'is-invalid': submitted && f.remark.errors }"></textarea>
                <div *ngIf="submitted && f.remark.errors" class="invalid-feedback">
                    <div *ngIf="submitted && f.remark.errors.required">{{'FORM.REQUIRED' | translate}}</div>
                    <div *ngIf="submitted && f.remark.errors.cannotContainSpace">field can not contain space.</div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="col-lg-12 text-center">
        <button type="button" class="btn btn-primary waves-effect"
            (click)="onConfirmSave()">{{'INBOUND_T2.CONFIRM' | translate}}</button>&nbsp;
        <button type="button" class="btn btn-dark waves-effect"
            (click)="activeModal.dismiss()">{{'INBOUND_T2.CLOSE' | translate}}</button>
    </div>
</div>