<kt-expansion-panel>
    <kt-expansion-panel-header [canExpand]="false">
        <h3 class="kt-portlet__head-title"> {{'_SHARED.COMMAND' | translate }} </h3>
    </kt-expansion-panel-header>
    <form [formGroup]="commandValidate">
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"><label>{{'_SHARED.ISSUES' | translate }}</label><textarea rows="5" formControlName="issue"
                    [placeholder]="'_SHARED.ISSUES' | translate" [readonly]="isReadOnly"  class="form-control"></textarea></div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"><label>{{'_SHARED.WORK_COMMAND' | translate }}</label><textarea rows="5" formControlName="command"
                    [placeholder]="'_SHARED.WORK_COMMAND' | translate" [readonly]="isReadOnly" class="form-control"></textarea></div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group"><label>{{'_SHARED.RECOMMEND' | translate }}</label><textarea rows="5" formControlName="recommend"
                        placeholder="{{'_SHARED.RECOMMEND' | translate }}" [readonly]="isReadOnly" class="form-control"></textarea></div>
            </div>

        </div> 
    </form>
     

</kt-expansion-panel>