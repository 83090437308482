import { Injectable } from '@angular/core'; 
import { ProvinceModel } from 'src/app/core/models/_shared/fix.model';
import { WorkAreaLoaderperipheralApiViewModel } from 'src/app/core/models/_sprint3/loader/loader-api-model';
import { EdcListViewModel } from 'src/app/core/view-models/_sprint3/store/work-area-edc.model';
import { FilterListModal } from 'src/app/features/user/shared-sp3/filter-tpl/filter-tpl.component';
import { Mapper } from '../../mapper';

@Injectable({
    providedIn: "root",
  })
export class FilterMapper implements Mapper<ProvinceModel,FilterListModal> {
  constructor( 
    ) { }
  mapForm(param: ProvinceModel): FilterListModal {
    const item = new FilterListModal(); 
    item.code = '';
    item.id = param.id; 
    item.name = param.nameTH;
    return item;
  }
  mapTo(param: FilterListModal): ProvinceModel {
    const item = new ProvinceModel();
    return item;
  }
}
