import { Component, OnInit } from '@angular/core';
import { OrderEdcBankHostViewModel, OrderEdcBankHostTypeViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { OrderEdcMainPatitionViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { MyDialogService } from 'src/app/shared/utils/my-dialog';
import { BankHostViewService } from 'src/app/core/services/shared/bank-host.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-shared-readonly-single-shop',
  templateUrl: './shared-readonly-single-shop.component.html',
  styleUrls: ['./shared-readonly-single-shop.component.scss']
})
export class SharedUserReadonlySingleShopComponent implements OnInit {
  data: OrderEdcMainPatitionViewModel[] = [];
  bankHosts: OrderEdcBankHostViewModel[] = [];
  functions: OrderEdcBankHostTypeViewModel[] = [];
  isMain: number;
  terminalId: string;
  rows: number = 1;
  submit: boolean;

  constructor(
    private dialogService: MyDialogService,
    private _bankHostService: BankHostViewService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.loadInitial();
  }

  async loadInitial() {
    this.functions = await this._bankHostService.bankHostFunctions();
  }


  isValid(): boolean {
    return true;
  }
}
