export class DashboardInventoryViewModel {
    label: string;
    avaliable: number;
    service: number;
    inActive: number;
}

export class DashboardCSOrderViewModel {
    label: string;
    install: number;
    reprogram: number;
    remove: number;
}

export class DashboardCustomerOrderViewModel {
    label: string;
    install: number;
    reprogram: number;
    remove: number;
}


export class DashboardOPViewModel {
    label: string;
    install: number;
    reprogram: number;
    remove: number;
}

export class DashboardWorkSheetViewModel {
    label: string;
    save: number;
    storeProcessing: number;
    submit: number;
}

export class DashboadStorePieFilterModel {
    country: string;
    litres: number;
}
export class DashboadStoreXyFilterModel {
    country: string;
    litres: number;
}

export class DashboadStorePieModel {
    label: string; 
    available : number;
    permanentInstall: number;
    promotionInstall: number;
    service: number;
    reserve: number;
    postponeInstall : number;
    value : number;

} 