import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper'; 
import { WorksheetLocationViewModel } from 'src/app/core/view-models/_sprint3/_shared/worksheet-information-view-model';
import { ShopDetailInfoCloseJobApis } from 'src/app/core/models/_sprint3/admin-bkk/close-job-model';

@Injectable({
    providedIn: "root",
}) 
export class AdminBKKClosejobLocationMapper implements Mapper<ShopDetailInfoCloseJobApis, WorksheetLocationViewModel> {
    mapForm(param: ShopDetailInfoCloseJobApis): WorksheetLocationViewModel {
        const item = new WorksheetLocationViewModel(); 
          
        item.shopCode = param.shopCode
        item.shopNameTH= param.shopNameTH;
        item.shopNameEN= param.shopNameEN;
        item.address = param.address;
        item.province= param.provinceName;
        item.district= param.districtName;
        item.subDistrict= param.subdistrictName;
        item.postCode= Number(param.zipCode);
        item.contactName= param.contactName;
        item.contactPhoneNumber= param.contactPhoneNumber;
        item.contactFaxNumber= param.contactFaxNumber;
        item.email= param.email;
        item.reg = param.reg;
        item.locationDetail= param.location;
        return item;
    }
    mapTo(param: WorksheetLocationViewModel): ShopDetailInfoCloseJobApis {
        return null
    }
}
