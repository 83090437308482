import { Injectable } from '@angular/core';
import { ResponsibleModel } from '../../models/user/help-desk/inbound-t1-model';
import { Mapper } from '../mapper';
import { ResponsibleApiModel } from '../../models/_inbound/onsite-info-apis-model';

@Injectable({
    providedIn: "root",
  })
  
export class AssignResponsible implements Mapper<ResponsibleApiModel,ResponsibleModel> {
    mapForm(param: ResponsibleApiModel): ResponsibleModel {
        if(param == null) return null;
        const item = new ResponsibleModel();
        
        item.id = param.id;
        item.code = param.code;
        item.firstName = param.firstName;
        item.lastName= param.lastName;
        item.fullName= `${param.code} ${param.firstName} ${param.lastName}`;
        return item;
    };

    mapTo(param: ResponsibleModel): ResponsibleApiModel {
        throw new Error("Method not implemented.");
    }
}
