import { Injectable } from '@angular/core';
import { OrderEdcBankHostTypeViewModel, OrderEdcBankHostViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { bankHostConst } from './_const/bank-host.const';
import { bankHostFunctionConst } from './_const/bank-host-function.const';

@Injectable({
    providedIn: 'root'
})
export class BankHostMockService {

    async bankHosts(): Promise<Array<OrderEdcBankHostViewModel>> {
        const mockList = bankHostConst as Array<OrderEdcBankHostViewModel>;
        return mockList;
    }

    async bankHostFunctions(): Promise<Array<OrderEdcBankHostTypeViewModel>> {
        const mockList = bankHostFunctionConst as Array<OrderEdcBankHostTypeViewModel>;
        return mockList;
    }
}