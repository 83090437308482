export const featureAllConst = [
    {
        id: 1,
        name: 'LOCAL'
    },
    {
        id: 2,
        name: 'VISA'
    },
    {
        id: 3,
        name: 'M/C'
    },
    {
        id: 4,
        name: 'JCB'
    },
    {
        id: 5,
        name: 'SMALL TICKET'
    },  
    {
        id: 6,
        name: 'B-Scanner'
    },  
    {
        id: 7,
        name: 'PRP'
    },  
    {
        id: 8,
        name: 'DCC'
    },  
    {
        id: 9,
        name: 'Purchasing'
    }
];