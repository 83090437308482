export class FileModel{
    id: number;
    originalName: string;
    storeName: string;
    mimeType: string;
    sizeInBytes: number;
    type: number;
    url: string;

    getFileSizeInKB(){
        return this.sizeInBytes / 1000;
    }
}