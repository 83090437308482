<div class="modal-header">
    <h4 class="modal-title">{{'_SHARED.EDC_DETAIL' | translate }}</h4>
    <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body modal-body-custom2">
            <div class="kt-portlet kt-portlet--mobile kt-portlet--tabs">
                <div class="kt-portlet__head">
                    <div class="kt-portlet__head-toolbar">
                        <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-danger nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold"
                            role="tablist">
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:;" [ngClass]="{'active': detailType == tabType.Information}"
                                    (click)="focusTabDetailType(tabType.Information)">{{'STORE.STOCK_CONTROL.INFORMATION' | translate}} 
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="javascript:;"
                                    [ngClass]="{'active': detailType == tabType.timeline}"
                                    (click)="focusTabDetailType(tabType.timeline)">{{'STORE.STOCK_CONTROL.TIMELINE' | translate}}
                                
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <div class="tab-content">
                        <div class="tab-pane active" role="tabpanel">
                            <ng-template switch-tab></ng-template>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    <div class="modal-footer">
        <div class="col-lg-12 text-right">
            <button type="button" class="btn btn-dark waves-effect" (click)="activeModal.dismiss()">{{'_SHARED.CLOSE' | translate }}</button>
        </div>
    </div>