import { Injectable } from '@angular/core';
import { CallHistoryApiModel, CallHistoryModel } from '../../models/user/help-desk/call-history-model';
import { Mapper } from '../mapper';

@Injectable({
    providedIn: "root",
  })
  

  
export class CallHistoryModelMapper implements Mapper<CallHistoryApiModel,CallHistoryModel> {
     
    mapForm(param: CallHistoryApiModel): CallHistoryModel {
        // const item = new CallHistoryModel();
        return {
            ...param,
        };
}
    mapTo(param: CallHistoryModel): CallHistoryApiModel {
        return {
            ...param
        };
    }
}