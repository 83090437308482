import { Component, OnInit, ViewChild } from '@angular/core';
import { WorksheetOrderDetailViewSharedComponent } from '../../worksheet-order-detail-view-shared/worksheet-order-detail-view-shared.component';
import { WorksheetCommandViewSharedComponent } from 'src/app/features/user/shared-sp3/worksheet-command-view-shared/worksheet-command-view-shared.component';
import { WorksheetLocationViewSharedComponent } from 'src/app/features/user/shared-sp3/worksheet-location-view-shared/worksheet-location-view-shared.component';
import { WorksheetContactViewSharedComponent } from 'src/app/features/user/shared-sp3/worksheet-contact-view-shared/worksheet-contact-view-shared.component';
import { WorksheetRemarkViewSharedComponent } from 'src/app/features/user/shared-sp3/worksheet-remark-view-shared/worksheet-remark-view-shared.component';
import { AvailableEdc, WorkSheetUpdateDetail, ClientDetailListInWorksheet } from 'src/app/core/view-models/_shared/worksheet.view-model';
import { SearchTableModelWorkSheet } from 'src/app/core/models/user/worksheet/search-model-worksheet';
import { NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { WorksheetService } from 'src/app/core/services/worksheet/worksheet.service';
import { SharedWorksheetService } from 'src/app/core/services/_sprint3/worksheet/worksheet.service';
import { orderConst } from 'src/app/core/_static/order.const';
import { WorkSheetViewEdcComponent } from '../../../cs/view/view-edc/view-edc.component';
import { CaseEdcListComponent } from 'src/app/features/user/shared-user/case-edc-list/case-edc-list.component';
import { LocationViewComponent } from 'src/app/features/user/help-desk/shared/_modal/location-view/location-view.component';
import { WorksheetAllInformationViewModel } from 'src/app/core/view-models/_sprint3/admin-bkk/work-area-model';
import { AdminAllClosejobLocationMapper } from 'src/app/core/models-mapper/_sprint3/admin-bkk/close-job-all-location-mapper';
import { AdminAlllosejobCommandMapper } from 'src/app/core/models-mapper/_sprint3/admin-bkk/close-job-all-command-mapper';
import { AdminAllWorksheetContactMapper } from 'src/app/core/models-mapper/_sprint3/admin-bkk/close-job-all-contact.mapper';
import { AdminAllWorksheetShopShopMapper } from 'src/app/core/models-mapper/_sprint3/admin-bkk/close-job-all-shop-worksheet-mapper';
import { ModalTidHistoryComponent } from 'src/app/features/user/shared-sp3/_modal/modal-tid-history/modal-tid-history.component';
import { CallHistoryModalComponent } from 'src/app/features/user/help-desk/shared/_modal/call-history/call-history.component';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class WorksheetViewInformationComponent implements OnInit {

  // @ViewChild("searchShop", { static: true }) searchShop: WorksheetOrderDetailViewSharedComponent;
  // @ViewChild('command', { static: true }) command: WorksheetCommandViewSharedComponent;
  @ViewChild('location', { static: true }) location: WorksheetLocationViewSharedComponent;
  @ViewChild('contact', { static: true }) contact: WorksheetContactViewSharedComponent; 

  id: number;
  orderId: number; 
  dataSend: WorkSheetUpdateDetail = new WorkSheetUpdateDetail();
  selectList: any[] = [];
  shopDetail: ClientDetailListInWorksheet = new ClientDetailListInWorksheet();
  // remark: string; 
  isSentToStore: boolean;
  colSearchModel: SearchTableModelWorkSheet = new SearchTableModelWorkSheet();
  colSearchSelectedModel: SearchTableModelWorkSheet = new SearchTableModelWorkSheet();

  item: WorksheetAllInformationViewModel = new WorksheetAllInformationViewModel();
  constructor(
    private modalService: NgbModal,
    private worksheetService: WorksheetService,
    private sharedWorksheetService: SharedWorksheetService,
    private locationMapper: AdminAllClosejobLocationMapper,
    private commandMapper: AdminAlllosejobCommandMapper,
    private contactMapper: AdminAllWorksheetContactMapper,
    private shopMapper: AdminAllWorksheetShopShopMapper,
  ) {

  }

  ngOnInit(): void { 
    this.loadInitial( );
  }
  async loadInitial() { 
    this.location.setInitial(this.locationMapper.mapForm(this.item));
    this.contact.setInitial(this.contactMapper.mapTo(this.item));
    console.log(this.item);
    // this.command.setInitial(this.commandMapper.mapForm(this.item));
  
    // this.searchShop.setData(this.shopMapper.mapTo(this.item.shopDetailInfo));
    // this.searchShop.worksheetId = this.id;
    // this.searchShop.workTypeId = this.item.workType?.id;
    // this.orderHistoryList = this.item.availableEdcs; 
    
  }  
  onHistoryClick() { 
    const modalRef = this.modalService.open(CallHistoryModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'xxl'
    });
    modalRef.componentInstance.id = this.item.clientId
  } 
  OnViewClick(item: AvailableEdc) {
    const modalRef = this.modalService.open(WorkSheetViewEdcComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'width70'
    });
    modalRef.componentInstance.edcId = item.edcId;
  }

  onCaseClick(item: AvailableEdc) {
    const modalRef = this.modalService.open(CaseEdcListComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'md'
    });
    modalRef.componentInstance.edcId = item.edcId;
    modalRef.componentInstance.isReaonly = true;

  }
  openCreaateWorksheetModal(id: number) {

    const modalRef = this.modalService.open(LocationViewComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'xl'
    });
    modalRef.componentInstance.id = this.id;
    modalRef.componentInstance.worksheetId = id;
    modalRef.componentInstance.onSaved.subscribe(async item => {
      this.loadInitial();
    });
  }

  openTidHistory(tid: number) {

    const modalRef = this.modalService.open(ModalTidHistoryComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'xl'
    });
    modalRef.componentInstance.tid = tid;  
  }
}
