import { Injectable } from "@angular/core";
import { Mapper } from '../mapper'; 
import { CreateSettingCalloutViewModel } from '../../view-models/_sprint3/_setting/setting-callout-model'; 
import { MechanicModel, ContactModel, CommandModel } from '../../view-models/_sprint3/worksheet/worksheet-model';
import { SettingCalloutGetDetailApisModel } from '../../models/_sprint3/setting/setting-callout-model';
import { SettingCalloutGetDetailViewModel } from '../../view-models/_sprint3/_setting/setting-callout-edit-model';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
import { Client } from '../../view-models/_shared/worksheet.view-model';

@Injectable({
    providedIn: "root",
})
export class SettingCalloutLocationMapper implements Mapper<Client, SettingCalloutGetDetailViewModel>{
    constructor(
    ) { }
    mapForm(param: Client): SettingCalloutGetDetailViewModel {
        const item = new CreateSettingCalloutViewModel();
        return null
    }
    mapTo(param: SettingCalloutGetDetailViewModel): Client {
        const item = new Client();
        item.id = param.id; 
        item.code= null ;
        item.group= null ;
        item.branchName= '' ;
        item.nameTH= param.shopNameTH ;
        item.nameEN= param.shopNameEN ;
        item.address= param.shopAddress ;
        item.addressTH= param.shopAddress ;
        item.province= param.province ;
        item.district= param.district ;
        item.subDistrict= param.subdistrict ;
        item.zipCode= param.zipCode ;
        item.contactName= param.contactName ;
        item.contactPhoneNumber= param.contactPhoneNumber ;
        item.contactTel= param.contactPhoneNumber ;
        item.contactFaxNumber= param.contactFaxNumber ;
        item.email= param.contactEmail ;
        item.placeInLocation= '' ;
        item.location= param.location ;
        item.reg= param.reg ;
        item.bankPhoneNumber= '' ;
        item.bankName= '' ;
        item.provinceId= param.province?.id ;
        item.districtId= param.district?.id ;
        item.subdistrictId= param.subdistrict?.id ;
        item.posCode= param.zipCode ; 
        item.locationCode= '' ; 
        item.bankTel= '' ; 
        item.bankBranchName= '' ; 
        item.provinceName= param.province?.nameTH ; 
        item.districtName= param.district?.nameTH ; 
        item.subDistrictName= param.subdistrict?.nameTH ; 
        return item
    }

}