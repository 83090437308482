import { PageResponseModel } from "../../_shared/page.model";

export class InboundT1Model {
  id: number;
  canImportant: boolean;
  canView: boolean;
  canEdit: boolean;
  canAssign: boolean;
  canOverdue: boolean;
  actionState: number;
  callMode: number;
  tier: string;
  responsibleRemark: string;
  isImportant: boolean;
  ticketId: string;
  statusType: string;
  topicType: string;
  ticketDate: Date;
  startDateTime: Date;
  endDateTime: Date;
  usingTime: string;
  source: string;
  bank: string;
  contactName: string;
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  address: string;
  ticketOwner: string;
  responsible: string;
  remark: string;
}

export class SearchInboundT1Criteria {
  documentNo: string = "";
  statusName: string = "";
  topicName: string = "";
  ticketDateTime: string = "";
  startActionDateTime: string = "";
  finishActionDateTime: string = "";
  usingTime: string = "";
  sourceName: string = "";
  bankName: string = "";
  contactName: string = "";
  shopCode: string = "";
  shopNameTH: string = "";
  shopNameEN: string = "";
  shopAddress: string = "";
  ticketOwnerName: string = "";
  responsibleName: string = "";
  terminalId: string = "";
}

// ======================== API ========================

export class SearchInboundT1ApiCriteria {
  keyword: string = "";
  taskDate: Date = new Date();
  fromTaskDate : Date = new Date();
  toTaskDate : Date = new Date();
  isOverdue: boolean = false;
  isPending: boolean = true;
  isInProgress: boolean = true;
  isComplete: boolean = true;
  isCallIn: boolean = true;
  isCallOut: boolean = true;
  ticketId: string = "";
  statusId: number = 0;
  topicId: number = 0;
  sourceId: number = 0;
  bankId: number = 0;
  statusTypeId: number = 0;
  terminalId: string= "";
  shopCode: string= "";
  shopNameTH: string= "";
  shopNameEN: string= "";
  responsible: string= "";
  ticketOwner: string= "";
  contactName: string= "";
}

export class InboundT1ApiModel {
  id: number;
  canMarkImportant: boolean;
  canView: boolean;
  canEdit: boolean;
  canRevise: boolean;
  canChangeResponsible: boolean;
  isImportant: boolean;
  documentNo: string;
  callMode: number;
  tier: string;
  actionState: number;
  isOverdue: boolean;
  status:Status;
  statusName: string;
  topicName: string;
  ticketDateTime: Date;
  startActionDateTime: Date;
  finishActionDateTime: Date;
  usingTime: string;
  sourceName: string;
  bankName: string;
  contactName: string;
  shopCode: string;
  shopNameTH: string;
  shopNameEN: string;
  shopAddress: string;
  ticketOwnerName: string;
  responsibleName: string;
  responsibleRemark: string;
  remark: string;
  tier2ResponsibleName:string;
  tier2OwnerName:string;
  terminalId:string;
  provinceName:string;
  problem:string;
  solution:string;

  

}
export class Status {
  id:number;
  name:string;
}

export class ImportantShowModel {
  isImportant: boolean;
}

export class CreateInboundT1Model {
  inboundId: number;
}

export class TicketResponsibleApiModel {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  fullName:string;
}
export class ResponsibleModel {
  id: number;
  code: string;
  firstName: string;
  lastName: string;
  fullName:string;
}
export class CreateAssignResponse {
  responsibleId: number;
  remark: string;
}

export class SummariesModel {
  totalAll: number;
  totalOverdue: number;
  totalPending: number;
  totalInProgress: number;
  totalComplete: number;
  totalRemain: number;
}
export class InboundT1AllModel {
  summary: SummariesModel;
  pageResult: PageResponseModel<InboundT1ApiModel>;
}
