import { WorksheetGeneralViewModel, SimInformationViewModel } from '../worksheet/worksheet-model';
import { WorksheetPMViewModel } from '../worksheet/worksheet-pm-model';
import { WorksheetSupportViewModel } from '../worksheet/worksheet-support-model';
import { WorksheetCloseJobViewModel, WorksheetCloseJobSNListViewModel } from '../worksheet/worksheet-list-share.view-model';
import { CaseViewModel, Case } from '../../_order/order-install.view-model';


export const dataWroksheet = 
{
    id: 1,
    shop: null,
    location: null,
    remark: 'หหหหหหหหหหหหห',
    topicId: 0,
    command: {
        issue: '',
        command: '', 
        recommend: '', 
    },
    contact: { 
        commandFile: {
            attachFileId: 0,
            name: ''
        }
        , 
        contactName: '',
        contactPhone: null,
        serviceDate: null,
        serviceHour: null,
        serviceMinute: null,
    },
    mechanic: {
        detailOfMechanic: '',
        mechanicFileName: '',
        totalGallery: 10,
        mechanicGallery:
            [{
                id: 1,
                name: 'หมวดที่ 1',
                totalGallery: 4,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },

                ]
            },
            {
                id: 1,
                name: 'หมวดที่ 2',
                totalGallery: 6,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                ]
            },
            ]
    },
    sendToStatus: 1,
    inTernalStatus: 0,
}


export const dataWroksheetPM = 
{
    id: 1,
    eventTypeId: 1,
    shop: null,
    sentToStore:false,
    location: null,
    remark: 'หหหหหหหหหหหหห',
    command: {
        issue: '',
        command: '', 
        recommend: '', 
    },
    contact: {
       
        commandFile: {
            attachFileId: 0,
            name: ''
        },
        contactName: '',
        contactPhone: null,
        serviceDate: null,
        serviceHour: null,
        serviceMinute: null,
    },
    mechanic: {
        detailOfMechanic: '',
        mechanicFileName: '',
        totalGallery: 10,
        mechanicGallery:
            [{
                id: 1,
                name: 'หมวดที่ 1',
                totalGallery: 4,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },

                ]
            },
            {
                id: 1,
                name: 'หมวดที่ 2',
                totalGallery: 6,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                ]
            },
            ]
    },
    availableEdcs: [  ],
    selectedEdcs: [  ]



}

export const dataWroksheetSupport =
{
    id: 1,
    shop: null,
    location: null,
    remark: 'หหหหหหหหหหหหห',
    command: {
        issue: '',
        command: '', 
        recommend: '', 
    },
    contact: {
        
        commandFile: {
            attachFileId: 0,
            name: ''
        },
        contactName: '',
        contactPhone: null,
        serviceDate: null,
        serviceHour: null,
        serviceMinute: null,
    },
    mechanic: {
        detailOfMechanic: '',
        mechanicFileName: '',
        totalGallery: 10,
        mechanicGallery:
            [{
                id: 1,
                name: 'หมวดที่ 1',
                totalGallery: 4,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },

                ]
            },
            {
                id: 1,
                name: 'หมวดที่ 2',
                totalGallery: 6,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                ]
            },
            ]
    },
    availableEdcs: [
        
    ],
    selectedEdcs: [
         
    ], 
    referWorksheetInfo:null


}


export const dataWroksheetClosejob  =
{
    id: 1,
    worksheetInformation:
    {
        worksheetNo: '20IN-202003-0006',
        worksheetType: {
            id: 2,
            name: 'INSTALL'
        },
        worksheetDate: new Date,
        operationDate: new Date,
        assignBy: 'สมหวัง นามสมมุติ',
        technicianName: 'สมหวัง นามสมมุติ',
        status: {
            id: 3,
            name: 'COMPLETE'
        },
        finishDate: new Date,
        phoneNumber: '088-8888888'

    },
    shopDetail: {
        orderId: 1,
        orderNo: '1912-0021',
        orderDate: new Date,
        shopCode: 'SC-00',
        shopNameTH: 'เอ็น สปีด ออฟชั่น',
        shopNameEN: 'N-SPEEDS OPTION',
        address: '56 ซ.บรมราชชนนี72 ถ.บรมราชชนนี',
        province: 'กรุงเทพมหานคร',
        district: 'เขตทวีวัฒนา',
        subDistrict: 'ศาลาธรรมสพน์',
        postCode: 10170,
        contactName: 'Jason hills',
        contactPhoneNumber: '091-3333333',
        contactFaxNumber: '02-2200-222',
        email: 'ssmkdir@gmail.com',
    },
    location: {
        orderId: 1,
        orderNo: '1912-0021',
        orderDate: new Date,
        shopCode: 'SC-00',
        shopNameTH: 'เอ็น สปีด ออฟชั่น',
        shopNameEN: 'N-SPEEDS OPTION',
        address: '56 ซ.บรมราชชนนี72 ถ.บรมราชชนนี',
        province: 'กรุงเทพมหานคร',
        district: 'เขตทวีวัฒนา',
        subDistrict: 'ศาลาธรรมสพน์',
        postCode: 10170,
        contactName: 'Jason hills',
        contactPhoneNumber: '091-3333333',
        contactFaxNumber: '02-2200-222',
        email: 'ssmkdir@gmail.com',
        reg: '',
        locationDetail: '',
    },
    command: {
        issue: 'ปัญหาที่รับแจ้ง',
        command: 'คำสั่งงาน',
        commandFile : {
            attachFileId: 0,
            name:'word.doc'
        },
        recommend: 'แนะนำการแก้ไข',
        contactName: 'ชื่อบุคคลที่ติดต่อได้',
        contactPhone: '088-888-5555',
        serviceDate: new Date,
        serviceHour: 4,
        serviceMinute: 1,
    },
    mechanic: {
        detailOfMechanic: '',
        mechanicFileName: '',
        totalGallery: 10,
        mechanicGallery:
            [{
                id: 1,
                name: 'หมวดที่ 1',
                totalGallery: 4,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },

                ]
            },
            {
                id: 1,
                name: 'หมวดที่ 2',
                totalGallery: 6,
                gallerList: [
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                    {
                        id: 1,
                        name: '',
                        url: '../../assets/media/users/300_21.jpg',
                    },
                ]
            },
            ]
    },
    contactName: '',
    contactPhone: '088-8888888',
    serviceDate: new Date,
    serviceHour: null,
    serviceMinute: null,
    attachFile: null,

}
export const seriallistWorksheetConst: WorksheetCloseJobSNListViewModel[] =
    [
        {
            id: '1',
            status: {
                id: 1,
                name: 'ติดตั้งสำเร็จ'
            },
            serialNumber: '14058CT21550741',
            tid: '1',
            mid: '2',
            installDate: '15/1/2563',
            shopName: 'N-Speed_options',
            multhi: false,
            main: 'NacBank',
            backup: '-',
            remark: '',
        },
        {
            id: '2',
            status: {
                id: 2,
                name: 'ลูกค้าไม่รับเครื่อง'
            },
            serialNumber: '16168WL24558586',
            tid: '50427089',
            mid: '991009757',
            installDate: '15/1/2563',
            shopName: 'N-SPEEDS OPTION	',
            multhi: true,
            main: 'LAN',
            backup: 'GPS',
            remark: '',
        }
    ]


export const simData  =
{
    id: 1,
    information:
    {
        formName: 'TAKE',
        commandName: 'TAKE',
        stateName: 'DOING',
        statusName: 'Save ',
        workTypeName: 'INSTALL',
        workSubTypeName: 'PERMANANCE',
        tid: 988563335,
        type: 'EDC',
        serialNumber: '16216CT25714014',
        worksheetNo: 'PN2020-06-0002',
        startDate: null,
        EndDate: null,
        nextTo: 'LOADER',
        modalName: 'CT220GEM 2 G',
        box: 'B-01',
        location: 'CT220GEM 2 G',
        actualDate: null,
        taskOwnerName: 'สมหญิง นามสมมุติ',
        taskResponsible: 'สมหญิง นามสมมุติ',
    },

    status: {
        id: 1,
        name: 'ใช้งาน'
    },
    remark: '',
}

export const caseData: CaseViewModel =
{
    case: {
        id: 1,
        name: 'OnCall'
    },
    remark: ''
}

export const statusCaseData: Case[] = [
    {
        id: 1,
        name: 'Oncall'
    },
    {
        id: 1,
        name: 'Onsite'
    },
    {
        id: 1,
        name: 'Onsite item'
    },
    {
        id: 1,
        name: 'Install Update'
    }
]
