<div class="kt-portlet kt-portlet--mobile">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                {{'ORDER.SHARED.SHOP_DETAIL'|translate}}
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body">
        <form class="kt-form">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.SHOP_CODE'|translate}} : </label>  
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.code}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.SHOP_NAME(TH)'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.nameTH}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.SHOP_NAME(EN)'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.nameEN}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.ADDRESS'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.address}}</span>
                    </div>
                </div>

                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.PROVINCE'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.province?.nameTH}} </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.DISTRICT'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.district?.nameTH}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.SUB_DISTRICT'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.subDistrict?.nameTH}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.POSTCODE'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.posCode}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.CONTACT_NAME'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.contactName}} </span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.PHONE_CONTACT_NAME'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.contactPhoneNumber}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.CONTACT_FAX_NUMBER'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.contactFaxNumber}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.EMAIL'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.email}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.LOCATION'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop.branchName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.PLACE_IN/LOCATION/FLOOR'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop?.location}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.REG'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop?.reg}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.BANK_NAME'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop?.bankName}}</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group mb-0">
                        <label class="kt-font-primary">{{'ORDER.SHARED.BANK_PHONE'|translate}} : </label> 
                        <span class="kt-subheader__breadcrumbs-separator">{{shop?.bankPhoneNumber}}</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>