import { Injectable } from '@angular/core';  
import { ApiService } from 'src/app/core/http/api.service';  
import { taskSettingData } from 'src/app/core/view-models/_sprint3/_mock/packing-task-const';
import { SettingTaskInformationViewModel, UpdateSettingWorkareaEdc } from 'src/app/core/view-models/_sprint3/_setting/task-model';
 
import { SettingTaskInformationApisModel } from 'src/app/core/models/_sprint3/setting/setting-edc-view-model';
import { GetSettingEdcMapper } from 'src/app/core/models-mapper/_sprint3/store/work-area-edc-setting-mapper';
import { ResponsiblesSaveModel } from 'src/app/core/view-models/_shared/common.view-model';
 

@Injectable({
  providedIn: "root",
})
export class SettingTaskService {


  constructor(
    private apiService: ApiService, 
    private getSettingEdcMapper:GetSettingEdcMapper
  ) { }
 
  async getEdc(id:number): Promise<SettingTaskInformationViewModel> {
    const result = await this.apiService.get<SettingTaskInformationApisModel>(`/setting/work-area/edcs/${id}`);
    return this.getSettingEdcMapper.mapForm(result);
  }
  async getperipheral(id:number): Promise<SettingTaskInformationViewModel> {
    const result = await this.apiService.get<SettingTaskInformationApisModel>(`/setting/work-area/peripherals/${id}`);
    return this.getSettingEdcMapper.mapForm(result);
  }
 
  async saveEdc(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/setting/work-area/edcs/${id}`,updateRequest);
    return result
  }
  async submitEdc(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/setting/work-area/edcs/${id}/submit`,updateRequest);
    return result
  }

  async savePeripheral(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/setting/work-area/peripherals/${id}`,updateRequest);
    return result
  }
  async submitPeripheral(id:number,updateRequest:UpdateSettingWorkareaEdc): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/setting/work-area/peripherals/${id}/submit`,updateRequest);
    return result
  }

  // async get(): Promise<SettingTaskInformationViewModel> {
  //   const mockList = taskSettingData as SettingTaskInformationViewModel;
  //   return mockList;
  // }  

  async saveEdcResponsibles(id:number,updateRequest:ResponsiblesSaveModel): Promise<boolean> {
    const result = await this.apiService.patch<boolean>(`/setting/work-area/edcs/${id}/close-instead`,updateRequest);
    return result
  }

} 