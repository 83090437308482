<app-shared-view-task-information #taskInformation></app-shared-view-task-information>
<form [formGroup]="formValidate">
    <div class="row">
        <!-- <div class="col-sm-3">
          
            <div class="form-group mb-0">
                <label class="kt-font-primary"><i class="required">*</i> {{ '_SHARED.TACKING_NUMBER' | translate }} : </label>
                <input type="text" class="form-control" notThaiChar [placeholder]="'_SHARED.TACKING_NUMBER' | translate" disabled
                    formControlName="trackingNumber" [ngClass]="{ 'is-invalid': submit && f.trackingNumber.errors }">
                <div *ngIf="submit && f.trackingNumber.errors" class="invalid-feedback">
                    <div *ngIf="f.trackingNumber.errors.required" [translate]="'FORM.REQUIRED'">
                    </div>
                </div>
            </div>

        </div>  -->
        <div class="col-sm-3">
          
            <div class="form-group mb-0">
                <label class="kt-font-primary"><i class="required">*</i> {{ '_SHARED.CON_NUMBER' | translate }} : </label>
                <input type="text" class="form-control" notThaiChar [placeholder]="'_SHARED.CON_NUMBER' | translate" disabled
                [(ngModel)]="item.conNumber" [ngModelOptions]="{standalone: true}"  [ngClass]="{ 'is-invalid': submit && !item.conNumber }">
                <div *ngIf="submit && !item.conNumber" class="invalid-feedback">
                    <div *ngIf="!item.conNumber" [translate]="'FORM.REQUIRED'">
                    </div>
                </div>
            </div>

        </div>
    </div>
    <br>


    <!-- <div class="row">
        <div class="col-sm-3">
            <div class="form-group mb-0">
                <label class="kt-font-primary">{{ '_SHARED.CURRENT_STATUS' | translate }}: </label>
                <br>
                <span class="kt-subheader__breadcrumbs-separator"> {{item.currentStatus?.name}} </span>

            </div>
        </div>
        <div class="col-sm-9">
            <div class="form-group mb-0">
                <label class="kt-font-primary">{{ '_SHARED.UPDATE_STATUS' | translate }}: </label>
                <br>
                <span class="col-sm-7 kt-subheader__breadcrumbs-separator">{{item.updateStatus?.name}} </span>
            </div>
        </div>
    </div> -->
    <br>
    <div class="row">
        <div class="col-sm-12">
            <div class="form-group mb-0">
                <label class="kt-font-primary">{{ '_SHARED.REMARK' | translate }}: </label>
                <br>
                <textarea class="form-control" rows="5" formControlName="remark" disabled></textarea>
            </div>
        </div>
    </div>
</form>