import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: 'input[englishOnly]'
})
export class EnglishOnlyDirective {
  
  constructor(
    private _el: ElementRef
  ) { }

  // private regex: RegExp = new RegExp(/[0-9]+(\.[0-9]*){0,1}$/g);
  // private regex = new RegExp("^([A-Z]|[a-z]|[0-9]|[/]|[\\]|[ ]|[\n]|[.]|[ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ])+$", "g");

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;

    // this._el.nativeElement.value = initalValue.replace(/[^ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ]*$/i, '');
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9d*,.?;:'"!$%()+-@<>#&~\s]*$/i, '');
    // this._el.nativeElement.value = initalValue.replace(/[^ก-๛\s]+$/i, '');
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}