import { Component, OnInit, ViewChild, ComponentRef, ComponentFactoryResolver } from '@angular/core';
import { ShopViewModel } from 'src/app/core/view-models/_shop/shop.view-model';
import { OrderDetailEdcMulthiViewModel } from 'src/app/core/view-models/_order/order-install.view-model';
import { OrderEdcBankHostViewModel } from 'src/app/core/view-models/_order/order-share.view-model';
import { AppOrderSharedShopDetailMulthiComponent } from '../../../order/_shared/shop-detail-multhi/shop-detail-multhi.component';
import { SwitchTabDirective } from 'src/app/shared/directives/switch-tab.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppOrderSharedTabReaonlyPartitionComponent } from '../../../order/_shared/_tab/reaonly-partition/reaonly-partition.component';
import { SwitchTabComponent } from 'src/app/shared/component/switch-tab.component';
import { ReadonlyMulthiShopDetailComponent } from '../../../order/_shared/_tab/readonly-multhi-shop/modal/readonly-multhi-shop-detail/readonly-multhi-shop-detail.component';

@Component({
  selector: 'app-shared-readonly-multhi-shop',
  templateUrl: './shared-readonly-multhi-shop.component.html',
  styleUrls: ['./shared-readonly-multhi-shop.component.scss']
})
export class SharedUserReadonlyMulthiShopComponent implements OnInit {
  shopItem: ShopViewModel = new ShopViewModel();
  data: OrderDetailEdcMulthiViewModel[] = [];
  eventType: number;
  createState: number;
  bankHosts: OrderEdcBankHostViewModel[] = [];
 
  @ViewChild('shop', { static: true }) shop: AppOrderSharedShopDetailMulthiComponent;

  isValid(): boolean {
    return true;
  }
  submit: boolean;
  @ViewChild(SwitchTabDirective, { static: true }) switchTab: SwitchTabDirective;
  componentRef: ComponentRef<any>;
  constructor(
    private modalService: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

  ngOnInit(): void {
    //this.loadReadonlyPartition();
  }

  loadReadonlyPartition(){
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AppOrderSharedTabReaonlyPartitionComponent);

    const viewContainerRef = this.switchTab.viewContainerRef;
    viewContainerRef.clear();
 
    this.componentRef = viewContainerRef.createComponent(componentFactory);
    (<SwitchTabComponent>this.componentRef.instance).data = this.data;
  }
  async loadCallBackActionItem() {

  }

  onViewClick(partitions: OrderDetailEdcMulthiViewModel) {
    console.log(partitions);
    const modalRef = this.modalService.open(ReadonlyMulthiShopDetailComponent, {
      backdrop: "static",
      keyboard: false,
      size: 'xl',
      centered: true

    });
    modalRef.componentInstance.item = partitions;

  }
}
