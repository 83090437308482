import { Injectable } from '@angular/core';
import { Mapper } from '../mapper';
import { HdcWorksheetApiModel, HdcWorksheetModel } from '../../models/user/help-desk/worksheet-model';

@Injectable({
    providedIn: 'root'
})
export class HdcWorksheetModelMapper implements Mapper<HdcWorksheetApiModel,HdcWorksheetModel> {
    mapForm(param: HdcWorksheetApiModel): HdcWorksheetModel {
        return {
            ...param
        };
    }
    mapTo(param: HdcWorksheetModel): HdcWorksheetApiModel {
        return {
            ...param
        };
    }
}
