
import { Injectable } from '@angular/core';
import { Mapper } from '../../mapper';
import { InboundBankHostApiModel } from '../../../models/_inbound/onsite-info-apis-model';
import { InboundBankHostViewModel } from '../../../view-models/_inbound/inbound.view-model';
import { StockControlBankListViewModel } from '../../../view-models/_sprint3/store/stock-control.model';
import { StockControlBankListApisModel } from '../../../models/_sprint3/stock/stock-control.model';
import { StockControlSimApisModel } from 'src/app/core/models/_sprint3/stock/stock-control-sim';
import { StockControlSimViewModel } from 'src/app/core/view-models/_sprint3/store/stock-control-sim';

@Injectable({
    providedIn: 'root'
})
export class StockSimListMapper implements Mapper<StockControlSimApisModel, StockControlSimViewModel>{
    constructor(){}
    mapForm(param: StockControlSimApisModel): StockControlSimViewModel {
        return {
            simId:param.simId, 
            status:param.status,
            provider:param.providerName,
            serialNumber:param.serialNumber,
            phoneNumber:param.phoneNumber,
            edcSerialNumber:param.edcSerialNumber,
            tid:param.tid,
            mid:param.mid,
            shopName:param.shopName, 
            bankName:param.bankName, 
            isView:param.canView,
            isEdit:param.canEdit,
            isSetting:param.canSetting,
            sapMaterialId:param.sapMaterialId
        }; 
    }
    mapTo(param: StockControlSimViewModel): StockControlSimApisModel {
        return  null 
    }

}